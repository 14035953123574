import { Link } from "react-router-dom";
import moment from "moment";
import TableHeader from "../../0-ResuableComponents/tableComponents/TableHeader";

const headers = [
  { name: "Name", field: "name", sortable: true },
  { name: "Email", field: "email", sortable: true },
  { name: "Mobile", field: "mobile", sortable: false },
  { name: "Activated on", field: "activatedOn", sortable: false },
  { name: "State", field: "state", sortable: false },
  { name: "City", field: "city", sortable: false },
  { name: "Cluster", field: "cluster", sortable: false },
  { name: "Activity Score", field: "activityScore", sortable: false },
  { name: "Lifetime order value", field: "ltm", sortable: false },
  { name: "Total Orders", field: "totalOrders", sortable: false },
  { name: "Orders Cancelled", field: "ordersCancelled", sortable: false },
  { name: "Orders Delivered", field: "ordersDelivered", sortable: false },
  { name: "Pending", field: "pending", sortable: false },
];

// import { useState } from "react";
const ConsumerTable = ({ consumers, setSorting }) => {
  return (
    <div className="col-lg-12 col-md-11">
      <div className="all-cate-tags">
        <div className="row justify- content-between">
          <div className="col-lg-12 col-md-12">
            <div className="card card-static-2 mb-30">
              <div className="card-title-2">
                <h4>All Consumers</h4>
              </div>
              <div className="card-body-table">
                <div className="table-responsive">
                  <table className="table ucp-table table-hover">
                    <TableHeader
                      headers={headers}
                      onSorting={(field, order) => setSorting({ field, order })}
                    />
                    <tbody>
                      {consumers?.map((consumer, index) => (
                        <>
                          <tr key={index}>
                            <td>
                              <Link to={`/consumers/${consumer._id}`}>
                                {consumer.name || "-"}
                              </Link>
                            </td>
                            <td>{consumer.email || "-"}</td>
                            <td>{consumer.mobileNumber || "-"}</td>
                            {/* activated on */}

                            <td>
                              {moment(consumer.createdAt).format("lll") || "-"}
                            </td>
                            {/* state */}
                            <td>-</td>
                            {/* email */}
                            {/* city */}
                            <td>-</td>
                            {/* cluster */}
                            <td>-</td>
                            {/* Activity score */}
                            <td>-</td>
                            {/* Lifetime order value */}
                            <td>-</td>

                            <td>{consumer.orderCount || "0"}</td>
                            <td>{consumer.ordersCancelled || "0"}</td>
                            <td>{consumer.ordersDelivered || "0"}</td>
                            <td>{consumer.ordersPending || "0"}</td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConsumerTable;
