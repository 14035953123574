import { Modal } from "react-bootstrap";
import DeleteIcon from "@material-ui/icons/Delete";
import ServiceEntrepreneursTable from "./ServiceEntrepreneursTable";

const ServiceEntrepreneursForm = ({ serviceEntrepreneurs, setServiceEntrepreneurs, handleSubmit,
                                    currentId, show, handleClose, handleChange, handleImageChange }) => {
  const { name, contact, location, photoUrl, 
    gst, workingDays, timing, gender, 
    services, customers, orders } = serviceEntrepreneurs;
  
    return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton data-dismiss="modal">
        <h5 className="modal-title" id="exampleModalLabel">
          {currentId ? "Update Service Entrepreneurs" : "+Add Service Entrepreneurs"}
        </h5>
      </Modal.Header>
      <Modal.Body>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="card card-static-2 mb-30">
                <div className="card-body-table">
                  <div className="news-content-right pd-20">
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div
                          className="col-lg-12 col-md-11"
                          style={{ paddingTop: "1rem" }}
                        >
                          <div className="form-group">
                            <label htmlFor="name" className="form-label">
                            Service Entrepreneurs Name
                              <span className="required">&#42;</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Service Entrepreneurs Name"
                              name="name"
                              value={name}
                              onChange={handleChange}
                            />
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-5" style={{ paddingTop: "1rem" }}>
                          <div className="form-group">
                            <label htmlFor="type" className="form-label">
                              Contact Details
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Enter Contact Detail"
                              name="type"
                              value={contact}
                              onChange={handleChange} />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-5" style={{ paddingTop: "1rem" }}>
                          <div className="form-group">
                            <label htmlFor="location" className="form-label">
                              Location
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Location"
                              name="location"
                              value={location}
                              onChange={handleChange} />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-5" style={{ paddingTop: "1rem", position: "relative"}} >
                          <div className="form-group" style={{ position: "absolute" }}>
                            <label htmlFor="gst" className="form-label">
                              GST Number
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter GST Number"
                              name="gst"
                              value={gst}
                              onChange= {handleChange}
                            />
                          </div>
                          
                          {/* <div className="col-lg-4 col-md-4" style={{ position: "absolute", left: "52%", top: "300%",
                            }}
                          >
                            <select
                              name="volumeUnit"
                              id="status"
                              className="form-control"
                              onChange={(e) =>
                                setServiceEntrepreneurs({
                                  ...serviceEntrepreneurs,
                                })
                              }
                            >
                              <option value="ml">ml</option>
                              <option value="l">l</option>
                              <option value="kl">kl</option>
                            </select>
                          </div> */}
                        </div>

                        <div className="col-lg-6 col-md-5" style={{
                            paddingTop: "1rem",
                            position: "relative",
                          }}
                        >
                          <div className="form-group"
                            style={{ position: "absolute" }} >
                            <label htmlFor="timing" className="form-label">
                              Timing
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Timing"
                              name="timing"
                              value={timing}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        

                        <div
                          className="col-lg-6 col-md-6"
                          style={{ paddingTop: "1rem", marginTop: "4rem" }}
                        >
                          <div className="form-group">
                            <label htmlFor="photoUrl" className="form-label">
                              Image
                            </label>
                            {
                               (typeof photoUrl === 'string') && photoUrl !== "" && currentId !== 0  ? 
                               <div className="cate-img-5" >
                              <img src={`${photoUrl}`} alt="product_img" /> 
                              <DeleteIcon
                            style={{ cursor: "pointer",color:'red',marginLeft:'121px',marginTop:'20px' }}
                            onClick={() => {setServiceEntrepreneurs({...serviceEntrepreneurs,photoUrl:""})}}
                          />
                          </div> : <input
                              type="file"
                              className="form-control"
                              name="photoUrl"
                              onChange={handleImageChange}
                              accept="image/*" /> }
                          </div>
                        </div>
                        

                        <div
                          className="col-lg-6 col-md-6"
                          style={{ paddingTop: "1rem", marginTop: "4rem" }}
                        >
                          <div className="form-group">
                            <label htmlFor="price" className="form-label">
                              Enter Working Days
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              name="workingsDays"
                              placeholder="Enter your working Days"
                              value={workingDays}
                              onChange={handleChange} />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-4" style={{ paddingTop: "1rem" }}>
                        <div className="form-group">
                        <label htmlFor="gender" className="form-label">
                              Gender
                            </label>
                            <select
                              name="Gender"
                              id="status"
                              className="form-control"
                              onChange={(e) =>
                                setServiceEntrepreneurs({
                                  ...serviceEntrepreneurs,
                                  gender:e.target.value
                                })} >
                              <option value="male">Male</option>
                              <option value="female">Female</option>
                              <option value="other">Other</option>
                              </select>
 

                        </div>
                                                     
                          <button className="save-btn hover-btn" type="submit">
                            {currentId ? "Update" : "Add New"}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ServiceEntrepreneursForm;
