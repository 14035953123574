import { Modal } from "react-bootstrap";
import DeleteIcon from "@material-ui/icons/Delete";

const VariantForm = ({
  variant,
  setVariant,
  handleSubmit,
  handleImageChange,
  handleChange,
  currentId,
  show,
  handleClose,
}) => {
  const {
    name,
    type,
    quantity,
    volume,
    weight,
    price,
    purchaseQtyLimit,
    status,
    photoUrl,
  } = variant;
  const { mrp, min, max } = price;
  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton data-dismiss="modal">
        <h5 className="modal-title" id="exampleModalLabel">
          {currentId ? "Update Variant" : "+Add New Variant"}
        </h5>
      </Modal.Header>
      <Modal.Body>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="card card-static-2 mb-30">
                <div className="card-body-table">
                  <div className="news-content-right pd-20">
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div
                          className="col-lg-12 col-md-11"
                          style={{ paddingTop: "1rem" }}
                        >
                          <div className="form-group">
                            <label htmlFor="name" className="form-label">
                              Variant Name
                              <span className="required">&#42;</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Variant Name"
                              name="name"
                              // required
                              value={name}
                              onChange={handleChange}
                            />
                          </div>
                        </div>

                        <div
                          className="col-lg-6 col-md-5"
                          style={{ paddingTop: "1rem" }}
                        >
                          <div className="form-group">
                            <label htmlFor="type" className="form-label">
                              Enter Type (if applicable)
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter Type"
                              name="type"
                              value={type}
                              onChange={handleChange}
                            />
                          </div>
                        </div>

                        <div
                          className="col-lg-6 col-md-5"
                          style={{ paddingTop: "1rem" }}
                        >
                          <div className="form-group">
                            <label htmlFor="quantity" className="form-label">
                              Enter Quantity (Units) (if applicable)
                            </label>
                            <input
                              type="Number"
                              className="form-control"
                              placeholder="Enter Quantity (Units)"
                              name="quantity"
                              value={quantity}
                              onChange={handleChange}
                            />
                          </div>
                        </div>

                        <div
                          className="col-lg-6 col-md-5"
                          style={{
                            paddingTop: "1rem",
                            position: "relative",
                          }}
                        >
                          <div
                            className="form-group"
                            style={{ position: "absolute" }}
                          >
                            <label htmlFor="volumeValue" className="form-label">
                              Enter Volume (if applicable)
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Volume"
                              name="volumeValue"
                              value={volume.value}
                              onChange={(e) =>
                                setVariant({
                                  ...variant,
                                  volume: {
                                    ...variant.volume,
                                    value: e.target.value,
                                  },
                                })
                              }
                            />
                          </div>
                          <div
                            className="col-lg-4 col-md-4"
                            style={{
                              position: "absolute",
                              left: "52%",
                              top: "300%",
                            }}
                          >
                            <select
                              name="volumeUnit"
                              id="status"
                              className="form-control"
                              onChange={(e) =>
                                setVariant({
                                  ...variant,
                                  volume: {
                                    ...variant.volume,
                                    unit: e.target.value,
                                  },
                                })
                              }
                            >
                              <option value="ml">ml</option>
                              <option value="l">l</option>
                              <option value="kl">kl</option>
                            </select>
                          </div>
                        </div>

                        <div
                          className="col-lg-6 col-md-5"
                          style={{
                            paddingTop: "1rem",
                            position: "relative",
                          }}
                        >
                          <div
                            className="form-group"
                            style={{ position: "absolute" }}
                          >
                            <label htmlFor="weightValue" className="form-label">
                              Enter Weight (if applicable)
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Weight"
                              name="weightValue"
                              value={weight.value}
                              onChange={(e) =>
                                setVariant({
                                  ...variant,
                                  weight: {
                                    ...variant.weight,
                                    value: e.target.value,
                                  },
                                })
                              }
                            />
                          </div>
                          <div
                            className="col-lg-4 col-md-4"
                            style={{
                              position: "absolute",
                              left: "52%",
                              top: "300%",
                            }}
                          >
                            <select
                              name="weightUnit"
                              id="status"
                              className="form-control"
                              onChange={(e) =>
                                setVariant({
                                  ...variant,
                                  weight: {
                                    ...variant.weight,
                                    unit: e.target.value,
                                  },
                                })
                              }
                            >
                              <option value="mg">mg</option>
                              <option value="g">g</option>
                              <option value="kg">kg</option>
                            </select>
                          </div>
                        </div>

                        <div
                          className="col-lg-6 col-md-6"
                          style={{ paddingTop: "1rem", marginTop: "4rem" }}
                        >
                          <div className="form-group">
                            <label htmlFor="photoUrl" className="form-label">
                              Image
                            </label>

                            {
                               (typeof photoUrl === 'string') && photoUrl !== "" && currentId !== 0  ? 
                               <div className="cate-img-5" >
                              <img src={`${photoUrl}`} alt="product_img" /> 
                              <DeleteIcon
                            style={{ cursor: "pointer",color:'red',marginLeft:'121px',marginTop:'20px' }}
                            onClick={() => {setVariant({...variant,photoUrl:""})}}
                          />
                          </div>  :<input
                              type="file"
                              className="form-control"
                              name="photoUrl"
                              onChange={handleImageChange}
                              accept="image/*"
                            />
                            }
                            {/* <input
                              type="file"
                              className="form-control"
                              name="photoUrl"
                              onChange={handleImageChange}
                              accept="image/*"
                            />
                            <div className="cate-img-5">
                              {photoUrl && (
                                <img src={`${photoUrl}`} alt="variant_img" />
                              )}
                            </div> */}
                          </div>
                        </div>

                        <div
                          className="col-lg-6 col-md-6"
                          style={{ paddingTop: "1rem", marginTop: "4rem" }}
                        >
                          <div className="form-group">
                            <label htmlFor="price" className="form-label">
                              Price (MRP)
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              name="price"
                              placeholder="price"
                              value={mrp}
                              // required
                              onChange={(e) => {
                                setVariant({
                                  ...variant,
                                  price: {
                                    ...variant.price,
                                    mrp: parseInt(e.target.value),
                                  },
                                });
                              }}
                            />
                          </div>
                        </div>

                        <div
                          className="col-lg-6 col-md-6"
                          style={{ paddingTop: "1rem" }}
                        >
                          <div className="form-group">
                            <label htmlFor="min" className="form-label">
                              Minimum Price
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              name="min"
                              placeholder="Minimum Price"
                              value={min}
                              onChange={(e) =>
                                setVariant({
                                  ...variant,
                                  price: {
                                    ...variant.price,
                                    min: parseInt(e.target.value),
                                  },
                                })
                              }
                            />
                          </div>
                        </div>

                        <div
                          className="col-lg-6 col-md-6"
                          style={{ paddingTop: "1rem" }}
                        >
                          <div className="form-group">
                            <label htmlFor="max" className="form-label">
                              Maximum Price
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              name="max"
                              placeholder="Maximum Price"
                              value={max}
                              onChange={(e) =>
                                setVariant({
                                  ...variant,
                                  price: {
                                    ...variant.price,
                                    max: parseInt(e.target.value),
                                  },
                                })
                              }
                            />
                          </div>
                        </div>

                        <div
                          className="col-lg-8 col-md-8"
                          style={{ paddingTop: "1rem" }}
                        >
                          <div className="form-group">
                            <label
                              htmlFor="purchaseQtyLimit"
                              className="form-label"
                            >
                              Purchase quantity limit per order
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              name="purchaseQtyLimit"
                              placeholder="limit per order"
                              value={purchaseQtyLimit}
                              onChange={handleChange}
                            />
                          </div>
                        </div>

                        <div
                          className="col-lg-4 col-md-4"
                          style={{ paddingTop: "1rem" }}
                        >
                          <div className="form-group">
                            <label htmlFor="" className="form-label">
                              Status
                            </label>
                            <select
                              name="status"
                              id="status"
                              className="form-control"
                              // required
                              value={status}
                              onChange={handleChange}
                            >
                              <option value="">select</option>
                              <option value="Live">Live</option>
                              <option value="Hidden">Hidden</option>
                              <option value="Draft">Draft</option>
                              <option value="Deleted">Deleted</option>
                            </select>
                          </div>
                          <button className="save-btn hover-btn" type="submit">
                            {currentId ? "Update" : "Add New"}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default VariantForm;
