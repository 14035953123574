const Error = () => {
  return (
    <div id="layoutSidenav_content">
      <h1 style={{ marginLeft: "1%", marginTop: "8%", paddingTop: "10%" }}>
        <center>404 Page Not found</center>
      </h1>
    </div>
  );
};

export default Error;
