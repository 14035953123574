// import { useEffect } from "react";
import Footer from "../0-ResuableComponents/footer/Footer";
import Table from "./table/Table";
import Card from "./card/Card";
const Home = () => {
  // const refresh = setTimeout(() => {
  //   window.location.reload();
  // }, 0);

  // useEffect(() => {
  //   refresh()
  // },[]);

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <h2 className="mt-30 page-title">Dashboard</h2>
          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item active">Dashboard</li>
          </ol>
          <div className="row">
            <Card
              className="dashboard-report-card purple"
              title="Order Pending"
              number={2}
            />
            <Card
              className="dashboard-report-card red"
              title="Order Cancel"
              number={0}
            />
            <Card
              className="dashboard-report-card info"
              title="Order Process"
              number={5}
            />
            <Card
              className="dashboard-report-card success"
              title="Today Income"
              number={`$9568.00`}
            />
            <Table />
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Home;
