import { Link } from "react-router-dom";
import moment from "moment";
import TableHeader from "../0-ResuableComponents/tableComponents/TableHeader";

const headers = [
  { name: "COUPON CODE", field: "id", sortable: false },
  { name: "Type", field: "type", sortable: false },
  { name: "Max Amount off", field: "maxDiscount", sortable: false },
  { name: "Discount%", field: "discountPercentage", sortable: false },
  { name: "Valid From", field: "ValidFrom", sortable: false },
  { name: "Valid Till", field: "ValidTill", sortable: false },
  { name: "Region Validity", field: "RegionValidity", sortable: false },
  { name: "Status", field: "status", sortable: false },
  { name: "Usage", field: "usage", sortable: false },
  { name: "Edit", field: "Edit", sortable: false },
  { name: "Last Updated By", field: "LastUpdatedBy", sortable: false },
  { name: "Last Updated on", field: "LastUpdatedOn", sortable: false },
  { name: "Added By", field: "addedBy", sortable: false },
  { name: "Added on", field: "addedOn", sortable: false },
];
const CouponsTable = ({ setSorting, couponList }) => {
  
  // console.log("couponList", couponList);
  return (
    <div className="col-lg-12 col-md-12" style={{ userSelect: "auto" }}>
      <div className="card card-static-2 mb-30">
        <div className="card-title-2">
          <h4>All Coupons</h4>
        </div>
        <div className="card-body-table">
          <div className="table-responsive">
            <table
              className="table ucp-table table-hover"
              style={{ minWidth: "140%" }}
            >
              <TableHeader
                headers={headers}
                onSorting={(field, order) => setSorting({ field, order })}
              />
              <tbody>
                {couponList?.map((coupon) => (
                  <tr key={coupon._id}>
                    <td>
                      <Link to={`/coupons/view/${coupon._id}`}>
                        {coupon.couponCode}
                      </Link>
                    </td>

                    <td>{coupon.couponType}</td>
                    <td>&#8377; {coupon.maxDiscount}</td>
                    <td>
                      {coupon.discountPercentage
                        ? `${coupon.discountPercentage}%`
                        : "-"}
                    </td>
                    <td style={{ width: "250px" }}>
                      {moment(coupon.validFrom).format("lll")}
                    </td>
                    <td style={{ width: "250px" }}>
                      {moment(coupon.validTill).format("lll")}
                    </td>
                    {/* <td>Product Validity</td> */}
                    <td style={{ width: "250px" }}>
                      {coupon?.cities?.length > 2 ? (
                        <>
                          {coupon.cities[0]},{coupon.cities[1]}
                          <Link to={`/coupons/view/${coupon._id}`}>
                            {""} & {coupon?.cities?.length - 2} more...
                          </Link>
                        </>
                      ) : (
                        coupon.cities.map((state) => state)
                      )}
                    </td>
                    <td
                      style={{
                        color: `${
                          coupon?.status === "Active" ? "green" : "red"
                        }`,
                      }}
                    >
                      {coupon.status}
                    </td>
                    <td>
                      <Link to={`/coupons/logs/${coupon._id}`}>Usage</Link>
                    </td>
                    <td className="action-btns">
                      <Link to={`/coupons/edit/${coupon._id}`}>
                        <i
                          className="fas fa-edit"
                          style={{ cursor: "pointer" }}
                        />
                      </Link>
                    </td>
                    <td>{coupon.metaInfo.updated?.by?.name}</td>
                    <td style={{ width: "250px" }}>
                      {moment(coupon.metaInfo.updated?.at).format("lll")}
                    </td>
                    <td>{coupon.metaInfo.created?.by?.name}</td>
                    <td style={{ width: "250px" }}>
                      {moment(coupon.metaInfo.created?.at).format("lll")}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CouponsTable;
