/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import { singleCoupon } from "../../../actions/couponActions";
import CouponLogsTable from "./CouponLogsTable";

const PriceLogs = ({ match }) => {
  const { auth } = useSelector((state) => ({ ...state }));
  const [coupon, setCoupon] = useState("");

  const fetchCoupon = async () => {
    let res = await singleCoupon(auth.token, match.params.couponId);
    setCoupon(res.data.coupon);
  };

  useEffect(() => {
    fetchCoupon();
  }, []);

  // console.log("coupon====>", coupon);

  return (
    <div id="layoutSidenav_content" style={{ userSelect: "auto" }}>
      <main>
        <div className="container-fluid">
          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/coupons">coupons</Link>
            </li>
            <li className="breadcrumb-item active">Coupon Logs</li>
          </ol>
          <div className="row">
            <div className="card card-static-2 mb-30 col-md-7 ml-4">
              <div className="shopowner-dts">
                <div className="shopowner-dt-list">
                  <span className="left-dt">Coupon Code</span>
                  <span className="right-dt">{coupon?.couponCode}</span>
                </div>
                <div className="shopowner-dt-list">
                  <span className="left-dt">Coupon Description</span>
                  <span className="right-dt">{coupon?.couponDescription}</span>
                </div>
                <div className="shopowner-dt-list">
                  <span className="left-dt">Total Discount Availed (INR)</span>
                  <span className="right-dt">&#8377; {coupon?.maxDiscount}</span>
                </div>
              </div>
            </div>
            <ol className="col-lg-4 col-md-4 back-btn ml-4">
              <Link
                to={`/coupons/edit/${coupon?._id}`}
                style={{ textDecoration: "none" }}
              >
                <Button variant="contained" className="status-btn hover-btn">
                  Edit This Coupon
                </Button>
              </Link>
            </ol>
          </div>
          <CouponLogsTable Coupons={coupon} />
        </div>
      </main>
      <footer className="py-4 bg-footer mt-auto">
        <div className="container-fluid">
          <div className="d-flex align-items-center justify-content-between small">
            <div className="text-muted-1">
              © 2021 <b>App Factory</b>
            </div>
            <div className="footer-links">
              <a href="/">Privacy Policy</a>
              <a href="/">Terms &amp; Conditions</a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default PriceLogs;
