import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { resetPassword } from "../../../actions/authAction";
import {
  showErrMsg,
  showSuccessMsg,
} from "../../0-ResuableComponents/utils/notification/Notification";
import {
  isLength,
  isMatch,
} from "../../0-ResuableComponents/utils/validation/Validation";
import ResetPasswordForm from "./ResetPasswordForm";
import { encryptPlaintext } from "../../0-ResuableComponents/security/cryptoFunctions";

const initialState = {
  password: "",
  cf_password: "",
  err: "",
  success: "",
};

const ResetPassword = ({ history }) => {
  const [data, setData] = useState(initialState);
  const { auth } = useSelector((state) => ({ ...state }));
  const { token } = auth;
  const dispatch = useDispatch();

  const { password, cf_password, err, success } = data;

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value, err: "", success: "" });
  };

  const logout = () => {
    dispatch({
      type: "LOGOUT",
      payload: null,
    });
    window.localStorage.removeItem("auth");
    history.push("/");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isLength(password))
      return setData({
        ...data,
        err: "Password must be at least 6 characters.",
        success: "",
      });

    if (!isMatch(password, cf_password))
      return setData({
        ...data,
        err: "Password did not match.",
        success: "",
      });

    // console.log("SEND DATA", { email });
    const encryptedPassword = encryptPlaintext(password);
    try {
      let res = await resetPassword(token, encryptedPassword);
      if (res.data) {
        // console.log(res.data);
        toast.success(res.data.message);
        logout();
      }
    } catch (err) {
      toast.error(err.response.data.errorMessage);
    }
  };

  return (
    <>
      <div
        id="layoutSidenav_content"
        style={{ marginLeft: "15%", marginTop: "4%" }}
      >
        <main>
          <div className="container-fluid">
            <div className="row">
              <p>
                {err && showErrMsg(err)}
                {success && showSuccessMsg(success)}
              </p>
            </div>
            <div className="row">
              <ResetPasswordForm
                password={password}
                cf_password={cf_password}
                handleSubmit={handleSubmit}
                handleChangeInput={handleChangeInput}
              />
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default ResetPassword;
