/** @format */

import DeleteIcon from "@material-ui/icons/Delete";
import { Link } from "react-router-dom";
import moment from "moment";
import TableHeader from "../../0-ResuableComponents/tableComponents/TableHeader";

const headers = [
  { name: "Category", field: "name", sortable: true },
  { name: "ID", field: "id", sortable: false },
  { name: "Trade", field: "tradeIds", sortable: false },
  { name: "Sub-Categories", field: "SubCategories", sortable: false },
  { name: "Created By", field: "CreatedBy", sortable: false },
  { name: "Created At", field: "CreatedAt", sortable: false },
  { name: "Last Updated By", field: "LastUpdatedBy", sortable: false },
  { name: "Last Updated At", field: "LastUpdatedAt", sortable: false },
  { name: "Edit", field: "Edit", sortable: false },
  { name: "Delete", field: "Delete", sortable: false },
];

const CategoryTable = ({
  categoryList,
  setCurrentId,
  handleCategoryDelete,
  setConfirmDialog,
  setSorting,
  handleShow,
}) => {
  // console.log("categoryList====>", categoryList);
  return (
    <div className='col-lg-12 col-md-7'>
      <div className='all-cate-tags'>
        <div className='row justify-content-between'>
          <div className='col-lg-12 col-md-12'>
            <div className='card card-static-2 mb-30'>
              <div className='card-title-2'>
                <h4>All Categories</h4>
              </div>
              <div className='card-body-table'>
                <div className='table-responsive'>
                  <table className='table ucp-table table-hover'>
                    <TableHeader
                      headers={headers}
                      onSorting={(field, order) => setSorting({ field, order })}
                    />
                    <tbody>
                      {(categoryList||[])?.map((category) => (
                        <tr key={category._id}>
                          <td>{category.name}</td>
                          <td>{category.id}</td>
                          <td>
                            <Link
                              to={`/catalog/categories/trades/${category.id}`}
                            >
                              {(category.tradeIds ||[])?.filter(
                                (trade) => !trade.isDeleted
                              ).length !== 1
                                ? `${
                                    (category.tradeIds ||[])?.filter(
                                      (trade) => !trade.isDeleted
                                    ).length
                                  } Trades`
                                : `${
                                    (category.tradeIds ||[])?.filter(
                                      (trade) => !trade.isDeleted
                                    ).length
                                  } Trade`}
                            </Link>
                          </td>
                          <td>
                            <Link
                              to={`/categories/subcategories/${category.id}`}
                            >
                              {category.subcategories.filter((subcategory) =>
                                ["FALSE", false].includes(subcategory.isDeleted)
                              ).length > 1
                                ? `${
                                    category.subcategories.filter(
                                      (subcategory) =>
                                        ["FALSE", false].includes(
                                          subcategory.isDeleted
                                        )
                                    ).length
                                  } Sub-Categories`
                                : `${
                                    category.subcategories.filter(
                                      (subcategory) =>
                                        ["FALSE", false].includes(
                                          subcategory.isDeleted
                                        )
                                    ).length
                                  } Sub-Category`}
                            </Link>
                          </td>
                          <td>{category.metaInfo.created?.by?.name}</td>
                          <td>
                            {moment(category.metaInfo.created.at).format("lll")}
                          </td>
                          <td>{category.metaInfo.updated?.by?.name}</td>
                          <td>
                            {moment(category.metaInfo.updated.at).format("lll")}
                          </td>
                          <td className='action-btns'>
                            <i
                              className='fas fa-edit'
                              data-toggle='modal'
                              data-target='#exampleModal'
                              data-whatever='@mdo'
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setCurrentId(category._id);
                                handleShow();
                              }}
                            />
                          </td>
                          <td>
                            <DeleteIcon
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setConfirmDialog({
                                  isOpen: true,
                                  title: "Are you sure to delete this record?",
                                  subTitle: "You can't undo this operation",
                                  onConfirm: () => {
                                    handleCategoryDelete(category._id);
                                  },
                                });
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryTable;
