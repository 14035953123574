/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import TableSearch from "../../0-ResuableComponents/tableComponents/TableSearch";
import Pagination from "../../0-ResuableComponents/pagination/Pagination";
import ConfirmDialog from "../../0-ResuableComponents/confirmDialog/ConfirmDialog";
import ServiceCategoryForm  from "./ServiceCategoryForm";
import ServiceCategoryTable from "./ServiceCategoryTable";
import axios from "axios";
import { createNewServiceCategory,allServiceCategory,updateServiceCategory,deleteServiceCategory,getSingleServiceCategory
} from "../../../actions/serviceCategoryActions";
import flatten from '../../../helpers/Flatten';

const CreateServiceCategory = () => {


  const [serviceCategory, setServiceCategory] = useState({ name: "", 
  criteria:{gender:[],area:[]},
  representative_icon: "",business_idea_ids:[] });
  const { auth } = useSelector((state) => ({ ...state }));
  const { token } = auth;
  const { name,business_idea_ids,criteria ,representative_icon} = serviceCategory;
  const [currentId, setCurrentId] = useState(0);
  const [serviceCategoryList, setServiceCategoryList] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(1000);
  const [search, setSearch] = useState("");
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [show, setShow] = useState(false);
  const [businessIdeas,setBusinessIdeas] =useState([]);
  const [selectedBusinessIdeas , setSelectedBusinessIdeas ] = useState([]);
  // const [selectedBusinessIdeasId , setSelectedBusinessIdeasId ] = useState([]);
  
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const fetchAllServiceCategory = async () => {
    let res = await allServiceCategory(token);
    setServiceCategoryList(res.data);
  };

  const fetchBusinessIdeas = async () => {
    // const url = `${process.env.REACT_APP_SWATI_API_URL}/api/v1/bussinessIdeas/all-detailed`;
  const url = 'https://swatiapi.letsendorse.net/api/v1/bussinessIdeas/all-detailed';
  
  axios.get(url).then((response) => {
    if (response.status) {
      setBusinessIdeas(response.data.bussinessIdeas || []);
    }
  });
  }

  const singleServiceCategory = currentId
    ? serviceCategoryList.data.find((category) => category._id === currentId)
    : null;

  useEffect(() => {
    fetchAllServiceCategory();
    if (singleServiceCategory) setServiceCategory(singleServiceCategory);
  }, [currentId]);

  
  useEffect(()=>{
    fetchBusinessIdeas();
  },[])

  const serviceCategoryData = useMemo(() => {
    let computedTrades = serviceCategoryList?.data;

    if (search) {
      computedTrades = computedTrades?.filter((trade) =>
        trade.name.toLowerCase().includes(search.toLowerCase())
      );
    }

    //Sorting Trades
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedTrades = computedTrades?.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    return computedTrades;
  }, [serviceCategoryList, search, sorting]);

  const clear = () => {
    setCurrentId(0);
    setServiceCategory({name: "",criteria:{gender:[],area:[]},
    representative_icon: "",
    business_idea_ids:[] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let criteriaObj = serviceCategory.criteria;
    let criteriaToSend = Object.keys(criteriaObj).reduce((prev, curr)=> {prev[curr]=(criteriaObj[curr] || []).map(x=>x.value); return prev;},{});

    let serviceCategoryData = new FormData();
    serviceCategoryData.append("name", name);
    
    serviceCategoryData.append("business_idea_ids",JSON.stringify(business_idea_ids));

    const flat_criterias = flatten(criteriaToSend);
    Object.keys(flat_criterias).forEach(cri=>{
    serviceCategoryData.append(`criteria.${cri}`, flat_criterias[cri]);
    })
    // serviceCategoryData.append("criteria", JSON.parse(criteriaToSend));
    representative_icon && serviceCategoryData.append("photoFile", representative_icon);
    try {
      if (currentId === 0) {
        await createNewServiceCategory(token, serviceCategoryData);
        fetchAllServiceCategory();
        toast.success(`New Service Category ${name} is added Successfully...`);
        clear();
        handleClose();
      } else {
        setConfirmDialog({
          isOpen: true,
          title: "Are you sure to Update this record?",
          subTitle: "You can't undo this operation",
          onConfirm: async () => {
            setConfirmDialog({
              ...confirmDialog,
              isOpen: false,
            });
            await updateServiceCategory(token, serviceCategoryData, currentId)
              .then(() => {
                fetchAllServiceCategory();
                toast.success(
                  `Service Category ${name} is updated Successfully...`
                );
                clear();
                handleClose();
              })
              .catch((err) => toast.error(err.response.data.errorMessage));
          },
        });
      }
    } catch (err) {
      toast.error(err.response.data.errorMessage);
    }
  };

  const handleImageChange = (e) => {
    setServiceCategory({ ...serviceCategory, representative_icon: e.target.files[0] });
  };

  const handleChange = (e) => {
    setServiceCategory({ ...serviceCategory, [e.target.name]: e.target.value });
  };

  const handleServiceCategoryDelete = async (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    deleteServiceCategory(token, id)
      .then((res) => {
        toast.success("Service Category Deleted successfully...");
        fetchAllServiceCategory();
      })
      .catch((err) => toast.error(err.response.data.errorMessage));
  };

  // Get current rows
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = serviceCategoryData?.slice(indexOfFirstRow, indexOfLastRow);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div id="layoutSidenav_content">
      <div className="container-fluid">
        <h2 className="mt-25 page-title">Service categories</h2>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Dashboard</Link>
          </li>
          <li className="breadcrumb-item active">Service categories</li>
        </ol>
        <div className="col-lg-12 col-md-12 back-btn">
          <button
            className="save-btn hover-btn"
            onClick={() => { setCurrentId(0);clear(); handleShow()}} >
            + Add New Service Categories
          </button>
        </div>
        <TableSearch
          onSearch={(value) => {
            setSearch(value);
          }}
          placeholder="Search by Service Categories Name"
        />
        <ServiceCategoryForm 
          serviceCategory={serviceCategory}
          setServiceCategory={setServiceCategory}
          handleSubmit={handleSubmit}
          currentId={currentId}
          show={show}
          handleClose={handleClose}
          businessIdeas={businessIdeas}
          setSelectedBusinessIdeas={setSelectedBusinessIdeas}
          handleImageChange={handleImageChange}
          handleChange={handleChange} 
          selectedBusinessIdeas={selectedBusinessIdeas}
          />
        
        <div className="row">
          <ServiceCategoryTable
            serviceCategoryList={currentRows}
            setCurrentId={setCurrentId}
            handleServiceCategoryDelete={handleServiceCategoryDelete}
            setConfirmDialog={setConfirmDialog}
            setSorting={setSorting}
            handleShow={handleShow}
            businessIdeas={businessIdeas}
          />
        </div>
        <Pagination
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          totalRows={serviceCategoryList?.data?.length}
          paginate={paginate}
          url="/service-categories"
        />
      </div>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </div>
  );
};

export default CreateServiceCategory;