import axios from "axios";
import { BASE_URL } from "./api";
// const config = {
//   headers: {
//     "Content-Type": "application/json",
//   },
// };
// CREATE A NEW PRODUCT
export const createNewProduct = async (token, data) =>
  await axios.post(`${BASE_URL}/products`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// GET ALL THE PRODUCTS
export const allProducts = async (token) =>
  await axios.get(`${BASE_URL}/products`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// UPDATE A PRODUCT
export const updateProduct = async (token, data, productId) =>
  await axios.put(`${BASE_URL}/products/${productId}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// DELETE THE PRODUCT
export const deleteProduct = async (token, productId) => {
  await axios.delete(`${BASE_URL}/products/${productId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// GET SINGLE THE PRODUCT
export const singleProduct = async (token, productId) =>
  await axios.get(`${BASE_URL}/products/${productId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// GET PRODUCTS BY SUB-CATEGORY ID
export const productsBySubcategory = async (token, subCategoryId) =>
  await axios.get(`${BASE_URL}/products/bySubcategory/${subCategoryId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// // ---SEARCH RESULTS---
// export const searchListings = async (query) =>
//   await axios.post(`${BASE_URL}/search-listings`, query);


