// import React, { useEffect } from "react";

const ZoneForm = ({
  handleSubmit,
  setUpdateDetails,
  updateDetails,
  currentZone,
  setCurrentZone,
}) => {
  return (
    <>
      {currentZone ? (
        <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  UPDATE ZONE
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="col-lg-12 col-md-11">
                  <div className="card card-static-2 mb-30">
                    <div className="card-title-2">
                      <h4>UPDATE</h4>
                    </div>
                    <div className="card-body-table">
                      <div className="news-content-right pd-20">
                        <form onSubmit={handleSubmit}>
                          <div className="form-group">
                            <label htmlFor="name" className="form-label">
                              Area*
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Area Name"
                              name="areaName"
                              required
                              value={currentZone.area}
                              onChange={(e) =>
                                setCurrentZone({
                                  ...currentZone,
                                  area: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="form-group">
                            <label htmlFor="companyName" className="form-label">
                              Status*
                            </label>
                            <select
                              type="text"
                              className="form-control"
                              name="status"
                              required
                              value={currentZone.status}
                              onChange={(e) =>
                                setCurrentZone({
                                  ...currentZone,
                                  status: e.target.value,
                                })
                              }
                            >
                              <option value="Active">Active</option>
                              <option value="Inactive">Inactive</option>
                            </select>
                          </div>
                          <div className="form-group">
                            <label htmlFor="companyName" className="form-label">
                              Estimated families
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Estimated Familes"
                              name="estimatedFamilies"
                              required
                              value={currentZone.estimatedFamilies}
                              onChange={(e) =>
                                setCurrentZone({
                                  ...currentZone,
                                  estimatedFamilies: e.target.value,
                                })
                              }
                            />
                          </div>
                          <button className="save-btn hover-btn">UPDATE</button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ZoneForm;
