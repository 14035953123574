// import DeleteIcon from "@material-ui/icons/Delete";
// import moment from "moment";
// import { useState } from "react";
// import TableHeader from "../../0-ResuableComponents/tableComponents/TableHeader";

// const headers = [
//   { name: "Name", field: "name", sortable: false },
//   {name:"Business Ideas",field:'business_idea_ids',sortable:false}];

// const BusinessModal = ({showbusinessIdeas,businessIdeas}) => {

//     let  arr = businessIdeas.filter((item)=> showbusinessIdeas.indexOf(item.id) === -1 );

//   return (
//     <div className="col-lg-12 col-md-11">
//       <div className="all-cate-tags">
//         <div className="row justify- content-between">
//           <div className="col-lg-12 col-md-12">
//             <div className="card card-static-2 mb-30">
//               <div className="card-title-2">
//                 <h4>Business Idea(s)</h4>
//               </div>
//               <div className="card-body-table">
//                 <div className="table-responsive">
//                   <table className="table ucp-table table-hover">
//                     <TableHeader
//                       headers={headers}
//                     />
//                     <tbody>
//                       {arr?.map((list) => (
//                         <tr key={list._id}>
//                             <td>{list._id}</td>
//                           <td>{list?.name}</td>
//                         </tr>
//                       ))}
//                     </tbody>
//                   </table>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default BusinessModal;
import moment from "moment";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import TableHeader from "../../0-ResuableComponents/tableComponents/TableHeader";


const BusinessModal = ({showbusinessIdeas,businessIdeas,show,handleClose}) => {

 let  arr = businessIdeas.filter((item)=> showbusinessIdeas.indexOf(item.id) === -1 );

const headers = [{ name: "Id", field: "_id", sortable: false }, {name:"Business Idea",field:'inEnglish',sortable:false}];

  return (
    <Modal show={show} onHide={handleClose} size="md">
      <div>
        <Modal.Header closeButton data-dismiss="modal">
          <h5 className="modal-title" id="exampleModalLabel">
            Business Idea(s)
          </h5>
        </Modal.Header>
        <Modal.Body>
        <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-md-12">
              <div className="card-body-table">
              <div className="table-responsive">
                   <table className="table ucp-table table-hover">
                     <TableHeader
                       headers={headers}
                     />
                     <tbody>
                       {arr?.map((list) => (
                         <tr key={list._id}>
                             <td>{list._id}</td>
                           <td>{list?.inEnglish}</td>
                         </tr>
                       ))}
                     </tbody>
                   </table>
                 </div>
               </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default BusinessModal ;

