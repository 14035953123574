import { Switch, Route, BrowserRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import NoMatch from "./components/0-ResuableComponents/Error/404";
import Header from "./components/0-ResuableComponents/header/Header";
import Home from "./components/2-dashboard/Dashboard";
import SideBar from "./components/0-ResuableComponents/sidebar/Sidebar";
import CreateTrade from "./components/3-catalog/trades/CreateTrade";
import CreateCategory from "./components/3-catalog/categories/CreateCategory";
import CreateBrand from "./components/3-catalog/brands/CreateBrand";
import AddProduct from "./components/3-catalog/products/AddProduct";
import createSubcategory from "./components/3-catalog/subcategories/CreateSubcategory";
import HyperLinkedTrades from "./components/3-catalog/hyperlinkedtrades/HyperLinkedTrades";
import CreateVariant from "./components/3-catalog/variants/CreateVariant";
import Login from "./components/1-auth/login/Login";
import ForgotPassword from "./components/1-auth/forgotPassword/ForgotPassword";
import ResetPassword from "./components/1-auth/resetPassword/ResetPassword";
import ResetForgotPassword from "./components/1-auth/resetForgotPassword/ResetForgotPassword";
import AddNewAdmin from "./components/4-admins/AddNewAdmin";
import ProtectedRoute from "./components/0-ResuableComponents/protectedRoute/ProtectRoute";
import AddEntrepreneur from "./components/5-entrepreneur/addEntrepreneur/AddEntrepreneur";
import EditEntrepreneur from "./components/5-entrepreneur/editEntrepreneur/EditEntrepreneur";
import Orders from "./components/9-orderManagement/Orders";

import Consumers from "./components/8-consumerMangement/Consumers";
import ConsumerProfile from "./components/8-consumerMangement/ConsumerProfile";
import ConsumerOrder from "./components/8-consumerMangement/OrderDetails";
import ViewEntrepreneur from "./components/5-entrepreneur/viewEntrepreneur/ViewEntrepreneur";
import Entrepreneurs from "./components/5-entrepreneur/Entrepreneurs";
import EntrepreneurProfile from "./components/5-entrepreneur/profile/EntrepreneurProfile";
import PriceLogs from "./components/5-entrepreneur/priceLogs/PriceLogs";
import City from "./components/7-cityManagement/addCity/City";
import CreateZones from "./components/7-cityManagement/addZone/CreateZones";
import Map2 from "./components/7-cityManagement/map/Map2";
import Coupon from "./components/6-coupons/Coupon";
import AddNewCoupon from "./components/6-coupons/addCoupon/AddNewCoupon";
import CouponLogs from "./components/6-coupons/couponLogs/CouponLogs";
import EditCoupon from "./components/6-coupons/editCoupon/EditCoupon";
import ViewCoupon from "./components/6-coupons/viewCoupon/ViewCoupon";
import CreateServiceCategories from "./components/10-serviceManagement/serviceCategories/CreateServiceCategories";
import Service from "./components/10-serviceManagement/service/Service";
import CreateServiceVariant from "./components/10-serviceManagement/variants/CreateServiceVariant";
import CreateServiceEntrepreneurs from "./components/10-serviceManagement/service-entrepreneurs/CreateServiceEntrepreneurs";
import EntrepreneurDetailsTab from "./components/11-entrepreneursDetails/EntrepreneursDetailsTab";
import NewOrders from "./components/12-orderManagement-new/NewOrders";
import NewOrderForm from "./components/12-orderManagement-new/NewOrderForm";
import NewConsumers from "./components/13-newConsumerMangement/NewConsumers";
import NewConsumerProfile from "./components/13-newConsumerMangement/NewConsumerProfile";
import NewOrderDetails from "./components/13-newConsumerMangement/NewOrderDetails";

const App = () => {
  const { auth } = useSelector((state) => ({ ...state }));

  let HideSidebar =
    window.location.pathname === `${auth?.token ? null : "/"}` &&
    "/request-forgot-password" &&
    "/reset-forgot-password/" ? null : (
      <SideBar />
    );

  return (
    <div className="App">
      <BrowserRouter>
        <main>
          <Header />
          <ToastContainer
            position="top-center"
            autoClose={2000}
            hideProgressBar
          />
          <div id="layoutSidenav">
            {!auth ? null : HideSidebar}
            <Switch>
              <Route exact path="/" component={auth?.token ? Home : Login} />
              <Route
                exact
                path="/request-forgot-password"
                component={ForgotPassword}
              />
              <Route
                exact
                path="/reset-forgot-password/"
                component={ResetForgotPassword}
              />
              <ProtectedRoute
                exact
                path="/change-password"
                component={ResetPassword}
              />
              <ProtectedRoute
                exact
                path="/catalog/trades"
                component={CreateTrade}
              />
              <ProtectedRoute
                exact
                path="/catalog/brands"
                component={CreateBrand}
              />
              <ProtectedRoute
                exact
                path="/catalog/categories"
                component={CreateCategory}
              />
              <ProtectedRoute
                exact
                path="/catalog/categories/trades/:categoryId"
                component={HyperLinkedTrades}
              />
              <ProtectedRoute
                exact
                path="/categories/subcategories/:categoryId"
                component={createSubcategory}
              />
              <ProtectedRoute
                exact
                path="/catalog/products"
                component={AddProduct}
              />
              <ProtectedRoute
                exact
                path="/catalog/products/variants/:productId"
                component={CreateVariant}
              />
              <ProtectedRoute exact path="/admins" component={AddNewAdmin} />
              <ProtectedRoute exact path="/dashboard" component={Home} />
              <ProtectedRoute exact path="/homies" component={Entrepreneurs} />
              <ProtectedRoute
                exact
                path="/homies/new"
                component={AddEntrepreneur}
              />
              <ProtectedRoute
                exact
                path="/homies/edit/:entrepreneurId"
                component={EditEntrepreneur}
              />
              <ProtectedRoute
                exact
                path="/homies/view/:entrepreneurId/catalog"
                component={ViewEntrepreneur}
              />
              <ProtectedRoute
                exact
                path="/homies/view/:entrepreneurId/:variantId/priceLogs"
                component={PriceLogs}
              />
              <ProtectedRoute
                exact
                path="/homies/view/:entrepreneurId"
                component={EntrepreneurProfile}
              />

              <Route path="/cities" component={City} />
              <Route path="/zones-by-city-id/:id" component={CreateZones} />
              <Route path="/map2" component={Map2} />

              <ProtectedRoute
                exact
                path="/homies/view/:entrepreneurId"
                component={EntrepreneurProfile}
              />
              <ProtectedRoute exact path="/orders" component={Orders} />
              <ProtectedRoute exact path="/consumers" component={Consumers} />
              <ProtectedRoute
                exact
                path="/newconsumers"
                component={NewConsumers}
              />
              <ProtectedRoute exact path="/neworders" component={NewOrders} />
              <ProtectedRoute
                exact
                path="/neworderform"
                component={NewOrderForm}
              />

              <ProtectedRoute
                exact
                path="/consumers/:consumerId"
                component={ConsumerProfile}
              />

              <ProtectedRoute
                exact
                path="/newconsumers/:consumerId"
                component={NewConsumerProfile}
              />
              <ProtectedRoute
                exact
                path="/consumers/:consumerId/:orderId"
                component={ConsumerOrder}
              />

              <ProtectedRoute
                exact
                path="/newconsumers/:consumerId/:orderId"
                component={NewOrderDetails}
              />

              <Route path="/cities" component={City} />
              <Route path="/zonesByID/:id" component={CreateZones} />
              <ProtectedRoute exact path="/coupons" component={Coupon} />
              <ProtectedRoute
                exact
                path="/coupons/logs/:couponId"
                component={CouponLogs}
              />
              <ProtectedRoute
                exact
                path="/coupons/new"
                component={AddNewCoupon}
              />
              <ProtectedRoute
                exact
                path="/coupons/edit/:couponId"
                component={EditCoupon}
              />
              <ProtectedRoute
                exact
                path="/coupons/view/:couponId"
                component={ViewCoupon}
              />
              <ProtectedRoute
                exact
                path="/service-categories"
                component={CreateServiceCategories}
              />
              <ProtectedRoute exact path="/services" component={Service} />
              <ProtectedRoute
                exact
                path="/services/variants/:serviceId"
                component={CreateServiceVariant}
              />
              {/* services-entrepreneurs */}
              <ProtectedRoute
                exact
                path="/services-entrepreneurs"
                component={CreateServiceEntrepreneurs}
              />
              <ProtectedRoute
                exact
                path="/services-entrepreneurs/:entrepreneurId"
                component={EntrepreneurDetailsTab}
              />
              {/* /services-entrepreneurs/${entrepreneurs._id} */}

              <Route component={NoMatch} />
            </Switch>
          </div>
        </main>
      </BrowserRouter>
    </div>
  );
};

export default App;
