/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CouponsTable from "./CouponsTable";
import Pagination from "../0-ResuableComponents/pagination/Pagination";
import { allCoupon } from "../../actions/couponActions";
import TableSearch from "../0-ResuableComponents/tableComponents/TableSearch";

const Coupons = () => {
  const { auth } = useSelector((state) => ({ ...state }));
  const [couponList, setCouponList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(200);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const fetchAllCoupon = async () => {
    let res = await allCoupon(auth.token);
    setCouponList(res.data.Coupons);
  };

  useEffect(() => {
    fetchAllCoupon();
  }, []);

  // console.log("couponList", couponList);
  const couponsData = useMemo(() => {
    let computedCoupons = couponList;

    if (search) {
      computedCoupons = computedCoupons?.filter(
        (coupon) =>
          coupon?.couponCode?.toLowerCase().includes(search.toLowerCase()) ||
          coupon?.status?.toLowerCase() === search.toLowerCase() ||
          coupon?.status?.toLowerCase().includes(search.toLowerCase())
      );
    }

    //Sorting Trades
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedCoupons = computedCoupons?.sort(
        (a, b) => reversed * a[sorting.field]?.localeCompare(b[sorting.field])
      );
    }

    return computedCoupons;
  }, [couponList, search, sorting]);

  // Get current rows
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = couponsData?.slice(indexOfFirstRow, indexOfLastRow);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div id="layoutSidenav_content">
      <div className="container-fluid">
        <h2 className="mt-25 page-title">Coupons</h2>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Dashboard</Link>
          </li>
          <li className="breadcrumb-item active">Coupons</li>
        </ol>
        <div className="col-lg-12 col-md-12 back-btn">
          <Link to="/coupons/new" style={{ textDecoration: "none" }}>
            <button
              className="save-btn hover-btn"
              // className="status-btn hover-btn"
            >
              + Add New Coupon
            </button>
          </Link>
        </div>
        <TableSearch
          onSearch={(value) => {
            setSearch(value);
          }}
          placeholder="Search by Coupon Code / status"
        />
        <div className="row">
          <CouponsTable couponList={currentRows} setSorting={setSorting} />
          <Pagination
            rowsPerPage={rowsPerPage}
            totalRows={couponList?.length}
            paginate={paginate}
            setRowsPerPage={setRowsPerPage}
            url="/coupons"
          />
        </div>
      </div>
    </div>
  );
};

export default Coupons;
