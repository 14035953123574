import moment from "moment";
import TableHeader from "../0-ResuableComponents/tableComponents/TableHeader";

const headers = [
  { name: "Name", field: "name", sortable: true },
  { name: "Email", field: "email", sortable: true },
  { name: "Phone", field: "phone", sortable: false },
  { name: "Role", field: "role", sortable: false },
  { name: "Status", field: "status", sortable: false },
  { name: "Created By", field: "CreatedBy", sortable: false },
  { name: "Created At", field: "CreatedAt", sortable: false },
  { name: "Updated By", field: "LastUpdatedBy", sortable: false },
  { name: "Updated At", field: "LastUpdatedAt", sortable: false },
  { name: "Edit", field: "Edit", sortable: false },
];
const AdminsTable = ({ adminList, setCurrentId, setSorting, handleShow }) => {
  // console.log("user list===>", adminList);
  return (
    <div className="col-lg-12 col-md-11">
      <div className="all-cate-tags">
        <div className="row justify-content-between">
          <div className="col-lg-12 col-md-12">
            <div className="card card-static-2 mb-30">
              <div className="card-title-2">
                <h4>All Admins</h4>
              </div>
              <div className="card-body-table">
                <div className="table-responsive">
                  <table className="table ucp-table table-hover">
                    <TableHeader
                      headers={headers}
                      onSorting={(field, order) => setSorting({ field, order })}
                    />
                    <tbody>
                      {adminList?.map((admin) => (
                        <tr key={admin._id}>
                          <td>{admin.name}</td>
                          <td>{admin.email}</td>
                          <td>{admin.mobileNumber}</td>
                          <td>{admin.role}</td>
                          <td
                            style={{
                              color: `${
                                admin.status === "Active" ? "green" : "red"
                              }`,
                            }}
                          >
                            {admin.status}
                          </td>
                          <td>{admin.metaInfo.created?.by?.name}</td>
                          <td>
                            {moment(admin.metaInfo.created?.at).format("lll")}
                          </td>
                          <td>{admin.metaInfo.updated?.by?.name}</td>
                          <td>
                            {moment(admin.metaInfo.updated?.at).format("lll")}
                          </td>
                          <td className="action-btns">
                            <i
                              className="fas fa-edit"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setCurrentId(admin._id);
                                handleShow();
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminsTable;
