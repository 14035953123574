import { useState } from "react";
import { toast } from "react-toastify";
import { login } from "../../../actions/authAction";
import { useDispatch } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import LoginForm from "./LoginForm";
import Footer from "../../0-ResuableComponents/footer/Footer";
import {
  encryptPlaintext,
  // decryptCiphertext,
} from "../../0-ResuableComponents/security/cryptoFunctions";

const Login = ({ history }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (recaptchaToken === "") {
      toast.error("select the ReCAPTCHA box");
      return;
    }
    // console.log("SEND LOGIN DATA", { email, password });
    const encryptedPassword = encryptPlaintext(password);
    // console.log("SEND LOGIN DATA", { email, encryptedPassword });
    // console.log("decryptCiphertext====>", decryptCiphertext(encryptedPassword));
    try {
      let res = await login({ email, encryptedPassword });

      if (res.data) {
        // console.log(
        //   "SAVE USER RES IN REDUX AND LOCAL STORAGE THEN REDIRECT ===> "
        // );
        // console.log(res.data);
        // save user and token to local storage
        window.localStorage.setItem("auth", JSON.stringify(res.data));
        // save user and token to redux
        dispatch({
          type: "LOGGED_IN_USER",
          payload: res.data,
        });
        toast.success("Successfully logged-In...");
        // setTimeout(() => {
        //   window.location.reload();
        // }, 0);
        history.push("/dashboard");
      }
    } catch (err) {
      toast.error(err.response?.data?.errorMessage);
    }
  };

  return (
    <div id="layoutSidenav_content" style={{ marginLeft: "28%" }}>
      <main>
        <div className="container-fluid">
          <div className="row">
            <LoginForm
              handleSubmit={handleSubmit}
              email={email}
              setEmail={setEmail}
              password={password}
              setPassword={setPassword}
              ReCAPTCHA={ReCAPTCHA}
              recaptchaToken={recaptchaToken}
              setRecaptchaToken={setRecaptchaToken}
            />
          </div>
          <Footer />
        </div>
      </main>
    </div>
  );
};

export default Login;
