/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const Header = () => {
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => ({ ...state }));
  const history = useHistory();

  const logout = () => {
    dispatch({
      type: "LOGOUT",
      payload: null,
    });
    window.localStorage.removeItem("auth");
    history.push("/");
  };

  const handleClick = () => {
    const sidebar = document.querySelector("#layoutSidenav_nav");
    sidebar.className =
      sidebar.className === "show-sidebar" || sidebar.className === ""
        ? "hide-sidebar"
        : "show-sidebar";
  };

  return (
    <>
      <nav className="sb-topnav navbar navbar-expand navbar-light bg-clr">
        <Link className="navbar-brand logo-brand" to="/dashboard">
          App Factory
        </Link>
        {auth !== null && (
          <>
            <button
              className="btn btn-link btn-sm order-1 order-lg-0"
              id="sidebarToggle"
              style={{ userSelect: "auto" }}
              onClick={handleClick}
            >
              <i
                id="toggle-btn"
                className="fas fa-bars"
                style={{ userSelect: "auto" }}
              />
            </button>
            <Link to="/dashboard" className="frnt-link">
              <i className="fas fa-external-link-alt" />
              Home
            </Link>
            <ul className="navbar-nav ml-auto mr-md-0">
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  id="userDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fas fa-user fa-fw" />
                </a>
                <div
                  className="dropdown-menu dropdown-menu-right"
                  aria-labelledby="userDropdown"
                >
                  {/* <Link className="dropdown-item admin-dropdown-item" to="/profile">
                    Edit Profile
                  </Link> */}
                  <Link
                    className="dropdown-item admin-dropdown-item"
                    to="/change-password"
                  >
                    Change Password
                  </Link>
                  <span
                    className="dropdown-item admin-dropdown-item"
                    style={{ cursor: "pointer" }}
                    onClick={logout}
                  >
                    Logout
                  </span>
                </div>
              </li>
            </ul>
          </>
        )}
      </nav>
    </>
  );
};

export default Header;
