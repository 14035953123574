/* eslint-disable react-hooks/exhaustive-deps */
// HYPER-LINKED TRADES TO A PARTICULAR CATEGORY
import { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import TableSearch from "../../0-ResuableComponents/tableComponents/TableSearch";
import Pagination from "../../0-ResuableComponents/pagination/Pagination";
import CategoryTradesTable from "./CategoryTradesTable";
import { singleCategory } from "../../../actions/categoryActions";
import { allTradesByCategory } from "../../../actions/tradeActions";
import { Button } from "@material-ui/core";

const CreateSubcategory = ({ match }) => {
  const [categoryTradesList, setCategoryTradesList] = useState([]);
  const { auth } = useSelector((state) => ({ ...state }));
  const { token } = auth;
  const history = useHistory();
  const [selectedCategory, setSelectedCategory] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const fetchCategory = async () => {
    let res = await singleCategory(token, match.params.categoryId);
    setSelectedCategory(res.data);
  };

  const fetchAllSubcategories = async () => {
    let res = await allTradesByCategory(token, match.params.categoryId);
    setCategoryTradesList(res.data);
  };

  const redirect = () => {
    history.push("/catalog/categories");
  };

  const tradesData = useMemo(() => {
    let computedCategoryTrades = categoryTradesList?.trades;

    if (search) {
      computedCategoryTrades = computedCategoryTrades?.filter((trade) =>
        trade.name.toLowerCase().includes(search.toLowerCase())
      );
    }

    //Sorting Trades
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedCategoryTrades = computedCategoryTrades?.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    return computedCategoryTrades;
  }, [categoryTradesList, search, sorting]);

  useEffect(() => {
    fetchAllSubcategories();
    fetchCategory();
  }, []);

  // Get current rows
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = tradesData?.slice(indexOfFirstRow, indexOfLastRow);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div id="layoutSidenav_content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 col-md-9 col-lg-6">
              <h2 className="mt-30 page-title">
                {selectedCategory?.category?.name} &#62;
              </h2>
            </div>
            <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
              <Button variant="contained" onClick={redirect}>
                <i className="fas fa-arrow-left" /> Back
              </Button>
            </div>
          </div>
          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li className="breadcrumb-item active">
              <Link to="/catalog/categories">Category</Link>
            </li>
            <li className="breadcrumb-item active">Trades</li>
          </ol>
          <TableSearch
            onSearch={(value) => {
              setSearch(value);
            }}
            placeholder="Search by Trade Name"
          />
          <div className="row">
            <CategoryTradesTable
              categoryTradesList={currentRows}
              setSorting={setSorting}
            />
            <Pagination
              rowsPerPage={rowsPerPage}
              totalRows={categoryTradesList?.trades?.length}
              paginate={paginate}
              setRowsPerPage={setRowsPerPage}
              url={`/catalog/categories/trades/${match.params.categoryId}`}
            />
          </div>
        </div>
    </div>
  );
};

export default CreateSubcategory;
