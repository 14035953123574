/** @format */

import { Modal } from "react-bootstrap";

import DeleteIcon from "@material-ui/icons/Delete";
const GenderList = ["Male", "Female", "Unisex", "Other"];

const ProductForm = ({
  product,
  setProduct,
  handleSubmit,
  brandList,
  subCategoryList,
  handleImageChange,
  handleChange,
  currentId,
  show,
  handleClose,
}) => {
  const {
    name,
    description,
    productHandlingTips = {},
    brandId,
    subcategoryId,
    gender,
    photoUrl,
    warranty,
    guarantee,
    returnConditions,
  } = product;
  const { picking, ordering } = productHandlingTips;
  console.log("photoUrl", photoUrl);
  return (
    <Modal show={show} onHide={handleClose} size='lg'>
      <div>
        <Modal.Header closeButton data-dismiss='modal'>
          <h5 className='modal-title' id='exampleModalLabel'>
            {currentId ? "Update Product" : "+ Add New Product"}
          </h5>
        </Modal.Header>
        <Modal.Body>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-lg-12 col-md-12'>
                <div className='card card-static-2 mb-30'>
                  <div className='card-body-table'>
                    <div className='news-content-right pd-20'>
                      <form onSubmit={handleSubmit}>
                        <div className='row'>
                          <div className='col-lg-6 col-md-6'>
                            <div className='form-group'>
                              <label htmlFor='brandId' className='form-babel'>
                                Brand<span className='required'>&#42;</span>
                              </label>
                              <select
                                name='brandId'
                                className='form-control'
                                onChange={handleChange}
                                value={brandId}
                                // required
                              >
                                <option value=''>None</option>
                                {brandList
                                  ? brandList?.brands?.map((brand, index) => (
                                      <option key={index} value={brand.id}>
                                        {brand.name}
                                      </option>
                                    ))
                                  : "loading..."}
                              </select>
                            </div>
                          </div>

                          <div className='col-lg-6 col-md-6'>
                            <div className='form-group'>
                              <label
                                htmlFor='subcategoryId'
                                className='form-babel'
                              >
                                SubCategory
                                <span className='required'>&#42;</span>
                              </label>
                              <select
                                name='subcategoryId'
                                className='form-control'
                                required
                                value={subcategoryId}
                                onChange={handleChange}
                              >
                                <option value=''>Select</option>
                                {subCategoryList
                                  ? subCategoryList?.subcategories?.map(
                                      (subCat, index) => (
                                        <option key={index} value={subCat.id}>
                                          {subCat.name}
                                        </option>
                                      )
                                    )
                                  : "loading..."}
                              </select>
                            </div>
                          </div>

                          <div className='col-lg-12 '>
                            <div className='form-group'>
                              <label htmlFor='gender' className='form-babel'>
                                Catered to
                                <span className='required'>&#42;</span>
                              </label>
                              <select
                                name='gender'
                                className='form-control'
                                required
                                value={gender}
                                onChange={handleChange}
                              >
                                <option value=''>Select</option>
                                {GenderList
                                  ? GenderList?.map((subCat, index) => (
                                      <option key={index} value={subCat}>
                                        {subCat}
                                      </option>
                                    ))
                                  : "loading..."}
                              </select>
                            </div>
                          </div>

                          <div
                            className='col-lg-12 col-md-11'
                            style={{ paddingTop: "1rem" }}
                          >
                            <div className='form-group'>
                              <label htmlFor='name' className='form-label'>
                                Product Name
                                <span className='required'>&#42;</span>
                              </label>
                              <input
                                type='text'
                                className='form-control'
                                placeholder='Product Name'
                                required
                                name='name'
                                value={name}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div
                            className='col-lg-12 col-md-11'
                            style={{ paddingTop: "1rem" }}
                          >
                            <div className='form-group'>
                              <label htmlFor='photoUrl' className='form-label'>
                                Image<span className='required'>&#42;</span>
                              </label>
                              {photoUrl && typeof photoUrl === "string" ? (
                                <>
                                  <img src={photoUrl} className='cate-img-5' />
                                  <DeleteIcon
                                    onClick={() =>
                                      setProduct({ ...product, photoUrl: "" })
                                    }
                                  />
                                </>
                              ) : (
                                <input
                                  type='file'
                                  className='form-control'
                                  name='photoUrl'
                                  required
                                  onChange={handleImageChange}
                                  accept='image/*'
                                />
                              )}
                              {/* <div className='cate-img-5'>
                                {photoUrl && (
                                  <img src={`${photoUrl}`} alt='product_img' />
                                )}
                              </div> */}
                            </div>
                          </div>

                          <div
                            className='col-lg-12 col-md-11'
                            style={{ paddingTop: "1rem" }}
                          >
                            <div className='form-group'>
                              <label
                                htmlFor='description'
                                className='form-label'
                              >
                                Description
                              </label>
                              <textarea
                                type='text'
                                className='form-control'
                                name='description'
                                placeholder='Description'
                                value={description}
                                onChange={(e) =>
                                  setProduct({
                                    ...product,
                                    description: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>

                          <div
                            className='col-lg-12 col-md-11'
                            style={{ paddingTop: "1rem" }}
                          >
                            <div className='form-group'>
                              <label htmlFor='ordering' className='form-label'>
                                Tips for the user
                              </label>
                              <textarea
                                type='text'
                                className='form-control'
                                name='ordering'
                                placeholder='Tips for the user'
                                value={ordering}
                                onChange={(e) =>
                                  setProduct({
                                    ...product,
                                    productHandlingTips: {
                                      ...product.productHandlingTips,
                                      ordering: e.target.value,
                                    },
                                  })
                                }
                              />
                            </div>
                          </div>

                          <div
                            className='col-lg-12 col-md-11'
                            style={{ paddingTop: "1rem" }}
                          >
                            <div className='form-group'>
                              <label htmlFor='picking' className='form-label'>
                                Tips for the deliverer
                              </label>
                              <textarea
                                type='text'
                                className='form-control'
                                name='picking'
                                placeholder='Tips for the deliverer'
                                value={picking}
                                onChange={(e) =>
                                  setProduct({
                                    ...product,
                                    productHandlingTips: {
                                      ...product.productHandlingTips,
                                      picking: e.target.value,
                                    },
                                  })
                                }
                              />
                            </div>
                            <div className='form-group'>
                              <label htmlFor='guarantee' className='form-label'>
                                Guarantee Details
                                <span className='required'>&#42;</span>
                              </label>
                              <textarea
                                type='text'
                                className='form-control'
                                name='guarantee'
                                placeholder='Guarantee details of the product'
                                value={guarantee}
                                required
                                onChange={(e) =>
                                  setProduct({
                                    ...product,
                                    guarantee: e.target.value,
                                  })
                                }
                              />
                            </div>
                            <div className='form-group'>
                              <label htmlFor='warranty' className='form-label'>
                                Warranty Details
                                <span className='required'>&#42;</span>
                              </label>
                              <textarea
                                type='text'
                                className='form-control'
                                name='warranty'
                                required
                                placeholder='Warranty details of the product'
                                value={warranty}
                                onChange={(e) =>
                                  setProduct({
                                    ...product,

                                    warranty: e.target.value,
                                  })
                                }
                              />
                            </div>
                            <div className='form-group'>
                              <label
                                htmlFor='returnConditions'
                                className='form-label'
                              >
                                Return conditions
                                <span className='required'>&#42;</span>
                              </label>
                              <textarea
                                type='text'
                                className='form-control'
                                required
                                name='returnConditions'
                                placeholder='Return conditions (timeline & other conditions)'
                                value={returnConditions}
                                onChange={(e) =>
                                  setProduct({
                                    ...product,

                                    returnConditions: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                          <button className='save-btn hover-btn' type='submit'>
                            {currentId ? "Update" : "Add New"}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default ProductForm;
