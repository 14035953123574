import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 350,
    maxWidth: 400,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MultiSelectBrands({
  selectedIds,
  List,
  setFieldValue,
  name,
  heading,
}) {
  const classes = useStyles();
  const isAllSelected = List.length > 0 && selectedIds.length === List.length;

  const handleChange = (e) => {
    const value = e.target.value;
    if (value[value.length - 1] === "all") {
      setFieldValue(
        name,
        selectedIds.length === List.length
          ? []
          : List.map((category) => category.id)
      );
      return;
    }
    setFieldValue(name, value);
  };

  return (
    <div style={{ display: "inline-block", marginTop: "4%" }}>
      <FormControl className={classes.formControl} variant="outlined">
        <InputLabel id="demo-mutiple-checkbox-label" className="form-label">
          {heading}
          <span className="required">&#42;</span>
        </InputLabel>
        <Select
          labelId="demo-mutiple-checkbox-label"
          id="demo-mutiple-checkbox"
          multiple
          value={selectedIds}
          onChange={handleChange}
          input={<Input />}
          renderValue={(selected) =>
            List?.filter((brand) => selected.includes(brand.id))
              .map((brand) => brand.name)
              .join(", ")
          }
          MenuProps={MenuProps}
        >
          {List.length > 1 && (
            <MenuItem
              value="all"
              classes={{
                root: isAllSelected ? classes.selectedAll : "",
              }}
            >
              <ListItemIcon>
                <Checkbox
                  classes={{ indeterminate: classes.indeterminateColor }}
                  checked={isAllSelected}
                  indeterminate={
                    selectedIds.length > 0 && selectedIds.length < List.length
                  }
                />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.selectAllText }}
                primary="Select All"
              />
            </MenuItem>
          )}

          {List?.map((category) => (
            <MenuItem key={category._id} value={category.id}>
              <Checkbox checked={selectedIds.indexOf(category.id) > -1} />
              <ListItemText primary={category.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
