/**
 * eslint-disable react-hooks/exhaustive-deps
 *
 * @format
 */
import { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import { toast } from "react-toastify";
import TableSearch from "../../0-ResuableComponents/tableComponents/TableSearch";
import Pagination from "../../0-ResuableComponents/pagination/Pagination";
import ConfirmDialog from "../../0-ResuableComponents/confirmDialog/ConfirmDialog";
import SubCategoryForm from "./SubCategoryForm";
import CategorySubcategoriesTable from "./CategorySubcategoriesTable";
import { singleCategory } from "../../../actions/categoryActions";
import {
  createNewSubCategory,
  updateSubCategory,
  deleteSubCategory,
  allSubcategoriesByCategory,
} from "../../../actions/subCategoryActions";

const CreateSubcategory = ({ match }) => {
  const [subCategory, setSubCategory] = useState({
    name: "",
    InputFilters: [],
  });
  const { auth } = useSelector((state) => ({ ...state }));
  const { token } = auth;
  const history = useHistory();
  const [selectedCategory, setSelectedCategory] = useState("");
  const [resetChildForm, setResetChildForm] = useState(false);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [currentId, setCurrentId] = useState(0);
  const [subCategoryFilters, setSubCategoryFilters] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [search, setSearch] = useState("");
  const [filtersApplicable, setFiltersApplicable] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [show, setShow] = useState(false);
  const [InputFilters, setInputFilters] = useState([]);
  const handleClose = () => {
    setShow(false);
    clear();
    setResetChildForm(true);
  };

  const handleShow = () => setShow(true);
  const singleSubCategory = currentId
    ? subCategoryList?.subcategories.find(
        (category) => category._id === currentId
      )
    : null;

  const fetchAllSubcategories = async () => {
    let res = await allSubcategoriesByCategory(token, match.params.categoryId);
    setSubCategoryList(res.data);
  };

  const fetchCategory = async () => {
    let res = await singleCategory(token, match.params.categoryId);
    setSelectedCategory(res.data);
  };
  const fetchSubCategoryFilters = async () => {
    try {
      const res = await allSubcategoriesByCategory(
        token,
        match.params.categoryId
      );
      setSubCategoryFilters(res.data.filters);
     
    } catch (error) {
      console.error(error);
    }
  };

  const redirect = () => {
    history.push("/catalog/categories");
  };

  useEffect(() => {
    fetchAllSubcategories();
    fetchCategory();
    if (singleSubCategory) setSubCategory(singleSubCategory);
    fetchSubCategoryFilters();
  }, [currentId]);

  const subcategoriesData = useMemo(() => {
    let computedSubcategories = subCategoryList?.subcategories;

    if (search) {
      computedSubcategories = computedSubcategories?.filter((subCategory) =>
        subCategory.name.toLowerCase().includes(search.toLowerCase())
      );
    }

    //Sorting Subcategories
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedSubcategories = computedSubcategories?.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    return computedSubcategories;
  }, [subCategoryList, search, sorting]);

  const clear = () => {
    setCurrentId(0);
    setSubCategory({
      name: "",
      InputFilters: [],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (currentId === 0) {
       
        await createNewSubCategory(token, {
          name: subCategory.name,
          filters: InputFilters,
          haveFilters: filtersApplicable,
          categoryId: match.params.categoryId,
        });

        fetchAllSubcategories();
        toast.success(
          `New Sub-Category ${subCategory.name} is added Successfully...`
        );
        clear();
        setResetChildForm(true);
        handleClose();
      } else {
        setConfirmDialog({
          isOpen: true,
          title: "Are you sure to Update this record?",
          subTitle: "You can't undo this operation",
          onConfirm: async () => {
            setConfirmDialog({
              ...confirmDialog,
              isOpen: false,
            });
            await updateSubCategory(
              token,
              subCategory,
              currentId
            )
              .then(() => {
                fetchAllSubcategories();
                toast.success(
                  `Sub-Category ${subCategory.name} is updated Successfully...`
                );
                clear();
                handleClose();
              })
              .catch((err) => toast.error(err.response.data.errorMessage));
          },
        });
      }
    } catch (err) {
      toast.error(err.response.data.errorMessage);
    }
  };

  const handleCategoryDelete = async (subCategoryId) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    deleteSubCategory(token, subCategoryId)
      .then((res) => {
        toast.success("Sub-Category Deleted successfully...");
        fetchAllSubcategories();
      })
      .catch((err) => toast.error(err.response.data.errorMessage));
  };

  // Get current rows
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const [currentRows, setCurrentRows] = useState([]);
  useEffect(()=>{
    const currentRowsToPush = subcategoriesData?.slice(indexOfFirstRow, indexOfLastRow);
    setCurrentRows(currentRowsToPush);
  }, [subcategoriesData, subCategoryList]);
  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const handleFiltersApplicableChange = (value) => {
    setFiltersApplicable(value);
  };
  return (
    <div id='layoutSidenav_content'>
      <main>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-5 col-md-9 col-lg-6'>
              <h2 className='mt-30 page-title'>
                {selectedCategory?.category?.name} &#62;
              </h2>
            </div>
            <div className='col-lg-5 col-md-3 col-lg-6 back-btn'>
              <Button variant='contained' onClick={redirect}>
                <i className='fas fa-arrow-left' /> Back
              </Button>
            </div>
          </div>
          <ol className='breadcrumb'>
            <li className='breadcrumb-item'>
              <Link to='/dashboard'>Dashboard</Link>
            </li>
            <li className='breadcrumb-item active'>
              <Link to='/catalog/categories'>Category</Link>
            </li>
            <li className='breadcrumb-item active'>Sub-Categories</li>
          </ol>
          <div className='col-lg-12 col-md-12 back-btn'>
            <button
              className='save-btn hover-btn'
              onClick={() => {
                setCurrentId(0);
                handleShow();
                clear();
              }}
            >
              + Add New Sub-Category
            </button>
          </div>
          <TableSearch
            onSearch={(value) => {
              setSearch(value);
            }}
            placeholder='Search by Sub-Category Name'
          />
          <SubCategoryForm
            subCategory={subCategory}
            setSubCategory={setSubCategory}
            handleSubmit={handleSubmit}
            currentId={currentId}
            show={show}
            InputFilters={InputFilters}
            setInputFilters={setInputFilters}
            addFilterInput={(data) => {
              setSelectedCategory({ ...subCategory, filters: [...data] });
            }}
            subCategoryFilters={subCategoryFilters}
            handleClose={handleClose}
            resetForm={resetChildForm}
            filtersApplicable={filtersApplicable}
            onFiltersApplicableChange={handleFiltersApplicableChange}
            setResetForm={setResetChildForm}
          />
          <div className='row'>
            <CategorySubcategoriesTable
              subCategoryList={currentRows}
              fetchAllSubcategories={fetchAllSubcategories}
              setCurrentId={setCurrentId}
              handleCategoryDelete={handleCategoryDelete}
              setConfirmDialog={setConfirmDialog}
              setSorting={setSorting}
              handleShow={handleShow}
            />
            <Pagination
              rowsPerPage={rowsPerPage}
              totalRows={subCategoryList?.subcategories?.length}
              paginate={paginate}
              setRowsPerPage={setRowsPerPage}
              url={`/categories/subcategories/${match.params.categoryId}`}
            />
          </div>
        </div>
        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />
      </main>
    </div>
  );
};

export default CreateSubcategory;
