/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button } from "@material-ui/core";
import { toast } from "react-toastify";
import TableSearch from "../../0-ResuableComponents/tableComponents/TableSearch";
import Pagination from "../../0-ResuableComponents/pagination/Pagination";
import ConfirmDialog from "../../0-ResuableComponents/confirmDialog/ConfirmDialog";
import VariantForm from "./VariantForm";
import ServiceVariantsTable from "./ServiceVariantsTable";
// import { singleProduct } from "../../../actions/productActions";
import {createNewVariant, updateVariant, deleteVariant,allVariantsByService, single_Variant } from "../../../actions/serviceVariantActions";

const CreateVariant = ({ match }) => {
  const [variant, setVariant] = useState({
    name: "",
    type: "",
    productId: "",
    quantity: "",
    volume: { unit: "", value: "" },
    weight: { unit: "", value: "" },
    photoUrl: "",
    price: { mrp: "", min: "", max: "" },
    purchaseQtyLimit: "",
    status: "",
  });
  const { auth } = useSelector((state) => ({ ...state }));
  const { token } = auth;
  const history = useHistory();
  const [variantList, setVariantList] = useState([]);
  const [currentId, setCurrentId] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(1000);
  const [search, setSearch] = useState("");
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const singleVariant = currentId
    ? variantList.variants.find((variant) => variant._id === currentId)
    : null;

  const fetchService = async () => {
    let res = await single_Variant(token, match.params.serviceId);
    setSelectedProduct(res.data);
  };

  const fetchAllVariants = async () => {
    let res = await allVariantsByService(token, match.params.serviceId);
    setVariantList(res.data);
  };

  const redirect = () => {
    history.push("/services");
  };

  const {
    name,
    type,
    quantity,
    volume,
    weight,
    photoUrl,
    price,
    purchaseQtyLimit,
    status,
  } = variant;

  useEffect(() => {
    fetchAllVariants();
    fetchService();
    if (singleVariant) setVariant(singleVariant);
  }, [currentId]);

  const clear = () => {
    setCurrentId(0);
    setVariant({
      name: "",
      type: "",
      quantity: "",
      volume: { unit: "", value: "" },
      weight: { unit: "", value: "" },
      photoUrl: "",
      price: { mrp: "", min: "", max: "" },
      purchaseQtyLimit: "",
      status: "",
    });
  };

  const variantsData = useMemo(() => {
    let computedVariants = variantList?.variants;

    if (search) {
      computedVariants = computedVariants?.filter((variant) =>
        variant?.name?.toLowerCase().includes(search.toLowerCase())
      );
    }

    //Sorting variants
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedVariants = computedVariants?.sort(
        (a, b) => reversed * a[sorting.field]?.localeCompare(b[sorting.field])
      );
    }

    return computedVariants;
  }, [variantList, search, sorting]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let variantData = new FormData();
    variantData.append("name", name);
    variantData.append("type", type);
    variantData.append("serviceId", match.params.serviceId);
    variantData.append("quantity", quantity);
    variantData.append(`volume.unit`, volume.unit);
    variantData.append(`volume.value`, volume.value);
    variantData.append(`weight.unit`, weight.unit);
    variantData.append(`weight.value`, weight.value);
    photoUrl && variantData.append("photoFile", photoUrl);
    variantData.append(`mrp`, price.mrp || "");
    variantData.append(`minPrice`, price.min || "");
    variantData.append(`maxPrice`, price.max || "");
    variantData.append("purchaseQtyLimit", purchaseQtyLimit);
    variantData.append("status", status);
    try {
      if (currentId === 0) {
        await createNewVariant(token, variantData);
        fetchAllVariants();
        toast.success(
          `Your New Variant ${variant.name} is added Successfully...`
        );
        clear();
        handleClose();
      } else {
        setConfirmDialog({
          isOpen: true,
          title: "Are you sure to Update this record?",
          subTitle: "You can't undo this operation",
          onConfirm: async () => {
            setConfirmDialog({
              ...confirmDialog,
              isOpen: false,
            });
            await updateVariant(token, variantData, currentId)
              .then(() => {
                fetchAllVariants();
                toast.success(
                  `Your Variant ${variant.name} is updated Successfully...`
                );
                clear();
                handleClose();
              })
              .catch((err) => toast.error(err.response.data.errorMessage));
          },
        });
      }
    } catch (err) {
      toast.error(err.response.data.errorMessage);
    }
  };

  const handleImageChange = (e) => {
    setVariant({ ...variant, photoUrl: e.target.files[0] });
  };

  const handleChange = (e) => {
    setVariant({ ...variant, [e.target.name]: e.target.value });
  };

  const handleVariantDelete = async (variantId) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    deleteVariant(token, variantId)
      .then((res) => {
        toast.success("Variant Deleted successfully...");
        fetchAllVariants();
      })
      .catch((err) => toast.error(err.response.data.errorMessage));
  };

  // Get current rows
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = variantsData?.slice(indexOfFirstRow, indexOfLastRow);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5 col-md-9 col-lg-6">
              <h2 className="mt-30 page-title">
                {selectedProduct?.product?.name} &#62;
              </h2>
            </div>
            <div className="col-lg-5 col-md-3 col-lg-6 back-btn">
              <Button variant="contained" onClick={redirect}>
                <i className="fas fa-arrow-left" /> Back
              </Button>
            </div>
          </div>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li className="breadcrumb-item active">
              <Link to="/services">Service</Link>
            </li>
            <li className="breadcrumb-item active">Variants</li>
          </ol>
          <div className="col-lg-12 col-md-12 back-btn">
            <button
              className="save-btn hover-btn"
              onClick={() => {
                setCurrentId(0);
                handleShow();
                // clear();
              }}
            >
              + Add New Variant
            </button>
          </div>
          <TableSearch
            onSearch={(value) => {
              setSearch(value);
            }}
            placeholder="Search by Variant Name"
          />
          <VariantForm
            variant={variant}
            setVariant={setVariant}
            handleSubmit={handleSubmit}
            handleImageChange={handleImageChange}
            handleChange={handleChange}
            currentId={currentId}
            show={show}
            handleClose={handleClose}
          />
          <div className="row">
            <ServiceVariantsTable
              variantList={currentRows}
              setCurrentId={setCurrentId}
              handleVariantDelete={handleVariantDelete}
              setConfirmDialog={setConfirmDialog}
              setSorting={setSorting}
              handleShow={handleShow}
            />
            <Pagination
              rowsPerPage={rowsPerPage}
              totalRows={variantList?.variants?.length}
              paginate={paginate}
              setRowsPerPage={setRowsPerPage}
              url={`/services/variants/${match.params.serviceId}`}
            />
          </div>
        </div>
        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />
      </main>
    </div>
  );
};

export default CreateVariant;
