import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { IconButton } from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { allTrades } from "../../../actions/tradeActions";

const TradeForm = ({
  selectedZone,
  showEditTrades,
  handleUpdateZone,
  show,
  handleClose,
}) => {
  const { auth } = useSelector((state) => ({ ...state }));
  const { token } = auth;
  const status = ["Active", "Inactive", "Hidden"];
  const [tradesFromDB, setTradesFromDB] = useState([]);
  const [zone, setZone] = useState(null);
  // const timeNow = moment().format('MMM Do YY, h:mm a')
  // const date = () => {
  //   var today = new Date();
  //   var dd = String(today.getDate()).padStart(2, "0");
  //   var mm = String(today.getMonth() + 1).padStart(2, "0");
  //   var yyyy = today.getFullYear();
  //   today = yyyy + "/" + mm + "/" + dd;
  //   return today;
  // };

  const fetchAllTrades = async () => {
    let res = await allTrades(token);
    setTradesFromDB(res.data.trades);
  };

  useEffect(() => {
    setZone(selectedZone);
    fetchAllTrades();
  }, [selectedZone]);

  // console.log(zone);

  const addTrades = () => {
    const updateTrades = zone.trades;
    updateTrades.push({
      trade: "",
      activationDate: "",
      deactivationDate: "",
      status: "Active",
    });
    setZone({ ...zone, trades: updateTrades });
  };

  const changeStatus = (index, value) => {
    const updateTrades = zone.trades;
    updateTrades[index].status = value;
    // if (value === "Inactive") {
    //   updateTrades[index].deactivationDate = date();
    // }
    setZone({ ...zone, trades: updateTrades });
  };

  const removeTrade = (index) => {
    const updateTrades = zone.trades;
    updateTrades.splice(index, 1);
    setZone({ ...zone, trades: updateTrades });
  };

  const changeTrade = (index, value) => {
    // console.log(value);
    const updateTrades = zone.trades;
    updateTrades[index].trade = value;
    // if (updateTrades[index].status = "") {
    // updateTrades[index].status = "Active";
    // updateTrades[index].activationDate = date();
    // }
    setZone({ ...zone, trades: updateTrades });
  };

  const changeActionvationDate = (index, value) => {
    const updateTrades = zone.trades;
    updateTrades[index].activationDate = value;
    setZone({ ...zone, trades: updateTrades });
  };

  const changeDeActionvationDate = (index, value) => {
    const updateTrades = zone.trades;
    updateTrades[index].deactivationDate = value;
    setZone({ ...zone, trades: updateTrades });
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton data-dismiss="modal">
        <h3 className="m-1"> UPDATE TRADES</h3>
      </Modal.Header>
      <Modal.Body>
        {zone && (
          <div className="col-lg-12 col-md-11">
            <div className="card card-static-2 mb-30" style={{ width: "100%" }}>
              <div className="card-body-table" style={{ width: "100%" }}>
                <div className="news-content-right pd-20">
                  <table
                    className="table ucp-table table-hover"
                    style={{ width: "100%" }}
                  >
                    <thead>
                      <tr>
                        <th scope="col">Trade Name</th>
                        <th scope="col">Date of Activation</th>
                        <th scope="col">Date of Deactivation</th>
                        <th scope="col-lg">Status</th>
                        <th scope="col">Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {zone?.trades?.map((trade, index) => (
                        <tr key={index}>
                          <select
                            style={{
                              border: 0,
                              width: "100%",
                              marginTop: 10,
                              marginLeft: 5,
                            }}
                            name="type"
                            className="form-control"
                            required
                            value={trade.trade}
                            onChange={(e) => changeTrade(index, e.target.value)}
                          >
                            <option value="">Select Trade</option>
                            {tradesFromDB &&
                              tradesFromDB.map((value, index) => (
                                <option key={index} value={value.name}>
                                  {value.name}
                                </option>
                              ))}
                          </select>
                          <td>
                            {/* {trade.activationDate
                              ? moment(trade.activationDate).format("lll")
                              : "NA"} */}
                            <input
                              className="form-control"
                              type="date"
                              name="validFrom"
                              defaultValue={trade?.activationDate?.slice(0, 10)}
                              onChange={(e) =>
                                changeActionvationDate(index, e.target.value)
                              }
                            />
                          </td>
                          <td>
                            {/* {trade.deactivationDate
                              ? moment(trade.deactivationDate).format("lll")
                              : "NA"} */}
                            <input
                              className="form-control"
                              type="date"
                              name="validFrom"
                              defaultValue={trade?.deactivationDate?.slice(
                                0,
                                10
                              )}
                              onChange={(e) =>
                                changeDeActionvationDate(index, e.target.value)
                              }
                            />
                          </td>
                          <td>
                            <select
                              style={{ border: 0, width: "100%" }}
                              name="type"
                              className="form-control"
                              value={trade?.status}
                              required
                              onChange={(e) =>
                                changeStatus(index, e.target.value)
                              }
                            >
                              {status.map((value, index) => (
                                <option key={index} value={value}>
                                  {value}
                                </option>
                              ))}
                            </select>
                          </td>
                          <td>
                            <IconButton onClick={() => removeTrade(index)}>
                              <DeleteOutlineIcon />
                            </IconButton>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div
                    className="button-class"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <button className="save-btn hover-btn" onClick={addTrades}>
                      + Trades
                    </button>
                    <button
                      className="save-btn hover-btn"
                      onClick={() => handleUpdateZone(zone, zone._id)}
                    >
                      SAVE CHANGES
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default TradeForm;
