/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-target-blank */
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import { singleEntrepreneur } from "../../../actions/entrepreneurActions";
import EntrepreneurKycTable from "./EntrepreneurKycTable";
import EntrepreneurAssignmentsTable from "./EntrepreneurAssignmentsTable";

const EntrepreneurProfile = ({ match }) => {
  const { auth } = useSelector((state) => ({ ...state }));
  const [entrepreneur, setEntrepreneur] = useState("");

  const fetchEntrepreneur = async () => {
    let res = await singleEntrepreneur(auth.token, match.params.entrepreneurId);
    setEntrepreneur(res.data.entrepreneur);
  };

  useEffect(() => {
    fetchEntrepreneur();
  }, []);

  return (
    <div id="layoutSidenav_content" style={{ userSelect: "auto" }}>
      <main>
        <div className="container-fluid">
          <h2 className="mt-30 page-title">{entrepreneur?.name}</h2>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/homies">Entrepreneurs</Link>
            </li>
            <li className="breadcrumb-item active">Entrepreneur view</li>
          </ol>
          <ol className="col-lg-12 col-md-12 back-btn">
            <Link
              to={`/homies/edit/${entrepreneur?._id}`}
              style={{ textDecoration: "none" }}
            >
              <button className="save-btn hover-btn">
                Update Entrepreneur
              </button>
            </Link>
          </ol>
          <div className="row">
            <div className="col-lg-4 col-md-5">
              <div className="card card-static-2 mb-30">
                <div className="card-body-table">
                  <div className="shop-content-left pd-20">
                    {entrepreneur?.photoUrl ? (
                      <div className="shop_img">
                        <img
                          src={entrepreneur?.photoUrl}
                          alt="entrepreneur_pic"
                        />
                      </div>
                    ) : (
                      "-"
                    )}

                    <div className="shop-dt-left">
                      <h4 style={{ marginLeft: "20%" }}>
                        {entrepreneur?.status === "Active" ? (
                          <span>&#128994;</span>
                        ) : entrepreneur?.status === "Inactive" ? (
                          <span>&#128308;</span>
                        ) : (
                          <span>&#128992;</span>
                        )}{" "}
                        {entrepreneur?.name}
                      </h4>
                      <span>
                        {entrepreneur?.city}, {entrepreneur?.state}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card card-static-2 mb-30">
                <div className="card-body-table">
                  <div className="shopowner-content-left pd-20">
                    <div className="shopowner-dt-left">
                      <h4>{entrepreneur?.name}</h4>
                      <span>Entrepreneur</span>
                    </div>
                    <div className="shopowner-dts">
                      <div className="shopowner-dt-list">
                        <span className="left-dt">Username</span>
                        <span className="right-dt">{entrepreneur?.name}</span>
                      </div>
                      <div className="shopowner-dt-list">
                        <span className="left-dt">Phone</span>
                        <span className="right-dt">{entrepreneur?.phone}</span>
                      </div>
                      <div className="shopowner-dt-list">
                        <span className="left-dt">What's App</span>
                        <span className="right-dt">
                          {entrepreneur?.whatsappNumber}
                        </span>
                      </div>
                      <div className="shopowner-dt-list">
                        <span className="left-dt">Alternate Number</span>
                        <span className="right-dt">
                          {entrepreneur?.alternateNumber}
                        </span>
                      </div>
                      <div className="shopowner-dt-list">
                        <span className="left-dt">Email</span>
                        <span className="right-dt">{entrepreneur?.email}</span>
                      </div>
                      <div className="shopowner-dt-list">
                        <span className="left-dt">City</span>
                        <span className="right-dt">{entrepreneur?.city}</span>
                      </div>
                      <div className="shopowner-dt-list">
                        <span className="left-dt">State</span>
                        <span className="right-dt">{entrepreneur?.state}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-7">
              <div className="card card-static-2 mb-30">
                <div className="card-body-table">
                  <div className="shopowner-content-left pd-20">
                    <div className="shopowner-dts mt-0">
                      <div className="shopowner-dt-list">
                        <span className="left-dt">Entity</span>
                        <span className="right-dt">
                          {entrepreneur?.registration?.entity}
                        </span>
                      </div>
                      <div className="shopowner-dt-list">
                        <span className="left-dt">Registration Proof</span>
                        <span className="right-dt">
                          {entrepreneur?.registration?.registrationProofUrl ? (
                            <a
                              href={
                                entrepreneur?.registration?.registrationProofUrl
                              }
                              title="View"
                              target="_blank"
                              // download
                            >
                              <PictureAsPdfIcon
                                color="secondary"
                                fontSize="large"
                              />
                            </a>
                          ) : (
                            "-"
                          )}
                        </span>
                      </div>
                      <div className="shopowner-dt-list">
                        <span className="left-dt">Entity PAN Number</span>
                        <span className="right-dt">
                          {entrepreneur?.registration?.entityPanNumber}
                        </span>
                      </div>
                      <div className="shopowner-dt-list">
                        <span className="left-dt">Entity PAN Card</span>
                        <span className="right-dt">
                          {entrepreneur?.registration?.entityPanUrl ? (
                            <div className="shop_img">
                              <a
                                href={entrepreneur?.registration?.entityPanUrl}
                                title="View"
                                target="_blank"
                                download
                                className="view-shop-btn"
                              >
                                <img
                                  src={entrepreneur?.registration?.entityPanUrl}
                                  alt="entity_pan"
                                />
                              </a>
                            </div>
                          ) : (
                            "-"
                          )}
                        </span>
                      </div>
                      <div className="shopowner-dt-list">
                        <span className="left-dt">GST Status</span>
                        <span className="right-dt">
                          {entrepreneur?.registration?.gst?.status}
                        </span>
                      </div>
                      {entrepreneur?.registration?.gst?.status ===
                      "Available" ? (
                        <div className="shopowner-dt-list">
                          <span className="left-dt">GST Proof</span>
                          <span className="right-dt">
                            <a
                              href={
                                entrepreneur?.registration?.gst
                                  ?.gstCertificateUrl
                              }
                              without
                              rel="noopener noreferrer"
                              target="_blank"
                              // title="View"
                            >
                              <PictureAsPdfIcon
                                color="secondary"
                                fontSize="large"
                              />
                            </a>
                          </span>
                        </div>
                      ) : null}

                      <div className="shopowner-dt-list">
                        <span className="left-dt">Status</span>
                        <span
                          className="right-dt"
                          style={{
                            color: `${
                              entrepreneur?.status === "Active"
                                ? "green"
                                : entrepreneur?.status === "Inactive"
                                ? "red"
                                : "orange"
                            }`,
                          }}
                        >
                          {entrepreneur?.status}
                        </span>
                      </div>
                      <div className="shopowner-dt-list">
                        <span className="left-dt">Account Holder</span>
                        <span className="right-dt">
                          {entrepreneur?.registration?.bankDetails?.accountName}
                        </span>
                      </div>
                      <div className="shopowner-dt-list">
                        <span className="left-dt">Account Number</span>
                        <span className="right-dt">
                          {
                            entrepreneur?.registration?.bankDetails
                              ?.accountNumber
                          }
                        </span>
                      </div>
                      <div className="shopowner-dt-list">
                        <span className="left-dt">Account Type</span>
                        <span className="right-dt">
                          {entrepreneur?.registration?.bankDetails?.accountType}
                        </span>
                      </div>
                      <div className="shopowner-dt-list">
                        <span className="left-dt">IFSC</span>
                        <span className="right-dt">
                          {entrepreneur?.registration?.bankDetails?.ifsc}
                        </span>
                      </div>
                      <div className="shopowner-dt-list">
                        <span className="left-dt">Bank</span>
                        <span className="right-dt">
                          {entrepreneur?.registration?.bankDetails?.bankName}
                        </span>
                      </div>
                      <div className="shopowner-dt-list">
                        <span className="left-dt">Delivery Slots</span>
                        <span className="right-dt">09.00 AM</span>
                      </div>
                      <div className="shopowner-dt-list">
                        <span className="left-dt">Address</span>
                        <span className="right-dt">
                          {entrepreneur?.completeAddress}
                        </span>
                      </div>
                      {/* <div className="shopowner-dt-list">
                        <span className="left-dt">Description</span>
                        <span className="right-dt">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Nullam vulputate, purus at tempor blandit, nulla
                          felis dictum eros, sed volutpat odio sapien id lectus.
                        </span>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <EntrepreneurKycTable entrepreneur={entrepreneur} />
          <EntrepreneurAssignmentsTable entrepreneur={entrepreneur} />
        </div>
      </main>
      <footer className="py-4 bg-footer mt-auto">
        <div className="container-fluid">
          <div className="d-flex align-items-center justify-content-between small">
            <div className="text-muted-1">
              © 2021 <b>App Factory</b>
            </div>
            <div className="footer-links">
              <Link to="/">Privacy Policy</Link>
              <Link to="/">Terms &amp; Conditions</Link>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default EntrepreneurProfile;
