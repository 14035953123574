// import { useState } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import moment from "moment";
// import Map from "../map/Map";

const ZoneTable = ({
  zoneList,
  setConfirmDialog,
  handleZoneDelete,
  setCurrentZone,
  // coordinates,
  // handleUpdateZone,
  displayEditZoneModal,
  displayEditTradesModal,
  handleShow,
  handleShowTrade,
  setSelectedZone,
}) => {
  // const [mapShow, setMapShow] = useState(false);

  // const findActiveTrades = (zone) => {
  //   let activeTrades = 0;
  //   zone?.trades?.forEach((trade) => {
  //     if (trade.status === "Active") {
  //       activeTrades++;
  //     }
  //   });
  //   return activeTrades;
  // };

  return (
    <>
      {zoneList ? (
        <div className="col-lg-12 col-md-7">
          <div className="all-cate-tags">
            <div className="row justify-content-between">
              <div className="col-lg-12 col-md-12">
                <div className="card card-static-2 mb-30">
                  <div className="card-title-2">
                    <h4>All Zones</h4>
                  </div>
                  <div className="card-body-table">
                    <div className="table-responsive">
                      <table className="table ucp-table table-hover">
                        <thead>
                          <tr>
                            <th scope="col">Zone ID</th>
                            <th scope="col">Area</th>
                            <th scope="col">Status</th>
                            <th scope="col">Estimated Familes</th>
                            <th scope="col">Added By</th>
                            <th scope="col">Added on</th>
                            <th scope="col">Updated By</th>
                            <th scope="col">Updated On</th>
                            <th scope="col">Trades</th>
                            {/* <th scope='col'>Edit Details</th> */}
                            <th scope="col">Edit Cordinates</th>
                            <th scope="col">Delete</th>
                          </tr>
                        </thead>
                        <tbody>
                          {zoneList?.map((zone) => (
                            <tr key={zone?._id}>
                              <td>{zone?.id}</td>
                              <td>{zone?.area}</td>
                              <td
                                style={{
                                  color: `${
                                    zone?.status === "Active"
                                      ? "green"
                                      : zone?.status === "Inactive"
                                      ? "red"
                                      : "orange"
                                  }`,
                                }}
                              >
                                {zone?.status}
                              </td>
                              <td>{zone?.estimatedFamilies}</td>
                              <td>
                                {zone?.metaInfo?.added?.by?.name
                                  ? zone?.metaInfo?.added?.by?.name
                                  : "-"}
                              </td>
                              <td>
                                {moment(zone?.metaInfo?.added?.on).format(
                                  "lll"
                                )}
                              </td>
                              <td>{zone?.metaInfo?.updated?.by?.name}</td>
                              <td>
                                {moment(zone?.metaInfo?.updated?.on).format(
                                  "lll"
                                )}
                              </td>
                              <td
                                style={{ cursor: "pointer", color: "blue" }}
                                onClick={() => {
                                  handleShowTrade();
                                  setCurrentZone(zone);
                                  displayEditTradesModal(zone);
                                  setSelectedZone(zone);
                                }}
                              >
                                {zone.trades.length} active
                              </td>
                              <td>
                                <i
                                  className="fas fa-edit"
                                  style={{
                                    cursor: "pointer",
                                    marginLeft: 40,
                                  }}
                                  onClick={() => {
                                    handleShow();
                                    setSelectedZone(zone);
                                    displayEditZoneModal(zone);
                                    setCurrentZone(zone);
                                  }}
                                />
                              </td>
                              <td>
                                <DeleteIcon
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    setConfirmDialog({
                                      isOpen: true,
                                      title:
                                        "Are you sure you want to delete this record?",
                                      subTitle: "You cant undo this operation",
                                      onConfirm: () => {
                                        handleZoneDelete(zone._id);
                                      },
                                    });
                                  }}
                                />
                              </td>
                            </tr>
                            //   /* {mapShow && (
                            //   <tr>
                            //     <td colSpan='6'>{mapShow ? <Map zoneList={zoneList}/>: null}</td>
                            //     <td colSpan='6'>{mapShow ? <EditCoordinates coordinates={zoneList}/> : null }</td>
                            //   </tr>
                            // )} */
                            //   /* <tr>
                            // <td colSpan='6'>{mapShow && <EditCoordinates coordinates={zoneList}/>}</td>
                            // </tr> */
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ZoneTable;
