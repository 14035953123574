import axios from 'axios';
import { BASE_URL_CITY_MANAGEMENT } from './api';
// const BASE_URL_CITY_MANAGEMENT = "http://localhost:8082";

//GET ALL Cities
export const getAllCities = async (token) =>
  await axios.get(`${BASE_URL_CITY_MANAGEMENT}/city`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

//ADD A NEW CITY
export const addNewCity = async (token, data) =>
  await axios.post(`${BASE_URL_CITY_MANAGEMENT}/city`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

//UPDATE CITY BY ID
export const updateCityByID = async (data, cityId, token) =>
  await axios.put(`${BASE_URL_CITY_MANAGEMENT}/city/${cityId}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

//DELETE CITY BY ID
export const deleteCityByID = async (token, cityId) =>
  await axios.delete(`${BASE_URL_CITY_MANAGEMENT}/city/${cityId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

//FOR CITY DUMP (TO GET STATES AND CITIES)

//GET ALL States
export const getAllStates = async (token) =>
  await axios.get(`${BASE_URL_CITY_MANAGEMENT}/locations/states`, {
    headers: {
      Authorization: `Bearer ${token}`
    },
  });


//GET ALL CITIES OF A PARTICULAR STATE
export const getCitiesOfStates = async (state, token) =>
  await axios.get(`${BASE_URL_CITY_MANAGEMENT}/locations/cities/${state}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
