import DeleteIcon from "@material-ui/icons/Delete";
import moment from "moment";
import { useState } from "react";
import TableHeader from "../../0-ResuableComponents/tableComponents/TableHeader";
import BusinessModal from "./BusinessModal";

const headers = [
  { name: "Name", field: "name", sortable: true },
  {name:"Business Ideas",field:'business_idea_ids',sortable:false},
  {name:"Logo",field:'representative_icon',sortable:false},
  { name: "Edit", field: "Edit", sortable: false },
  { name: "Delete", field: "Delete", sortable: false },
  { name: "Created At", field: "created_at", sortable: false },
  { name: "Last Updated At", field: "updated_at", sortable: false },
 
];

const ServiceCategoryTable = ({serviceCategoryList,
  setCurrentId,handleServiceCategoryDelete,
  setConfirmDialog,setSorting,handleShow,
  businessIdeas
}) => {
  
  const [showBusinessModal ,setShowBusinessModal] = useState(false);
  const [showbusinessIdeas,setShowBusinessIdeas] = useState([]);
  const handleClose =() => setShowBusinessModal(false);

  return (
    <div className="col-lg-12 col-md-11">
      <div className="all-cate-tags">
        <div className="row justify- content-between">
          <div className="col-lg-12 col-md-12">
            <div className="card card-static-2 mb-30">
              <div className="card-title-2">
                <h4>All Service Categories</h4>
              </div>
              <div className="card-body-table">
                <div className="table-responsive">
                  <table className="table ucp-table table-hover">
                    <TableHeader
                      headers={headers}
                      onSorting={(field, order) => setSorting({ field, order })}
                    />
                    <tbody>
                      {serviceCategoryList?.map((list) => (
                        <tr key={list._id}>
                          <td>{list?.name}</td>
                          <td onClick={()=>{setShowBusinessModal(true);
                                            setShowBusinessIdeas(list?.business_idea_ids)
                                            }} style={{cursor:'pointer'}}>
                            {list?.business_idea_ids.length || 0} business idea(s)
                            {/* {businessIdeas?.filter((item) => list?.business_idea_ids?.indexOf(item?._id) === 0).map((idea)=> idea?.inEnglish ) } */}
                          </td>
                          <td>  
                          {list?.representative_icon && ( <img style={{ height: "40px",width: "100%" }} 
                          src={ `${list?.representative_icon}`} alt="service_logo_img" />)}
                                </td>
                          <td className="action-btns">
                            <i
                              className="fas fa-edit"
                              data-toggle="modal"
                              data-target="#exampleModal"
                              data-whatever="@mdo"
                              style={{ cursor: "pointer" }}
                              onClick={() => { setCurrentId(list._id); handleShow(); }} />
                          </td>
                          <td>
                            <DeleteIcon
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setConfirmDialog({
                                  isOpen: true,
                                  title: "Are you sure to delete this record?",
                                  subTitle: "You can't undo this operation",
                                  onConfirm: () => {
                                    handleServiceCategoryDelete(list._id);
                                  },
                                });
                              }}
                            />
                          </td>
                          <td>
                            {moment(list?.created_at).format("lll")}
                          </td>
                          {/* <td>{list.updated_by}</td> */}
                          <td>
                            {moment(list?.updated_at).format("lll")}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      { showBusinessModal && <BusinessModal show={showBusinessModal} handleClose ={handleClose} showbusinessIdeas= {showbusinessIdeas} businessIdeas={businessIdeas}/>}
    </div>
  );
};

export default ServiceCategoryTable;
