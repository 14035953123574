import TableHeader from "../../0-ResuableComponents/tableComponents/TableHeader";
import moment from "moment";

const EntrepreneurAssignmentsTable = ({ entrepreneur, setSorting }) => {
  // console.log("entrepreneur===>", entrepreneur.assignment);

  const headers = [
    { name: "Cluster", field: "cluster", sortable: false },
    { name: "Trade ID", field: "trade", sortable: false },
    { name: "Category", field: "category", sortable: false },
    {
      name: "Date of Activation",
      field: "addedOn",
      sortable: false,
    },
  ];

  return (
    <div className="col-lg-12 col-md-12" style={{ userSelect: "auto" }}>
      <div className="card card-static-2 mt-30 mb-30">
        <div className="card-title-2">
          <h4>ASSIGNMENT</h4>
        </div>
        <div className="card-body-table">
          <div className="table-responsive">
            <table className="table ucp-table table-hover">
              <TableHeader
                headers={headers}
                onSorting={(field, order) => setSorting({ field, order })}
              />
              <tbody>
                {entrepreneur?.assignment?.map((assignment) => (
                  <tr key={assignment.cluster}>
                    <td>{assignment.cluster ? assignment.cluster : "-"}</td>
                    <td>{assignment.trades ? assignment.trades : "-"}</td>
                    <td>Categories</td>
                    <td>{moment(assignment.addedOn).format("lll")}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EntrepreneurAssignmentsTable;
