import moment from "moment";
import TableHeader from "../../0-ResuableComponents/tableComponents/TableHeader";

const headers = [
  { name: "Trade", field: "name", sortable: true },
  { name: "ID", field: "id", sortable: false },
  { name: "Created By", field: "CreatedBy", sortable: false },
  { name: "Created At", field: "CreatedAt", sortable: false },
  { name: "Last Updated By", field: "LastUpdatedBy", sortable: false },
  { name: "Last Updated At", field: "LastUpdatedAt", sortable: false },
];

const CategoryTradesTable = ({ categoryTradesList, search, setSorting }) => {
  return (
    <div className="col-lg-12 col-md-11">
      <div className="all-cate-tags">
        <div className="row justify- content-between">
          <div className="col-lg-12 col-md-12">
            <div className="card card-static-2 mb-30">
              <div className="card-title-2">
                <h4>HyperLinkedTrades</h4>
              </div>
              <div className="card-body-table">
                <div className="table-responsive">
                  <table className="table ucp-table table-hover">
                    <TableHeader
                      headers={headers}
                      onSorting={(field, order) => setSorting({ field, order })}
                    />
                    <tbody>
                      {categoryTradesList?.map((trade) => (
                        <tr key={trade._id}>
                          <td>{trade.name}</td>
                          <td>{trade.id}</td>
                          <td>
                            {moment(trade.metaInfo.created.at).format("lll")}
                          </td>
                          <td>{trade.metaInfo.created?.by?.name}</td>
                          <td>
                            {moment(trade.metaInfo.updated.at).format("lll")}
                          </td>
                          <td>{trade.metaInfo.updated?.by?.name}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryTradesTable;
