import { useCallback, useState } from "react";
import { useEffect } from "react";
const NewOrderItemsTable = (props) => {
  const { order, setOrder } = props;

  const [currentSubTotal, setCurrentSubTotal] = useState(0);
  const [currentDiscount, setCurrentDiscount] = useState(0);
  const [currentSubtotalAfterDiscounting, setCurrentSubtotalAfterDiscounting] =
    useState(0);

  const calculateSubTotal = () => {
    if (order) {
      let newOrde = { ...order };

      const calculatedTotal = newOrde?.orderList?.reduce((sum, orderItem) => {
        const { orderItemValueWithGst } = orderItem;
        // const valueWithoutGST = units * unitPrice;
        // const valueWithGST =
        //   valueWithoutGST + valueWithoutGST * (gstForOrderItem / 100);
        return sum + orderItemValueWithGst;
      }, 0);

      setCurrentSubTotal(calculatedTotal);
    } else {
      console.log("order in else in NewOrderitemsTable due to error ", order);
    }
  };

  const calculateCurrentDiscount = () => {
    if (currentSubTotal > 0 && order) {
      const { discount } = order;
      const { discountAmount } = discount;

      let tempDiscount = currentSubTotal * (discountAmount / 100);

      setCurrentDiscount(Number(tempDiscount.toFixed(2)));
    }
  };

  const calculateCurrentSubtotalAfterDiscounting = () => {
    if (currentSubTotal > 0) {
      let temp = currentSubTotal - currentDiscount - order?.redeemablePoints;
      setCurrentSubtotalAfterDiscounting(temp);
    }
  };

  useEffect(() => {
    calculateSubTotal();
  }, [order]);

  useEffect(() => {
    calculateCurrentDiscount();
  }, [currentSubTotal]);

  useEffect(() => {
    calculateCurrentSubtotalAfterDiscounting();
  }, [currentDiscount]);

  // const calculateValueWithoutGST = (singleOrder) => {
  //   let tempValueWithoutGST = singleOrder.units * singleOrder.unitPrice;

  //   return tempValueWithoutGST;
  // };

  // const calculateValueWithGST = (singleOrder) => {
  //   let tempValueWithGST1 = singleOrder.units * singleOrder.unitPrice;
  //   let tempValueWithGST2 =
  //     tempValueWithGST1 * (singleOrder.gstForOrderItem / 100);

  //   let tempValueWithGST3 = tempValueWithGST1 + tempValueWithGST2;

  //   return tempValueWithGST3;
  // };

  const downloadDetails = () => {
    console.log("download");
    window.print();
  };

  return (
    <div className="col-lg-12 col-md-11">
      <div className="all-cate-tags">
        <div className="row justify-content-between">
          <div className="col-lg-12 col-md-12">
            <div className="card card-static-2 mb-30">
              <div className="card-body-table">
                <div className="table-responsive">
                  <table className="table ucp-table table-hover">
                    <thead>
                      <tr>
                        <th>Sr No.</th>
                        <th>Product Name</th>
                        <th>Variant Name</th>
                        <th>Units</th>
                        <th>Unit Price (INR)</th>
                        <th>Price Without GST (INR)</th>
                        <th>GST(%) </th>
                        <th>Price with GST</th>
                      </tr>
                    </thead>
                    <tbody>
                      {order?.orderList?.map((singleOrder, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{singleOrder.product?.name}</td>
                            <td>{singleOrder.productVariant?.name}</td>
                            <td>{singleOrder.units}</td>
                            <td>{singleOrder.unitPrice}</td>
                            <td>{singleOrder.orderItemValueWithoutGst}</td>
                            <td>{singleOrder.gstForOrderItem}</td>
                            <td>{singleOrder.orderItemValueWithGst}</td>
                          </tr>
                        );
                      })}

                      {/* <tr
                        style={{
                          height: "50px",
                        }}
                        className="mt-3"
                      >
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr> */}

                      {/* <tr className="mt-2">
                        <td className="font-weight-bold">Sub-Total (INR)</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="font-weight-bold">
                          {currentSubTotal > 0 ? currentSubTotal : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>Discount</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>-{currentDiscount}</td>
                      </tr>
                      <tr>
                        <td>Points Redeemed</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          -
                          {order?.redeemablePoints > 0
                            ? order?.redeemablePoints
                            : 0}
                        </td>
                      </tr>

                      <tr>
                        <td>Sub-Total after Discount</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>{currentSubtotalAfterDiscounting}</td>
                      </tr>

                      <tr>
                        <td>Delivery Charge</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>{order.deliveryCharge}</td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold">Final Total (INR)</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td className="font-weight-bold">
                          {currentSubtotalAfterDiscounting > 0
                            ? currentSubtotalAfterDiscounting +
                              order.deliveryCharge
                            : "-"}
                        </td>
                      </tr>

                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          <button
                            className="btn btn-primary"
                            onClick={downloadDetails}
                          >
                            Print
                          </button>
                        </td>
                      </tr> */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-between">
          <div className="col-lg-12 col-md-12">
            <div className="card card-static-2 mb-30">
              <div className="card-body-table">
                <div className="table-responsive">
                  <table className="table ucp-table table-hover">
                    <tbody style={{ width: "100%" }}>
                      <tr>
                        <td className="font-weight-bold">Sub-Total (INR)</td>

                        <td
                          style={{
                            textAlign: "end",
                            // paddingRight: "40px",
                          }}
                          className="font-weight-bold"
                        >
                          {currentSubTotal > 0 ? currentSubTotal : "-"}
                        </td>
                      </tr>

                      <tr>
                        <td>Discount</td>

                        <td
                          style={{
                            textAlign: "end",
                            //paddingRight: "40px",
                          }}
                        >
                          -{currentDiscount > 0 ? currentDiscount : "-"}
                        </td>
                      </tr>
                      <tr>
                        <td>Points Redeemed</td>

                        <td
                          style={{
                            textAlign: "end",
                            // paddingRight: "40px",
                          }}
                        >
                          -
                          {order?.redeemablePoints > 0
                            ? order?.redeemablePoints
                            : 0}
                        </td>
                      </tr>

                      <tr>
                        <td>Sub-Total after Deductions</td>

                        <td
                          style={{
                            textAlign: "end",
                            // paddingRight: "40px",
                          }}
                        >
                          {currentSubtotalAfterDiscounting > 0
                            ? currentSubtotalAfterDiscounting
                            : "-"}
                        </td>
                      </tr>

                      <tr>
                        <td>Delivery Charge</td>

                        <td
                          style={{
                            textAlign: "end",
                            // paddingRight: "40px",
                          }}
                        >
                          {order.deliveryCharge}
                        </td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold">Final Total (INR)</td>

                        <td
                          style={{
                            textAlign: "end",
                            //paddingRight: "40px",
                          }}
                          className="font-weight-bold"
                        >
                          {currentSubtotalAfterDiscounting > 0
                            ? currentSubtotalAfterDiscounting +
                              order.deliveryCharge
                            : "-"}
                        </td>
                      </tr>

                      <tr>
                        <td></td>

                        <td
                          style={{
                            textAlign: "end",
                            //paddingRight: "40px",
                          }}
                        >
                          <button
                            className="btn btn-primary"
                            onClick={downloadDetails}
                          >
                            Print
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewOrderItemsTable;
