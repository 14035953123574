import { Link } from "react-router-dom";
import moment from "moment";
import { datadump } from "../../actions/consumerAction";

// import { useState } from "react";
const CostumerDetailsTable = ({ consumer }) => {
  return (
    <div className="col-md-auto mt-3">
      <div className="row justify- content-between">
        <div className="col-lg-12 col-md-12">
          <div className="card card-static-2 mb-30">
            <div className="card-body-table">
              <div className="table-responsive">
                <table className="table ucp-table table-hover d-flex">
                  <tbody>
                    <tr>
                      <th>Name</th>
                      <td>{consumer?.data?.name || ""}</td>
                    </tr>
                    <tr>
                      <th>Mobile</th>
                      <td>{consumer?.data?.contact_details?.mobile || ""}</td>
                    </tr>
                    <tr>
                      <th>Email</th>
                      <td>{consumer?.data?.contact_details?.email || ""}</td>
                    </tr>
                  
                    <tr>
                      <th>Address</th>
                      <td>{ consumer?.data?.contact_details?.location?.googleMapUrl || '-' }</td>
                    </tr>
                    <tr>
                      <th>Opening Hours</th>
                      <td>{ consumer.opening_hours || '-' }</td>
                    </tr>
                    <tr>
                      <th>GST Number</th>
                      <td>{ consumer?.data?.contact_details?.gst || '-' }</td>
                    </tr>
                    <tr>
                      <th>Managed By</th>
                      <td>{ consumer.manager || '-' }</td>
                    </tr>
                    <tr>
                      <th>Services Catalog and Prices</th>
                      <thead>
                      <tr>
                        <th>Service Name</th>
                        <th>Availability</th>
                        <th>Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {consumer?.data?.rate_card?.map((order, index) => {
                        return (
                          <tr key={index}>
                            <td>{order.service_id}</td>
                            <td>{order.availability ? "YES":"NO"}</td>
                            <td>{order.rate}</td>
                          </tr>
                        );
                      })}
                       </tbody>
                    </tr>
                  </tbody>
                  <tbody style={{ borderTop: "0px" }}>
                    <tr>
                      <th>Photo</th>
                      <td>{consumer?.data?.contact_details?.photo && ( 
                    <img style={{ height: "150px",width: "100%" }} 
                          src={`${consumer?.data?.contact_details?.photo}` } alt="customer_img" />)}</td>
                    {/* <img style={{ height: "150px",width: "100%" ,marginTop: "20px" }} 
                          src={"https://images-platform.99static.com/GrLsk8Nm-CYERQ5-I0FOWTXLwUc=/0x0:1080x1080/500x500/top/smart/99designs-contests-attachments/113/113547/attachment_113547504"} alt="customer_img" /> */}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CostumerDetailsTable;
