import { useState, useEffect } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { toast } from "react-toastify";
import {
  Grid,
  TextField,
  IconButton,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
} from "@material-ui/core";

const EditCoordinates = ({
  // coordinates,
  // setCoordinates,
  showEditZone,
  selectedZone,
  handleUpdateZone,
  show,
  handleClose,
}) => {
  const status = ["Active", "Inactive", "Hidden"];
  const [zone, setZone] = useState(null);
  // const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setZone(selectedZone);
    // setShowModal(showEditZone);
  }, [selectedZone]);

  const handleChange = (value, index, type) => {
    const updateCoordinates = zone?.coordinates;
    if (type === "lat") {
      updateCoordinates[index].lat =
        value.length === 0 ? "" : parseFloat(value);
    } else {
      updateCoordinates[index].lng =
        value.length === 0 ? "" : parseFloat(value);
    }
    setZone({ ...zone, coordinates: updateCoordinates });
  };

  const handleAddMore = () => {
    const updateCoordinates = zone.coordinates;
    updateCoordinates.push({ lat: "", lng: "" });
    setZone({ ...zone, coordinates: updateCoordinates });
  };

  const handleRemoveCoordinates = (index) => {
    const updateCoordinates = zone.coordinates;
    updateCoordinates.splice(index, 1);
    setZone({ ...zone, coordinates: updateCoordinates });
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton data-dismiss="modal">
        <h3 className="m-1">Update Zone</h3>
      </Modal.Header>
      <Modal.Body>
        {zone && (
          <>
            <Row style={{ marginBottom: "10px" }}>
              <Col lg={4}>
                <TextField
                  label="Area"
                  placeholder="Area Name"
                  variant="outlined"
                  className="mb-2"
                  name="area"
                  value={zone?.area}
                  onChange={(e) => setZone({ ...zone, area: e.target.value })}
                />
              </Col>
              <Col lg={4}>
                <TextField
                  label="Estimated Families"
                  placeholder="Estimated Families"
                  variant="outlined"
                  className="mb-2"
                  name="estimatedFamilies"
                  value={zone?.estimatedFamilies}
                  onChange={(e) =>
                    setZone({ ...zone, estimatedFamilies: e.target.value })
                  }
                />
              </Col>
              <Col lg={4}>
                <FormControl variant="outlined">
                  <InputLabel>Status</InputLabel>
                  <Select
                    style={{ width: 200 }}
                    value={zone?.status}
                    onChange={(e) =>
                      setZone({ ...zone, status: e.target.value })
                    }
                  >
                    {status.map((stat) => (
                      <MenuItem value={stat}>{stat}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Col>
            </Row>

            {zone?.coordinates?.map((coordinate, index) => (
              <Grid container spacing={3} key={index}>
                <Grid item md={4}>
                  <TextField
                    name="lat"
                    type="Number"
                    label="Latitude"
                    placeholder="Enter Latitude"
                    variant="outlined"
                    value={coordinate?.lat}
                    onChange={(e) => handleChange(e.target.value, index, "lat")}
                  />
                </Grid>
                <Grid item md={4}>
                  <TextField
                    name="lng"
                    type="Number"
                    label="Longitude"
                    placeholder="Enter Longitude"
                    variant="outlined"
                    value={coordinate?.lng}
                    onChange={(e) => handleChange(e.target.value, index, "lng")}
                  />

                  {index === zone.coordinates.length - 1 && (
                    <button
                      className="save-btn hover-btn"
                      style={{ marginTop: "10px" }}
                      onClick={() => handleAddMore()}
                    >
                      Add More
                    </button>
                  )}
                </Grid>

                <Grid item lg={1}>
                  {index > 2 && (
                    <IconButton onClick={() => handleRemoveCoordinates(index)}>
                      <DeleteOutlineIcon />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            ))}
            <div
              className="m-4"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <button
                className="save-btn hover-btn"
                onClick={() => {
                  zone.coordinates.length > 2
                    ? handleUpdateZone(zone, zone._id)
                    : toast.error("Atlease 3 coordinates required");
                }}
              >
                UPDATE ZONE
              </button>
            </div>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default EditCoordinates;
