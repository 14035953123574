/* eslint-disable no-undef */
import { useEffect } from "react";

const Map = ({ zoneList }) => {
  // console.log("FROM MAPS===>", coordinates);
  // console.log("ZONE LIST===>", zoneList);

  let map;
  let infoWindow;

  function initMap() {
    map = new google.maps.Map(document.getElementById("map"), {
      zoom: 5,
      center:
        zoneList?.length > 0 && zoneList[0].coordinates
          ? {
              lat: zoneList[0].coordinates[0].lat,
              lng: zoneList[0].coordinates[0].lng,
            }
          : { lat: 12.913096, lng: 77.635141 },
      mapTypeId: "terrain",
    });
    // Define the LatLng coordinates for the polygon.

    for (let zone = 0; zone < zoneList.length; zone++) {
      for (
        let coordinate = 0;
        coordinate < zoneList[zone].coordinates.length;
        coordinate++
      ) {
        // console.log("zoneList[zone]", zoneList[zone].area);
        const coords = zoneList[zone].coordinates;

        // Construct the polygon.
        const zonePolygon = new google.maps.Polygon({
          area: zoneList[zone].area,
          paths: coords,
          strokeColor: "#C5341F",
          strokeOpacity: 0.8,
          strokeWeight: 1,
          fillColor: "#0000FF00",
          fillOpacity: 0.35,
        });

        zonePolygon.setMap(map);
        // Add a listener for the click event.
        zonePolygon.addListener("click", showPolygon);
      }
    }
    infoWindow = new google.maps.InfoWindow();
  }

  function showPolygon(event) {
    // Since this polygon has only one path, we can call getPath() to return the
    // MVCArray of LatLngs.
    const polygon = this;
    // console.log("polygon", polygon.area);
    const vertices = polygon.getPath();
    let contentString =
      `<b>${polygon.area} area</b><br>` +
      "Clicked location: <br>" +
      event.latLng.lat() +
      "," +
      event.latLng.lng() +
      "<br>";

    // Iterate over the vertices.
    for (let i = 0; i < vertices.getLength(); i++) {
      const xy = vertices.getAt(i);
      contentString +=
        "<br>" + "Coordinate " + i + ":<br>" + xy.lat() + "," + xy.lng();
    }
    // Replace the info window's content and position.
    infoWindow.setContent(contentString);
    infoWindow.setPosition(event.latLng);
    infoWindow.open(map);
  }

  useEffect(() => {
    initMap();
  }, [zoneList]);

  return <div id="map" style={{ height: "400px", marginTop: "15px" }}></div>;
};

export default Map;
