// import moment from "moment";
import TableHeader from "../../0-ResuableComponents/tableComponents/TableHeader";

const OfferLogsTable = ({ setSorting, offerLogsList }) => {

  const headers = [
    { name: "Last Applied on", field: "LastAppliedOn", sortable: false },
    { name: "Last Applied by", field: "LastAppliedBy", sortable: false },
    { name: "Status", field: "status", sortable: false },
    {
      name: "Total Discount Availed (INR)",
      field: "discount",
      sortable: false,
    },
  ];

  return (
    <div className="col-lg-12 col-md-12" style={{ userSelect: "auto" }}>
      <div className="card card-static-2 mt-30 mb-30">
        <div className="card-body-table">
          <div className="table-responsive">
            <table className="table ucp-table table-hover">
              <TableHeader
                headers={headers}
                onSorting={(field, order) => setSorting({ field, order })}
              />
              <tbody>
                {offerLogsList?.map((offer) => (
                  <tr key={offer._id}>
                    <td>Last Applied on</td>
                    <td>Last Applied by</td>
                    <td
                      style={{
                        color: `${
                          offer?.status === "Active"
                            ? "green"
                            : offer?.status === "Inactive"
                            ? "red"
                            : "orange"
                        }`,
                      }}
                    >
                      {offer.status}Status
                    </td>
                    <td>Total Discount Availed (INR)</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfferLogsTable;
