import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "@material-ui/core";
import { allUsers, consumerData } from "../../actions/consumerAction";
import ConsumerDetailsTable from "./tables/consumerDetailsTable";
import OrderHistoryTable from "./tables/OrderHistorytable";
import AddressTable from "./tables/AddressTable";
import WalletTable from "./tables/WalletTable";
import CoinTable from "./tables/CoinTable";
import NoOrder from "./NoOrder";
import ReferralTable from "./tables/ReferralTable";

// REACT_APP_API_ORDER =
//   "https://appfactorydevapi.letsendorse.com/order-service/order/details/60c1fdd6f955c0ac6beb214b" 60c30ea8e39881ba27ba619a;

const ConsumerProfile = ({ match }) => {
  const { auth } = useSelector((state) => ({ ...state }));
  const [showOrderHistory, setShowOrderHistory] = useState(true);
  const [showWalletLedger, setShowWalletLedger] = useState(false);
  const [showCoinLedger, setShowCoinLedger] = useState(false);
  const [showReferrals, setShowReferrals] = useState(false);
  const [showAdresses, setShowAddresses] = useState(false);
  const [showInsights, setShowInsights] = useState(false);
  const [orderexist, setOrderExist] = useState(true);
  const [order, setOrder] = useState([]);
  const [bag, setbag] = useState([]);

  const [consumer, setConsumer] = useState({});
  const consumerId = match.params.consumerId;

  const fetchConsumerData = async () => {
    let res = await consumerData(auth.token, consumerId);
    const consumer = res.data.consumer;
    consumer[0].orderCount && consumer[0].orderCount > 0
      ? setOrderExist(true)
      : setOrderExist(false);
    setConsumer(consumer[0]);
    setOrder(consumer[0].orders);
  };
  useEffect(() => {
    fetchConsumerData();
  }, []);

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <Link to="/consumers">
            <Button variant="contained">
              <i className="fas fa-arrow-left" /> Back
            </Button>
          </Link>
          <div className="col-lg-5 col-md-9 col-lg-6">
            <h2 className="mt-30 page-title">{consumer?.name} &#62;</h2>
          </div>
          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Dashboard</Link>
            </li>

            <li className="breadcrumb-item active">
              <Link to="/consumers">Consumers</Link>
            </li>

            <li className="breadcrumb-item active">Consumer Profile</li>
          </ol>
          <div className="row">
            <ConsumerDetailsTable consumer={consumer} />

            <div className="buttons col-md">
              <button
                variant="contained"
                className={showOrderHistory && "selected"}
                onClick={() => {
                  setShowOrderHistory(true);
                  setShowWalletLedger(false);
                  setShowCoinLedger(false);
                  setShowReferrals(false);
                  setShowAddresses(false);
                  setShowInsights(false);
                  console.log(order);
                  console.log(consumer);
                }}
              >
                Order
              </button>
              <button
                className={showWalletLedger && "selected"}
                onClick={() => {
                  setShowOrderHistory(false);
                  setShowWalletLedger(true);
                  setShowCoinLedger(false);
                  setShowReferrals(false);
                  setShowAddresses(false);
                  setShowInsights(false);
                }}
              >
                Wallet Ledger
              </button>
              <button
                className={showCoinLedger && "selected"}
                onClick={() => {
                  setShowOrderHistory(false);
                  setShowWalletLedger(false);
                  setShowCoinLedger(true);
                  setShowReferrals(false);
                  setShowAddresses(false);
                  setShowInsights(false);
                }}
              >
                Coins Ledger
              </button>
              <button
                className={showReferrals && "selected"}
                onClick={() => {
                  setShowOrderHistory(false);
                  setShowWalletLedger(false);
                  setShowCoinLedger(false);
                  setShowReferrals(true);
                  setShowAddresses(false);
                  setShowInsights(false);
                }}
              >
                Referrals
              </button>
              <button
                className={showAdresses && "selected"}
                onClick={() => {
                  setShowOrderHistory(false);
                  setShowWalletLedger(false);
                  setShowCoinLedger(false);
                  setShowReferrals(false);
                  setShowAddresses(true);
                  setShowInsights(false);
                }}
              >
                Addresses
              </button>
              <button
                className={showInsights && "selected"}
                onClick={() => {
                  setShowOrderHistory(false);
                  setShowWalletLedger(false);
                  setShowCoinLedger(false);
                  setShowReferrals(false);
                  setShowAddresses(false);
                  setShowInsights(true);
                }}
              >
                Insights
              </button>
            </div>
            {orderexist || <NoOrder />}

            {orderexist && showOrderHistory && (
              <OrderHistoryTable orders={order} bag />
            )}

            {orderexist && showWalletLedger && <WalletTable />}

            {orderexist && showCoinLedger && <CoinTable />}

            {orderexist && showReferrals && <ReferralTable />}

            {orderexist && showAdresses && <AddressTable />}

            {/* {orderexist && showInsights && <h3>insights</h3>} */}
          </div>
        </div>
      </main>
    </div>
  );
};

export default ConsumerProfile;
