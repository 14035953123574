import DeleteIcon from "@material-ui/icons/Delete";
import moment from "moment";
import TableHeader from "../../0-ResuableComponents/tableComponents/TableHeader";

const headers = [
  { name: "Trade", field: "name", sortable: true },
  { name: "Created By", field: "CreatedBy", sortable: false },
  { name: "Created At", field: "CreatedAt", sortable: false },
  { name: "Last Updated By", field: "LastUpdatedBy", sortable: false },
  { name: "Last Updated At", field: "LastUpdatedAt", sortable: false },
  { name: "Edit", field: "Edit", sortable: false },
  { name: "Delete", field: "Delete", sortable: false },
];

const TradeTable = ({
  tradeList,
  setCurrentId,
  handleTradeDelete,
  setConfirmDialog,
  setSorting,
  handleShow,
}) => {
   console.log("tradeListttttt====>", tradeList);
  return (
    <div className="col-lg-12 col-md-11">
      <div className="all-cate-tags">
        <div className="row justify- content-between">
          <div className="col-lg-12 col-md-12">
            <div className="card card-static-2 mb-30">
              <div className="card-title-2">
                <h4>All Trades</h4>
              </div>
              <div className="card-body-table">
                <div className="table-responsive">
                  <table className="table ucp-table table-hover">
                    <TableHeader
                      headers={headers}
                      onSorting={(field, order) => setSorting({ field, order })}
                    />
                    <tbody>
                      {tradeList?.map((trade) => (
                        <tr key={trade._id}>
                          <td>{trade.name}</td>
                          <td>{trade.metaInfo.created?.by?.name}</td>
                          <td>
                            {moment(trade.metaInfo.created.at).format("lll")}
                          </td>
                          <td>{trade.metaInfo.updated?.by?.name}</td>
                          <td>
                            {moment(trade.metaInfo.updated.at).format("lll")}
                          </td>
                          <td className="action-btns">
                            <i
                              className="fas fa-edit"
                              data-toggle="modal"
                              data-target="#exampleModal"
                              data-whatever="@mdo"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setCurrentId(trade._id);
                                handleShow();
                              }}
                            />
                          </td>
                          <td>
                            <DeleteIcon
                              style={{ cursor: "pointer" }}
                              // onClick={() => {
                              //   handleTradeDelete(trade._id);
                              // }}
                              onClick={() => {
                                setConfirmDialog({
                                  isOpen: true,
                                  title: "Are you sure to delete this record?",
                                  subTitle: "You can't undo this operation",
                                  onConfirm: () => {
                                    handleTradeDelete(trade._id);
                                  },
                                });
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TradeTable;
