import React, { useState } from "react";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { toast } from "react-toastify";
import {
  Paper,
  Grid,
  TextField,
  IconButton,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@material-ui/core";

const AddZoneForm = ({
  setShowMap,
  setShowForm,
  setNewZone,
  newZone,
  handleAddZone,
}) => {
  const coordinateTemplate = {
    lat: "",
    lng: "",
    latErr: "",
    longErr: "",
  };
  const [coordinates, setCoordinates] = useState([coordinateTemplate]);

  const addCoordinates = () => {
    setCoordinates([...coordinates, coordinateTemplate]);
    setNewZone({
      ...newZone,
      coordinates: [...coordinates, coordinateTemplate],
    });
  };

  const handleChange = (e, index, lat, long) => {
    const updatedCoordinates = coordinates.map((coordinate, i) =>
      index === i
        ? Object.assign(coordinate, {
            [e.target.name]: parseFloat(e.target.value),
            latErr:
              lat &&
              e.target.value.match(
                /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/
              )
                ? false
                : lat &&
                  !e.target.value.match(
                    /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/
                  )
                ? true
                : false,
            longErr:
              long &&
              e.target.value.match(
                /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/
              )
                ? false
                : long &&
                  !e.target.value.match(
                    /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/
                  )
                ? true
                : false,
          })
        : coordinate
    );
    setCoordinates(updatedCoordinates);
    // console.log("updatedCoordinates===>", updatedCoordinates);
    setNewZone({ ...newZone, coordinates: JSON.stringify(updatedCoordinates) });
  };

  const removeCoordinate = (index) => {
    const filteredCoordinates = [...coordinates];
    filteredCoordinates.splice(index, 1);
    setCoordinates(filteredCoordinates);
    setNewZone({ ...newZone, coordinates: JSON.stringify(filteredCoordinates) });
  };

  const handleSubmit = () => {
    setNewZone({ ...newZone, coordinates: coordinates });
    setShowMap(true);
    setShowForm(false);
    handleAddZone();
  };

  return (
    <Paper>
      <Grid container spacing={4} style={{ marginTop: "10px" }}>
        <Grid item xs={4} className="form-group">
          <TextField
            name="area"
            label="Area"
            placeholder="Area Name"
            variant="outlined"
            className="form-control"
            required
            value={newZone.area}
            onChange={(e) => setNewZone({ ...newZone, area: e.target.value })}
          />
        </Grid>
        <Grid item md={4}>
          <TextField
            name="estimatedFamilies"
            label="Estimated Families"
            placeholder="Estimated Families"
            type="Number"
            variant="outlined"
            className="form-control"
            required
            value={newZone.estimatedFamilies}
            onChange={(e) =>
              setNewZone({
                ...newZone,
                estimatedFamilies: parseInt(e.target.value),
              })
            }
          />
        </Grid>
        <Grid item md={4} className="form-group">
          <FormControl
            variant="outlined"
            className="form-control"
            style={{ height: "76.8%" }}
          >
            <InputLabel>Status*</InputLabel>
            <Select
              name="status"
              label="status"
              className="form-control"
              variant="outlined"
              style={{ height: "105%" }}
              required
              value={newZone.status}
              onChange={(e) =>
                setNewZone({ ...newZone, status: e.target.value })
              }
            >
              <MenuItem value="">
                <em>select status</em>
              </MenuItem>
              <MenuItem value="Active">Active</MenuItem>
              <MenuItem value="Inactive">Inactive</MenuItem>
              <MenuItem value="Hidden">Hidden</MenuItem>
            </Select>
            {/* <FormHelperText>Required</FormHelperText> */}
          </FormControl>
        </Grid>
      </Grid>

      {coordinates.map((coordinate, index) => (
        <Grid container spacing={3} key={index}>
          <Grid item md={4}>
            <TextField
              name="lat"
              label="Latitude"
              placeholder="Enter Latitude"
              type="Number"
              variant="outlined"
              className="form-control"
              style={{ marginTop: "30px" }}
              error={coordinate.latErr}
              helperText={coordinate.latErr ? "Invalid Lat format." : ""}
              onChange={(e) => handleChange(e, index, true, false)}
            />
          </Grid>
          <Grid item md={4}>
            <TextField
              name="lng"
              label="Longitude"
              placeholder="Enter Longitude"
              type="Number"
              variant="outlined"
              className="form-control"
              style={{ marginTop: "30px" }}
              error={coordinate.longErr}
              helperText={coordinate.longErr ? "Invalid Long format." : ""}
              onChange={(e) => handleChange(e, index, false, true)}
            />

            {index === coordinates.length - 1 && (
              <button
                className="save-btn hover-btn"
                onClick={addCoordinates}
                style={{ marginTop: "35px" }}
              >
                Add More
              </button>
            )}
          </Grid>

          <Grid item lg={1}>
            {index > 2 && (
              <IconButton
                onClick={() => removeCoordinate(index)}
                style={{ marginTop: "30px", display: "inline" }}
              >
                <DeleteOutlineIcon />
              </IconButton>
            )}
          </Grid>
        </Grid>
      ))}
      <div
        className="mt-4"
        style={{ display: "flex", justifyContent: "flex-end", padding: "10px" }}
      >
        <button
          className="save-btn hover-btn"
          disabled={
            !newZone.area || !newZone.estimatedFamilies || !newZone.status
          }
          onClick={() => {
            coordinates.length > 2
              ? handleSubmit()
              : toast.error(
                  "At-least 3 coordinates are required to add a zone"
                );
          }}
        >
          SUBMIT ALL
        </button>
      </div>
    </Paper>
  );
};

export default AddZoneForm;
