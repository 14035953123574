import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import {
  getAllCities,
  addNewCity,
  updateCityByID,
  deleteCityByID,
  getAllStates,
  // getCitiesOfStates,
} from "../../../actions/cityAction";
import EditCityForm from "./EditCityForm";
import CityForm from "./CityForm";
import TradeForm from "./TradeForm";
import CityTable from "./CityTable";
import ConfirmDialog from "../../0-ResuableComponents/confirmDialog/ConfirmDialog";

const City = () => {
  const { auth } = useSelector((state) => ({ ...state }));
  const { token } = auth;
  const [showModal, setShowModal] = useState(false);
  const [cityList, setCityList] = useState([]);
  const [city, setCity] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [showEditCityModal, setShowEditCityModal] = useState(false);
  const [showEditTradesModal, setShowEditTradesModal] = useState(false);
  const [allStates, setAllStates] = useState([]);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const fetchAllStates = async () => {
    let res = await getAllStates(token);
    // console.log("states >>>> ", res);
    setAllStates(res.data.states);
  };

  const fetchAllCities = async () => {
    let res = await getAllCities(token);
    // console.log("ALL CITIES here", res);
    setCityList(res.data.cities.reverse());
    // console.log("cities table", res)
  };

  useEffect(() => {
    fetchAllCities();
    fetchAllStates();
  }, []);

  const [data, setData] = useState({
    state: "",
    city: "",
    status: "",
    rollout: "",
    trades: [
      {
        trade: "",
        activationDate: "",
        deactivationDate: "",
        status: "Inactive",
      },
    ],
  });

  const handleCityAdd = async (e) => {
    e.preventDefault();
    const cityToAdd = {
      state: "",
      city: "",
      status: "",
      rollout: "",
      trades: [
        {
          trade: "",
          activationDate: "",
          deactivationDate: "",
          status: "Inactive",
        },
      ],
    };
    cityToAdd.state = data.stateName;
    cityToAdd.city = data.cityName;
    cityToAdd.status = data.status;
    cityToAdd.rollout = data.rollout;
    cityToAdd.trades = JSON.stringify(data.trades);
    try {
      await addNewCity(token, cityToAdd);
      fetchAllCities();
      toast.success("succesfully added city");
      setShowModal(false);
    } catch (err) {
      toast.error(err.response.data.errorMessage);
    }
  };

  const handleCityUpdate = async (data, id) => {
    const detailsToUpdate = data;
    detailsToUpdate.trades = JSON.stringify(data.trades);
    try {
      await updateCityByID(detailsToUpdate, id, token);
      fetchAllCities();
      toast.success("City updated succesfully");
      setShowEditCityModal(false);
      setShowEditTradesModal(false);
    } catch (err) {
      toast.error(err.response.data.errorMessage);
    }
  };

  const handleCityDelete = async (cityID) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    deleteCityByID(token, cityID)
      .then((res) => {
        toast.success("City Deleted successfully...");
        fetchAllCities();
      })
      .catch((err) => toast.error(err.response.data.errorMessage));
  };

  // // Get current rows
  // const indexOfLastRow = currentPage * rowsPerPage;
  // const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  // const currentRows = cityList?.trades?.slice(indexOfFirstRow, indexOfLastRow);

  // Change page
  // const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div id="layoutSidenav_content">
      <div className="container-fluid">
        <h2 className="mt-25 page-title">Cities</h2>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Dashboard</Link>
          </li>
          <li className="breadcrumb-item active">Cities</li>
        </ol>
        <div className="col-lg-12 col-md-12 back-btn mb-30">
          <button
            className="save-btn hover-btn"
            onClick={() => setShowModal(true)}
          >
            + Add New City
          </button>
        </div>
        <CityForm
          setData={setData}
          data={data}
          handleSubmit={handleCityAdd}
          // currentId={currentId}
          states={allStates}
          showModal={showModal}
          closemodal={() => setShowModal(false)}
        />

        {city ? (
          <TradeForm
            city={city}
            setCity={setCity}
            setData={setData}
            data={data}
            fetchAllCities={fetchAllCities}
            showModal={showEditTradesModal}
            handleUpdateCity={handleCityUpdate}
            setShowEditTradesModal={setShowEditTradesModal}
          />
        ) : null}

        {selectedCity ? (
          <EditCityForm
            showModal={showEditCityModal}
            city={selectedCity}
            fetchAllCities={fetchAllCities}
            handleCityUpdate={handleCityUpdate}
            setShowEditCityModal={setShowEditCityModal}
          />
        ) : null}

        <div className="row">
          <CityTable
            cityList={cityList}
            handleCityDelete={handleCityDelete}
            setConfirmDialog={setConfirmDialog}
            setCity={setCity}
            setSelectedCity={setSelectedCity}
            setShowEditCityModal={setShowEditCityModal}
            setShowEditTradesModal={setShowEditTradesModal}
          />

          {/* {console.log("EDIT CITY MODALLLLLLLLLLLLLLLLLLL", showEditCityModal)} */}
        </div>
        {/* <Pagination
            rowsPerPage={rowsPerPage}
            // totalRows={tradeList?.trades?.length}
            paginate={paginate}
            url="/create-trade"
          /> */}
      </div>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </div>
  );
};

export default City;
