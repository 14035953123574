import DeleteIcon from "@material-ui/icons/Delete";
import { Link } from "react-router-dom";
import moment from "moment";
// import CityForm from "./CityForm";

const CityTable = ({
  cityList,
  handleCityDelete,
  setConfirmDialog,
  setCity,
  setSelectedCity,
  setShowEditCityModal,
  setShowEditTradesModal,
}) => {
  // console.log("cityList====>", cityList);
  const findActiveTrades = (city) => {
    let activeTrades = 0;
    city.trades.forEach((trade) => {
      if (trade.status === "Active") {
        activeTrades++;
      }
    });
    return activeTrades;
  };

  return (
    <div className="col-lg-12 col-md-7">
      <div className="all-cate-tags">
        <div className="row justify-content-between">
          <div className="col-lg-12 col-md-12">
            <div className="card card-static-2 mb-30">
              <div className="card-title-2">
                <h4>All Cities</h4>
              </div>
              <div className="card-body-table" id="sortTable">
                <div className="table-responsive">
                  <table className="table ucp-table table-hover">
                    <thead>
                      <tr>
                        <th scope="col">City ID</th>
                        <th scope="col">State</th>
                        <th scope="col">City</th>
                        <th scope="col">Zones</th>
                        <th scope="col">Status</th>
                        <th scope="col">Rollout</th>
                        <th scope="col">Added By</th>
                        <th scope="col">Added on</th>
                        <th scope="col">Updated By</th>
                        <th scope="col">Updated On</th>
                        <th scope="col">Trades</th>
                        <th scope="col">Edit</th>
                        <th scope="col">Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cityList
                        ?.filter((item) => item.isDeleted !== true)
                        ?.map((city, index) => (
                          <tr key={index}>
                            <td>{city.id}</td>
                            <td>{city.state}</td>
                            <td>{city.city}</td>
                            <td>
                              <Link to={`/zonesByID/${city.id}`}>zones</Link>
                            </td>
                            <td
                              style={{
                                color: `${
                                  city?.status === "Active"
                                    ? "green"
                                    : city?.status === "Inactive"
                                    ? "red"
                                    : "orange"
                                }`,
                              }}
                            >
                              {city.status}
                            </td>
                            <td>{city.rollout}</td>
                            <td>{city?.metaInfo?.added?.by?.name}</td>
                            <td>
                              {moment(city?.metaInfo?.added?.on).format("lll")}
                            </td>
                            <td>{city?.metaInfo?.updated?.by?.name}</td>
                            <td>
                              {moment(city?.metaInfo?.updated?.on).format(
                                "lll"
                              )}
                            </td>
                            <td
                              data-target="#exampleModal2"
                              style={{ cursor: "pointer", color: "blue" }}
                              onClick={() => {
                                setCity(city);
                                setShowEditTradesModal(true);
                              }}
                            >
                              {findActiveTrades(city)} active
                            </td>
                            <td className="action-btns">
                              <i
                                className="fas fa-edit"
                                data-target="#exampleModal3"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setSelectedCity(city);
                                  setShowEditCityModal(true);
                                }}
                              />
                            </td>
                            <td>
                              <DeleteIcon
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setConfirmDialog({
                                    isOpen: true,
                                    title:
                                      "Are you sure you want to delete this record?",
                                    subTitle: "You cant undo this operation",
                                    onConfirm: () => {
                                      handleCityDelete(city._id);
                                    },
                                  });
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CityTable;
