import {  } from "../../actions/consumerAction";
import moment from "moment";

const CustomerListTable = ({customerList}) => {

  return (
    <div className="col-lg-12 col-md-11">
      <div className="all-cate-tags">
        <div className="row justify- content-between">
          <div className="col-lg-12 col-md-12">
            <div className="card card-static-2 mb-30">
              <div className="card-body-table">
                <div className="table-responsive">
                  <table className="table ucp-table table-hover">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Mobile</th>
                        <th>Added On</th>
                        {/* <th>Total Order Count</th> */}
                        <th>Total Revenue from Customer</th>
                        <th>Customer Rating </th>
                      </tr>
                    </thead>
                    <tbody>
                      {customerList?.map((order, index) => {
                        return (
                          <tr key={index}>
                            <td>{order?.name}</td>
                            <td>{order?.mobile_no}</td>
                            <td>{order?.created_at? moment(order?.created_at).format("lll"): ""}</td>
                            <td>{order.walletBalance}</td>
                          </tr>
                        );
                      })}
                      {/* <tr className="bold-table-text">
                        <th>Grand Total</th>
                        <th></th>
                        <th>0</th>
                        <th>100</th>
                        <th>100</th>
                      </tr> */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerListTable;
