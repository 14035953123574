const Card = ({ className, title, number }) => {
  return (
    <div className="col-xl-3 col-md-6">
      <div className={className}>
        <div className="card-content">
          <span className="card-title">{title}</span>
          <span className="card-count">{number}</span>
        </div>
        <div className="card-media">
          <i className="fab fa-rev" />
        </div>
      </div>
    </div>
  );
};

export default Card;
