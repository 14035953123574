import React from "react";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import useStyles from "./styles";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300,
    },
  },
};

export default function MultipleSelect({
  selectedIds,
  List,
  setFieldValue,
  name,
  heading,
}) {
  const classes = useStyles();

  const city = List?.map((result) => result.map((result) => result));
  const cities = city?.flat();

  const key = "district";
  const cityList = [
    ...new Map(cities.map((item) => [item[key], item])).values(),
  ];

  const isAllSelected = List.length > 0 && selectedIds.length === List.length;

  const handleChange = (e) => {
    const value = e.target.value;
    if (value[value.length - 1] === "all") {
      setFieldValue(
        name,
        selectedIds.length === cityList.length
          ? []
          : cityList.map((city) => city.district)
      );
      return;
    }
    setFieldValue(name, value);
  };

  return (
    <div style={{ display: "inline-block", marginTop: "4%" }}>
      <FormControl className={classes.formControl} variant="outlined">
        <InputLabel id="demo-mutiple-checkbox-label" className="form-label">
          {heading}
          <span className="required">&#42;</span>
        </InputLabel>
        <Select
          labelId="demo-mutiple-checkbox-label"
          id="demo-mutiple-checkbox"
          multiple
          value={selectedIds}
          onChange={handleChange}
          input={<Input />}
          renderValue={(selected) => selected?.join(", ")}
          MenuProps={MenuProps}
        >
          {cityList.length > 1 && (
            <MenuItem
              value="all"
              classes={{
                root: isAllSelected ? classes.selectedAll : "",
              }}
            >
              <ListItemIcon>
                <Checkbox
                  classes={{ indeterminate: classes.indeterminateColor }}
                  checked={isAllSelected}
                  indeterminate={
                    selectedIds.length > 0 &&
                    selectedIds.length < cityList.length
                  }
                />
              </ListItemIcon>
              <ListItemText
                classes={{ primary: classes.selectAllText }}
                primary="Select All"
              />
            </MenuItem>
          )}

          {cityList?.map((city) => (
            <MenuItem key={city.district} value={city.district}>
              <Checkbox checked={selectedIds.indexOf(city.district) > -1} />
              <ListItemText primary={`${city.district} (${city.state})`} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
