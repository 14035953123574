import { Link } from "react-router-dom";
import moment from "moment";

// import { useState } from "react";
const ConsumerDetailsTable = ({ consumer }) => {
  return (
    <div className="col-md-auto">
      <div className="row justify- content-between">
        <div className="col-lg-12 col-md-12">
          <div className="card card-static-2 mb-30">
            <div className="card-body-table">
              <div className="table-responsive">
                <table className="table ucp-table table-hover d-flex">
                  <tbody>
                    <tr>
                      <th>Name</th>
                      <td>{consumer.name}</td>
                    </tr>
                    <tr>
                      <th>Mobile</th>
                      <td>{consumer.mobileNumber}</td>
                    </tr>
                    <tr>
                      <th>Email</th>
                      <td>{consumer.email || "-"}</td>
                    </tr>
                    <tr>
                      <th>Activated on</th>
                      <td>{moment(consumer.createdAt).format("lll")}</td>
                    </tr>
                    <tr>
                      <th>Last Active On</th>
                      <td>{consumer.lastActiveAt || "-"}</td>
                    </tr>
                    <tr>
                      <th>Address</th>
                      <td>{2}</td>
                    </tr>
                  </tbody>
                  <tbody style={{ borderTop: "0px" }}>
                    <tr>
                      <th>LTV</th>
                      <td>{consumer.ltv || "-"}</td>
                    </tr>

                    <tr>
                      <th>Average Order Value</th>
                      <td>{"-"}</td>
                    </tr>
                    <tr>
                      <th>Total Orders</th>
                      <td>{consumer.orderCount}</td>
                    </tr>
                    <tr>
                      <th>consumer Source</th>
                      <td>organic || through referral</td>
                    </tr>
                    <tr>
                      <th>Referral Code</th>
                      <td>9X543TM</td>
                    </tr>
                    <tr>
                      <th>Total Referrals given</th>
                      <td>2</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConsumerDetailsTable;
