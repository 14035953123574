import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 380,
      maxWidth: 400,
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
  }));
    
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
const BusinessIdeasList = ({bussinessIds,
    businessList,
    setTrade,
    trade}) => {
        const classes = useStyles();
        const handleChange = (e) => {
            console.log('e: ', e.target.value);
            setTrade({ ...trade, bussinessIds: e.target.value });
          };

          
          useEffect(() => {
            console.log("business ideasssss",businessList)
            }, [])
  return (
    <div>
    <FormControl className={classes.formControl}>
    <InputLabel id="demo-mutiple-checkbox-label">
     Select Business Ideas<span className="required">&#42;</span>
   </InputLabel>
   <Select
     labelId="demo-mutiple-checkbox-label"
     id="demo-mutiple-checkbox"
     multiple
     required
     value={bussinessIds}
     onChange={handleChange}
     input={<Input />}
     renderValue={(selected) =>
       businessList
         ?.filter((trade) => selected.includes(trade._id))
         .map((trade) => trade.combine)
         .join(", ")
     }
     MenuProps={MenuProps}
   >
     {businessList?.map((trade) => (
       <MenuItem key={trade} value={trade._id}>
         <Checkbox checked={bussinessIds.indexOf(trade._id) > -1} />
         <ListItemText primary={trade.combine} />
       </MenuItem>
     ))}
   </Select>
    </FormControl>
</div>
  )
}

export default BusinessIdeasList