import axios from "axios";
import { BASE_URL } from "./api";

// CREATE A NEW BRAND
export const createNewBrand = async (token, data) =>{
  await axios.post(`${BASE_URL}/brands`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

// GET ALL THE BRANDS
export const allBrands = async (token) =>
  await axios.get(`${BASE_URL}/brands`,{
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// UPDATE A BRAND
export const updateBrand = async (token, data, brandId) =>{
  await axios.put(`${BASE_URL}/brands/${brandId}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

// DELETE THE BRAND
export const deleteBrand = async (token, brandId) => {
  await axios.delete(`${BASE_URL}/brands/${brandId}`,{
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// // GET SINGLE THE BRAND
export const singleBrand = async (token, brandId) =>
  await axios.get(`${BASE_URL}/brands/${brandId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// // ---SEARCH RESULTS---
// export const searchListings = async (query) =>
//   await axios.post(`${BASE_URL}/search-listings`, query);
