import { Link } from "react-router-dom";
import moment from "moment";
import { useState, useMemo } from "react";
import TableHeader from "../0-ResuableComponents/tableComponents/TableHeader";
import { consumerData } from "../../actions/consumerAction";
import { useSelector } from "react-redux";

const headers = [
  { name: "Order ID", field: "orderId", sortable: true },
  { name: "Order Time", field: "orderTime", sortable: false },
  { name: "Ordered By", field: "OrderedBy", sortable: false },
  { name: "Order Status", field: "orderStatus", sortable: false },
  { name: "Order Amount", field: "orderAmount", sortable: false },
  { name: "Total Items", field: "totalItems", sortable: false },
  {
    name: "Order Amount Delivered",
    field: "orderAmountDelivered",
    sortable: false,
  },
  { name: "State", field: "state", sortable: false },
  { name: "City", field: "city", sortable: false },
  { name: "Cluster", field: "cluster", sortable: false },
  { name: "Assigned to", field: "assignedTo", sortable: false },
];

const OrderTable = ({ orders, setSorting }) => {
  const { auth } = useSelector((state) => ({ ...state }));
  const [namee, setNamee] = useState("");
  const consumerName = async (id) => {
    const res = await consumerData(auth.token, id);

    const name = res?.data?.consumer[0]?.name;
    setNamee(name);
  };

  const tdname = (id) => {
    consumerName(id);
    const name = namee;
    return name;
  };
  return (
    <div className="col-lg-auto col-md-auto">
      <div className="all-cate-tags">
        <div className="row justify-content-between">
          <div className="col-lg-12 col-md-12">
            <div className="card card-static-2 mb-30">
              <div className="card-title-2">
                <h4>All Orders</h4>
              </div>
              <div className="card-body-table">
                <div className="table-responsive">
                  <table
                    id="order-table"
                    className="table ucp-table table-hover"
                  >
                    <TableHeader
                      headers={headers}
                      onSorting={(field, order) => setSorting({ field, order })}
                    />
                    <tbody>
                      {orders?.map((order, index) => (
                        <>
                          <tr key={index}>
                            <td>
                              <Link
                                to={`consumers/${order.consumerId}/${order._id}`}
                              >
                                {order?._id}
                              </Link>
                            </td>

                            <td>
                              {order?.createdAt
                                ? moment(order?.createdAt).format("lll")
                                : "-"}
                            </td>
                            <td>
                              <Link to={`/consumers/${order.consumerId}`}>
                                {`${tdname(order.consumerId)}`}
                              </Link>
                            </td>
                            <td>
                              {order?.status === "Order Placed"
                                ? "Pending"
                                : "Delivered" || "0"}
                            </td>
                            <td>{order?.totalValue || "-"}</td>
                            <td>{order?.totalItems}</td>
                            <td>{order?.totalPayableAmount || "-"}</td>
                            <td>{order?.deliveryAddress?.state || "-"}</td>
                            <td>{order?.deliveryAddress?.city || "-"}</td>
                            <td>-</td>

                            <td>{order?.entrepreneurId || "-"}</td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderTable;
