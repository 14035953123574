/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import TableSearch from "../../0-ResuableComponents/tableComponents/TableSearch";
import Pagination from "../../0-ResuableComponents/pagination/Pagination";
import ConfirmDialog from "../../0-ResuableComponents/confirmDialog/ConfirmDialog";
import ServiceEntrepreneursForm  from "./ServiceEntrepreneursForm";
import ServiceEntrepreneursTable from "./ServiceEntrepreneursTable";
import { createNewServiceEntrepreneurs,allServiceEntrepreneurs,updateServiceEntrepreneurs,
    deleteServiceEntrepreneurs
} from "../../../actions/serviceEntrepreneursActions";

const CreateServiceEntrepreneurs = () => {

  const [serviceEntrepreneurs, setServiceEntrepreneurs] = useState({ name: "", contact:"",
                                                           location:'',photoUrl:"", gst:'',
                                                           workingDays:'',timing:'', gender:'',
                                                           services:[],customers:[],orders:[] });
  const { auth } = useSelector((state) => ({ ...state }));
  const { token } = auth;
  const { name,contact,location,photoUrl,gst,workingDays,timing,gender,services,customers, orders} = serviceEntrepreneurs;
  const [currentId, setCurrentId] = useState(0);
  const [serviceEntrepreneursList, setServiceEntrepreneursList] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [search, setSearch] = useState("");
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [show, setShow] = useState(false);
  
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const fetchAllServiceEntrepreneurs = async () => {
    let res = await allServiceEntrepreneurs(token);
    console.log("Res",res);
    setServiceEntrepreneursList(res.data);
  };

  const singleServiceEntrepreneurs = currentId
    ? serviceEntrepreneursList.entities.find((category) => category._id === currentId)
    : null;

  useEffect(() => {
    fetchAllServiceEntrepreneurs();
    if (singleServiceEntrepreneurs) setServiceEntrepreneurs(singleServiceEntrepreneurs);
  }, [currentId]);

  const serviceCategoryData = useMemo(() => {
    let computedTrades = serviceEntrepreneursList?.entities;

    if (search) {
      computedTrades = computedTrades?.filter((trade) =>
        trade.name.toLowerCase().includes(search.toLowerCase())
      );
    }

    //Sorting Trades
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedTrades = computedTrades?.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    return computedTrades;
  }, [serviceEntrepreneursList, search, sorting]);

  const clear = () => {
    setCurrentId(0);
    setServiceEntrepreneurs({name: "", contact:"", location:'',photoUrl:"", gst:'', workingDays:'',timing:'', gender:'',
    services:[],customers:[],orders:[] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    let serviceEntrepreneursData = new FormData();
    serviceEntrepreneursData.append("name", name);
    serviceEntrepreneursData.append("contact",contact);
    serviceEntrepreneursData.append("location",location);
    serviceEntrepreneursData.append("photoUrl",photoUrl);
    serviceEntrepreneursData.append("gst",gst);
    serviceEntrepreneursData.append("workingDays",workingDays);
    serviceEntrepreneursData.append("gender",gender);
    serviceEntrepreneursData.append("timing",timing);
    serviceEntrepreneursData.append("services",JSON.stringify(services));
    serviceEntrepreneursData.append("customers",JSON.stringify(customers));
    serviceEntrepreneursData.append("orders",JSON.stringify(orders));

    try {
      if (currentId === 0) {
        await createNewServiceEntrepreneurs(token, serviceCategoryData);
        fetchAllServiceEntrepreneurs();
        toast.success(`New Service Entrepreneurs ${name} is added Successfully...`);
        clear();
        handleClose();
      } else {
        setConfirmDialog({
          isOpen: true,
          title: "Are you sure to Update this record?",
          subTitle: "You can't undo this operation",
          onConfirm: async () => {
            setConfirmDialog({
              ...confirmDialog,
              isOpen: false,
            });
            await updateServiceEntrepreneurs(token, serviceCategoryData, currentId)
              .then(() => {
                fetchAllServiceEntrepreneurs();
                toast.success(
                  `Service Entrepreneurs ${name} is updated Successfully...`
                );
                clear();
                handleClose();
              })
              .catch((err) => toast.error(err.response.data.errorMessage));
          },
        });
      }
    } catch (err) {
      toast.error(err.response.data.errorMessage);
    }
  };

  const handleImageChange = (e) => {
    setServiceEntrepreneurs({ ...serviceEntrepreneurs, photoUrl: e.target.files[0] });
  };

  const handleChange = (e) => {
    setServiceEntrepreneurs({ ...serviceEntrepreneurs, [e.target.name]: e.target.value });
  };

  const handleServiceEntrepreneursDelete = async (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    deleteServiceEntrepreneurs(token, id)
      .then((res) => {
        toast.success("Service Entrepreneurs Deleted successfully...");
        fetchAllServiceEntrepreneurs();
      })
      .catch((err) => toast.error(err.response.data.errorMessage));
  };

  // Get current rows
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = serviceCategoryData?.slice(indexOfFirstRow, indexOfLastRow);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div id="layoutSidenav_content">
      <div className="container-fluid">
        <h2 className="mt-25 page-title">Service Entrepreneurs</h2>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Dashboard</Link>
          </li>
          <li className="breadcrumb-item active">Service Entrepreneurs</li>
        </ol>
        {/* <div className="col-lg-12 col-md-12 back-btn">
          <button
            className="save-btn hover-btn"
            onClick={() => { setCurrentId(0);clear(); handleShow()}} >
            + Add New Service Entrepreneurs
          </button>
        </div> */}
        <TableSearch
          onSearch={(value) => {
            setSearch(value);
          }}
          placeholder="Search by Service Entrepreneurs Name"
        />
        <ServiceEntrepreneursForm 
          serviceEntrepreneurs={serviceEntrepreneurs}
          setServiceEntrepreneurs={setServiceEntrepreneurs}
          handleSubmit={handleSubmit}
          currentId={currentId}
          show={show}
          handleClose={handleClose}
          handleChange={handleChange} 
          handleImageChange ={handleImageChange}
          />
        
        <div className="row">
          <ServiceEntrepreneursTable
            serviceEntrepreneursList={currentRows}
            setCurrentId={setCurrentId}
            handleServiceEntrepreneursDelete={handleServiceEntrepreneursDelete}
            setConfirmDialog={setConfirmDialog}
            setSorting={setSorting}
            handleShow={handleShow} />
        </div>
        <Pagination
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          totalRows={serviceEntrepreneursList?.data?.length}
          paginate={paginate}
          url="/services-entrepreneurs"
        />
      </div>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </div>
  );
};

export default CreateServiceEntrepreneurs;