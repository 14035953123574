import axios from "axios";
import { BASE_URL_ENTREPRENEUR, BASE_URL_LOCATION } from "./api";

// ADD A NEW ENTREPRENEUR
export const addNewEntrepreneur = async (token, data) =>
  await axios.post(`${BASE_URL_ENTREPRENEUR}/entrepreneur`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// SAVE A NEW ENTREPRENEUR
export const saveNewEntrepreneur = async (token, data) =>
  await axios.post(
    `${BASE_URL_ENTREPRENEUR}/entrepreneur?type=SaveProgress`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

// GET ALL THE ENTREPRENEUR
export const allEntrepreneur = async (token) =>
  await axios.get(`${BASE_URL_ENTREPRENEUR}/entrepreneur`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// GET SINGLE THE ENTREPRENEUR
export const singleEntrepreneur = async (token, entrepreneurId) =>
  await axios.get(`${BASE_URL_ENTREPRENEUR}/entrepreneur/${entrepreneurId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// UPDATE AN ENTREPRENEUR
export const updateEntrepreneur = async (token, data, entrepreneurId) => {
  await axios.put(
    `${BASE_URL_ENTREPRENEUR}/entrepreneur/${entrepreneurId}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

// GET ALL STATES
export const allStates = async (token) =>
  await axios.get(`${BASE_URL_LOCATION}/locations/states`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// GET CITIES RESPECTED TO A PARTICULAR STATE
export const stateCities = async (token, state) =>
  await axios.get(`${BASE_URL_LOCATION}/locations/cities/${state}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  
// /locations/cities/Bihar
