import axios from "axios";
import { BASE_URL_COUPON } from "./api";

// ADD A NEW COUPON
export const addNewCoupon = async (token, data) =>
  await axios.post(`${BASE_URL_COUPON}/coupon`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// GET ALL THE COUPON
export const allCoupon = async (token) =>
  await axios.get(`${BASE_URL_COUPON}/coupon`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// GET SINGLE THE COUPON
export const singleCoupon = async (token, couponId) =>
  await axios.get(`${BASE_URL_COUPON}/coupon/${couponId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// UPDATE AN COUPON
export const updateCoupon = async (token, data, couponId) => {
  await axios.put(`${BASE_URL_COUPON}/coupon/${couponId}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// DELETE THE SINGLE COUPON
export const deleteCoupon = async (token, couponId) => {
  await axios.delete(`${BASE_URL_COUPON}/coupon/${couponId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// DELETE THE ALL THE COUPONS
export const deleteAllCoupon = async (token) => {
  await axios.delete(`${BASE_URL_COUPON}/coupon`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
