import GetAppIcon from "@material-ui/icons/GetApp";
import moment from "moment";

// import { useState } from "react";
const SingleOrderDetailsTable = ({ order, totalValue }) => {
  const downloadDetails = () => {
    console.log("download");
    window.print();
  };
  const { _id, createdAt, status, paymentMethod, selectedSlot } = order;

  return (
    <>
      <div className="col-md-auto">
        <div className="row justify- content-between">
          <div className="col-lg-12 col-md-12">
            <div className="card card-static-2 mb-30">
              <div className="card-body-table">
                <div className="table-responsive">
                  <table className="table ucp-table table-hover d-flex ">
                    <tbody>
                      <tr>
                        <tr>
                          <th>Order ID</th>
                          <td>{_id}</td>
                        </tr>
                        <tr>
                          <th>Order Status</th>
                          <td>{status}</td>
                        </tr>
                        <tr>
                          <th>Delivery Address</th>
                          <td>
                            {order?.deliveryAddress?.address?.completeAddress +
                              ", " +
                              order?.deliveryAddress?.address?.district +
                              ", " +
                              order?.deliveryAddress?.address?.state || "-"}
                          </td>
                        </tr>
                        <tr>
                          <th>Order Date</th>
                          <td>{moment(createdAt).format("lll") || "-"}</td>
                        </tr>
                        <tr>
                          <th>Order Amount(INR)</th>
                          <td>{totalValue}</td>
                        </tr>
                        <tr>
                          <th>Preferred Slots</th>
                          <td>{order?.selectedSlot?.timeSlot || "-"}</td>
                        </tr>
                        <tr>
                          <th>Payment Mode</th>
                          <td>{paymentMethod || "-"}</td>
                        </tr>
                        <tr>
                          <th>Order Status</th>
                          <td>{status}</td>
                        </tr>
                        {/* <tr>
                          <th>Payment Status</th>
                          <td>{paymentStatus}</td>
                        </tr> */}
                      </tr>
                    </tbody>
                    {/* <tr>
                          <td>ORDERED</td>
                          <td>{orderDateTime}</td>
                        </tr>
                        <tr>
                          <td>APPROVED</td>
                          <td>{orderDateTime}</td>
                        </tr>
                        <tr> */}
                    {/* <td>
                            {paymentStatus === "Paid"
                              ? "DELIVERED"
                              : "PROCESSING"}
                          </td>
                          <td>
                            {paymentStatus === "Paid" ? orderDateTime : "-"}
                          </td> */}
                    {/* </tr> */}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {status === "Delivered" && (
        <button className="download-btn download" onClick={downloadDetails}>
          Download <GetAppIcon />
        </button>
      )}
    </>
  );
};

export default SingleOrderDetailsTable;
