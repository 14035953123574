/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { stateCities } from "../../../actions/entrepreneurActions";
import TableHeader from "../../0-ResuableComponents/tableComponents/TableHeader";

const CouponStatesCitiesClustersTable = ({ setSorting, coupon }) => {
  const [cityList, setCityList] = useState([]);
  const { auth } = useSelector((state) => ({ ...state }));
  const { token } = auth;
  const flatCityList = cityList?.flat();

  const fetchAllCities = async () => {
    if (!coupon?.states) return;

    const promises = coupon?.states?.map((item, idx) => {
      return stateCities(token, coupon?.states[idx]).then((response) => {
        return response.data.cities;
      });
    });

    Promise.all(promises).then((results) => {
      const cities = results.map((result) => result);
      setCityList(cities.filter((city) => city.length > 0));
    });
  };

  useEffect(() => {
    fetchAllCities();
  }, [coupon?.states]);

  const headers = [
    { name: "City", field: "city", sortable: false },
    { name: "Cluster", field: "cluster", sortable: false },
    { name: "Status", field: "status", sortable: false },
  ];

  return (
    <div className="col-lg-12 col-md-12" style={{ userSelect: "auto" }}>
      <div className="card card-static-2 mt-30 mb-30">
        {coupon?.states?.map((state, idx) => (
          <div key={state}>
            <div className="card-title-2">
              <h4>{state}</h4>
            </div>
            <div className="card-body-table">
              <div className="table-responsive">
                <table className="table ucp-table table-hover">
                  <TableHeader
                    headers={headers}
                    onSorting={(field, order) => setSorting({ field, order })}
                  />
                  <tbody>
                    {flatCityList
                      ?.filter((city) => {
                        if (
                          city.state === coupon?.states[idx] &&
                          coupon?.cities?.includes(city.district)
                        ) {
                          // console.log("city===>", city)
                          return city;
                        }
                      })
                      ?.map((city) => (
                        <tr key={city.district}>
                          <td>{city.district}</td>
                          <td>{state}</td>
                          <td
                            style={{
                              color: `${
                                coupon?.status === "Active"
                                  ? "green"
                                  : coupon?.status === "Inactive"
                                  ? "red"
                                  : "orange"
                              }`,
                            }}
                          >
                            {coupon?.status}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CouponStatesCitiesClustersTable;
// {/* <pre>{JSON.stringify(city, 4, null)}</pre> */}
