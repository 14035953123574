/** @format */
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "react-bootstrap/Button";
import React, { useState, useRef, useEffect } from "react";
import { PlusOutlined } from "@ant-design/icons";

import {
  Divider,
  Input,
  Select,
  Space,
  Modal,
  Checkbox,
  Pagination,
  Tag,
} from "antd";

const FILTER_NAMES = [
  { label: "Size", value: "size" },
  { label: "Color", value: "color" },
  { label: "Hair Type", value: "hair-type" },
  { label: "Quantity", value: "quantity" },
  { label: "Key Ingredient", value: "key-ingredient" },
  { label: "Concern", value: "concern" },
  { label: "Hair Thickness", value: "hair-thickness" },
  { label: "Source", value: "source" },
];
const VALUE_OPTIONS = {
  size: [
    { label: "Pack", value: "pack" },
    { label: "0-50 ML", value: "0 to 50 ml" },
    { label: "100-300 ML", value: "100 to 300 ml" },
    { label: "300-500 ML", value: "300 to 500 ml" },
    { label: "600 ML & Above", value: "600 ml and above" },
    { label: "S", value: "s" },
    { label: "M", value: "m" },
    { label: "XL", value: "xl" },
    { label: "L", value: "l" },
    { label: "XS", value: "xs" },
  ],
  "hair-type": [
    { label: "Curly Hair", value: "curly-hair" },
    { label: "Straight Hair", value: "straight-hair" },
    { label: "Normal", value: "normal" },
    { label: "Oily", value: "oily" },
    { label: "Dry", value: "dry" },
    { label: "Coloured", value: "coloured" },
    { label: "Wavy", value: "wavy" },
  ],
  color: [
    { label: "Red", value: "red" },
    { label: "Blue", value: "blue" },
    { label: "Green", value: "green" },
    { label: "Black", value: "black" },
  ],
  quantity: [
    { label: "1", value: "1" },
    { label: "2", value: "2" },
    { label: "3", value: "3" },
  ],
  "key-ingredient": [
    { label: "Honey", value: "honey" },
    { label: "AHA", value: "AHA" },
    { label: "Avacado", value: "avacado" },
    { label: "Rita", value: "rita" },
    { label: "Amla", value: "amla" },
    { label: "BHA", value: "BHA" },
  ],
  concern: [
    { label: "Anti-Frizz", value: "anti-frizz" },
    { label: "Vegan", value: "vegan" },
    { label: "Dullness", value: "dullness" },
    { label: "Oil Control", value: "oil-control" },
    { label: "Daily Use", value: "daily-use" },
    { label: "Dry Scalp", value: "dry-scalp" },
  ],
  "hair-thickness": [
    { label: "Normal", value: "normal" },
    { label: "Fine", value: "fine" },
  ],
  source: [
    { label: "Honey Bee", value: "honey-bee" },
    { label: "Floral", value: "floral" },
  ],
};

const SubCategoryForm = ({
  subCategory,
  setSubCategory,
  handleSubmit,
  currentId,
  show,
  handleClose,
  addFilterInput,
  resetForm,
  setResetForm,
  onFiltersApplicableChange,
  InputFilters,
  setInputFilters,
}) => {
  const { name, filters, haveFilters } = subCategory;

 
  const [filtersApplicable, setFiltersApplicable] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterCounter, setFilterCounter] = useState(0);
  const [showFilterInput, setShowFilterInput] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [selectedValues, setSelectedValues] = useState([]);
  const [items, setItems] = useState([]);
  const [otherFilterName, setOtherFilterName] = useState("");
  const [otherFilterValues, setOtherFilterValues] = useState("");
  const [addFilterClicked, setAddFilterClicked] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [editedData, setEditedData] = useState("");
  const [editingRow, setEditingRow] = useState(null);
  const filterNameRef = useRef(null);
  const filterValueRef = useRef(null);

  const addOtherFilterItem = (e, editing) => {
    e.preventDefault();
    if (editing) {
      if (otherFilterName) {
        const commaIndex = otherFilterName.indexOf(",");
        const formattedName =
          commaIndex !== -1
            ? otherFilterName.slice(0, commaIndex).trim()
            : otherFilterName.trim();
        const newItem = {
          label: formattedName.charAt(0).toUpperCase() + formattedName.slice(1),
          value: formattedName.toLowerCase(),
        };
        setItems((prevItems) => [newItem, ...prevItems]);
        setOtherFilterName("");
        setSelectedFilter(newItem);
      }
      setTimeout(() => {
        filterNameRef.current?.focus();
      }, 0);
    } else {
      if (otherFilterName) {
        const commaIndex = otherFilterName.indexOf(",");
        const formattedName =
          commaIndex !== -1
            ? otherFilterName.slice(0, commaIndex).trim()
            : otherFilterName.trim();
        const newItem = {
          label: formattedName.charAt(0).toUpperCase() + formattedName.slice(1),
          value: formattedName.toLowerCase(),
        };
        setItems((prevItems) => [newItem, ...prevItems]);
        setOtherFilterName("");
        setSelectedFilter(newItem);
      }
      setTimeout(() => {
        filterNameRef.current?.focus();
      }, 0);
    }
  };
  const addOtherFilterValue = (e) => {
    e.preventDefault();
    if (otherFilterValues) {
      const newValues = otherFilterValues
        .split(",")
        .map((value) => value.trim());
      if (newValues.length > 0) {
        const newItems = newValues.map((value) => (typeof value==='object'? value: ({
          label: value,
          value: value.toLowerCase(),
        })));

        setItems((prevItems) => [...newItems, ...prevItems]);
        setOtherFilterValues("");
        setSelectedValues([...selectedValues, ...newItems]);
        
      
      }
    }
    setTimeout(() => {
      filterValueRef.current?.focus();
    }, 0);
  };
  // filter applicable or not checkbox
  const handleFiltersApplicableChange = () => {
    const filterValueToSet = !filtersApplicable;
    setFiltersApplicable(filterValueToSet);

    setShowFilterInput(false);
    setSelectedValues([]);
    // setFilterInputs([]);
    setFilterCounter(0);
    setInputFilters([]);
    setSubCategory({
      ...subCategory,
      InputFilters: [],
      haveFilters: filterValueToSet,
      filters: [],
    });
    setSelectedFilter(null);
    onFiltersApplicableChange(filterValueToSet);
  };
  // dding new filters in updation
  const handleFilterUpdation = () => {
    setShowFilterInput(true);
    setFiltersApplicable(true);
  };
  // To add new filter
  const handleAddFilterName = () => {
    setShowFilterInput(true);

    if (selectedFilter && selectedValues.length > 0) {
      const newFilterInputs = [
        {
          id: filterCounter + 1,
          filter: selectedFilter.value,
          values: selectedValues.map((value) => (typeof value === 'object' ? value : {
            value: value,
          })),
          filterHide: false,
        },
        ...(subCategory.filters || []),
      ];
      
  
  ;
    
      setSubCategory({ ...subCategory, filters: newFilterInputs });
      setInputFilters(newFilterInputs);
    
      setFilterCounter(filterCounter + 1);
      setSelectedFilter(null);
      setSelectedValues([]);
      setAddFilterClicked(true);
      setIsButtonDisabled(false); 
    }
  };
  

  // To hide filter
  const handleFilterHide = (id, filterHide) => {
    if (typeof filterHide === "object") {
     
      filterHide = !filterHide.target.checked;
    }

    let { filters } = subCategory;
    filters = filters.map((fil) =>
      fil.id === id ? { ...fil, filterHide: !filterHide } : fil
    );
  
   
    const subcategoryToSave = { ...subCategory, filters };
   
    setSubCategory(subcategoryToSave);
    setInputFilters(filters);
  };

  const availableFilters = [
    ...FILTER_NAMES.filter(
      (filter) =>
        !(subCategory.filters || []).find(
          (input) => input.filter === filter.value
        ) &&
        (!selectedFilter || filter.value !== selectedFilter.value)
    ),
  ];

  const handleFilterChange = (value, isEdit) => {
    const filter = FILTER_NAMES.find((filter) => filter.value === value);
    setSelectedFilter(filter);
    setSelectedValues([]);
    if (isEdit) {
      setEditedData({ ...editedData, filter: filter.value });
    }
  };
  const itemsPerPage = 3;

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = currentPage * itemsPerPage;
  const [paginatedFilterInputs, setPaginatedFilterInputs] = useState([]);

  useEffect(() => {
    setPaginatedFilterInputs(
      (subCategory.filters || []).slice(startIndex, endIndex)
    );
  }, [subCategory]);
  useEffect(() => {
    if (resetForm) {
      
      setFiltersApplicable(false);
      setSelectedFilter(null);
      setSelectedValues([]);
      setOtherFilterName("");
      setOtherFilterValues("");
      setAddFilterClicked(false);
      setIsButtonDisabled(false);
      setEditingRow("");
      setResetForm(false);
    }
  }, [resetForm, setResetForm]);

  const showSubCategoryFiltersTable = currentId;

  const startEditing = (filterId, initialValue) => {
   
    
    setEditedData(initialValue);
    setSelectedValues(initialValue.values || []);
   
    setEditingRow(filterId);
  };
  const cancelEditing = () => {
    setEditingRow(null);
    setSelectedValues(null);
    setSelectedFilter(false);
  };
  const saveEditedFilter = (
    filterId,
    updatedFilterName,
    updatedFilterValues,
    updatedFilterHide
  ) => {
    const editedFilter = filters.find((input) => input.id === filterId);

    if (editedFilter) {
      
      editedFilter.filter = updatedFilterName;
      
      editedFilter.values = updatedFilterValues?.map((value) =>
        typeof value === "object" ? value : { value }
      );
     
      editedFilter.filterHide = updatedFilterHide;
      
      const updatedFilterInputs = filters.map((input) =>
        input.id === filterId ? editedFilter : input
      );
      setSubCategory({ ...subCategory, filters: updatedFilterInputs });
     
      setEditingRow(null);
    }
  };
 
  return (
    <Modal
      open={show}
      width={1000}
      title={currentId ? "Update Sub-Category" : "+ Add New Sub-Category"}
      onCancel={handleClose}
      footer={null}
    >
      <div>
        <div className='col-lg-12 col-md-11'>
          <div className='card card-static-2 mb-30'>
            <div className='card-body-table'>
              <div className='news-content-right pd-20'>
                <form onSubmit={handleSubmit}>
                  <div className='form-group'>
                    <label htmlFor='name' className='form-label'>
                      Sub-Category Name
                      <span className='required'>&#42;</span>
                    </label>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='Sub category name'
                      required
                      name='name'
                      value={name}
                      onChange={(e) =>
                        setSubCategory({
                          ...subCategory,
                          name: e.target.value,
                        })
                      }
                    />
                    <div>
                      <label
                        htmlFor='filtersApplicable'
                        className='form-label mt-2'
                      >
                        Filters applicable? &nbsp;
                        <Checkbox
                          value='Filter Applicable'
                          checked={
                            currentId && haveFilters ? true : filtersApplicable
                          }
                          onClick={handleFiltersApplicableChange}
                          disabled={currentId && haveFilters}
                        />
                      </label>
                    </div>
                    {currentId ? (
                      <Button onClick={handleFilterUpdation}>
                        Add new Filters
                      </Button>
                    ) : (
                      ""
                    )}

                    {filtersApplicable && (
                      <div className='d-flex mt-2'>
                        <div className='form-group col-lg-4 col-md-10'>
                          <label htmlFor='filterName' className='form-label'>
                            Filter Name:
                          </label>
                          <Select
                            style={{
                              width: 300,
                            }}
                            placeholder='Select Filters'
                            dropdownRender={(menu) => (
                              <>
                                {menu}
                                <Divider
                                  style={{
                                    margin: "8px 0",
                                  }}
                                />
                                <Space
                                  style={{
                                    padding: "0 8px 4px",
                                  }}
                                >
                                  <Input
                                    placeholder='Enter filter name'
                                    ref={filterNameRef}
                                    value={otherFilterName}
                                    onChange={(e) =>
                                      setOtherFilterName(e.target.value)
                                    }
                                  />
                                  <Button
                                    type='text'
                                    icon={<PlusOutlined />}
                                    onClick={addOtherFilterItem}
                                  >
                                    Add item 
                                  </Button>
                                </Space>
                              </>
                            )}
                            options={availableFilters}
                            value={selectedFilter}
                            onChange={handleFilterChange}
                            showSearch
                          />
                        </div>
                        {selectedFilter && (
                          <div className='form-group col-lg-4 col-md-10'>
                            <label
                              htmlFor='filterValues'
                              className='form-label'
                              style={{
                                marginLeft: 75,
                              }}
                            >
                              Filter Values:
                            </label>
                            <Select
                              style={{
                                width: 300,
                                marginLeft: 75,
                              }}
                              placeholder='Select value'
                              dropdownRender={(menu) => (
                                <>
                                  {menu}
                                  <Divider
                                    style={{
                                      margin: "8px 0",
                                    }}
                                  />
                                  <Space
                                    style={{
                                      padding: "0 8px 4px",
                                    }}
                                  >
                                    <Input
                                      placeholder='Enter values'
                                      ref={filterValueRef}
                                      value={otherFilterValues}
                                      onChange={(e) =>
 {                                       setOtherFilterValues(e.target.value)}
                                      }
                                    />
                                    <Button
                                      type='text'
                                      icon={<PlusOutlined />}
                                      onClick={addOtherFilterValue}
                                    >
                                      Add item 
                                    </Button>
                                  </Space>
                                </>
                              )}
                              options={VALUE_OPTIONS[selectedFilter.value]}
                              value={selectedValues}
                              onChange={(values) => {
                               
                                setSelectedValues(values);
                                setSubCategory((prevSubCategory) => ({
                                  ...prevSubCategory,
                                  filterValue: values.map((value) =>
                                    typeof value === "object"
                                      ? value.value || ""
                                      : value
                                  ),
                                }));
                              }}
                              mode='multiple'
                              showSearch
                            />
                          </div>
                        )}

                        {selectedFilter && selectedValues.length > 0 && (
                          <div className='form-group col-lg-4 col-md-10 d-flex align-items-center '>
                            <Button
                              variant='success'
                              onClick={handleAddFilterName}
                              style={{ marginLeft: 95 }}
                            >
                              Save Filter
                            </Button>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  {/* Table to display filter values */}
                  {showSubCategoryFiltersTable ? (
                    <>
                      {filters ? (
                        <>
                          <table className='table table-bordered mt-2'>
                            {/* Table headers */}
                            <thead>
                              <tr>
                                <th>Sl.No</th>
                                <th>Filter Name</th>
                                <th>Values</th>
                                <th>Hide Filter</th>
                                <th>Edit</th>
                              </tr>
                            </thead>
                            {/* Table body */}
                            <tbody>
                              {(subCategory.filters || [])
                                .slice(startIndex, startIndex + itemsPerPage)
                                .map((filter, index) => (
                                  <tr key={filter.id}>
                                    <td>{(startIndex+index) + 1}</td>
                                    <td>
                                      {editingRow === filter.id ? (
                                        <Select
                                          style={{
                                            width: 300,
                                          }}
                                          placeholder='Select Filters'
                                          dropdownRender={(menu) => (
                                            <>
                                              {menu}
                                              <Divider
                                                style={{
                                                  margin: "8px 0",
                                                }}
                                              />
                                              <Space
                                                style={{
                                                  padding: "0 8px 4px",
                                                }}
                                              >
                                                <Input
                                                  placeholder='Enter filter name'
                                                  ref={filterNameRef}
                                                  value={otherFilterName}
                                                  onChange={(e) =>
                                                    setOtherFilterName(
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                                <Button
                                                  type='text'
                                                  icon={<PlusOutlined />}
                                                  onClick={addOtherFilterItem}
                                                >
                                                  Add item 
                                                </Button>
                                              </Space>
                                            </>
                                          )}
                                          options={
                                            FILTER_NAMES
                                            
                                          }
                                          value={editedData.filter}
                                          onChange={handleFilterChange}
                                          showSearch
                                        />
                                      ) : (
                                        // Render plain text if not editing
                                        filter.filter.charAt(0).toUpperCase() +
                                        filter.filter
                                          .slice(1)
                                          .toLowerCase()
                                          .replace(/-/g, " ")
                                      )}
                                    </td>
                                    <td>
                                      {editingRow === filter.id ? (
                                        <Select
                                          style={{ width: 300 }}
                                          placeholder='Select value'
                                          dropdownRender={(menu) => (
                                            <>
                                              {menu}

                                              <Divider
                                                style={{ margin: "8px 0" }}
                                              />
                                              <Space
                                                style={{ padding: "0 8px 4px" }}
                                              >
                                                <Input
                                                  placeholder='Enter values'
                                                  ref={filterValueRef}
                                                  value={otherFilterValues}
                                                  onChange={(e) =>
                                                    setOtherFilterValues(
                                                      e.target.value
                                                    )
                                                  }
                                                />
                                                <Button
                                                  type='text'
                                                  icon={<PlusOutlined />}
                                                  onClick={addOtherFilterValue}
                                                >
                                                  Add item 
                                                </Button>
                                              </Space>
                                            </>
                                          )}
                                          options={VALUE_OPTIONS[
                                            editedData.filter
                                          ]?.map((value) => ({
                                            value: value.value,
                                            label:
                                              value.value
                                                ?.charAt(0)
                                                .toUpperCase() +
                                              value.value
                                                ?.slice(1)
                                                .toLowerCase()
                                                .replace(/-/g, " "),
                                          }))}
                                          value={selectedValues}
                                          onChange={(values) => {
                                            setSelectedValues(values);
                                            setSubCategory(
                                              (prevSubCategory) => ({
                                                ...prevSubCategory,
                                                filterValue: values?.map(
                                                  (value) => value.value
                                                ),
                                              })
                                            );
                                        
                                          }}
                                          mode='multiple'
                                          showSearch
                                        />
                                      ) : (
                                        filter.values
                                          .map(
                                            (value) =>
                                              { 
                                                return typeof value.value === 'object' ? value.value.value
                                                ?.charAt(0)
                                                .toUpperCase() +
                                              value.value.value
                                                ?.slice(1)
                                                .toLowerCase()
                                                .replace(/-/g, " ") : value.value
                                                ?.charAt(0)
                                                .toUpperCase() +
                                              value.value
                                                ?.slice(1)
                                                .toLowerCase()
                                                .replace(/-/g, " ")}
                                          )
                                          .join(", ")
                                      )}
                                    </td>
                                    {editingRow === filter.id ? (
                                      <td>
                                        <Checkbox
                                          checked={filter.filterHide}
                                          
                                          onClick={() =>
                                            handleFilterHide(
                                              filter.id,
                                              filter.filterHide
                                            )
                                          }
                                        />
                                      </td>
                                    ) : (
                                      <td>
                                       
                                        {filter.filterHide ? "Yes" : "No"}
                                      </td>
                                    )}
                                    <td>
                                      {editingRow === filter.id ? (
                                        <div>
                                          <Button
                                            disabled={
                                              selectedValues.length === 0
                                            }
                                            onClick={() =>
                                              saveEditedFilter(
                                                filter.id,
                                                editedData.filter,
                                                selectedValues,
                                                filter.filterHide
                                              )
                                            }
                                            className='btn btn-success'
                                          >
                                            Save
                                          </Button>
                                          <span
                                            style={{ marginRight: "10px" }}
                                          ></span>
                                          <button
                                            onClick={cancelEditing}
                                            className='btn btn-danger'
                                          >
                                            Cancel
                                          </button>
                                        </div>
                                      ) : (
                                        <i
                                          className='fas fa-edit'
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            startEditing(filter.id, filter)
                                          }
                                        />
                                      )}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                          <Pagination
                            current={currentPage}
                            defaultCurrent={1}
                            total={filters.length}
                            pageSize={itemsPerPage}
                            onChange={handlePageChange}
                          />
                        </>
                      ) : (
                        <p>No subcategory filters available.</p>
                      )}
                    </>
                  ) : (
                   
                    (subCategory.filters || []).length > 0 && (
                      <>
                        <table className='table table-bordered mt-2'>
                          {/* Table headers */}
                          <thead>
                            <tr>
                              <th>Sl.No</th>
                              <th>Filter Name</th>
                              <th>Values</th>
                              <th>Hide Filter</th>
                            </tr>
                          </thead>
                          {/* Table body */}
                          <tbody>
                            {paginatedFilterInputs.map((input, index) => (
                              <tr key={input.id}>
                                <td>{startIndex + index + 1}</td>
                                <td>
                                  {input.filter?.charAt(0).toUpperCase() +
                                    input.filter
                                      .slice(1)
                                      .toLowerCase()
                                      .replace(/-/g, " ")}
                                </td>
                                <td>
                                  {input.values
                                    .map(
                                      (item) =>
                                       { 
                                       
                                        return (typeof item.value === 'object' ? item.value.value?.charAt(0).toUpperCase() +
                                        item.value.value
                                          .slice(1)
                                          .toLowerCase()
                                          .replace(/-/g, " ") :  item.value?.charAt(0).toUpperCase() +
                                        item.value
                                          .slice(1)
                                          .toLowerCase()
                                          .replace(/-/g, " "))}
                                    )
                                    .join(", ")}
                                </td>
                                {/*
                                 <td>
                                  <DeleteIcon
                                    style={{ cursor: "pointer" }}
                                    onClick={() => removeFilterName(input.id)}
                                  />
                                </td>
                                */}

                                <td>
                                  <Checkbox
                                    checked={input.filterHide}
                                    onChange={(e) =>
                                      handleFilterHide(input.id, e, true)
                                    }
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                        {subCategory.filters.length > itemsPerPage && (
                          <Pagination
                            current={currentPage}
                            defaultCurrent={1}
                            total={subCategory.filters.length}
                            pageSize={itemsPerPage}
                            onChange={handlePageChange}
                          />
                        )}
                      </>
                    )
                  )}
                  <div className='col-12 mt-3'>
                    <button
                      type='submit'
                      disabled={
                        currentId
                          ? (editingRow
                              ? !(
                                  editedData.filter &&
                                  editedData.values &&
                                  editedData.values.length
                                )
                              : false) ||
                            (selectedFilter && selectedValues.length === 0) ||
                            (selectedFilter && selectedValues.length !== 0) ||
                            (filtersApplicable && !addFilterClicked)
                          : (selectedFilter && selectedValues.length === 0) ||
                            (selectedFilter && selectedValues.length !== 0) ||
                            (filtersApplicable && !addFilterClicked)
                      }
                      className={`save-btn ${
                        (selectedFilter && selectedValues.length === 0) ||
                        (selectedFilter && selectedValues.length !== 0) ||
                        (filtersApplicable && !addFilterClicked)
                          ? ""
                          : "hover-btn"
                      }`}
                      style={{
                        backgroundColor:
                          (selectedFilter && selectedValues.length === 0) ||
                          (selectedFilter && selectedValues.length !== 0) ||
                          (filtersApplicable && !addFilterClicked)
                            ? "gray"
                            : "",
                      }}
                    >
                      {currentId
                        ? "Update Sub Category"
                        : "Add New Sub Category"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SubCategoryForm;
