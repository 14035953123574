/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import AddNewAdminForm from "./AddNewAdminForm";
import AdminsTable from "./AdminsTable";
import TableSearch from "../0-ResuableComponents/tableComponents/TableSearch";
import Pagination from "../0-ResuableComponents/pagination/Pagination";
import { addNewAdmin, allAdmin, updateAdmin } from "../../actions/authAction";

const AddNewAdmin = () => {
  const [admin, setAdmin] = useState({
    name: "",
    email: "",
    role: "Admin",
    status: "Active",
    mobileNumber: "",
  });
  const { auth } = useSelector((state) => ({ ...state }));
  const [adminList, setAdminList] = useState([]);
  const [currentId, setCurrentId] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const fetchAllAdmin = async () => {
    let res = await allAdmin(auth.token);
    setAdminList(res.data);
  };

  const singleAdmin = currentId
    ? adminList.admins.find((admin) => admin._id === currentId)
    : null;

  useEffect(() => {
    fetchAllAdmin();
    if (singleAdmin) setAdmin(singleAdmin);
  }, [currentId]);

  const adminsData = useMemo(() => {
    let computedAdmins = adminList?.admins;

    if (search) {
      computedAdmins = computedAdmins?.filter(
        (admin) =>
          admin.name.toLowerCase().includes(search.toLowerCase()) ||
          admin.email.toLowerCase().includes(search.toLowerCase())
      );
    }

    //Sorting Trades
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedAdmins = computedAdmins?.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    return computedAdmins;
  }, [adminList, search, sorting]);

  const clear = () => {
    setCurrentId(0);
    setAdmin({ name: "", email: "", mobileNumber: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (currentId === 0) {
        await addNewAdmin(auth.token, admin);
        fetchAllAdmin();
        toast.success(`${admin.name} is added Successfully...`);
        clear();
      } else {
        setConfirmDialog({
          isOpen: true,
          title: "Are you sure to Update this record?",
          subTitle: "You can't undo this operation",
          onConfirm: async () => {
            setConfirmDialog({
              ...confirmDialog,
              isOpen: false,
            });
            await updateAdmin(auth.token, admin, currentId)
              .then(() => {
                fetchAllAdmin();
                toast.success(`${admin.name} is updated Successfully...`);
                clear();
                handleClose();
              })
              .catch((err) => toast.error(err.response.data.errorMessage));
          },
        });
      }
    } catch (err) {
      toast.error(err.response.data.errorMessage);
    }
  };

  // Get current rows
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = adminsData?.slice(indexOfFirstRow, indexOfLastRow);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <h2 className="mt-25 page-title">Admins</h2>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li className="breadcrumb-item active">Admins</li>
          </ol>
          <div className="col-lg-12 col-md-12 back-btn">
            <button
              className="save-btn hover-btn"
              onClick={() => {
                setCurrentId(0);
                handleShow();
                clear();
              }}
            >
              + Add New Admin
            </button>
          </div>
          <TableSearch
            onSearch={(value) => {
              setSearch(value);
            }}
            placeholder="Search by Name/ Email"
          />
          <AddNewAdminForm
            admin={admin}
            setAdmin={setAdmin}
            handleSubmit={handleSubmit}
            currentId={currentId}
            show={show}
            handleClose={handleClose}
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
          />
          <div className="row">
            <AdminsTable
              adminList={currentRows}
              setCurrentId={setCurrentId}
              setSorting={setSorting}
              handleShow={handleShow}
            />
            <Pagination
              rowsPerPage={rowsPerPage}
              totalRows={adminList?.admins?.length}
              paginate={paginate}
              setRowsPerPage={setRowsPerPage}
              url="/admins"
            />
          </div>
        </div>
      </main>
    </div>
  );
};

export default AddNewAdmin;
