import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { updateStatusLogOfMarketPlaceOrder } from "../../../actions/marketplaceAction";

const ALLOWED_STATUS_LABELS = [
  "Order Placed",
  "Sent to Vendor",
  "Ready to Ship",
  "Shipped",
  "Delivered",
  "Return Requested",
  "Returned to Hub",
  "Returned to Vendor",
];

const OrderStatusTable = (props) => {
  const { order, setOrder } = props;
  const { auth } = useSelector((state) => ({ ...state }));

  const [updatedStatusOrder, setUpdatedStatusOrder] = useState(order);
  const [currentStatus, setCurrentStatus] = useState();
  const [updateStatuslabel, setUpdateStatuslabel] = useState("");
  const [updateStatusTimeStamp, setUpdateStatusTimeStamp] = useState("");

  const handleUpdateStatusLog = async (e) => {
    // setOrder(updatedStatusOrder);
    const tempStatusObject = {
      statusLabel: updateStatuslabel,
      timeStamp: updateStatusTimeStamp,
    };

    var isStatusAlreadyAvaliable;

    const { orderStatusLog } = order;
    const statusList = [...orderStatusLog];

    if (statusList) {
      for (let i = 0; i < statusList.length; i++) {
        let singleStatusObj = statusList[i];
        // console.log("singleStatusObj ", singleStatusObj);
        if (singleStatusObj.statusLabel == tempStatusObject.statusLabel) {
          //singleStatusObj.timeStamp = tempStatusObject.timeStamp;
          isStatusAlreadyAvaliable = true;

          singleStatusObj.timeStamp = tempStatusObject.timeStamp;
        }
      }
    }

    if (statusList) {
      for (let i = 0; i < statusList.length; i++) {
        let singleStatusObj = statusList[i];

        if (singleStatusObj.statusLabel != tempStatusObject.statusLabel) {
          //statusList.push(tempStatusObject);

          isStatusAlreadyAvaliable = false;
        }
      }
    }

    if (isStatusAlreadyAvaliable) {
      console.log("if true : statusList ", statusList);
    }
    if (!isStatusAlreadyAvaliable) {
      statusList.push(tempStatusObject);
      console.log("if false : statusList ", statusList);
    }

    const finalStatusObj = {
      statusList: statusList,
    };

    console.log("finalStatusObj ==> ", finalStatusObj);
    const result = await updateStatusLogOfMarketPlaceOrder(
      auth.token,
      order?._id,
      finalStatusObj
    );
  };

  // const updateStatusByIDOfOrder = async (statusList) => {
  //   const res = await console.log(
  //     "res.data of update status function => ",
  //     res.data
  //   );

  //   return res.data;
  // };

  // const hanleNewCurrentStatusChange = (e) => {
  //   const tempNewCurrentStatus = e.target.value;
  //   // console.log("tempNewCurrentStatus ==>> ", tempNewCurrentStatus);
  //   setUpdatedStatusOrder({
  //     ...order,
  //     statusLog: {
  //       ...order.statusLog,
  //       currentStatus: tempNewCurrentStatus,
  //     },
  //   });
  // };

  // const handleTimeStampChange = (index, e) => {
  //   const newTimeStamp = e.target.value;
  //   console.log("newTimeStamp ==>> ", newTimeStamp);
  //   const parsedDate = new Date(newTimeStamp);

  //   // Format the date and time values manually
  //   const day = parsedDate.getDate();
  //   const month = parsedDate.toLocaleString("default", { month: "short" });
  //   const year = parsedDate.getFullYear();
  //   const hour = parsedDate.getHours();
  //   const minute = parsedDate.getMinutes();
  //   const amPm = hour >= 12 ? "PM" : "AM";
  //   const formattedDate = `${day} ${month} ${year}`;
  //   const formattedTime = `${hour % 12}:${minute
  //     .toString()
  //     .padStart(2, "0")} ${amPm}`;

  //   // Create the formatted string in the desired format
  //   const formattedTimeStamp = `${formattedDate} , ${formattedTime}`;

  //   // Update the timeStamp in the statusList array
  //   // const updatedStatusList = order.statusLog.statusList.map(
  //   //   (singleStatus, i) => {
  //   //     if (i === index) {
  //   //       return { ...singleStatus, timeStamp: formattedTimeStamp };
  //   //     }
  //   //     return singleStatus;
  //   //   }
  //   // );
  //   //console.log("this is updatedStatusList ==>> ", updatedStatusList);

  //   const newStatusList = [...updatedStatusOrder.statusLog.statusList]; // Create a shallow copy of the updated status list
  //   newStatusList[index].timeStamp = formattedTimeStamp; // Update the timestamp for the respective index
  //   //setUpdatedStatusList(newStatusList);

  //   // Update the statusList in the statusLog
  //   setUpdatedStatusOrder({
  //     ...order,
  //     statusLog: {
  //       ...order.statusLog,
  //       statusList: newStatusList,
  //     },
  //   });
  // };

  const handleSingleTimeStampChange = (e) => {
    const tempTimeStamp = e.target.value;
    //console.log("temmTimeStamp => ", tempTimeStamp);
    setUpdateStatusTimeStamp(tempTimeStamp);
  };

  const handleSingleStatusLabelChange = (e) => {
    const tempStatuslabel = e.target.value;
    // console.log("tempStatuslabel => ", tempStatuslabel);
    setUpdateStatuslabel(tempStatuslabel);
  };

  const findCurrentStatus = () => {
    const tempCurrentStatus = order?.orderStatusLog?.reduce((acc, cur) => {
      if (new Date(cur.timeStamp) > new Date(acc.timeStamp)) {
        return cur;
      }
      return acc;
    });
    console.log(
      "tempCurrentStatus.statusLabel ",
      tempCurrentStatus?.statusLabel
    );

    setCurrentStatus(tempCurrentStatus?.statusLabel);
  };

  // useEffect(() => {
  //   console.log(
  //     "this is statusLog from <<<<updatedStatusOrder>>>> from useEffect ==>> ",
  //     updatedStatusOrder.statusLog
  //   );
  // }, [updatedStatusOrder]);

  useEffect(() => {
    findCurrentStatus();
  }, [order]);

  return (
    <div className="border mb-5">
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div className="border">
          <div className="border p-2 text-dark">Current Status</div>
          <div className="border p-2 text-dark">Status Log</div>
        </div>
        <div className="border">
          <div className="p-2 text-dark">
            {currentStatus?.length > 0 ? currentStatus : "No Status Aavailable"}
          </div>
          {order?.orderStatusLog?.map((log, index) => (
            <div
              style={{ display: "flex", flexDirection: "row" }}
              key={log.statusLabel}
              className="border"
            >
              <div
                className="p-2 text-dark"
                style={{ minWidth: "120px", borderRight: "1px solid gray" }}
              >
                {log.statusLabel}
              </div>

              <div className="p-2">
                {log.timeStamp.length > 0 ? log.timeStamp : "-"}
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="m-2">
        <button
          type="button"
          className="btn btn-primary"
          data-bs-toggle="modal"
          data-bs-target="#statusOfOrder"
        >
          Update Status
        </button>

        <div className="modal" id="statusOfOrder">
          <div className="modal-dialog">
            <div className="modal-content">
              {/* <div className="modal-header"></div> */}
              <div className="modal-body">
                <div style={{ display: "flex" }}>
                  <label style={{ minWidth: "155px", margin: "4px" }}>
                    Choose Status
                  </label>
                  <select
                    value={updateStatuslabel}
                    onChange={(e) => handleSingleStatusLabelChange(e)}
                  >
                    {ALLOWED_STATUS_LABELS?.map((item, index) => (
                      <option
                        value={item}
                        style={{ minWidth: "150px", margin: "4px" }}
                      >
                        {item}
                      </option>
                    ))}
                  </select>

                  <input
                    defaultValue={""}
                    onChange={(e) => handleSingleTimeStampChange(e)}
                    style={{ margin: "4px" }}
                    type="datetime-local"
                  />
                </div>
                {/* <hr /> */}
                {/* <div style={{ display: "flex", flexDirection: "row" }}>
                  <label style={{ minWidth: "155px", margin: "4px" }}>
                    Current Status
                  </label>

                  <select
                    defaultValue={order?.statusLog?.currentStatus}
                    onChange={(e) => hanleNewCurrentStatusChange(e)}
                    style={{ minWidth: "155px" }}
                  >
                    {order?.orderStatusLog?.map((item, index) => (
                      <option value={item.statusLabel} key={item.statusLabel}>
                        {item.statusLabel}
                      </option>
                    ))}
                  </select>
                </div> */}
                <hr />
                <button
                  onClick={(e) => handleUpdateStatusLog(e)}
                  className="btn btn-primary mr-2"
                  data-bs-dismiss="modal"
                >
                  Update
                </button>
                <button className="btn btn-danger" data-bs-dismiss="modal">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderStatusTable;
