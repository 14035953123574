import { Formik, Form, Field, ErrorMessage } from "formik";
import { Grid, Paper } from "@material-ui/core";
import { validationSchema } from "../validationSchema/CouponValidation";
import TextError from "../../0-ResuableComponents/formComponents/Error";
import MultiSelectTrade from "../multiSelect/MultiSelectTrade";
import MultiSelectCity from "../multiSelect/MultiSelectCity";
import MultiSelectCategory from "../multiSelect/MultiSelectCategory";
import MultiSelectSubCategory from "../multiSelect/MultiSelectSubCategory";
import MultiSelectProduct from "../multiSelect/MultiSelectProduct";
import MultiSelectBrand from "../multiSelect/MultiSelectBrand";
import MultiSelectState from "../multiSelect/MultiSelectState";
import MultiSelectCluster from "../multiSelect/MultiSelectCluster";

const EditCouponForm = ({
  formValues,
  initialValues,
  onSubmit,
  stateList,
  setState,
  cityList,
  tradeList,
  setTradeId,
  categoryList,
  setCategoryId,
  subCategoryList,
  productList,
  setSubCategoryId,
  setProductId,
  brandList,
}) => {
  // console.log("stateList====>", stateList);

  return (
    <Formik
      initialValues={formValues || initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {(formik) => {
        console.log("Formik props", formik);
        // console.log("Formik values = = = =>", formik.values);
        const { setFieldValue } = formik;
        return (
          <Form>
            <Paper>
              <Grid
                container
                spacing={4}
                style={{ padding: "1rem", marginBottom: "2rem" }}
              >
                <Grid item xs={7}>
                  <div className="form-group">
                    <label htmlFor="couponCode" className="form-label">
                      Coupon Code<span className="required">&#42;</span>
                    </label>
                    <Field
                      type="text"
                      className="form-control"
                      name="couponCode"
                      placeholder="Enter Coupon Code"
                    />
                    <ErrorMessage name="couponCode" component={TextError} />
                  </div>
                </Grid>

                <Grid item xs={5}>
                  <div className="form-group">
                    <label htmlFor="couponType" className="form-label">
                      Coupon Type<span className="required">&#42;</span>
                    </label>
                    <Field
                      as="select"
                      className="form-control"
                      name="couponType"
                    >
                      <option selected>--Select Coupon Type--</option>
                      <option value="Flat Discount">Flat Discount</option>
                      <option value="Percentage Discount">
                        Percentage Discount
                      </option>
                    </Field>
                    <ErrorMessage name="couponType" component={TextError} />
                  </div>
                </Grid>

                <Grid item xs={12}>
                  <div className="form-group">
                    <label htmlFor="couponDescription" className="form-label">
                      Coupon Description<span className="required">&#42;</span>
                    </label>
                    <div className="card card-editor">
                      <div className="content-editor">
                        <Field
                          as="textarea"
                          style={{ height: "5rem" }}
                          className="text-control"
                          placeholder="Enter Coupon Description"
                          name="couponDescription"
                        />
                        <ErrorMessage
                          name="couponDescription"
                          component={TextError}
                        />
                      </div>
                    </div>
                  </div>
                </Grid>

                <Grid
                  item
                  xs={
                    formik.values.couponType === "Percentage Discount" ? 6 : 12
                  }
                >
                  <div className="form-group">
                    <label htmlFor="maxDiscount" className="form-label">
                      Max Discount offered (INR)
                      <span className="required">&#42;</span>
                    </label>
                    <Field
                      className="form-control"
                      type="number"
                      placeholder="Enter Max Discount offered"
                      name="maxDiscount"
                    />
                    <ErrorMessage name="maxDiscount" component={TextError} />
                  </div>
                </Grid>

                {formik.values.couponType === "Percentage Discount" ? (
                  <Grid item xs={6}>
                    <div className="form-group">
                      <label
                        htmlFor="discountPercentage"
                        className="form-label"
                      >
                        Discount Percentage(%)
                      </label>
                      <Field
                        className="form-control"
                        type="number"
                        placeholder="Enter Discount Percentage"
                        name="discountPercentage"
                      />
                      <ErrorMessage
                        name="discountPercentage"
                        component={TextError}
                      />
                    </div>
                  </Grid>
                ) : null}

                <Grid item xs={4}>
                  <div className="form-group">
                    <label htmlFor="validFrom" className="form-label">
                      Valid from<span className="required">&#42;</span>
                    </label>
                    <input
                      className="form-control"
                      type="date"
                      name="validFrom"
                      defaultValue={formik.values?.validFrom?.slice(0, 10)}
                      onChange={(e) => {
                        setFieldValue("validFrom", e.target.value);
                      }}
                    />
                    <span
                      className="errorMessage"
                      style={{ fontSize: "0.9rem" }}
                    >
                      {formik.errors.validFrom}
                    </span>
                  </div>
                </Grid>

                <Grid item xs={4}>
                  <div className="form-group">
                    <label htmlFor="validTill" className="form-label">
                      Valid Till<span className="required">&#42;</span>
                    </label>
                    <input
                      className="form-control"
                      type="date"
                      name="validTill"
                      defaultValue={formik.values?.validTill?.slice(0, 10)}
                      min={formik.values.validFrom}
                      onChange={(e) => {
                        setFieldValue("validTill", e.target.value);
                      }}
                    />
                    <span
                      className="errorMessage"
                      style={{ fontSize: "0.9rem" }}
                    >
                      {formik.errors.validTill}
                    </span>
                  </div>
                </Grid>

                <Grid item xs={4}>
                  <div className="form-group">
                    <label htmlFor="status" className="form-label">
                      Status<span className="required">&#42;</span>
                    </label>
                    <Field as="select" className="form-control" name="status">
                      <option selected>--Select Status--</option>
                      <option value="Active">Active</option>
                      <option value="Inactive">Inactive</option>
                    </Field>
                    <ErrorMessage name="status" component={TextError} />
                  </div>
                </Grid>

                <Grid item xs={4}>
                  <div className="form-group">
                    <MultiSelectTrade
                      setFieldValue={setFieldValue}
                      List={tradeList}
                      heading="Select Trades"
                      name="tradeIds"
                      selectedIds={formik.values.tradeIds}
                      setTradeId={setTradeId}
                    />{" "}
                    <br />
                    <span
                      className="errorMessage"
                      style={{ fontSize: "0.9rem" }}
                    >
                      {formik.errors.tradeIds}
                    </span>
                  </div>
                </Grid>

                <Grid item xs={4}>
                  <div className="form-group">
                    <MultiSelectCategory
                      setFieldValue={setFieldValue}
                      List={categoryList}
                      heading="Select Categories"
                      name="categoryIds"
                      selectedIds={formik.values.categoryIds}
                      trades={formik.values.tradeIds}
                      setCategoryId={setCategoryId}
                    />{" "}
                    <br />
                    <span
                      className="errorMessage"
                      style={{ fontSize: "0.9rem" }}
                    >
                      {formik.errors.categoryIds}
                    </span>
                  </div>
                </Grid>

                <Grid item xs={4}>
                  <div className="form-group">
                    <MultiSelectSubCategory
                      setFieldValue={setFieldValue}
                      List={subCategoryList}
                      heading="Select Sub-Categories"
                      name="subCategoryIds"
                      selectedIds={formik.values.subCategoryIds}
                      categories={formik.values.categories}
                      setSubCategoryId={setSubCategoryId}
                    />
                    <br />
                    <ErrorMessage name="subCategoryIds" component={TextError} />
                  </div>
                </Grid>

                <Grid item xs={6}>
                  <div className="form-group">
                    <MultiSelectProduct
                      setFieldValue={setFieldValue}
                      List={productList}
                      heading="Select Products"
                      name="productIds"
                      selectedIds={formik.values.productIds}
                      setProductId={setProductId}
                    />
                    <br />
                    <ErrorMessage name="productIds" component={TextError} />
                  </div>
                </Grid>

                <Grid item xs={6}>
                  <div className="form-group">
                    <MultiSelectBrand
                      setFieldValue={setFieldValue}
                      List={brandList}
                      heading="Select Brands"
                      name="brandIds"
                      selectedIds={formik.values.brandIds}
                    />
                    <br />
                    <ErrorMessage name="brandIds" component={TextError} />
                  </div>
                </Grid>

                <Grid item xs={4}>
                  <div className="form-group">
                    <MultiSelectState
                      setFieldValue={setFieldValue}
                      List={stateList}
                      heading="Select States"
                      name="states"
                      selectedIds={formik.values.states}
                      setState={setState}
                    />{" "}
                    <br />
                    <ErrorMessage name="states" component={TextError} />
                  </div>
                </Grid>

                <Grid item xs={4}>
                  <div className="form-group">
                    <MultiSelectCity
                      setFieldValue={setFieldValue}
                      List={cityList}
                      heading="Select Cities"
                      name="cities"
                      selectedIds={formik.values.cities}
                    />{" "}
                    <br />
                    <ErrorMessage name="cities" component={TextError} />
                  </div>
                </Grid>

                <Grid item xs={4}>
                  <div className="form-group">
                    <MultiSelectCluster
                      setFieldValue={setFieldValue}
                      List={cityList}
                      heading="Select Clusters"
                      name="clusters"
                      selectedIds={formik.values.clusters}
                    />{" "}
                    <br />
                    <ErrorMessage name="clusters" component={TextError} />
                  </div>
                </Grid>
              </Grid>
            </Paper>

            <Grid>
              <button
                style={{ display: "inline-block", marginBottom: "2%" }}
                className="save-btn hover-btn"
                type="submit"
                disabled={!formik.isValid || formik.isSubmitting}
              >
                {formik.isSubmitting ? "Updating..." : "Update"}
              </button>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EditCouponForm;
