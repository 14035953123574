import DeleteIcon from "@material-ui/icons/Delete";
import moment from "moment";
import TableHeader from "../../0-ResuableComponents/tableComponents/TableHeader";

const headers = [
  { name: "Variant", field: "name", sortable: true },
  { name: "Variant ID", field: "id", sortable: false },
  { name: "Price(MRP)", field: "price", sortable: false },
  { name: "Created By", field: "CreatedBy", sortable: false },
  { name: "Created At", field: "CreatedAt", sortable: false },
  { name: "Last Updated By", field: "LastUpdatedBy", sortable: false },
  { name: "Last Updated At", field: "LastUpdatedAt", sortable: false },
  { name: "Edit", field: "Edit", sortable: false },
  { name: "Delete", field: "Delete", sortable: false },
];

const ProductVariantsTable = ({
  variantList,
  setCurrentId,
  handleVariantDelete,
  setConfirmDialog,
  setSorting,
  setVariant,
  handleShow,
}) => {
  // console.log("variantList===>", variantList);
  return (
    <div className="col-lg-12 col-md-11">
      <div className="all-cate-tags">
        <div className="row justify- content-between">
          <div className="col-lg-12 col-md-12">
            <div className="card card-static-2 mb-30">
              <div className="card-title-2">
                <h4>Variants</h4>
              </div>
              <div className="card-body-table">
                <div className="table-responsive">
                  <table className="table ucp-table table-hover">
                    <TableHeader
                      headers={headers}
                      onSorting={(field, order) => setSorting({ field, order })}
                    />
                    <tbody>
                      {variantList?.map((variant) => (
                        <tr key={variant._id}>
                          <td>{variant.name}</td>
                          <td>{variant.id}</td>
                          <td> &#x20B9; {variant?.price?.mrp}</td>
                          <td>{variant.metaInfo.created?.by?.name}</td>
                          <td>
                            {moment(variant.metaInfo?.created?.at).format(
                              "lll"
                            )}
                          </td>
                          <td>{variant.metaInfo.updated?.by?.name}</td>
                          <td>
                            {moment(variant.metaInfo?.updated?.at).format(
                              "lll"
                            )}
                          </td>
                          <td className="action-btns">
                            <i
                              className="fas fa-edit"
                              data-toggle="modal"
                              data-target="#exampleModal1"
                              data-whatever="@mdo"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                console.log("Variant:", variant)
                                setCurrentId(variant._id);
                                setVariant(variant);
                                handleShow();
                              }}
                            />
                          </td>
                          <td>
                            <DeleteIcon
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setConfirmDialog({
                                  isOpen: true,
                                  title: "Are you sure to delete this record?",
                                  subTitle: "You can't undo this operation",
                                  onConfirm: () => {
                                    handleVariantDelete(variant._id);
                                  },
                                });
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductVariantsTable;
