import moment from "moment";
import { Link } from "react-router-dom";
import TableHeader from "../../0-ResuableComponents/tableComponents/TableHeader";

const headers = [
  { name: "Category", field: "name", sortable: false },
  { name: "Product", field: "email", sortable: false },
  { name: "Variant Name", field: "phone", sortable: false },
  { name: "Variant Code", field: "status", sortable: false },
  { name: "Variant Availability", field: "catalog", sortable: false },
  { name: "Updated By", field: "LastUpdatedBy", sortable: false },
  { name: "Updated At", field: "LastUpdatedAt", sortable: false },
  { name: "Price to Customer (INR)", field: "CreatedBy", sortable: false },
  { name: "Price Last Updated On", field: "CreatedAt", sortable: false },
  { name: "Price Last Updated By", field: "CreatedAt", sortable: false },
  { name: "Log of revisions", field: "Edit", sortable: false },
];

const EntrepreneurTable = ({ setSorting, entrepreneur, variantList }) => {
  // console.log("===>", variantList);
  return (
    <div className="col-lg-12 col-md-11">
      <div className="all-cate-tags">
        <div className="row justify-content-between">
          <div className="col-lg-12 col-md-12">
            <div className="card card-static-2 mb-30">
              <div className="card-body-table">
                <div className="table-responsive">
                  <table className="table ucp-table table-hover">
                    <TableHeader
                      headers={headers}
                      onSorting={(field, order) => setSorting({ field, order })}
                    />
                    <tbody>
                      {variantList?.map((variant) => (
                        <tr key={variant._id}>
                          <td>category</td>
                          <td>{variant.productId}</td>
                          <td>{variant.name}</td>
                          <td>{variant.id}</td>
                          <td>Variant Availability</td>
                          <td>{variant.metaInfo.updated?.by?.name}</td>
                          <td>
                            {moment(variant.metaInfo.updated?.at).format("lll")}
                          </td>
                          <td> &#x20B9; {variant.price.mrp}</td>
                          <td>
                            {moment(variant.metaInfo.updated?.at).format("lll")}
                          </td>
                          <td>{variant.metaInfo.updated?.by?.name}</td>
                          <td>
                            <Link
                              to={`/homies/view/${entrepreneur._id}/${variant._id}/priceLogs`}
                            >
                              Log of revisions
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EntrepreneurTable;
