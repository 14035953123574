/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import TableSearch from "../../0-ResuableComponents/tableComponents/TableSearch";
import Pagination from "../../0-ResuableComponents/pagination/Pagination";
import ConfirmDialog from "../../0-ResuableComponents/confirmDialog/ConfirmDialog";
import TradeForm from "./TradeForm";
import TradeTable from "./TradeTable";
import {
  createNewTrade,
  allTrades,
  updateTrade,
  deleteTrade,
} from "../../../actions/tradeActions";

const Trades = () => {
  const [trade, setTrade] = useState({ name: "" ,bussinessIds:[]});
  const { auth } = useSelector((state) => ({ ...state }));
  const { token } = auth;
  const { name } = trade;
  const [currentId, setCurrentId] = useState(0);
  const [tradeList, setTradeList] = useState([]);
  const [businessList, setBusinessList] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [search, setSearch] = useState("");
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // const fetchAllTrades = async () => {
  //   let res = await allTrades(token);
  //   setTradeList(res.data);
  // };
  const fetchAllTrades = async () => {
    let res = await allTrades(token);
    console.log('all trades: ', res.data);
    setTradeList(res.data);
    fetchAllBusinessIdeas();
    // const url = 'https://swatiapi.letsendorse.net/api/v1/bussinessIdeas/all-detailed';
    // axios.get(url).then((response) => {
    //   if (response.status) {
    //     setTradeList(response.data.bussinessIdeas || []);
    //     //console.log('response.data.bussinessIdeas: ', response.data);
    //   }
    // });
  };

   const fetchAllBusinessIdeas = async () => {
  
    const url = 'https://swatiapi.letsendorse.net/api/v1/bussinessIdeas/all-detailed';
    axios.get(url).then((response) => {
      if (response.status) {
        setBusinessList(response.data.bussinessIdeas || []);
        console.log('response.data.bussinessIdeas: ', response.data);
      }
    });
  };
  const singleTrade = currentId
    ? tradeList.trades.find((trade) => trade._id === currentId)
    : null;

  useEffect(() => {
   
    fetchAllTrades();
    if (singleTrade) setTrade(singleTrade);
  }, [currentId]);
useEffect(() => {
  fetchAllBusinessIdeas();
}, [])
  const tradesData = useMemo(() => {
    let computedTrades = tradeList?.trades;
   // console.log('computedTrades: ', computedTrades);

    if (search) {
      computedTrades = computedTrades?.filter((trade) =>
        trade.name.toLowerCase().includes(search.toLowerCase())
      );
    }

    //Sorting Trades
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedTrades = computedTrades?.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    return computedTrades;
  }, [tradeList, search, sorting]);

  const clear = () => {
    setCurrentId(0);
    setTrade({ name: "" });
    setBusinessList([]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (currentId === 0) {
       
        await createNewTrade(token, trade);
        fetchAllTrades();
        toast.success(`New Trade ${trade.name} is added Successfully...`);
        clear();
        handleClose();
      } else {
        setConfirmDialog({
          isOpen: true,
          title: "Are you sure to Update this record?",
          subTitle: "You can't undo this operation",
          onConfirm: async () => {
            setConfirmDialog({
              ...confirmDialog,
              isOpen: false,
            });
            await updateTrade(token, trade, currentId)
              .then(() => {
                fetchAllTrades();
                toast.success(`Trade ${trade.name} is updated Successfully...`);
                clear();
                handleClose();
              })
              .catch((err) => toast.error(err.response.data.errorMessage));
          },
        });
      }
    } catch (err) {
      toast.error(err.response.data.errorMessage);
      // console.log("=======>", err.response.data.errorMessage);
    }
  };

  const handleTradeDelete = async (tradeId) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    deleteTrade(token, tradeId)
      .then((res) => {
        toast.success("Trade Deleted successfully...");
        fetchAllTrades();
      })
      .catch((err) => toast.error(err.response.data.errorMessage));
  };

  // Get current rows
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = tradesData?.slice(indexOfFirstRow, indexOfLastRow);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div id="layoutSidenav_content">
      <div className="container-fluid">
        <h2 className="mt-25 page-title">Trades</h2>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Dashboard</Link>
          </li>
          <li className="breadcrumb-item active">Trades</li>
        </ol>
        <div className="col-lg-12 col-md-12 back-btn">
          <button
            className="save-btn hover-btn"
            onClick={() => {
              setCurrentId(0);
              clear();
              handleShow();
            }}
          >
            + Add New Trade
          </button>
        </div>
        <TableSearch
          onSearch={(value) => {
            setSearch(value);
          }}
          placeholder="Search by Trade Name"
        />
        <TradeForm
          name={name}
          trade={trade}
          setTrade={setTrade}
          handleSubmit={handleSubmit}
          currentId={currentId}
          show={show}
          businessList={businessList}
          handleClose={handleClose}
        />
        <div className="row">
          <TradeTable
            tradeList={currentRows}
            // tradeList={currentRows}
            setCurrentId={setCurrentId}
            handleTradeDelete={handleTradeDelete}
            setConfirmDialog={setConfirmDialog}
            setSorting={setSorting}
            handleShow={handleShow}
          />
        </div>
        <Pagination
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          totalRows={tradeList?.trades?.length}
          paginate={paginate}
          url="/catalog/trades"
        />
      </div>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </div>
  );
};

export default Trades;