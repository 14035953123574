import DeleteIcon from "@material-ui/icons/Delete";
import { Link } from "react-router-dom";
import moment from "moment";
import TableHeader from "../../0-ResuableComponents/tableComponents/TableHeader";

const headers = [
  { name: "Name", field: "name", sortable: true },
  { name: "Service Category", field: "service_category_id", sortable: false },
  { name: "Variants", field: "variants", sortable: false },
  { name: "Frequency", field: "frequency_in_days", sortable: false },
  { name: "Edit", field: "Edit", sortable: false },
  { name: "Delete", field: "Delete", sortable: false },
  // { name: "Created By", field: "created_at", sortable: false },
  { name: "Created At", field: "created_by", sortable: false },
  // { name: "Last Updated By", field: "updated_by", sortable: false },
  { name: "Last Updated At", field: "updated_at", sortable: false },
  
];

const ServicesTable = ({
  serviceList,
  setCurrentId,
  handleServiceDelete,
  setConfirmDialog,
  setSorting,
  handleShow,
}) => {
  return (
    <div className="col-lg-12 col-md-11">
      <div className="all-cate-tags">
        <div className="row justify- content-between">
          <div className="col-lg-12 col-md-12">
            <div className="card card-static-2 mb-30">
              <div className="card-title-2">
                <h4>All Services</h4>
              </div>
              <div className="card-body-table">
                <div className="table-responsive">
                  <table className="table ucp-table table-hover">
                    <TableHeader
                      headers={headers}
                      onSorting={(field, order) => setSorting({ field, order })}
                    />
                    <tbody>
                      {serviceList?.map((service) => (
                        <tr key={service._id}>
                          <td>{service.name}</td>
                          <td>{service?.service_category_info?.name}</td>
                          <td>
                            <Link
                              to={`/services/variants/${service._id}`}
                            >
                              {service?.variants?.filter(
                                (variant) => !variant.isDeleted
                              ).length > 1
                                ? `${
                                    service?.variants?.filter(
                                      (variant) => !variant.isDeleted
                                    ).length
                                  } Variants`
                                : `${
                                    service?.variants?.filter(
                                      (variant) => !variant.isDeleted
                                    ).length
                                  } Variant`}
                            </Link>
                          </td>
                          <td>{service.frequency_in_days}</td>

                          <td className="action-btns">
                            <i
                              className="fas fa-edit"
                              data-toggle="modal"
                              data-target="#exampleModal"
                              data-whatever="@mdo"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setCurrentId(service._id);
                                handleShow();
                              }}
                            />
                          </td>
                          <td>
                            <DeleteIcon
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setConfirmDialog({
                                  isOpen: true,
                                  title: "Are you sure to delete this record?",
                                  subTitle: "You can't undo this operation",
                                  onConfirm: () => {
                                    handleServiceDelete(service._id);
                                  },
                                });
                              }}
                            />
                          </td>
                          
                          {/* <td>{service?.created_by}</td> */}
                          <td>
                            {moment(service?.created_at).format("lll")}
                          </td>
                          {/* <td>{service?.updated_by}</td> */}
                          <td>
                            {moment(service?.updated_at).format("lll")}
                          </td>
                          
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesTable;
