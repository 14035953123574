import "../login.css";
import { Link } from "react-router-dom";

const LoginForm = ({
  handleSubmit,
  email,
  setEmail,
  password,
  setPassword,
  ReCAPTCHA,
  recaptchaToken,
  setRecaptchaToken,
}) => {
  return (
    <div id="layoutSidenav_content">
      <div role="document">
        <div className="modal-content">
          <div className="modal-body">
            <div className="login-modal">
              <div className="row">
                <div className="col-lg-10 pad-right-0">
                  <div className="login-modal-left"></div>
                </div>
                <div className="col-lg-7 pad-left-0">
                  <form onSubmit={handleSubmit}>
                    <div className="login-modal-right">
                      <div className="tab-content">
                        <div
                          className="tab-pane active"
                          id="login"
                          role="tabpanel"
                        >
                          <h5 className="heading-design-h5">
                            Login to your account
                          </h5>
                          <fieldset className="form-group">
                            <label>Enter Email</label>
                            <input
                              type="email"
                              className="form-control"
                              name="email"
                              required
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </fieldset>

                          <fieldset className="form-group">
                            <label>Enter Password</label>
                            <input
                              type="password"
                              className="form-control"
                              name="password"
                              required
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                          </fieldset>

                          <fieldset
                            className="form-group"
                            style={{ marginLeft: "17%" }}
                          >
                            <ReCAPTCHA
                              // ref={reCaptcha}
                              sitekey="6Lc67PcaAAAAAAO40E2wGKPxB5HDcGt4dJBW8JxR"
                              onChange={(token) => setRecaptchaToken(token)}
                              onExpired={(e) => setRecaptchaToken("")}
                            />
                          </fieldset>

                          <fieldset className="form-group">
                            <button
                              type="submit"
                              className="btn btn-lg btn-secondary btn-block"
                              disabled={!email || !password || !recaptchaToken}
                            >
                              Enter to your account
                            </button>
                          </fieldset>
                        </div>
                      </div>

                      <div
                        className="clearfix"
                        style={{ paddingTop: "1rem" }}
                      />
                      <div className="text-center login-footer-tab">
                        <ul className="nav nav-tabs" role="tablist">
                          <li className="nav-item">
                            <Link
                              className="nav-link"
                              data-toggle="tab"
                              to="/request-forgot-password"
                              role="tab"
                            >
                              <i className="mdi mdi-pencil" /> FORGOT PASSWORD
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
