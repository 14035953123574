import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "@material-ui/core";
import { getSingleServiceEntrepreneurs ,getSingleEntitiesCustomerAndOrders} from "../../actions/serviceEntrepreneursActions";
import CostumerDetailsTable from "./CustomerDetailsTable";
import CustomerListTable from "./CustomerListTable";
import OrderListTable from "./OrderListTable";

const ConsumerProfile = ({ match }) => {
  const { auth } = useSelector((state) => ({ ...state }));
  const [showEntrepreneurDetails, setShowEntrepreneurDetails] = useState(true);
  const [showCustomerList, setShowCustomerList] = useState(false);
  const [showOrderList, setShowOrderList] = useState(false);
  const [orderList,setOrderList] = useState([]);
  const [customerList,setCustomerList] = useState([]);

  // const [orderexist, setOrderExist] = useState(true);
  const [order, setOrder] = useState([]);
  const [bag, setbag] = useState([]);

  const [consumer, setConsumer] = useState({});
  const entrepreneurId = match.params.entrepreneurId;

  const fetchConsumerData = async () => {
    let res = await getSingleServiceEntrepreneurs(auth.token, entrepreneurId);
    const consumer = res.data;
    setConsumer(consumer);

  };
  const fetchCustomerAndOrder = async ()=>{
    let res = await getSingleEntitiesCustomerAndOrders(auth.token, entrepreneurId);
    const consumer = res.data;
    setCustomerList(consumer.customers);
    setOrderList(consumer.orders);

  }
  useEffect(() => {
    fetchConsumerData();
    fetchCustomerAndOrder();

    
  }, []);

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <Link to="/services-entrepreneurs">
            <Button variant="contained">
              <i className="fas fa-arrow-left" /> Back
            </Button>
          </Link>
          <div className="col-lg-5 col-md-9 col-lg-6">
            <h2 className="mt-30 page-title">{consumer?.name} </h2>
          </div>
          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li className="breadcrumb-item active">Entrepreneur Profile</li>
          </ol>
          <div className="row">
            <div className="buttons col-md">
              <button
                variant="contained"
                className={showEntrepreneurDetails && "selected"}
                onClick={() => {
                  setShowEntrepreneurDetails(true);
                  setShowCustomerList(false);
                  setShowOrderList(false);
                }}
              >
                Salon Details
              </button>
              <button
                className={showCustomerList && "selected"}
                onClick={() => {
                  setShowEntrepreneurDetails(false);
                  setShowCustomerList(true);
                  setShowOrderList(false);
                }}
              >
                Customer List
              </button>
              <button
                className={showOrderList && "selected"}
                onClick={() => {
                  setShowEntrepreneurDetails(false);
                  setShowCustomerList(false);
                  setShowOrderList(true);
                }}
              >
                Order List
              </button>

            </div>

            { showEntrepreneurDetails && (
             <CostumerDetailsTable consumer={consumer} />
            )}
            { showCustomerList && <CustomerListTable customerList={customerList} />}
            { showOrderList && <OrderListTable  orderList={orderList}  />}
           
          </div>
        </div>
      </main>
    </div>
  );
};

export default ConsumerProfile;
