/* eslint-disable react/jsx-no-target-blank */
import React from "react";

const EntrepreneurKycTable = ({ entrepreneur }) => {
  // console.log("entrepreneur===>", entrepreneur);

  return (
    <div className="col-lg-12 col-md-12" style={{ userSelect: "auto" }}>
      <div className="card card-static-2 mt-30 mb-30">
        <div className="card-title-2">
          <h4>KYC</h4>
        </div>
        <div className="card-body-table">
          <div className="table-responsive">
            <table className="table ucp-table table-hover">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Image</th>
                  <th>ID Number</th>
                  <th>View Image</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>PAN</td>
                  <td>
                    <div className="cate-img-5">
                      {entrepreneur?.kyc?.pan?.imageUrl ? (
                        <img
                          src={`${entrepreneur?.kyc?.pan?.imageUrl}`}
                          alt="PAN"
                        />
                      ) : (
                        "-"
                      )}
                    </div>
                  </td>
                  <td>
                    {entrepreneur?.kyc?.pan?.number
                      ? entrepreneur?.kyc?.pan?.number
                      : "-"}
                  </td>
                  <td className="action-btns">
                    {entrepreneur?.kyc?.pan?.imageUrl ? (
                      <a
                        href={`${entrepreneur?.kyc?.pan?.imageUrl}`}
                        title="View"
                        target="_blank"
                        className="view-shop-btn"
                      >
                        <i className="fas fa-eye"></i>
                      </a>
                    ) : (
                      "-"
                    )}
                  </td>
                </tr>
                <tr>
                  <td>AADHAR</td>
                  <td>
                    <div className="cate-img-5">
                      {entrepreneur?.kyc?.aadhar?.imageUrl ? (
                        <img
                          src={`${entrepreneur?.kyc?.aadhar?.imageUrl}`}
                          alt="AADHAR"
                        />
                      ) : (
                        "-"
                      )}
                    </div>
                  </td>
                  <td>
                    {entrepreneur?.kyc?.aadhar?.number
                      ? entrepreneur?.kyc?.aadhar?.number
                      : "-"}
                  </td>
                  <td className="action-btns">
                    {entrepreneur?.kyc?.aadhar?.imageUrl ? (
                      <a
                        href={`${entrepreneur?.kyc?.aadhar?.imageUrl}`}
                        className="view-shop-btn"
                        title="View"
                        target="_blank"
                        download
                      >
                        <i className="fas fa-eye"></i>
                      </a>
                    ) : (
                      "-"
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EntrepreneurKycTable;
