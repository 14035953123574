import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 250,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MultipleSelect({
  tradeIds,
  tradeList,
  setFieldValue,
  name,
}) {
  const classes = useStyles();

  return (
    <div style={{ display: "inline-block", marginTop: "4%" }}>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-mutiple-checkbox-label">
          Select Trades<span className="required">&#42;</span>
        </InputLabel>
        <Select
          labelId="demo-mutiple-checkbox-label"
          id="demo-mutiple-checkbox"
          multiple
          value={tradeIds}
          onChange={(e) => {
            setFieldValue(name, e.target.value);
          }}
          input={<Input />}
          renderValue={(selected) =>
            tradeList?.trades
              ?.filter((trade) => selected.includes(trade.id))
              .map((trade) => trade.name)
              .join(", ")
          }
          MenuProps={MenuProps}
        >
          {tradeList?.trades?.map((trade) => (
            <MenuItem key={trade.name} value={trade.id}>
              <Checkbox checked={tradeIds.indexOf(trade.id) > -1} />
              <ListItemText primary={trade.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
