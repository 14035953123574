import { useState, useEffect, useMemo } from "react";
import { allOrders, dumpdata, States, Cities } from "../../actions/orderAction";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import TableSearch from "../0-ResuableComponents/tableComponents/TableSearch";
import Pagination from "../0-ResuableComponents/pagination/Pagination";
import OrderTable from "./OrdersTable";
import TuneIcon from "@material-ui/icons/Tune";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import ClearAllIcon from "@material-ui/icons/ClearAll";

const useStyles = makeStyles((theme) => ({
  textField: {
    padding: 5,
    marginTop: 5,
    backgroundColor: "white",
  },
}));

const Orders = () => {
  const classes = useStyles();

  const [showFilter, setShowFilter] = useState(false);
  const [orders, setOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const { auth } = useSelector((state) => ({ ...state }));
  const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(200);
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [states, setStates] = useState([]);
  const [state, setState] = useState("allstate");

  const [showCity, setShowCity] = useState(false);
  const [cities, setCities] = useState([]);
  const [city, setCity] = useState("allcities");

  const [orderStatus, setOrderStatus] = useState("allorders");

  const [fromDate, setFromDate] = useState("dd/mm/yyyy");
  const [toDate, setToDate] = useState("dd/mm/yyyy");

  const fetchStates = async () => {
    const res = await States(auth.token);
    setStates(res.data.states);
    console.log(res);
  };

  const fetchCities = async () => {
    const res = await Cities(auth.token, state);
    setCities(res.data.cities);
  };

  const filterbyDate = () => {};

  const ordersData = useMemo(() => {
    let computedOrders = orders;

    computedOrders = [...computedOrders, ...dumpdata];
    console.log(computedOrders);
    if (search) {
      computedOrders = computedOrders?.filter((order) =>
        order?._id?.toLowerCase().includes(search.toLowerCase())
      );
    }
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedOrders = computedOrders?.sort(
        (a, b) => reversed * a[sorting.field]?.localeCompare(b[sorting.field])
      );
    }

    if (state !== "allstate") {
      computedOrders = computedOrders?.filter(
        (order) => order?.deliveryAddress?.state === state
      );
    }

    if (city !== "allcities") {
      computedOrders = computedOrders?.filter(
        (order) => order?.deliveryAddress?.city === city
      );
    }

    if (orderStatus !== "allorders") {
      computedOrders = computedOrders?.filter(
        (order) => order?.status === orderStatus
      );
    }
    if (toDate !== "dd/mm/yyyy" && fromDate !== "dd/mm/yyyy") {
      console.log(toDate);
      const a = new Date(toDate);
      const b = new Date(fromDate);

      // computedOrders.filter((order) => {
      //   var orderDate = Date(order?.createdAt);
      //   return dates.inRange(orderDate, b, a);
      // });
    }
    computedOrders.sort(function (a, b) {
      var dateA = new Date(a.createdAt),
        dateB = new Date(b.createdAt);
      return dateA - dateB;
    });
    return computedOrders;
  }, [orders, search, sorting, state, city, orderStatus, toDate, fromDate]);

  const filterByState = (e) => {
    const selectedState = e.target.value;
    if (selectedState !== "allstate") {
      setShowCity(true);
    } else {
      setShowCity(false);
    }
    setCity("allcities");
    setState(selectedState);
  };

  const filterByCity = (e) => {
    const selectedCity = e.target.value;
    setCity(selectedCity);
  };

  const filterbyOrderStatus = (e) => {
    const selectedOrderStatus = e.target.value;
    setOrderStatus(selectedOrderStatus);
  };
  const fetchAllOrders = async () => {
    const res = await allOrders(auth.token);
    console.log("orderssssss=======--------?>?>", res);
    console.log("orders ===>", res.data.ordersSummary);
    setOrders(res.data.ordersSummary);
  };

  const resetFilters = () => {
    console.log("clear");
    setState("allstate");
    setShowCity(false);
    setCity("allcities");
    setOrderStatus("allorders");
    setToDate("dd/mm/yyyy");
    setFromDate("dd/mm/yyyy");
  };
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    fetchAllOrders();
    fetchStates();

    if (showCity) {
      fetchCities();
    }
  }, [currentPage, state]);

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = ordersData?.slice(indexOfFirstRow, indexOfLastRow);

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <h2 className="mt-25 page-title">Orders</h2>
          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li className="breadcrumb-item active">Orders</li>
          </ol>
          {/* search  */}
          <div className="d-flex justify-content-end position-relative">
            <h4 className="filter-head" onClick={() => setShowFilter(true)}>
              Filters <TuneIcon />
            </h4>
            <div
              className={`${
                showFilter
                  ? "row filter-div show-filter"
                  : "row filter-div hide-filter"
              }`}
            >
              <h4
                className="filter-head"
                style={{ width: "100%" }}
                onClick={() => setShowFilter(false)}
              >
                Filters <CloseIcon />
              </h4>
              <select
                id="state"
                className="form-control"
                onChange={filterByState}
                value={state}
              >
                <option selected value="allstate">
                  All States
                </option>
                {states?.map((state, index) => {
                  return (
                    <option key={index} value={state}>
                      {state}
                    </option>
                  );
                })}
              </select>
              {showCity && (
                <select
                  id="city"
                  className="form-control"
                  onChange={filterByCity}
                  value={city}
                >
                  <option selected value="allcities">
                    All Cities
                  </option>
                  {cities?.map((city, index) => {
                    return (
                      <option key={index} value={city?.district}>
                        {city?.district}
                      </option>
                    );
                  })}
                </select>
              )}
              <select name="Cluster" id="cluster" className="form-control">
                <option selected>All Clusters</option>
                <option value="cluster1">Cluster1</option>
                <option value="cluster2">Cluster2</option>
                <option value="cluster3">Cluster3</option>
                <option value="cluster4">Cluster4</option>
              </select>
              <select
                id="status"
                className="form-control"
                onChange={filterbyOrderStatus}
                value={orderStatus}
              >
                <option selected value="allorders">
                  Order Status
                </option>
                <option value="Order Placed">Pending</option>
                <option value="Delivered">Delivered</option>
              </select>

              <label htmlFor="fromDate" className="filter-label">
                From
              </label>
              <TextField
                id="fromdDate"
                type="date"
                className={classes.textField}
                value={fromDate}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => setFromDate(e.target.value)}
              />
              <label htmlFor="toDate" className="filter-label">
                To
              </label>

              <TextField
                id="toDate"
                type="date"
                value={toDate}
                className={classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => setToDate(e.target.value)}
              />

              <button
                className="btn btn-outline-danger"
                onClick={() => resetFilters()}
              >
                Reset <ClearAllIcon />
              </button>
            </div>
          </div>
          <TableSearch
            onSearch={(value) => {
              setSearch(value);
            }}
            placeholder="Search by Id"
          />

          <div className="row">
            <OrderTable orders={currentRows} setSorting={setSorting} />
          </div>
        </div>

        {/* pagination */}
        <Pagination
          rowsPerPage={rowsPerPage}
          totalRows={orders?.length}
          paginate={paginate}
          setRowsPerPage={setRowsPerPage}
          url="/orders"
        />
      </main>
    </div>
  );
};

export default Orders;
