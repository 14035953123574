import "../login.css";

const ResetPasswordForm = ({
  password,
  cf_password,
  handleChangeInput,
  handleSubmit,
}) => {
  return (
    <div id="layoutSidenav_content">
      <div>
        <div role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="login-modal">
                <div className="row">
                  <div className="col-lg-10 pad-right-0">
                    <div className="login-modal-left"></div>
                  </div>
                  <div className="col-lg-7 pad-left-0">
                    <form onSubmit={handleSubmit}>
                      <div className="login-modal-right">
                        <div className="tab-content">
                          <div
                            className="tab-pane active"
                            id="login"
                            role="tabpanel"
                          >
                            <h5 className="heading-design-h5">
                              Reset your Password
                            </h5>
                            <fieldset className="form-group">
                              <label>Enter Password</label>
                              <input
                                type="password"
                                className="form-control"
                                name="password"
                                required
                                value={password}
                                onChange={handleChangeInput}
                              />
                            </fieldset>

                            <fieldset className="form-group">
                              <label>Confirm Password</label>
                              <input
                                type="password"
                                className="form-control"
                                name="cf_password"
                                required
                                value={cf_password}
                                onChange={handleChangeInput}
                              />
                            </fieldset>

                            <fieldset className="form-group">
                              <button
                                type="submit"
                                className="btn btn-lg btn-secondary btn-block"
                                disabled={!password || !cf_password}
                              >
                                Reset Password
                              </button>
                            </fieldset>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordForm;
