import {
  BASE_URL_LOCATION,
  BASE_URL_MARKET_PLACE_ORDERS,
  BASE_URL,
} from "./api";
import axios from "axios";
//appfactorydevapi.letsendorse.com/order-service/order/admin?startDate=06/06/2020&endDate=06/08/2021

export const allMarketPlaceOrders = async (token) =>
  await axios.get(`${BASE_URL_MARKET_PLACE_ORDERS}/orders`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const getSingleMarketPlaceOrderById = async (token, id) =>
  await axios.get(
    `${BASE_URL_MARKET_PLACE_ORDERS}/orders/details/admin/${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

export const updateStatusLogOfMarketPlaceOrder = async (token, id, data) =>
  await axios.put(
    `${BASE_URL_MARKET_PLACE_ORDERS}/order-status-log/${id}`,
    data,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
