/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import TableSearch from "../../0-ResuableComponents/tableComponents/TableSearch";
import Pagination from "../../0-ResuableComponents/pagination/Pagination";
import ConfirmDialog from "../../0-ResuableComponents/confirmDialog/ConfirmDialog";
import CategoryForm from "./CategoryForm";
import CategoryTable from "./CategoryTable";
import axios from "axios";
import { allTrades } from "../../../actions/tradeActions";
import {
  createNewCategory,
  updateCategory,
  allCategories,
  deleteCategory,
} from "../../../actions/categoryActions";

const CreateCategory = () => {
  const [category, setCategory] = useState({
    name: "",
    tradeIds: [],
    photoUrl: "",
  });
  const { auth } = useSelector((state) => ({ ...state }));
  const { token } = auth;
  const [categoryList, setCategoryList] = useState([]);
  const [tradeList, setTradeList] = useState([]);
  const [currentId, setCurrentId] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [search, setSearch] = useState("");
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const singleCategory = currentId
    ? categoryList?.categories.find((category) => category._id === currentId)
    : null;

  const fetchAllTrades = async () => {
     let res = await allTrades(token);
    // console.log('Business ideas: ', res.data);
     setTradeList(res.data);
     console.log('res.data: ', res.data);
    // const url = 'https://swatiapi.letsendorse.net/api/v1/bussinessIdeas/all-detailed';
    // axios.get(url).then((response) => {
    //   if (response.status) {
    //     setTradeList(response.data.bussinessIdeas || []);
    //     //console.log('response.data.bussinessIdeas: ', response.data);
    //   }
    // });
  };

  const fetchAllCategories = async () => {
    let res = await allCategories(token);
    setCategoryList(res.data);
  };

  useEffect(() => {
    fetchAllCategories();
    fetchAllTrades();
    if (singleCategory) setCategory(singleCategory);
  }, [currentId]);

  const categoriesData = useMemo(() => {
    let computedCategories = categoryList?.categories;

    if (search) {
      computedCategories = computedCategories?.filter((category) =>
        category.name.toLowerCase().includes(search.toLowerCase())
      );
    }

    //Sorting categories
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedCategories = computedCategories?.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    return computedCategories;
  }, [categoryList, search, sorting]);

  const clear = () => {
    setCurrentId(0);
    setCategory({ name: "", tradeIds: [], photoUrl: "" });
  };

  const { name, tradeIds, photoUrl } = category;

  const handleSubmit = async (e) => {
    e.preventDefault();
    // var arr = bussinessIds;
    let categoryData = new FormData();
    categoryData.append("name", name);
    categoryData.append("tradeIds", JSON.stringify(tradeIds));
    // for (var i = 0; i < arr.length; i++) {
    //   categoryData.append("bussinessIds", arr[i]);
    // }
    photoUrl && categoryData.append("photoFile", photoUrl);

    try {
      if (currentId === 0) {
        await createNewCategory(token, categoryData);
        fetchAllCategories();
        toast.success(`New Category ${category.name} is added Successfully...`);
        clear();
        handleClose();
      } else {
        setConfirmDialog({
          isOpen: true,
          title: "Are you sure to Update this record?",
          subTitle: "You can't undo this operation",
          onConfirm: async () => {
            setConfirmDialog({
              ...confirmDialog,
              isOpen: false,
            });
            await updateCategory(token, categoryData, currentId)
              .then(() => {
                fetchAllCategories();
                toast.success(
                  `Category ${category.name} is updated Successfully...`
                );
                clear();
                handleClose();
              })
              .catch((err) => toast.error(err.response.data.errorMessage));
          },
        });
      }
    } catch (err) {
      toast.error(err.response.data.errorMessage);
    }
  };

  const handleCategoryDelete = async (categoryId) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    deleteCategory(token, categoryId)
      .then((res) => {
        toast.success("Category Deleted successfully...");
        fetchAllCategories();
      })
      .catch((err) => toast.error(err.response.data.errorMessage));
  };

  const handleImageChange = (e) => {
    setCategory({ ...category, photoUrl: e.target.files[0] });
  };

  // Get current rows
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = categoriesData?.slice(indexOfFirstRow, indexOfLastRow);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div id="layoutSidenav_content">
      <div className="container-fluid">
        <h2 className="mt-25 page-title">Categories</h2>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Dashboard</Link>
          </li>
          <li className="breadcrumb-item active">Categories</li>
        </ol>
        <div className="col-lg-12 col-md-12 back-btn">
          <button
            className="save-btn hover-btn"
            onClick={() => {
              setCurrentId(0);
              clear();
              handleShow();
            }}
          >
            + Add New Category
          </button>
        </div>
        <TableSearch
          onSearch={(value) => {
            setSearch(value);
          }}
          placeholder="Search by Category Name"
        />
        <CategoryForm
          category={category}
          setCategory={setCategory}
          handleSubmit={handleSubmit}
          tradeList={tradeList}
          currentId={currentId}
          handleImageChange={handleImageChange}
          show={show}
          handleClose={handleClose}
        />
        <div className="row">
          <CategoryTable
            categoryList={currentRows}
            setCurrentId={setCurrentId}
            handleCategoryDelete={handleCategoryDelete}
            setConfirmDialog={setConfirmDialog}
            setSorting={setSorting}
            handleShow={handleShow}
          />
          <Pagination
            rowsPerPage={rowsPerPage}
            totalRows={categoryList?.categories?.length}
            paginate={paginate}
            setRowsPerPage={setRowsPerPage}
            url="/catalog/categories"
          />
        </div>
      </div>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </div>
  );
};

export default CreateCategory;
