/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import EntrepreneurTable from "./EntrepreneurTable";
import Pagination from "../../0-ResuableComponents/pagination/Pagination";
import { singleEntrepreneur } from "../../../actions/entrepreneurActions";
import TableSearch from "../../0-ResuableComponents/tableComponents/TableSearch";
import { allVariants } from "../../../actions/variantActions";

const ViewEntrepreneur = ({ match }) => {
  const { auth } = useSelector((state) => ({ ...state }));
  const [entrepreneur, setEntrepreneur] = useState("");
  const [variantList, setVariantList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(1000);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const fetchEntrepreneur = async () => {
    let res = await singleEntrepreneur(auth.token, match.params.entrepreneurId);
    setEntrepreneur(res.data.entrepreneur);
  };

  const fetchAllVariants = async () => {
    let res = await allVariants(auth.token);
    setVariantList(res.data);
  };

  useEffect(() => {
    fetchEntrepreneur();
    fetchAllVariants();
  }, []);

  const variantsData = useMemo(() => {
    let computedVariants = variantList?.variants;

    if (search) {
      computedVariants = computedVariants?.filter((variant) =>
        variant?.name?.toLowerCase().includes(search.toLowerCase())
      );
    }

    //Sorting variants
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedVariants = computedVariants?.sort(
        (a, b) => reversed * a[sorting.field]?.localeCompare(b[sorting.field])
      );
    }

    return computedVariants;
  }, [variantList, search, sorting]);

  // Get current rows
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = variantsData?.slice(indexOfFirstRow, indexOfLastRow);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <h2 className="mt-25 page-title">{entrepreneur?.name} &#62;</h2>
          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/homies">Entrepreneurs</Link>
            </li>
            <li className="breadcrumb-item active">Catalog</li>
          </ol>

          <TableSearch
            onSearch={(value) => {
              setSearch(value);
            }}
            placeholder="Search by Variant Name"
          />
          <div className="row">
            <EntrepreneurTable
              variantList={currentRows}
              setSorting={setSorting}
              entrepreneur={entrepreneur}
            />
            <Pagination
              rowsPerPage={rowsPerPage}
              totalRows={variantList?.variants?.length}
              paginate={paginate}
              setRowsPerPage={setRowsPerPage}
              url={`/homies/view/${match.params.entrepreneurId}/catalog`}
            />
          </div>
        </div>
      </main>
    </div>
  );
};

export default ViewEntrepreneur;
