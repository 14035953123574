/* eslint-disable no-useless-escape */
import * as Yup from "yup";

export const validationSchema = Yup.object({
  name: Yup.string().required("Required"),
  state: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
  phone: Yup.string()
    .required("Required")
    .matches(
      /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
      "Invalid Phone Number"
    ),
  whatsappNumber: Yup.string()
    .required("Required")
    .matches(
      /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
      "Invalid Phone Number"
    ),
  alternateNumber: Yup.string()
    .required("Required")
    .matches(
      /^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
      "Must be only digits"
    ),
  completeAddress: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email format").required("Required"),
  pincode: Yup.string()
    .required("Required")
    .matches(/^[1-9][0-9]{5}$/, "Invalid PIN Code"),
  // photoUrl: Yup.required("Required"),
  status: Yup.string().required("Required"),
  activatedFrom: Yup.date().required("Required"),
  kyc: Yup.object().shape({
    aadhar: Yup.object().shape({
      number: Yup.string()
        .required("Required")
        .matches(/^(\d{12}|\d{16})$/, "Invalid Aadhar Number"),
    }),
    pan: Yup.object().shape({
      number: Yup.string()
        .required("Required")
        .matches(
          /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
          "Invalid PAN Number"
        ),
    }),
  }),
  registration: Yup.object().shape({
    entity: Yup.string().required("Required"),
    gst: Yup.object().shape({
      status: Yup.string().required("Required"),
    }),
    entityPanNumber: Yup.string()
      .required("Required")
      .matches(/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/, "Invalid PAN Number"),
    bankDetails: Yup.object().shape({
      bankName: Yup.string().required("Required"),
      accountName: Yup.string().required("Required"),
      accountNumber: Yup.string()
        .required("Required")
        .matches(/^[0-9]+$/, "Must be only digits"),
      ifsc: Yup.string()
        .required("Required")
        .matches(/^[A-Za-z]{4}0[A-Z0-9]{6}$/, "Invalid IFSC Code"),
      accountType: Yup.string().required("Required"),
    }),
  }),
  assignment: Yup.array().of(
    Yup.object().shape({
      cluster: Yup.string().required("Required"),
      trades: Yup.array().min(1, "select at-least one trade"),
      addedOn: Yup.date().required("Required"),
    })
  ),
});
