import axios from "axios";
import { BASE_URL_ADMIN } from "./api";

// ADMIN LOG-IN
export const login = async (user) =>
  await axios.post(`${BASE_URL_ADMIN}/login`, user);

// ADMIN FORGOT-PASSWORD
export const forgotPassword = async (email) =>
  await axios.post(`${BASE_URL_ADMIN}/forgot-password`, email);

export const resetPassword = async (token, encryptedPassword) =>
  await axios.put(
    `${BASE_URL_ADMIN}/reset-password`,
    { encryptedPassword },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

// ADMIN RESET-PASSWORD
export const resetForgotPassword = async (token, encryptedPassword) =>
  await axios.post(`${BASE_URL_ADMIN}/reset-forgot-password`, {
    token,
    encryptedPassword,
  });

// ADD A NEW ADMIN
export const addNewAdmin = async (token, data) =>
  await axios.post(`${BASE_URL_ADMIN}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// GET ALL THE ADMIN
export const allAdmin = async (token) =>
  await axios.get(`${BASE_URL_ADMIN}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// UPDATE AN ADMIN
export const updateAdmin = async (token, data, adminId) => {
  await axios.put(`${BASE_URL_ADMIN}/update/${adminId}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
