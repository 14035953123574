import axios from "axios";
import { BASE_URL_USER, BASE_URL_ORDER } from "./api";

export const allUsers = async (token) =>
  await axios.get(`${BASE_URL_USER}/consumer`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const ConsumerOrder = async (token, orderId) =>
  await axios.get(`${BASE_URL_ORDER}/order/details/${orderId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  
export const consumerData = async (token, consumerId) =>
  await axios.get(`${BASE_URL_USER}/consumer/${consumerId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const datadump = [
  {
    orderId: "O-000018",
    orderDateTime: "8:00:00PM, 23-05-2021",
    orderComponents: "VIEW",
    orderValue: 500,
    orderStatus: "Delivered",
    timeToDelivery: "08",
    deliveryStatus: "Partial",
    orderValueFullfilled: 400,
    deliveryAddress: "Address1",
    assignedTo: "X",
    paymentMethod: "COD",
    paymentStatus: "Paid",
    walletSumUsed: 0,
    coinsUsed: 0,
    orderRating: 2,
    walletRefund: 0,
    walletBalance: 0,
    coinBalance: 0,
  },
  {
    orderId: "O-000019",
    orderDateTime: "9:00:00PM, 03-01-2021",
    orderComponents: "VIEW",
    orderValue: 470,
    orderStatus: "Pending",
    timeToDelivery: "+12",
    deliveryStatus: "Pending",
    orderValueFullfilled: null,
    deliveryAddress: "Address1",
    assignedTo: "X",
    paymentMethod: "COD",
    paymentStatus: "Pending",
    walletSumUsed: 0,
    coinsUsed: 0,
    orderRating: 4,
    walletRefund: 0,
    walletBalance: 0,
    coinBalance: 0,
  },
  {
    orderId: "O-000021",
    orderDateTime: "7:00:00PM, 13-02-2021",
    orderComponents: "VIEW",
    orderValue: 900,
    orderStatus: "Delivered",
    timeToDelivery: "10",
    deliveryStatus: "Partial",
    orderValueFullfilled: 875,
    deliveryAddress: "Address1",
    assignedTo: "X",
    paymentMethod: "Credit Card",
    paymentStatus: "Paid",
    walletSumUsed: 0,
    coinsUsed: 0,
    orderRating: 2,
    walletRefund: 100,
    walletBalance: 100,
    coinBalance: 0,
  },
];

export const itemdump = {
  items: [
    {
      variantId: "POOOO1",
      variantName: "PRODUCT A",
      unitPrice: 36,
      quantity: 3,
      cost: 108,
      status: "Delivered",
      totalCost: 108,
    },
    {
      variantId: "P00014",
      variantName: "PRODUCT B",
      unitPrice: 100,
      quantity: 1,
      cost: 100,
      status: "Delivered",
      totalCost: 100,
    },
    {
      variantId: "POOO18",
      variantName: "PRODUCT C",
      unitPrice: 50,
      quantity: 2,
      cost: 100,
      status: "Dropped/Cancelled",
      totalCost: 0,
    },
  ],

  bill: {
    subtotal: 208,
    discount: 0,
    tax: 10,
    deliveryCharge: 30,
    packagingCharge: 0,
    tip: 0,
    totalPayable: 248,
    totalDigitalPayment: 0,
    totalCash: 250,
    totalReceivedHomie: 2,
    totalRecievedCoins: 0,
    pendingPayment: 0,
  },
};
