import DeleteIcon from "@material-ui/icons/Delete";
import moment from "moment";
import TableHeader from "../../0-ResuableComponents/tableComponents/TableHeader";

const headers = [
  { name: "Name", field: "name", sortable: true },
  // { name: "Manufacturer", field: "company", sortable: true },
  { name: "GSTIN", field: "gst", sortable: false },
  { name: "HQ Address", field: "hqAddress", sortable: false },

  { name: "Status", field: "status", sortable: false },
  { name: "Last Updated", field: "LastUpdatedAt", sortable: false },
  // { name: "Created By", field: "CreatedBy", sortable: false },
  // { name: "Created At", field: "CreatedAt", sortable: false },
  // { name: "Updated By", field: "LastUpdatedBy", sortable: false },
  // { name: "Updated At", field: "LastUpdatedAt", sortable: false },
  { name: "Edit", field: "Edit", sortable: false },
  { name: "Delete", field: "Delete", sortable: false },
];
const handleTranformDateTime = (str) => {
  let dateString = str;
  const date = new Date(dateString);
  const options = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  const timeString = date.toLocaleDateString("en-US", options);
  const slicedTimeString = timeString.slice(9, timeString.length);
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.getFullYear();
  // console.log("this is timeString ==>> ", timeString);
  // console.log("this is slicedTimeString ==>> ", slicedTimeString);

  const getOrdinalSuffix = (day) => {
    const suffixes = ["th", "st", "nd", "rd"];
    const index = (day > 3 && day < 21) || day % 10 > 3 ? 0 : day % 10;
    return suffixes[index];
  };
  const formattedDateString = `${slicedTimeString} on ${day}${getOrdinalSuffix(
    day
  )} ${month}, ${year}`;

  return formattedDateString;
};

const BrandTable = ({
  brandList,
  setCurrentId,
  handleBrandDelete,
  setConfirmDialog,
  setSorting,
  handleShow,
}) => {
  return (
    <div className="col-lg-12 col-md-11">
      <div className="all-cate-tags">
        <div className="row justify-content-between">
          <div className="col-lg-12 col-md-12">
            <div className="card card-static-2 mb-30">
              <div className="card-title-2">
                <h4>All Brands</h4>
              </div>
              <div className="card-body-table">
                <div className="table-responsive">
                  <table className="table ucp-table table-hover">
                    <TableHeader
                      headers={headers}
                      onSorting={(field, order) => setSorting({ field, order })}
                    />
                    <tbody>
                      {brandList?.map((brand) => (
                        <tr key={brand._id}>
                          <td>{brand.name}</td>

                          <td>{brand.gst}</td>
                          <td>
                            {/* {console.log(
                              "brand.hqAddres ==>> ",
                              brand?.hqAddress
                            )} */}
                            {brand?.hqAddress && brand?.hqAddress
                              ? Object.values(brand?.hqAddress).join(", ")
                              : "-"}
                          </td>

                          <td>
                            {brand?.status && brand.status ? brand.status : "-"}
                          </td>

                          <td>
                            By {brand?.metaInfo?.updated?.by?.name}
                            {" at "}
                            {handleTranformDateTime(
                              brand?.metaInfo?.updated?.at
                            )}
                          </td>

                          {/* <td>{brand.metaInfo.created?.by?.name}</td>
                          <td>
                            {moment(brand.metaInfo.created.at).format("lll")}
                          </td>
                          <td>{brand.metaInfo.updated?.by?.name}</td>
                          <td>
                            {moment(brand.metaInfo.updated.at).format("lll")}
                          </td> */}
                          <td className="action-btns">
                            <i
                              className="fas fa-edit"
                              data-toggle="modal"
                              data-target="#exampleModal"
                              data-whatever="@mdo"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setCurrentId(brand._id);
                                handleShow();
                              }}
                            />
                          </td>
                          <td>
                            <DeleteIcon
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setConfirmDialog({
                                  isOpen: true,
                                  title: "Are you sure to delete this record?",
                                  subTitle: "You can't undo this operation",
                                  onConfirm: () => {
                                    handleBrandDelete(brand._id);
                                  },
                                });
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandTable;
