import GetAppIcon from "@material-ui/icons/GetApp";
import moment from "moment";

// import { useState } from "react";

const NewSingleOrderDetailsTable = ({ order, totalValue }) => {
  // const { _id, createdAt, status, paymentMethod, selectedSlot } = order;

  return (
    <>
      <div className="col-md-auto">
        <div className="row justify- content-between">
          <div className="col-lg-12 col-md-12">
            <div className="card card-static-2 mb-30">
              <div className="card-body-table">
                <div className="table-responsive">
                  <table className="table ucp-table table-hover d-flex ">
                    <tbody>
                      <tr>
                        <tr>
                          <th>Order ID</th>
                          <td>{order.orderId || "-"}</td>
                        </tr>
                        <tr>
                          <th>Customer ID</th>
                          <td>{order?.consumer || "-"}</td>
                        </tr>
                        <tr>
                          <th>Delivery Address</th>
                          <td style={{ maxWidth: "300px" }}>
                            {order?.deliveryAddress?.line1 +
                              " " +
                              order?.deliveryAddress?.city +
                              " " +
                              order?.deliveryAddress?.state +
                              " " +
                              order?.deliveryAddress?.pincode || "-"}
                          </td>
                        </tr>
                        <tr>
                          <th>Order Date</th>
                          <td>
                            {moment(order?.orderedDate).format("lll") || (
                              <span>"-"</span>
                            )}
                          </td>
                        </tr>
                        <tr>
                          <th>Order Amount(INR)</th>
                          <td>{order?.totalOrderValue}</td>
                        </tr>

                        <tr>
                          <th>Payment Mode</th>
                          <td>{order?.paymentMode || <span>-</span>}</td>
                        </tr>
                        <tr>
                          <th>Payment Reference</th>
                          <td>{order?.paymentReference || <span>-</span>}</td>
                        </tr>

                        <tr>
                          <th>Discount Code</th>
                          <td>
                            {order?.discount?.discountCode || <span>-</span>}
                          </td>
                        </tr>
                      </tr>
                    </tbody>
                    {/* <tr>
                          <td>ORDERED</td>
                          <td>{orderDateTime}</td>
                        </tr>
                        <tr>
                          <td>APPROVED</td>
                          <td>{orderDateTime}</td>
                        </tr>
                        <tr> */}
                    {/* <td>
                            {paymentStatus === "Paid"
                              ? "DELIVERED"
                              : "PROCESSING"}
                          </td>
                          <td>
                            {paymentStatus === "Paid" ? orderDateTime : "-"}
                          </td> */}
                    {/* </tr> */}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewSingleOrderDetailsTable;
