import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { itemdump, ConsumerOrder } from "../../actions/consumerAction";
import SingleOrderDetailsTable from "./tables/NewSingleOrderDetails";
import NewOrderItemsTable from "./tables/NewOrderItemsTable";
import { Button } from "@material-ui/core";
import StatusTable from "./tables/StatusTable";
import {
  SINGLE_DUMMY_ORDER,
  DUMMY_ORDER_DATA_Arr,
} from "../../constants/DummyData";
import { getSingleMarketPlaceOrderById } from "../../actions/marketplaceAction";

const NewOrderDetails = ({ match }) => {
  const { auth } = useSelector((state) => ({ ...state }));
  const paramOrderId = match.params.orderId;
  // console.log("paramOrderId ==>> ", paramOrderId);
  const consumerId = match.params.consumerId;
  const HardCodedId = "644b5be0952e24053032185a";

  const [order, setOrder] = useState({});

  const [bag, setBag] = useState({});

  const fetchSingleMarketPlaceOrder = async () => {
    const res = await getSingleMarketPlaceOrderById(auth.token, paramOrderId);

    setOrder(res.data.orderDetails);
  };

  useEffect(() => {
    fetchSingleMarketPlaceOrder();
  }, []);

  //console.log("adfeguere this is order from NewOrderDetails.js ==> ", order);

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <Link to={`/consumers/${consumerId}`}>
            <Button variant="contained">
              <i className="fas fa-arrow-left" />{" "}
              <span className="ml-2">Back</span>
            </Button>
          </Link>
          {/* <div className="col-lg-5 col-md-9 col-lg-6">
            <h2 className="mt-30 page-title ">
              <div className="d-inline-flex flex-row"></div>
              <span>{">"} </span> <span>{order?.orderID}</span>
            </h2>
          </div> */}
          <ol className="breadcrumb mb-3 mt-5">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li className="breadcrumb-item active">
              <Link to="/newconsumers">New Consumers</Link>
            </li>
            <li className="breadcrumb-item active">
              <Link to={`/newconsumers/${consumerId}`}>
                New Consumer Profile
              </Link>
            </li>
            <li className="breadcrumb-item active">Order</li>
          </ol>
          <h5
            style={{ textAlign: "center", fontWeight: "bold", color: "black" }}
          >
            LetsEndorse Development Private Limited
          </h5>
          <div className="row">
            <div className="row">
              <SingleOrderDetailsTable
                order={order}
                totalValue={bag?.totalValue}
              />
            </div>
            <div style={{ marginLeft: "20px" }}>
              <StatusTable order={order} setOrder={setOrder} />
            </div>
          </div>

          <div className="row">
            <NewOrderItemsTable order={order} setOrder={setOrder} />
          </div>
        </div>
      </main>
    </div>
  );
};

export default NewOrderDetails;
