import { Link } from "react-router-dom";

const Pagination = ({
  rowsPerPage,
  setRowsPerPage,
  totalRows,
  paginate,
  url,
}) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalRows / rowsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <>
      <nav style={{ marginLeft: "1.4%" }}>
        <ul className="pagination">
          <li className="page-item">
            <select
              name="pageSize"
              className="page-link"
              required
              value={rowsPerPage}
              onChange={(e) => setRowsPerPage(e.target.value)}
            >
              <option disabled>Select page size</option>
              <option value={5}>5</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value={200}>200</option>
              <option value={500}>500</option>
              <option value={1000}>1000</option>
            </select>
          </li>
          <>
            {pageNumbers.map((number) => (
              <li key={number} className="page-item">
                <Link
                  onClick={() => paginate(number)}
                  to={`${url}`}
                  className="page-link"
                >
                  {number}
                </Link>
              </li>
            ))}
          </>
        </ul>
      </nav>
    </>
  );
};

export default Pagination;
