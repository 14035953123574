import axios from "axios";
import { BASE_URL } from "./api";

// CREATE A NEW CATEGORY
export const createNewCategory = async (token, data) =>
  await axios.post(`${BASE_URL}/categories`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// GET ALL THE CATEGORIES
export const allCategories = async (token) =>
  await axios.get(`${BASE_URL}/categories`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// UPDATE A CATEGORY
export const updateCategory = async (token, data, categoryId) =>
  await axios.put(`${BASE_URL}/categories/${categoryId}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// DELETE THE CATEGORY
export const deleteCategory = async (token, categoryId) => {
  await axios.delete(`${BASE_URL}/categories/${categoryId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// GET SINGLE THE CATEGORY
export const singleCategory = async (token, categoryId) =>
  await axios.get(`${BASE_URL}/categories/${categoryId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// GET CATEGORIES BY TRADE ID
export const CategoriesByTrade = async (token, tradeId) =>
  await axios.get(`${BASE_URL}/categories/byTrade/${tradeId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// // ---SEARCH RESULTS---
// export const searchListings = async (query) =>
//   await axios.post(`${BASE_URL}/search-listings`, query);
