import { useState, useEffect } from "react";
import { IconButton } from "@material-ui/core";
import { useSelector } from "react-redux";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { Col, Row } from "react-bootstrap";
import { allTrades } from "../../../actions/tradeActions";
import { getCitiesOfStates } from "../../../actions/cityAction";
import { Modal } from "react-bootstrap";

const CityForm = ({
  setData,
  handleSubmit,
  states,
  data,
  showModal,
  closemodal,
}) => {
  const status = ["Active", "Inactive", "Hidden"];
  const rollOut = ["Full", "Partial"];
  const [selectedState, setSelectedState] = useState(
    states.length ? states[0] : null
  );
  const [cities, setCities] = useState([]);
  const [tradesFromDB, setTradesFromDB] = useState([]);
  const tradeTemplate = {
    tradeName: "",
  };
  const { auth } = useSelector((state) => ({ ...state }));
  const { token } = auth;
  const [trades, setTrades] = useState([tradeTemplate]);

  const handleAddMore = () => {
    setTrades([...trades, tradeTemplate]);
  };

  useEffect(() => {
    fetchCitiesFromState(selectedState);
  }, [selectedState]);

  useEffect(() => {
    fetchAllTrades();
  }, []);

  const fetchCitiesFromState = async (state) => {
    let res = await getCitiesOfStates(state, token);
    // console.log("cities >>>> ", res);
    setCities(res.data.cities);
  };

  const fetchAllTrades = async () => {
    let res = await allTrades(token);
    // console.log("trades >>>> ", res);
    setTradesFromDB(res.data.trades);
  };

  const removeTrades = (index) => {
    const filteredTrades = [...trades];
    // console.log(">>>>>>filteredTrades", filteredTrades);
    filteredTrades.splice(index, 1);
    setTrades(filteredTrades);
    // console.log(">>>>>>finalfilteredTrades", filteredTrades);
  };

  return (
    <Modal
      show={showModal}
      onHide={() => closemodal()}
      size="md"
      className="p-0"
    >
      <div>
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              ADD NEW CITY
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => closemodal()}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="col-lg-12 col-md-11">
              <div className="card card-static-2 mb-30">
                <div className="card-title-2">ADD NEW</div>
                <div className="card-body-table">
                  <div className="news-content-right pd-20">
                    <form onSubmit={handleSubmit}>
                      <div className="form-group">
                        <label htmlFor="name" className="form-label">
                          State Name*
                        </label>
                        <select
                          name="type"
                          className="form-control"
                          //   value={type}
                          required
                          onChange={(e) => {
                            // console.log("states event >>>> ", e.target);
                            setSelectedState(e.target.value);
                            setData({ ...data, stateName: e.target.value });
                          }}
                        >
                          <option value="">Select State</option>
                          {states &&
                            states.map((value, index) => (
                              <option key={index} value={value}>
                                {value}
                              </option>
                            ))}
                        </select>
                      </div>

                      <div className="form-group">
                        <label htmlFor="name" className="form-label">
                          City Name*
                        </label>
                        <select
                          name="type"
                          className="form-control"
                          // value={type}
                          required
                          onChange={(e) =>
                            // console.log("event >>>>>>>>", e.target.value)
                            setData({ ...data, cityName: e.target.value })
                          }
                        >
                          <option value="">Select City</option>
                          {cities &&
                            cities.map((value, index) => (
                              <option key={index} value={value.district}>
                                {value.district}
                              </option>
                            ))}
                        </select>
                      </div>

                      <div className="form-group">
                        <label htmlFor="type" className="form-babel">
                          Status*
                        </label>
                        <select
                          name="type"
                          className="form-control"
                          //   value={type}
                          required
                          onChange={(e) =>
                            setData({ ...data, status: e.target.value })
                          }
                        >
                          <option value="">Select Type</option>
                          {status.map((value, index) => (
                            <option key={index} value={value}>
                              {value}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="form-group">
                        <label htmlFor="type" className="form-babel">
                          Rollout*
                        </label>
                        <select
                          name="type"
                          className="form-control"
                          required
                          onChange={(e) =>
                            setData({ ...data, rollout: e.target.value })
                          }
                        >
                          <option value="">Select Type</option>
                          {rollOut.map((value, index) => (
                            <option key={index} value={value}>
                              {value}
                            </option>
                          ))}
                        </select>
                      </div>

                      {trades.map((trade, index) => (
                        <>
                          <div className="form-group">
                            {index === 0 && (
                              <label
                                htmlFor="companyName"
                                className="form-label"
                              >
                                Trades*
                              </label>
                            )}
                            <Row>
                              <Col sm={8}>
                                <select
                                  type="text"
                                  className="form-control"
                                  placeholder="Add Trades"
                                  name="tradeName"
                                  onChange={(e) =>
                                    setData({
                                      ...data,
                                      trades: [
                                        {
                                          trade: e.target.value,
                                          status: "Inactive",
                                        },
                                      ],
                                    })
                                  }
                                >
                                  <option value="">Select Trade</option>
                                  {tradesFromDB &&
                                    tradesFromDB.map((value, index) => (
                                      <option key={index} value={value.name}>
                                        {value.name}
                                      </option>
                                    ))}
                                </select>
                              </Col>
                              {index === trades.length - 1 && (
                                <Col sm={2}>
                                  <button
                                    className="save-btn btn-primary hover-btn mt-0"
                                    onClick={handleAddMore}
                                  >
                                    +
                                  </button>
                                </Col>
                              )}
                              <Col sm={2}>
                                <IconButton onClick={() => removeTrades(index)}>
                                  <DeleteOutlineIcon />
                                </IconButton>
                              </Col>
                            </Row>
                          </div>
                        </>
                      ))}

                      <div
                        className="button-class"
                        style={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        {/* <button className='save-btn btn-primary hover-btn' onClick={handleAddMore}>
                          + Trades
                        </button> */}
                        <button
                          className="save-btn hover-btn"
                          onClick={handleSubmit}
                        >
                          SAVE CITY
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CityForm;
