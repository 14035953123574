/** @format */

import { Form } from "react-bootstrap";
import ToggleButton from "react-bootstrap/ToggleButton";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { useState, useEffect } from "react";

import DeleteIcon from "@material-ui/icons/Delete";
import { Modal, Select } from "antd";
const { Option } = Select;
const VariantForm = ({
  variant,
  setVariant,
  handleSubmit,
  handleImageChange,
  handlePackagedProductImageChange,
  handleChange,
  currentId,
  show,
  filterList,
  setSelectedFilters,
  handleClose,
}) => {
  const {
    name,
    type,
    skuId,
    costPrice,
    sellingPrice,
    hsn,
    videoLink,
    outOfStock,
    quantity,
    volume,
    weight,
    price,
    purchaseQtyLimit,
    status,
    deliveryFee,
    packagingMaterial,
    packagingCost,
    deliveryTime,
    photoUrlofPackagedProduct,
    minOrderQuantity,
    photoUrl,
    weightUnit,
    volumeUnit
  } = variant;
  const { mrp, min, max } = price;

  const handleVideoChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const newVideoLink = [...variant.videoLink];
    newVideoLink[index] = value;
    setVariant({ ...variant, videoLink: newVideoLink });
  };

  const addVideoInputField = (e) => {
    e.preventDefault();
    const lastValue = variant.videoLink[variant.videoLink.length - 1];

    // If the last value is empty, don't add a new input field
    if (!lastValue) {
      return;
    }
    const allLinksValid = videoLink.every((link) => link !== "");
    // If any value is empty, don't add a new input field
    if (allLinksValid) {
      setVariant({ ...variant, videoLink: [...variant.videoLink, ""] });
    }
  };

  const handleFilterChange = (filterName, selectedValues) => {
    setSelectedFilters((prevSelectedFilters) => ({
      ...prevSelectedFilters,
      [filterName]: selectedValues,
    }));
  };

  return (
    <Modal
      open={show}
      onCancel={handleClose}
      footer={null}
      title={currentId ? "Update Variant" : "+Add New Variant"}
      width={700}
    >
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-lg-12 col-md-12'>
            <div className='card card-static-2 mb-30'>
              <div className='card-body-table'>
                <div className='news-content-right pd-20'>
                  <form onSubmit={handleSubmit}>
                    <div className='row'>
                      <div
                        className='col-lg-12 col-md-11'
                        style={{ paddingTop: "1rem" }}
                      >
                        <div className='form-group'>
                          <label htmlFor='name' className='form-label'>
                            Variant Name
                            <span className='required'>&#42;</span>
                          </label>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='Variant Name'
                            name='name'
                            required
                            value={name}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div
                        className='col-lg-12 col-md-11'
                        style={{ paddingTop: "1rem" }}
                      >
                        <div className='form-group'>
                          <label htmlFor='type' className='form-label'>
                            Enter Type (if applicable)
                          </label>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='Enter Type'
                            name='type'
                            value={type}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div
                        className='col-lg-12 col-md-11'
                        style={{ paddingTop: "1rem" }}
                      >
                        <div className='form-group'>
                          <label htmlFor='quantity' className='form-label'>
                            Enter Quantity (Units) (if applicable)
                          </label>
                          <input
                            type='Number'
                            className='form-control'
                            placeholder='Enter Quantity (Units)'
                            name='quantity'
                            value={quantity}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div
                        className='col-lg-6 col-md-5'
                        style={{
                          paddingTop: "1rem",
                          position: "relative",
                        }}
                      >
                        <div
                          className='form-group'
                          style={{ position: "absolute" }}
                        >
                          <label htmlFor='volumeValue' className='form-label'>
                            Enter Volume (if applicable)
                          </label>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='Volume'
                            name='volume'
                            value={volume.value}
                            onChange={(e) =>
                              setVariant({
                                ...variant,
                                volume: {
                                  ...variant.volume,
                                  value: e.target.value,
                                },
                              })
                            }
                          />
                        </div>
                        <div
                          className='col-lg-4 col-md-4'
                          style={{
                            position: "absolute",
                            left: "52%",
                            top: "300%",
                          }}
                        >
                          <select
                            name='volumeUnit'
                            id='volumeUnit'
                            className='form-control'
                            value={volume.unit}
                            onChange={(e) =>
                              setVariant({
                                ...variant,
                                volume: {
                                  ...variant.volume,
                                  unit: e.target.value,
                                },
                              })
                            }
                          >
                            <option value='ml'>ml</option>
                            <option value='l'>l</option>
                            <option value='kl'>kl</option>
                          </select>
                        </div>
                      </div>

                      <div
                        className='col-lg-6 col-md-5'
                        style={{
                          paddingTop: "1rem",
                          position: "relative",
                        }}
                      >
                        <div
                          className='form-group'
                          style={{ position: "absolute" }}
                        >
                          <label htmlFor='weightValue' className='form-label'>
                            Enter Weight (if applicable)
                          </label>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='Weight'
                            name='weight'
                            value={weight.value}
                            onChange={(e) =>
                              setVariant({
                                ...variant,
                                weight: {
                                  ...variant.weight,
                                  value: e.target.value,
                                },
                              })
                            }
                          />
                        </div>
                        <div
                          className='col-lg-4 col-md-4'
                          style={{
                            position: "absolute",
                            left: "52%",
                            top: "300%",
                          }}
                        >
                          <select
                            name='weightUnit'
                            id='weightUnit'
                            value={weight.unit}
                            className='form-control'
                            onChange={(e) =>
                              setVariant({
                                ...variant,
                                weight: {
                                  ...variant.weight,
                                  unit: e.target.value,
                                },
                              })
                            }
                          >
                           
                            <option value='mg'>mg</option>
                            <option value='g'>g</option>
                            <option value='kg'>kg</option>
                          </select>
                        </div>
                      </div>

                      <div
                        className='col-lg-12 col-md-11'
                        style={{ paddingTop: "1rem", marginTop: "4rem" }}
                      >
                        <div className='form-group'>
                          <label htmlFor='photoUrl' className='form-label'>
                         Variant Images
                          </label>
                           {photoUrl && photoUrl.length >0 && Array.isArray(photoUrl) ? (
                            photoUrl.map((photo, photoIndex)=> <>
                             <img src={photo} className='cate-img-5' />
                                   <DeleteIcon onClick={() => setVariant({ ...variant, photoUrl: variant.photoUrl.filter((x,id)=>id!==photoIndex) })} />
                            </>)
                           ):(
                             <input
                            type='file'
                            multiple
                            className='form-control'
                            name='photoUrl'
                            onChange={(e, index) => handleImageChange(e, index)}
                            accept='image/*'
                          />
                           )
                             
                           }
                       
                          {/* <div className="cate-img-5">
                              {photoUrl && (
                                <img src={`${photoUrl}`} alt="variant_img" />
                              )}
                            </div> */}
                        </div>
                      </div>

                      
                      <div
                        className='col-lg-12 col-md-11'
                        style={{ paddingTop: "1rem" }}
                      >
                        <label htmlFor='videoLink' className='form-label'>
                          Video Links
                        </label>
                        <button
                          style={{
                            border: "1px solid gray",
                            borderRadius: "8px",
                            marginLeft: "4px",
                            paddingTop: "0px",
                            paddingBottom: "0px",
                            fontSize: "10px",
                            height: "20px",
                            display: "inline-flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignContent: "center",
                          }}
                          onClick={(e) => addVideoInputField(e)}
                        >
                          <span
                            style={{
                              paddingTop: "0px",
                              paddingBottom: "0px",
                              fontSize: "8px",
                            }}
                          >
                            +
                          </span>
                        </button>
                        {variant?.videoLink?.map((link, index) => {
                          return (
                            <div key={index} className='form-group'>
                              <input
                                type='text'
                                onChange={(evnt) =>
                                  handleVideoChange(index, evnt)
                                }
                                value={link}
                                name='link'
                                className='form-control'
                                placeholder=' https://www.example.com'
                                pattern='^(http(s)://.)[-a-zA-Z0-9@:%._+~#=]{2,256}.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$'
                              />
                            </div>
                          );
                        })}
                      </div>

                      <div
                        className='col-lg-12 col-md-11'
                        style={{ paddingTop: "1rem" }}
                      >
                        <div className='form-group'>
                          <label htmlFor='skuId' className='form-label'>
                            Enter SKU ID
                            <span className='required'>&#42;</span>
                          </label>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='Enter SKU ID'
                            name='skuId'
                            required
                            value={skuId}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      
                      <div
                        className='col-lg-12 col-md-11'
                        style={{ paddingTop: "1rem" }}
                      >
                        <div className='form-group'>
                          <label htmlFor='hsn' className='form-label'>
                            HSN
                          </label>
                          <input
                            type='text'
                            className='form-control'
                            placeholder='Enter HSN'
                            name='hsn'
                            value={hsn}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div
                        className='col-lg-12 col-md-11'
                        style={{ paddingTop: "1rem" }}
                      >
                        <div className='form-group'>
                          <label htmlFor='' className='form-label'>
                            Status<span className='required'>&#42;</span>
                          </label>
                          <select
                            name='status'
                            id='status'
                            className='form-control'
                            required
                            value={status}
                            onChange={handleChange}
                          >
                            <option value=''>Select</option>
                            <option value='Live'>Live</option>
                            <option value='Hidden'>Hidden</option>
                            <option value='Draft'>Draft</option>
                            <option value='Deleted'>Deleted</option>
                          </select>
                        </div>
                      </div>


                      <div
                        className='col-lg-12 col-md-11'
                        style={{ paddingTop: "1rem" }}
                      >
                        <div className='form-group'>
                          <label htmlFor='outOfStock' className='form-label'>
                            Out of Stock ?
                          </label>
                          <select
                            name='outOfStock'
                            id='outOfStock'
                            className='form-control'
                            value={outOfStock}
                            onChange={handleChange}
                          >
                            <option value=''>Select</option>
                            <option value='Yes'>Yes</option>
                            <option value='No'>No</option>
                          </select>
                        </div>
                      </div>
                  
                      <div
                        className='col-lg-12 col-md-11'
                        style={{ paddingTop: "1rem" }}
                      >
                        <div className='form-group'>
                          <label htmlFor='price' className='form-label'>
                            Price<span className='required'>&#42;</span> (MRP)
                          </label>
                          <input
                            type='number'
                            className='form-control'
                            name='price'
                            placeholder='Price'
                            value={mrp}
                            required
                            onChange={(e) => {
                              setVariant({
                                ...variant,
                                price: {
                                  ...variant.price,
                                  mrp: parseInt(e.target.value),
                                },
                              });
                            }}
                          />
                        </div>
                      </div>

                      <div
                        className='col-lg-12 col-md-11'
                        style={{ paddingTop: "1rem" }}
                      >
                        <div className='form-group'>
                          <label htmlFor='costPrice' className='form-label'>
                            Cost Price
                            <span className='required'>&#42;</span>
                          </label>
                          <input
                            type='number'
                            className='form-control'
                            placeholder='Enter Cost Price'
                            name='costPrice'
                            required
                            value={costPrice}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div
                        className='col-lg-12 col-md-11'
                        style={{ paddingTop: "1rem" }}
                      >
                        <div className='form-group'>
                          <label htmlFor='sellingPrice' className='form-label'>
                            Selling Price
                            <span className='required'>&#42;</span>
                          </label>
                          <input
                            type='number'
                            className='form-control'
                            placeholder='Enter Selling Price'
                            name='sellingPrice'
                            required
                            value={sellingPrice}
                            onChange={handleChange}
                          />
                        </div>
                      </div>

                      <div
                        className='col-lg-12 col-md-11'
                        style={{ paddingTop: "1rem" }}
                      >
                        <div className='form-group'>
                          <label
                            htmlFor='purchaseQtyLimit'
                            className='form-label'
                          >
                            Purchase Quantity Limit Per Order
                            <span className='required'>&#42;</span>
                          </label>
                          <input
                            type='number'
                            className='form-control'
                            name='purchaseQtyLimit'
                            placeholder='Limit per order'
                            required
                            value={purchaseQtyLimit}
                            onChange={handleChange}
                          />
                        </div>
                      </div>


                      <div
                        className='col-lg-12 col-md-11'
                        style={{ paddingTop: "1rem" }}
                      >
                        <div className='form-group'>
                          <label
                            htmlFor='minOrderQuantity'
                            className='form-label'
                          >
                            Minimum Order Quantity
                            <span className='required'>&#42;</span>
                          </label>
                          <input
                            type='number'
                            className='form-control'
                            name='minOrderQuantity'
                            placeholder='Minimum order quantity'
                            required
                            value={minOrderQuantity}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      {/* <div
                        className='col-lg-12 col-md-11'
                        style={{ paddingTop: "1rem" }}
                      >
                        <div className='form-group'>
                          <label htmlFor='min' className='form-label'>
                            Minimum Price
                          </label>
                          <input
                            type='number'
                            className='form-control'
                            name='min'
                            placeholder='Minimum Price'
                            value={min}
                            onChange={(e) =>
                              setVariant({
                                ...variant,
                                price: {
                                  ...variant.price,
                                  min: parseInt(e.target.value),
                                },
                              })
                            }
                          />
                        </div>
                      </div> */}
                      {/* <div
                        className='col-lg-12 col-md-11'
                        style={{ paddingTop: "1rem" }}
                      >
                        <div className='form-group'>
                          <label htmlFor='max' className='form-label'>
                            Maximum Price
                          </label>
                          <input
                            type='number'
                            className='form-control'
                            name='max'
                            placeholder='Maximum Price'
                            value={max}
                            onChange={(e) =>
                              setVariant({
                                ...variant,
                                price: {
                                  ...variant.price,
                                  max: parseInt(e.target.value),
                                },
                              })
                            }
                          />
                        </div>
                      </div> */}

                  

                    

                    
<div
                        className='col-lg-12 col-md-11'
                        style={{ paddingTop: "1rem" }}
                      >
                        <div className='form-group'>
                          <label
                            htmlFor='photoUrlofPackagedProduct'
                            className='form-label'
                          >
                            Packaged Product Images
                            <span className='required'>&#42;</span>
                          </label>
                          {photoUrlofPackagedProduct && photoUrlofPackagedProduct.length >0 && Array.isArray(photoUrlofPackagedProduct) ? (
                            photoUrlofPackagedProduct.map((photo, photoIndex)=> <>
                             <img src={photo} className='cate-img-5' />
                                   <DeleteIcon onClick={() => setVariant({ ...variant, photoUrlofPackagedProduct: photoUrlofPackagedProduct.filter((x,id)=>id!==photoIndex) })} />
                            </>)
                            ):(
      
                                <input
                            type='file'
                            multiple
                            className='form-control'
                            name='photoUrlofPackagedProduct'
                            required
                            onChange={(e, index) =>
                              handlePackagedProductImageChange(e, index)
                            }
                            accept='image/*'
                          />
                          )}
                      
                          {/* <div className="cate-img-5">
                              {photoUrl && (
                                <img src={`${photoUrl}`} alt="variant_img" />
                              )}
                            </div> */}
                        </div>
                      </div>


                   

                      <div
                        className='col-lg-12 col-md-11'
                        style={{ paddingTop: "1rem" }}
                      >
                        <div className='form-group'>
                          <label
                            htmlFor='packagingMaterial'
                            className='form-label'
                          >
                            Packaging Material
                            <span className='required'>&#42;</span>
                          </label>
                          <input
                            type='text'
                            className='form-control'
                            name='packagingMaterial'
                            placeholder='Packaging Material'
                            required
                            value={packagingMaterial}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                     
                      <div
                        className='col-lg-12 col-md-11'
                        style={{ paddingTop: "1rem" }}
                      >
                        <div className='form-group'>
                          <label htmlFor='packagingCost' className='form-label'>
                            Packaging Cost
                            <span className='required'>&#42;</span>
                          </label>
                          <input
                            type='number'
                            className='form-control'
                            name='packagingCost'
                            placeholder='Packaging Cost'
                            required
                            value={packagingCost}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div
                        className='col-lg-12 col-md-11'
                        style={{ paddingTop: "1rem" }}
                      >
                        <div className='form-group'>
                          <label htmlFor='deliveryTime' className='form-label'>
                            Delivery time(in days)
                            <span className='required'>&#42;</span>
                          </label>
                          <input
                            type='text'
                            className='form-control'
                            name='deliveryTime'
                            placeholder='Delivery time(in days)'
                            required
                            value={deliveryTime}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div
                        className='col-lg-12 col-md-11'
                        style={{ paddingTop: "1rem" }}
                      >
                        <div className='form-group'>
                          <label htmlFor='deliveryfee' className='form-label'>
                            Delivery Fee
                            <span className='required'>&#42;</span>
                          </label>
                          <input
                            type='number'
                            className='form-control'
                            name='deliveryFee'
                            placeholder='Delivery fee'
                            required
                            value={deliveryFee}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                   

                  

                     

                   
                      <div
                        className='col-lg-3 col-md-3'
                        style={{ paddingTop: "1rem" }}
                      >
                        <label htmlFor='' className='form-label'>
                          Select Filters
                        </label>
                      </div>

                      <div className='col-lg-12 col-md-11'>
                        <div className='card mt-2 p-3'>
                          <div className='row'>
                            {filterList.length === 0 ? (
                              <div className='alert alert-info'>No Filters</div>
                            ) : (
                              filterList.map((filter) => (
                                <div
                                  key={filter.id}
                                  className='col-lg-6 col-md-4 mb-3'
                                >
                                  <label
                                    style={{ textTransform: "capitalize" }}
                                    className='form-label'
                                  >
                                    {filter.filter} :&nbsp;
                                  </label>
                                  <Select
                                    style={{ width: 300 }}
                                    placeholder='Select Filters'
                                    mode='multiple'
                                    showSearch
                                    onChange={(selectedValues) =>
                                      handleFilterChange(
                                        filter.filter,
                                        selectedValues
                                      )
                                    }
                                  >
                                    {filter.values.map((value) => (
                                      <Option
                                        key={value.value}
                                        value={value.value}
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        {value.value}
                                      </Option>
                                    ))}
                                  </Select>
                                </div>
                              ))
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ paddingTop: "1rem" }}>
                      <button className='save-btn hover-btn' type='submit'>
                        {currentId ? "Update" : "Add New"}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default VariantForm;
