import axios from "axios";
import { BASE_URL_CITY_MANAGEMENT } from "./api";
// const BASE_URL_CITY_MANAGEMENT = "http://localhost:8082";

// GET ALL ZONES OF A PARTICULAR CITY BY cityId
export const getAllZonesByCityId = async (token, cityId) =>
  await axios.get(`${BASE_URL_CITY_MANAGEMENT}/zone/byCityId/${cityId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// ADD ZONE
export const addZone = async (token, data, cityId) =>
  await axios.post(`${BASE_URL_CITY_MANAGEMENT}/zone/${cityId}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// UPDATE ZONE
export const updateZoneById = async (token, data, zoneId) =>
  await axios.put(`${BASE_URL_CITY_MANAGEMENT}/zone/${zoneId}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// DELETE ZONE
export const deleteZoneById = async (token, zoneId) =>
  await axios.delete(`${BASE_URL_CITY_MANAGEMENT}/zone/${zoneId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
