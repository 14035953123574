/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import { singleCoupon } from "../../../actions/couponActions";
import CouponStatesCitiesClustersTable from "./CouponStatesCitiesClustersTable";
import TradesCategoriesSubCategoriesTable from "./TradesCategoriesSubCategoriesTable";

const ViewCoupon = ({ match }) => {
  const { auth } = useSelector((state) => ({ ...state }));
  const [coupon, setCoupon] = useState("");

  const fetchCoupon = async () => {
    let res = await singleCoupon(auth.token, match.params.couponId);
    setCoupon(res.data.coupon);
  };

  useEffect(() => {
    fetchCoupon();
  }, []);

  return (
    <div id="layoutSidenav_content" style={{ userSelect: "auto" }}>
      <main>
        <div className="container-fluid">
          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/coupons">coupons</Link>
            </li>
            <li className="breadcrumb-item active">View Coupon</li>
          </ol>
          <div className="row">
            <div className="card card-static-2 mb-30 col-md-9 ml-4">
              <div className="shopowner-dts">
                <div className="shopowner-dt-list">
                  <span className="left-dt">Coupon Code</span>
                  <span className="right-dt">{coupon?.couponCode}</span>
                </div>
                <div className="shopowner-dt-list">
                  <span className="left-dt">Status</span>
                  <span
                    className="right-dt"
                    style={{
                      color: `${
                        coupon?.status === "Active"
                          ? "green"
                          : coupon?.status === "Inactive"
                          ? "red"
                          : "orange"
                      }`,
                    }}
                  >
                    {coupon?.status}
                  </span>
                </div>
                <div className="shopowner-dt-list">
                  <span className="left-dt">Total Discount Availed (INR)</span>
                  <span className="right-dt">
                    &#8377; {coupon?.maxDiscount}
                  </span>
                </div>
                <div className="shopowner-dt-list">
                  <span className="left-dt">Discount Percentage</span>
                  <span className="right-dt">
                    {coupon?.discountPercentage
                      ? `${coupon?.discountPercentage}%`
                      : "-"}
                  </span>
                </div>
                <div className="shopowner-dt-list">
                  <span className="left-dt">Coupon Type</span>
                  <span className="right-dt">{coupon?.couponType}</span>
                </div>
                <div className="shopowner-dt-list">
                  <span className="left-dt">Coupon Description</span>
                  <span className="right-dt">{coupon?.couponDescription}</span>
                </div>
                <div className="shopowner-dt-list">
                  <span className="left-dt">Valid From </span>
                  <span className="right-dt">
                    {moment(coupon?.validFrom).format("lll")}
                  </span>
                </div>
                <div className="shopowner-dt-list">
                  <span className="left-dt">Valid Till</span>
                  <span className="right-dt">
                    {moment(coupon?.validTill).format("lll")}
                  </span>
                </div>
                <div className="shopowner-dt-list">
                  <span className="left-dt">Last Updated By</span>
                  <span className="right-dt">
                    {coupon?.metaInfo?.updated?.by?.name}
                  </span>
                </div>
                <div className="shopowner-dt-list">
                  <span className="left-dt">Last Updated At</span>
                  <span className="right-dt">
                    {moment(coupon?.metaInfo?.updated?.at).format("lll")}
                  </span>
                </div>
                <div className="shopowner-dt-list">
                  <span className="left-dt">Created By</span>
                  <span className="right-dt">
                    {coupon?.metaInfo?.created?.by?.name}
                  </span>
                </div>
                <div className="shopowner-dt-list">
                  <span className="left-dt">Created At</span>
                  <span className="right-dt">
                    {moment(coupon?.metaInfo?.created?.at).format("lll")}
                  </span>
                </div>
              </div>
            </div>
            <ol className="col-lg-2 col-md-2 back-btn ml-4">
              <Link
                to={`/coupons/edit/${coupon?._id}`}
                style={{ textDecoration: "none" }}
              >
                <button className="status-btn hover-btn">
                  Edit This Coupon
                </button>
              </Link>
            </ol>
          </div>
          <TradesCategoriesSubCategoriesTable coupon={coupon} />
          <CouponStatesCitiesClustersTable coupon={coupon} />
        </div>
      </main>
      <footer className="py-4 bg-footer mt-auto">
        <div className="container-fluid">
          <div className="d-flex align-items-center justify-content-between small">
            <div className="text-muted-1">
              © 2021 <b>App Factory</b>
            </div>
            <div className="footer-links">
              <a href="/">Privacy Policy</a>
              <a href="/">Terms &amp; Conditions</a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default ViewCoupon;
