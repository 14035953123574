import { datadump } from "../../actions/consumerAction";
import moment from "moment";

const OrderListTable = ({orderList}) => {
  return (
    <div className="col-lg-12 col-md-11">
      <div className="all-cate-tags">
        <div className="row justify- content-between">
          <div className="col-lg-12 col-md-12">
            <div className="card card-static-2 mb-30">
              <div className="card-body-table">
                <div className="table-responsive">
                  <table className="table ucp-table table-hover">
                    <thead>
                      <tr>
                        <th>Order ID</th>
                        <th>Date</th>
                        <th>Services</th>
                        <th>Gross Total</th>
                        <th>Discount(INR)</th>
                        <th>Net Total</th>
                        <th>Payment Mode</th>
                        <th>Order Rating</th>
                      </tr>
                    </thead>
                    <tbody>
                      {orderList?.map((order, index) => {
                        return (
                          <tr key={index}>
                            <td>{order?._id}</td>
                            <td>{order?.date ? moment(order?.date).format("lll"): ""}</td>
                            <td>{order?.services?.length}</td>
                            <td>{order?.discount + order?.paid_amount}</td>
                            <td>{order?.discount}</td>
                            {/* <td>{order?.walletBalance}</td> */}
                            {/* <td>{order?.NetTotal}</td> */}
                            <td>{order?.paid_amount}</td>
                            <td>{order?.payment_type}</td>
                            <td>{order?.customer_rating}</td>
                          </tr>
                        );
                      })}
                      {/* <tr className="bold-table-text">
                        <th>Grand Total</th>
                        <th></th>
                        <th>0</th>
                        <th>100</th>
                        <th>100</th>
                      </tr> */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderListTable;
