/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, FeatureGroup, Polygon } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
// import { useRef } from "react";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";

const PolygonMap = () => {
  const [center, setCenter] = useState({ lat: 12.9716, lng: 77.5946 });
  const [mapLayers, setMapLayers] = useState([]);
  const ZOOM_LEVEL = 12;
  // const mapRef = useRef();
  const purpleOptions = { color: "purple" };

  useEffect(() => {
    console.log(mapLayers);
  }, [mapLayers]);

  const handleCreate = (e) => {
    // console.log(e);
    const { layerType, layer } = e;
    if (layerType === "polygon") {
      const { _leaflet_id } = layer;
      setMapLayers((layers) => [
        ...layers,
        { id: _leaflet_id, latlngs: layer._latlngs[0] },
      ]);
    }
  };

  const multiPolygon = [
    [
      [12.9716, 77.5946],
      [12.61095, 77.363311],
      [12.622431, 77.434997],
    ],
    [
      [12.608016, 77.390228],
      [12.561028, 77.38308],
      [12.5701068, 77.422363],
    ],
  ];

  const handleEdit = (e) => {
    console.log(e);
    const {
      layers: { _layers },
    } = e;
    Object.values(_layers).map(({ _leaflet_id, editing }) => {
      setMapLayers((layers) =>
        layers.map((l) =>
          l.id === _leaflet_id
            ? { ...l, latlngs: { ...editing.latlngs[0] } }
            : l
        )
      );
    });
  };

  const handleDelete = (e) => {
    console.log(e);
    const {
      layers: { _layers },
    } = e;

    Object.values(_layers).map(({ _leaflet_id }) => {
      setMapLayers((layers) => layers.filter((l) => l.id !== _leaflet_id));
    });
  };

  return (
    <div id="layoutSidenav_content">
      <MapContainer center={center} zoom={ZOOM_LEVEL} className="map">
        <FeatureGroup>
          <EditControl
            position="topright"
            onCreated={handleCreate}
            onEdited={handleEdit}
            onDeleted={handleDelete}
            draw={{
              rectangle: false,
              polyline: false,
              circles: false,
              circlemarker: false,
              marker: false,
            }}
          />
        </FeatureGroup>
        <FeatureGroup>
          <EditControl
            position="topright"
            onDeleted={handleDelete}
            //this is the necessary function. It goes through each layer
            //and runs my save function on the layer, converted to GeoJSON
            //which is an organic function of leaflet layers.

            onEdited={handleEdit}
            // edit={{ remove: false }}
            draw={{
              marker: false,
              circle: false,
              rectangle: false,
              polygon: false,
              polyline: false,
            }}
          />
          <Polygon pathOptions={purpleOptions} positions={multiPolygon} />
        </FeatureGroup>
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
      </MapContainer>
    </div>
  );
};

export default PolygonMap;
