import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
// import { updateCityByID, fetchAllCities } from "../../../actions/cityAction";
import moment from "moment";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { allTrades } from "../../../actions/tradeActions";
import { Modal } from "react-bootstrap";
import { IconButton } from "@material-ui/core";

const TradeForm = ({
  city,
  setCity,
  fetchAllCities,
  setData,
  data,
  showModal,
  handleUpdateCity,
  setShowEditTradesModal,
}) => {
  const template = {
    trades: [
      { trade: "", activationDate: "", deactivationDate: "", status: "" },
    ],
    status: city.status,
    rollout: city.rollout,
  };
  // const newTemplate = {
  //   trade: "",
  //   activationDate: "",
  //   deactivationDate: "",
  //   status: "",
  // };
  const status = ["Active", "Inactive", "Hidden"];
  const [trades, setTrades] = useState([]);
  const [updatedStatus, setUpdatedStatus] = useState();
  const [isChanged, setIsChanged] = useState(false);
  // const [tradeAdded, setTradeAdded] = useState(false);
  // const [newAddedTrades, setNewAddedTrades] = useState({});
  const [tradesFromDB, setTradesFromDB] = useState([]);
  const [singleTrade, setSingleTrade] = useState(template);

  const { auth } = useSelector((state) => ({ ...state }));
  const { token } = auth;

  const [citydata, setCityData] = useState(null);

  const date = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0");
    var yyyy = today.getFullYear();
    today = yyyy + "/" + mm + "/" + dd;
    return today;
  };

  const fetchAllTrades = async () => {
    let res = await allTrades(token);
    // console.log("trades >>>> ", res);
    setTradesFromDB(res.data.trades);
  };

  useEffect(() => {
    setCityData(city);
    setTrades(city.trades);
    fetchAllTrades();
  }, [city]);

  useEffect(() => {
    if (isChanged && updatedStatus === "Inactive") {
      setSingleTrade({
        ...singleTrade,
        trades: [
          {
            trade: trades[0].trade,
            deactivationDate: date(),
            status: updatedStatus,
          },
        ],
      });
    } else if (isChanged && updatedStatus === "Active") {
      setSingleTrade({
        ...singleTrade,
        trades: [
          {
            trade: trades[0].trade,
            activationDate: date(),
            status: updatedStatus,
          },
        ],
      });
    }
  }, [isChanged, updatedStatus]);

  // const handleUpdate = async () => {
  //   const id = city.id;
  //   try {
  //     console.log(singleTrade);
  //     const res = await updateCityByID(singleTrade, id, token);
  //     fetchAllCities();
  //   } catch (error) {
  //     console.log("error in updating city", error);
  //   }
  // };

  // const handleTradeAdd = () => {
  //     setTradeAdded(true)
  //     setTrades([...trades, newTemplate]);
  //     console.log("trades from tf >>>>>> ", trades)
  // }

  const addTrades = () => {
    const updateTrades = citydata.trades;
    updateTrades.push({
      trade: "",
      activationDate: "",
      deactivationDate: "",
      status: "",
    });
    setCityData({ ...citydata, trades: updateTrades });
  };

  const changeStatus = (index, value) => {
    const updateTrades = citydata.trades;
    updateTrades[index].status = value;
    if (value === "Inactive") {
      updateTrades[index].deactivationDate = date();
    }
    setCityData({ ...citydata, trades: updateTrades });
  };

  const removeTrade = (index) => {
    const updateTrades = citydata.trades;
    updateTrades.splice(index, 1);
    setCityData({ ...citydata, trades: updateTrades });
  };

  const changeTrade = (index, value) => {
    // console.log(value);
    const updateTrades = citydata.trades;
    updateTrades[index].trade = value;
    // if (updateTrades[index].status = "") {
    updateTrades[index].status = "Active";
    updateTrades[index].activationDate = date();
    // }
    setCityData({ ...citydata, trades: updateTrades });
  };
  // console.log("CITY DATATATATATTATA", citydata);

  return (
    <Modal
      show={showModal}
      onHide={() => setShowEditTradesModal(false)}
      size="lg"
      className="p-4"
    >
      <Modal.Header closeButton data-dismiss="modal">
        <h5>UPDATE TRADES</h5>
      </Modal.Header>
      <Modal.Body>
        <div className="col-lg-12 col-md-11">
          <div className="card card-static-2 mb-30" style={{ width: "100%" }}>
            <div className="card-body-table" style={{ width: "100%" }}>
              <div className="news-content-right pd-20">
                <table
                  className="table ucp-table table-hover"
                  style={{ width: "100%" }}
                >
                  <thead>
                    <tr>
                      <th scope="col">Trade Name</th>
                      <th scope="col">Date of Activation</th>
                      <th scope="col">Date of Deactivation</th>
                      <th scope="col-lg">Status</th>
                      <th scope="col">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {trades.map((trade, index) => (
                      <tr key={index}>
                        {trade.trade !== "" ? (
                          <td>{trade.trade}</td>
                        ) : (
                          <select
                            style={{ border: 0, width: "100%" }}
                            name="type"
                            className="form-control"
                            required
                            onChange={(e) => changeTrade(index, e.target.value)}
                          >
                            <option value="">Select Trade</option>
                            {tradesFromDB &&
                              tradesFromDB.map((value, index) => (
                                <option key={index} value={value.name}>
                                  {value.name}
                                </option>
                              ))}
                          </select>
                        )}
                        <td>
                          {trade.activationDate
                            ? moment(trade.activationDate).format("lll")
                            : "NA"}
                        </td>
                        <td>
                          {trade.deactivationDate
                            ? moment(trade.deactivationDate).format("lll")
                            : "NA"}
                        </td>
                        <td>
                          <select
                            style={{ border: 0, width: "100%" }}
                            name="type"
                            className="form-control"
                            // value={trade?.status}
                            required
                            onChange={(e) => {
                              changeStatus(index, e.target.value);
                            }}
                          >
                            {status.map((value, index) => (
                              <option key={index} value={value}>
                                {value}
                              </option>
                            ))}
                          </select>
                        </td>
                        <td>
                          <IconButton onClick={() => removeTrade()}>
                            <DeleteOutlineIcon />
                          </IconButton>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div
                  className="button-class"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <button
                    className="save-btn btn-primary hover-btn"
                    onClick={addTrades}
                  >
                    + Trades
                  </button>
                  <button
                    className="save-btn hover-btn"
                    onClick={() => handleUpdateCity(citydata, citydata._id)}
                  >
                    SAVE CHANGES
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default TradeForm;
