import { Grid, Paper } from "@material-ui/core";
import MultipleSelectTrade from "../multiSelect/MultiSelectTrades";
import TextError from "../formComponents/Error";

const AddEntrepreneurForm = ({
  Formik,
  Form,
  Field,
  FieldArray,
  ErrorMessage,
  formValues,
  initialValues,
  validationSchema,
  onSubmit,
  onSaveHanlder,
  stateList,
  setState,
  cityList,
  tradeList,
  setSubmitForm,
}) => {
  return (
    <Formik
      initialValues={formValues || initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {(formik) => {
        // console.log("Formik props", formik);
        const { setFieldValue } = formik;
        return (
          <Form>
            {/* Entrepreneur details */}
            <Paper>
              <Grid
                container
                spacing={4}
                style={{ padding: "1rem", marginBottom: "2rem" }}
              >
                <div className="card-title-2">
                  <h4>
                    <b>PARTICULARS</b>
                  </h4>
                </div>
                <Grid item xs={6}>
                  <div className="form-group">
                    <label htmlFor="name" className="form-label">
                      Full Name<span className="required">&#42;</span>
                    </label>
                    <Field
                      type="text"
                      id="name"
                      className="form-control"
                      name="name"
                      placeholder="Enter Full Name"
                    />
                    <ErrorMessage name="name" component={TextError} />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="form-group">
                    <label htmlFor="email" className="form-label">
                      Email Address<span className="required">&#42;</span>
                    </label>
                    <Field
                      className="form-control"
                      type="email"
                      placeholder="Enter Email Address"
                      name="email"
                    />
                    <ErrorMessage name="email" component={TextError} />
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div className="form-group">
                    <label htmlFor="phone" className="form-label">
                      Phone Number<span className="required">&#42;</span>
                    </label>
                    <Field
                      className="form-control"
                      type="number"
                      placeholder="Enter Your Phone Number"
                      name="phone"
                    />
                    <ErrorMessage name="phone" component={TextError} />
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div className="form-group">
                    <label htmlFor="whatsappNumber" className="form-label">
                      WhatsApp Number<span className="required">&#42;</span>
                    </label>
                    <Field
                      className="form-control"
                      type="number"
                      placeholder="Enter Your WhatsApp Number"
                      name="whatsappNumber"
                    />
                    <ErrorMessage name="whatsappNumber" component={TextError} />
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div className="form-group">
                    <label htmlFor="alternateNumber" className="form-label">
                      Alternate Phone Number
                      <span className="required">&#42;</span>
                    </label>
                    <Field
                      className="form-control"
                      type="number"
                      placeholder="Enter Alternate Phone Number"
                      name="alternateNumber"
                    />
                    <ErrorMessage
                      name="alternateNumber"
                      component={TextError}
                    />
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div className="form-group">
                    <label htmlFor="state" className="form-label">
                      State<span className="required">&#42;</span>
                    </label>
                    <select
                      className="form-control"
                      name="state"
                      placeholder="Select State"
                      onChange={(e) => {
                        setFieldValue("state", e.target.value);
                        setState(e.target.value);
                      }}
                    >
                      <option selected>--Select State--</option>
                      {stateList
                        ? stateList?.states?.map((state, index) => (
                            <option key={index} value={state}>
                              {state}
                            </option>
                          ))
                        : "loading..."}
                    </select>
                    <ErrorMessage name="state" component={TextError} />
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div className="form-group">
                    <label htmlFor="city" className="form-label">
                      City<span className="required">&#42;</span>
                    </label>
                    <Field
                      as="select"
                      className="form-control"
                      name="city"
                      placeholder="Select city"
                    >
                      <option selected>--Select City--</option>
                      {cityList
                        ? cityList?.map((city, index) => (
                            <option key={index} value={city.district}>
                              {city.district}
                            </option>
                          ))
                        : "loading..."}
                    </Field>
                    <ErrorMessage name="city" component={TextError} />
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div className="form-group">
                    <label htmlFor="pincode" className="form-label">
                      Pin Code<span className="required">&#42;</span>
                    </label>
                    <Field
                      className="form-control"
                      type="number"
                      placeholder="Enter Pin Code"
                      name="pincode"
                    />
                    <ErrorMessage name="pincode" component={TextError} />
                  </div>
                </Grid>
                <Grid item xs={8}>
                  <div className="form-group">
                    <label htmlFor="completeAddress" className="form-label">
                      Address<span className="required">&#42;</span>
                    </label>
                    <div className="card card-editor">
                      <div className="content-editor">
                        <Field
                          as="textarea"
                          style={{ height: "5rem" }}
                          className="text-control"
                          placeholder="Enter Address"
                          name="completeAddress"
                        />
                        <ErrorMessage
                          name="completeAddress"
                          component={TextError}
                        />
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div className="form-group">
                    <label htmlFor="status" className="form-label">
                      Status<span className="required">&#42;</span>
                    </label>
                    <Field as="select" className="form-control" name="status">
                      <option selected>--Select Status--</option>
                      <option value="Active">Active</option>
                      <option value="Inactive">Inactive</option>
                      <option value="Draft">Draft</option>
                    </Field>
                    <ErrorMessage name="status" component={TextError} />
                  </div>
                </Grid>
                <Grid item xs={5}>
                  <div className="form-group">
                    <label htmlFor="photoUrl" className="form-label">
                      {" "}
                      Photo<span className="required">&#42;</span>
                    </label>
                    <input
                      className="form-control"
                      type="file"
                      placeholder="upload photo"
                      required
                      name="photoUrl"
                      onChange={(e) => {
                        setFieldValue("photoUrl", e.target.files[0]);
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={5}>
                  <div className="form-group">
                    <label htmlFor="activatedFrom" className="form-label">
                      Activated from<span className="required">&#42;</span>
                    </label>
                    <input
                      className="form-control"
                      type="date"
                      placeholder="select date"
                      name="activatedFrom"
                      onChange={(e) => {
                        setFieldValue("activatedFrom", e.target.value);
                      }}
                    />
                    <span
                      className="errorMessage"
                      style={{ fontSize: "0.9rem" }}
                    >
                      {formik.errors.activatedFrom}
                    </span>
                  </div>
                </Grid>
              </Grid>
            </Paper>

            {/* KYC */}
            <Paper>
              <Grid
                container
                spacing={4}
                style={{ padding: "1rem", marginBottom: "2rem" }}
              >
                <div className="card-title-2">
                  <h4>
                    <b>KYC</b>
                  </h4>
                </div>
                <Grid item xs={6}>
                  <div className="form-group">
                    <label htmlFor="kyc.aadhar.number" className="form-label">
                      Aadhar Number<span className="required">&#42;</span>
                    </label>
                    <div className="content-editor">
                      <Field
                        className="form-control"
                        type="number"
                        placeholder="Enter Aadhar Number"
                        name="kyc.aadhar.number"
                      />
                      <ErrorMessage
                        name="kyc.aadhar.number"
                        component={TextError}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="form-group">
                    <label htmlFor="kyc.aadhar.imageUrl" className="form-label">
                      Upload Aadhar Card<span className="required">&#42;</span>{" "}
                      (containing both front and back)
                    </label>
                    <input
                      className="form-control"
                      type="file"
                      required
                      placeholder="upload Aadhar Front"
                      name="kyc.aadhar.imageUrl"
                      onChange={(e) => {
                        setFieldValue("kyc.aadhar.imageUrl", e.target.files[0]);
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="form-group">
                    <label htmlFor="kyc.pan.number" className="form-label">
                      PAN Number<span className="required">&#42;</span>
                    </label>
                    <div className="content-editor">
                      <Field
                        className="form-control"
                        type="text"
                        placeholder="Enter PAN Number"
                        name="kyc.pan.number"
                      />
                      <ErrorMessage
                        name="kyc.pan.number"
                        component={TextError}
                      />
                    </div>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="form-group">
                    <label htmlFor="kyc.pan.imageUrl" className="form-label">
                      Upload PAN Card<span className="required">&#42;</span>
                    </label>
                    <input
                      className="form-control"
                      type="file"
                      required
                      placeholder="upload PAN Card"
                      name="kyc.pan.imageUrl"
                      onChange={(e) => {
                        setFieldValue("kyc.pan.imageUrl", e.target.files[0]);
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
            </Paper>

            {/* REGISTRATION  */}

            <Paper style={{ padding: "1rem", marginBottom: "2rem" }}>
              <Grid container spacing={4}>
                <div className="card-title-2">
                  <h4>
                    <b>REGISTRATION</b>
                  </h4>
                </div>
                <Grid item xs={6}>
                  <div className="form-group">
                    <label htmlFor="registration.entity" className="form-label">
                      Entity<span className="required">&#42;</span>
                    </label>
                    <Field
                      as="select"
                      className="form-control"
                      name="registration.entity"
                    >
                      <option selected>--Select Entity--</option>
                      <option value="Yet to be registered">
                        Yet to be registered
                      </option>
                      <option value="Proprietorship">Proprietorship</option>
                      <option value="Private Limited">Private Limited</option>
                      <option value="Partnership">Partnership</option>
                    </Field>
                    <ErrorMessage
                      name="registration.entity"
                      component={TextError}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="form-group">
                    <label
                      htmlFor="registration.registrationProofUrl"
                      className="form-label"
                    >
                      Registration Proof<span className="required">&#42;</span>
                    </label>
                    <input
                      className="form-control"
                      type="file"
                      required
                      placeholder="upload Registration Proof"
                      name="registration.registrationProofUrl"
                      onChange={(e) => {
                        setFieldValue(
                          "registration.registrationProofUrl",
                          e.target.files[0]
                        );
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div className="form-group">
                    <label
                      htmlFor="registration.gst.status"
                      className="form-label"
                    >
                      {" "}
                      Select GST Status<span className="required">&#42;</span>
                    </label>
                    <Field
                      as="select"
                      className="form-control"
                      name="registration.gst.status"
                    >
                      <option disabled>select GST status</option>
                      <option value="Unavailable">Unavailable</option>
                      <option value="Available">Available</option>
                    </Field>
                    <ErrorMessage
                      name="registration.gst.status"
                      component={TextError}
                    />
                  </div>
                </Grid>
                <Grid item xs={8}>
                  <div className="form-group">
                    <label
                      htmlFor="registration.gst.gstCertificateUrl"
                      className="form-label"
                    >
                      GST Proof<span className="required">&#42;</span>
                    </label>
                    <input
                      className="form-control"
                      type="file"
                      disabled={
                        formik.values.registration.gst.status === "Unavailable"
                      }
                      required={
                        formik.values.registration.gst.status === "Available"
                      }
                      placeholder="upload GST Proof"
                      name="registration.gst.gstCertificateUrl"
                      onChange={(e) => {
                        setFieldValue(
                          "registration.gst.gstCertificateUrl",
                          e.target.files[0]
                        );
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="form-group">
                    <label
                      htmlFor="registration.entityPanNumber"
                      className="form-label"
                    >
                      Entity PAN Number<span className="required">&#42;</span>
                    </label>
                    <div className="card card-editor">
                      <div className="content-editor">
                        <Field
                          className="form-control"
                          type="text"
                          placeholder="Enter Entity PAN Number"
                          name="registration.entityPanNumber"
                        />
                        <ErrorMessage
                          name="registration.entityPanNumber"
                          component={TextError}
                        />
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="form-group">
                    <label
                      htmlFor="registration.entityPanUrl"
                      className="form-label"
                    >
                      Upload Entity PAN Card
                      <span className="required">&#42;</span>
                    </label>
                    <input
                      className="form-control"
                      type="file"
                      required
                      placeholder="Upload Entity PAN Card"
                      name="registration.entityPanUrl"
                      onChange={(e) => {
                        setFieldValue(
                          "registration.entityPanUrl",
                          e.target.files[0]
                        );
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="form-group">
                    <label
                      htmlFor="registration.bankDetails.accountNumber"
                      className="form-label"
                    >
                      Account No<span className="required">&#42;</span>
                    </label>
                    <div className="card card-editor">
                      <div className="content-editor">
                        <Field
                          className="form-control"
                          type="text"
                          placeholder="Enter Account Number"
                          name="registration.bankDetails.accountNumber"
                        />
                        <ErrorMessage
                          name="registration.bankDetails.accountNumber"
                          component={TextError}
                        />
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div className="form-group">
                    <label
                      htmlFor="registration.bankDetails.accountName"
                      className="form-label"
                    >
                      Account Holder Name<span className="required">&#42;</span>
                    </label>
                    <div className="card card-editor">
                      <div className="content-editor">
                        <Field
                          className="form-control"
                          type="text"
                          placeholder="Enter Account Holder Name"
                          name="registration.bankDetails.accountName"
                        />
                        <ErrorMessage
                          name="registration.bankDetails.accountName"
                          component={TextError}
                        />
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div className="form-group">
                    <label
                      htmlFor="registration.bankDetails.accountType"
                      className="form-label"
                    >
                      Account Type<span className="required">&#42;</span>
                    </label>
                    <Field
                      as="select"
                      className="form-control"
                      name="registration.bankDetails.accountType"
                    >
                      <option selected>--Select Account Type--</option>
                      <option value="Savings">Savings</option>
                      <option value="Current">Current</option>
                      <option value="Salary">Salary</option>
                      <option value="Fixed deposit">Fixed deposit</option>
                    </Field>
                    <ErrorMessage
                      name="registration.bankDetails.accountType"
                      component={TextError}
                    />
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div className="form-group">
                    <label
                      htmlFor="registration.bankDetails.ifsc"
                      className="form-label"
                    >
                      IFSC Code<span className="required">&#42;</span>
                    </label>
                    <div className="card card-editor">
                      <div className="content-editor">
                        <Field
                          className="form-control"
                          type="text"
                          placeholder="Enter IFSC Code"
                          name="registration.bankDetails.ifsc"
                        />
                        <ErrorMessage
                          name="registration.bankDetails.ifsc"
                          component={TextError}
                        />
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <div className="form-group">
                    <label
                      htmlFor="registration.bankDetails.bankName"
                      className="form-label"
                    >
                      Bank Name<span className="required">&#42;</span>
                    </label>
                    <div className="card card-editor">
                      <div className="content-editor">
                        <Field
                          className="form-control"
                          type="text"
                          placeholder="Enter Bank Name"
                          name="registration.bankDetails.bankName"
                        />
                        <ErrorMessage
                          name="registration.bankDetails.bankName"
                          component={TextError}
                        />
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Paper>

            {/* ASSIGNMENT */}
            <Paper variant="outlined">
              <div
                className="container-fluid"
                style={{ padding: "1rem", marginBottom: "2rem" }}
              >
                <div className="card-title-2">
                  <h4>
                    <b>ASSIGNMENT</b>
                  </h4>
                </div>

                <FieldArray
                  name="assignment"
                  className="row"
                  render={(arrayHelpers) => {
                    const assignments = formik.values.assignment;
                    // console.log("assignment", assignments);
                    return (
                      <>
                        {assignments && assignments.length > 0
                          ? assignments.map((assignment, index) => (
                              <div
                                key={index}
                                className="row"
                                style={{ marginLeft: "0.8%" }}
                              >
                                <div
                                  className="col-lg-4 col-md-4"
                                  style={{ paddingTop: "1rem" }}
                                >
                                  <div className="form-group">
                                    <label
                                      htmlFor={`assignment[${index}].cluster`}
                                      className="form-label"
                                    >
                                      Select Cluster
                                      <span className="required">&#42;</span>
                                    </label>
                                    <Field
                                      as="select"
                                      className="form-control"
                                      name={`assignment[${index}].cluster`}
                                    >
                                      <option selected>
                                        --Select Cluster--
                                      </option>
                                      <option value="cluster1">c1</option>
                                      <option value="cluster2">c2</option>
                                    </Field>
                                    <ErrorMessage
                                      name={`assignment[${index}].cluster`}
                                      component={TextError}
                                    />
                                  </div>
                                </div>

                                <div
                                  className="col-lg-3 col-md-3"
                                  style={{ paddingTop: "1rem" }}
                                >
                                  <MultipleSelectTrade
                                    setFieldValue={setFieldValue}
                                    name={`assignment[${index}].trades`}
                                    tradeIds={assignments[index].trades}
                                    tradeList={tradeList}
                                    style={{ marginLeft: "2%" }}
                                  />
                                  <ErrorMessage
                                    name={`assignment[${index}].cluster`}
                                    component={TextError}
                                  />
                                </div>

                                <div
                                  className="col-lg-3 col-md-3"
                                  style={{
                                    paddingTop: "1rem",
                                    marginLeft: "2%",
                                  }}
                                >
                                  <div className="form-group">
                                    <label
                                      htmlFor="activatedFrom"
                                      className="form-label"
                                    >
                                      Activated from
                                      <span className="required">&#42;</span>
                                    </label>
                                    <input
                                      className="form-control"
                                      type="date"
                                      placeholder="select date"
                                      name={`assignment[${index}].addedOn`}
                                      onChange={(e) => {
                                        setFieldValue(
                                          `assignment[${index}].addedOn`,
                                          e.target.value
                                        );
                                      }}
                                    />
                                  </div>
                                  {/* <span
                                    className="errorMessage"
                                    style={{ fontSize: "0.9rem" }}
                                  >
                                    {formik.errors?.assignment[index]?.addedOn}
                                  </span> */}
                                </div>

                                {assignments?.length > 1 ? (
                                  <button
                                    type="button"
                                    className="remove-btn hvr-btn"
                                    onClick={() => arrayHelpers.remove(index)}
                                    style={{
                                      marginTop: "3.8%",
                                    }}
                                  >
                                    Remove
                                  </button>
                                ) : null}
                              </div>
                            ))
                          : null}
                        <button
                          type="button"
                          className="save-btn hover-btn"
                          style={{ marginTop: "1.3%" }}
                          onClick={() => {
                            arrayHelpers.push({
                              cluster: "",
                              trades: [],
                              addedOn: "",
                            });
                            return;
                          }}
                        >
                          Add More
                        </button>
                      </>
                    );
                  }}
                />
              </div>
              <Grid>
                <button
                  type="button"
                  style={{
                    display: "inline-block",
                    marginLeft: "80.3%",
                    marginBottom: "2%",
                  }}
                  disabled={!formik.values.phone}
                  className="save-btn hover-btn"
                  onClick={() => {
                    formik.setFieldTouched("phone");
                    formik.validateField("phone");
                    onSaveHanlder(formik.values);
                  }}
                >
                  Save
                </button>
                <button
                  style={{ display: "inline-block", marginBottom: "2%" }}
                  className="save-btn hover-btn"
                  type="submit"
                  onClick={() => setSubmitForm(true)}
                  disabled={
                    !formik.values.assignment[0]?.addedOn ||
                    !formik.isValid ||
                    formik.isSubmitting
                  }
                >
                  {formik.isSubmitting ? "Submitting..." : "Submit"}
                </button>
              </Grid>
            </Paper>
            {/* end */}
          </Form>
        );
      }}
    </Formik>
  );
};

export default AddEntrepreneurForm;
