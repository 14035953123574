// import { datadump } from "../../../actions/userAction";
import { Link } from "react-router-dom";
const ReferralTable = () => {
  return (
    <div className="col-lg-12 col-md-11">
      <div className="all-cate-tags">
        <div className="row justify- content-between">
          <div className="col-lg-12 col-md-12">
            <div className="card card-static-2 mb-30">
              <div className="card-body-table">
                <div className="table-responsive">
                  <table className="table ucp-table table-hover">
                    <thead>
                      <tr>
                        <th>S.no.</th>
                        <th>Referral shared with</th>
                        <th>Coins earned through Referral</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1.</td>
                        <td>
                          <Link to="/consumers">consumer name</Link>
                        </td>
                        <td>50</td>
                      </tr>
                      <tr>
                        <td>2.</td>
                        <td>
                          <Link to="/consumers">consumer name</Link>
                        </td>
                        <td>50</td>
                      </tr>
                      <tr className="bold-table-text">
                        <th></th>
                        <th>Grand Total</th>
                        <th>100</th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferralTable;
