import { Link } from "react-router-dom";
import "../login.css";

const ForgotPasswordForm = ({email, setEmail, handleSubmit}) => {
  return (
    <div id="layoutSidenav_content">
      <div>
        <div role="document">
          <div className="modal-content">
            <div className="modal-body">
              <div className="login-modal">
                <div className="row">
                  <div className="col-lg-11 pad-right-0">
                    <div className="login-modal-left"></div>
                  </div>
                  <div className="col-lg-7 pad-left-0">
                    <form onSubmit={handleSubmit}>
                      <div className="login-modal-right">
                        <div className="tab-content">
                          <div
                            className="tab-pane active"
                            id="login"
                            role="tabpanel"
                          >
                            <h5 className="heading-design-h5">
                              Forgot Password
                            </h5>
                            <fieldset className="form-group">
                              <label>Enter Email</label>
                              <input
                                type="email"
                                className="form-control"
                                name="email"
                                required
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                              />
                            </fieldset>

                            <fieldset
                              className="form-group"
                              style={{ paddingTop: "1rem" }}
                            >
                              <button
                                type="submit"
                                className="btn btn-lg btn-secondary btn-block"
                                disabled={!email}
                              >
                                Verify your email
                              </button>
                            </fieldset>
                          </div>
                        </div>

                        <div
                          className="clearfix"
                          style={{ paddingTop: "1rem" }}
                        />
                        <div className="text-center login-footer-tab">
                          <ul className="nav nav-tabs" role="tablist">
                            <li className="nav-item">
                              <Link
                                className="nav-link active"
                                data-toggle="tab"
                                to="/"
                                role="tab"
                              >
                                <i className="mdi mdi-lock" /> LOGIN
                              </Link>
                            </li>
                            {/* <li className="nav-item">
                              <Link
                                className="nav-link"
                                data-toggle="tab"
                                to="/reset-forgot-password"
                                role="tab"
                              >
                                <i className="mdi mdi-pencil" /> FORGOT PASSWORD
                              </Link>
                            </li> */}
                          </ul>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordForm;
