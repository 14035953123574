/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useHistory, Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import AddEntrepreneurForm from "./AddEntrepreneurForm";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import { validationSchema } from "../validationSchema/EntrepreneurValidation";
import {
  addNewEntrepreneur,
  saveNewEntrepreneur,
} from "../../../actions/entrepreneurActions";
import { allStates, stateCities } from "../../../actions/entrepreneurActions";
import { allTrades } from "../../../actions/tradeActions";

const initialValues = {
  name: "",
  state: "",
  city: "",
  phone: "",
  whatsappNumber: "",
  alternateNumber: "",
  completeAddress: "",
  email: "",
  pincode: "",
  photoUrl: "",
  status: "Active",
  activatedFrom: "",
  kyc: {
    aadhar: {
      number: "",
      imageUrl: "",
    },
    pan: {
      number: "",
      imageUrl: "",
    },
  },
  registration: {
    entity: "",
    registrationProofUrl: "",
    gst: {
      status: "Unavailable",
      gstCertificateUrl: "",
    },
    entityPanNumber: "",
    entityPanUrl: "",
    bankDetails: {
      bankName: "",
      accountName: "",
      accountNumber: "",
      ifsc: "",
      accountType: "",
    },
  },
  assignment: [{ cluster: "", trades: [], addedOn: "" }],
};

const AddEntrepreneur = () => {
  const [submitForm, setSubmitForm] = useState(false);
  const { auth } = useSelector((state) => ({ ...state }));
  const { token } = auth;
  const [state, setState] = useState("");
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [tradeList, setTradeList] = useState([]);

  const fetchAllStates = async () => {
    let res = await allStates(token);
    setStateList(res.data);
  };

  const fetchAllCities = async () => {
    if (!state) return;
    let res = await stateCities(token, state);
    setCityList(res.data.cities);
  };

  const fetchAllTrades = async () => {
    let res = await allTrades(token);
    setTradeList(res.data);
  };

  useEffect(() => {
    fetchAllTrades();
  }, []);

  useEffect(() => {
    fetchAllStates();
    fetchAllCities();
  }, [state]);

  const onSaveHanlder = async (values) => {
    // e.preventDefault();
    // console.log("values", values)
    let { assignment } = values;
    // console.log("assignment", assignment)

    let entrepreneurData = new FormData();
    entrepreneurData.append("name", values.name);
    entrepreneurData.append("state", values.state);
    entrepreneurData.append("city", values.city);
    entrepreneurData.append("phone", values.phone);
    entrepreneurData.append("whatsappNumber", values.whatsappNumber);
    entrepreneurData.append("alternateNumber", values.alternateNumber);
    entrepreneurData.append("completeAddress", values.completeAddress);
    entrepreneurData.append("email", values.email);
    entrepreneurData.append("pincode", values.pincode);
    values.photoUrl && entrepreneurData.append("profileImage", values.photoUrl);
    entrepreneurData.append("status", values.status);
    entrepreneurData.append("activatedFrom", values.activatedFrom);
    entrepreneurData.append(`kyc.aadhar.number`, values.kyc.aadhar.number);
    values.kyc.aadhar.imageUrl &&
      entrepreneurData.append(`aadharImage`, values.kyc.aadhar.imageUrl);
    entrepreneurData.append(`kyc.pan.number`, values.kyc.pan.number);
    values.kyc.pan.imageUrl &&
      entrepreneurData.append(`panImage`, values.kyc.pan.imageUrl);
    entrepreneurData.append(`registration.entity`, values.registration.entity);
    entrepreneurData.append(
      `registrationProof`,
      values.registration.registrationProofUrl
    );
    entrepreneurData.append(
      `registration.gst.status`,
      values.registration.gst.status
    );
    values.registration.gst.status === "Available" &&
      entrepreneurData.append(
        `gstCertificate`,
        values.registration.gst.gstCertificateUrl
      );
    entrepreneurData.append(
      `registration.entityPanNumber`,
      values.registration.entityPanNumber
    );
    entrepreneurData.append(`entityPanImage`, values.registration.entityPanUrl);
    entrepreneurData.append(
      `registration.bankDetails.bankName`,
      values.registration.bankDetails.bankName
    );
    entrepreneurData.append(
      `registration.bankDetails.accountName`,
      values.registration.bankDetails.accountName
    );
    entrepreneurData.append(
      `registration.bankDetails.accountNumber`,
      values.registration.bankDetails.accountNumber
    );
    entrepreneurData.append(
      `registration.bankDetails.ifsc`,
      values.registration.bankDetails.ifsc
    );
    entrepreneurData.append(
      `registration.bankDetails.accountType`,
      values.registration.bankDetails.accountType
    );

    // for (var i = 0; i < assignment.length; i++) {
    //   entrepreneurData.append(`registration.assignment`, assignment[i]);
    // }
    entrepreneurData.append("assignment", JSON.stringify(assignment));
    // console.log([...entrepreneurData]);

    try {
      await saveNewEntrepreneur(token, entrepreneurData);
      toast.success(`saved Successfully...`);
      history.push("/homies");
      // redirect();
      // onSubmitProps.setSubmitting(false);
      // onSubmitProps.resetForm();
    } catch (err) {
      // toast.error(err.response.data.errorMessage);
      console.log("err====>", err);
    }
  };

  const onSubmit = async (values, onSubmitProps) => {
    // e.preventDefault();
    let { assignment } = values;

    let entrepreneurData = new FormData();
    entrepreneurData.append("name", values.name);
    entrepreneurData.append("state", values.state);
    entrepreneurData.append("city", values.city);
    entrepreneurData.append("phone", values.phone);
    entrepreneurData.append("whatsappNumber", values.whatsappNumber);
    entrepreneurData.append("alternateNumber", values.alternateNumber);
    entrepreneurData.append("completeAddress", values.completeAddress);
    entrepreneurData.append("email", values.email);
    entrepreneurData.append("pincode", values.pincode);
    values.photoUrl && entrepreneurData.append("profileImage", values.photoUrl);
    entrepreneurData.append("status", values.status);
    entrepreneurData.append("activatedFrom", values.activatedFrom);
    entrepreneurData.append(`kyc.aadhar.number`, values.kyc.aadhar.number);
    values.kyc.aadhar.imageUrl &&
      entrepreneurData.append(`aadharImage`, values.kyc.aadhar.imageUrl);
    entrepreneurData.append(`kyc.pan.number`, values.kyc.pan.number);
    values.kyc.pan.imageUrl &&
      entrepreneurData.append(`panImage`, values.kyc.pan.imageUrl);
    entrepreneurData.append(`registration.entity`, values.registration.entity);
    entrepreneurData.append(
      `registrationProof`,
      values.registration.registrationProofUrl
    );
    entrepreneurData.append(
      `registration.gst.status`,
      values.registration.gst.status
    );
    values.registration.gst.status === "Available" &&
      entrepreneurData.append(
        `gstCertificate`,
        values.registration.gst.gstCertificateUrl
      );
    entrepreneurData.append(
      `registration.entityPanNumber`,
      values.registration.entityPanNumber
    );
    entrepreneurData.append(`entityPanImage`, values.registration.entityPanUrl);
    entrepreneurData.append(
      `registration.bankDetails.bankName`,
      values.registration.bankDetails.bankName
    );
    entrepreneurData.append(
      `registration.bankDetails.accountName`,
      values.registration.bankDetails.accountName
    );
    entrepreneurData.append(
      `registration.bankDetails.accountNumber`,
      values.registration.bankDetails.accountNumber
    );
    entrepreneurData.append(
      `registration.bankDetails.ifsc`,
      values.registration.bankDetails.ifsc
    );
    entrepreneurData.append(
      `registration.bankDetails.accountType`,
      values.registration.bankDetails.accountType
    );
    entrepreneurData.append("assignment", JSON.stringify(assignment));

    try {
      if (!submitForm) return;
      await addNewEntrepreneur(token, entrepreneurData);
      toast.success(`Submitted Successfully...`);
      onSubmitProps.setSubmitting(false);
      onSubmitProps.resetForm();
      history.push("/homies");
    } catch (err) {
      toast.error(err.response.data.errorMessage);
    }
  };

  const history = useHistory();
  const redirect = () => {
    history.push("/homies");
  };

  return (
    <div id="layoutSidenav_content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6">
            <h2 className="mt-30 page-title">Entrepreneur</h2>
          </div>
          <div className="col-md-6 back-btn">
            <Button variant="contained" onClick={redirect}>
              <i className="fas fa-arrow-left" /> Back
            </Button>
          </div>
        </div>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Dashboard</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/homies">Entrepreneurs</Link>
          </li>
          <li className="breadcrumb-item active">Add Entrepreneur</li>
        </ol>
        <AddEntrepreneurForm
          Formik={Formik}
          Form={Form}
          Field={Field}
          ErrorMessage={ErrorMessage}
          FieldArray={FieldArray}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          onSaveHanlder={onSaveHanlder}
          stateList={stateList}
          tradeList={tradeList}
          cityList={cityList}
          setState={setState}
          setSubmitForm={setSubmitForm}
        />
      </div>
    </div>
  );
};

export default AddEntrepreneur;
