import TableHeader from "../../0-ResuableComponents/tableComponents/TableHeader";
import moment from "moment";

const PriceLogsTable = ({ entrepreneur, setSorting, variant }) => {
  // console.log("entrepreneur===>", entrepreneur);
  // console.log("variant===>", variant);

  const headers = [
    { name: "Price to Customer (INR)", field: "name", sortable: false },
    { name: "Price Last Updated On", field: "name", sortable: false },
    { name: "Price Last Updated By", field: "name", sortable: false },
  ];

  return (
    <div className="col-lg-12 col-md-12" style={{ userSelect: "auto" }}>
      <div className="card card-static-2 mt-30 mb-30">
        <div className="card-body-table">
          <div className="table-responsive">
            <table className="table ucp-table table-hover">
              <TableHeader
                headers={headers}
                onSorting={(field, order) => setSorting({ field, order })}
              />
              <tbody>
                {/* {entrepreneur?.assignment?.map((assignment) => ( */}
                <tr>
                  <td> &#x20B9; {variant?.price?.mrp}</td>
                  <td>
                    {moment(variant?.metaInfo?.updated?.at).format("lll")}
                  </td>
                  <td>{variant?.metaInfo?.updated?.by?.name}</td>
                </tr>
                {/* ))} */}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PriceLogsTable;
