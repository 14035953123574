import ConfirmDialog from "../0-ResuableComponents/confirmDialog/ConfirmDialog";
import { Modal } from "react-bootstrap";

const AddNewAdminForm = ({
  admin,
  setAdmin,
  handleSubmit,
  currentId,
  show,
  handleClose,
  confirmDialog,
  setConfirmDialog,
}) => {
  const { name, email, role, status, mobileNumber } = admin;

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <div>
        <Modal.Header closeButton data-dismiss="modal">
          <h5 className="modal-title" id="exampleModalLabel">
            {currentId ? "Update Admin" : "+ Add New Admin"}
          </h5>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="card card-static-2 mb-30">
                  <div className="card-body-table">
                    <div className="news-content-right pd-20">
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div
                            className="col-lg-12 col-md-11"
                            style={{ paddingTop: "1rem" }}
                          >
                            <div className="form-group">
                              <label htmlFor="name" className="form-label">
                                Name<span className="required">&#42;</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Admin Name"
                                required
                                name="name"
                                value={name}
                                onChange={(e) =>
                                  setAdmin({ ...admin, name: e.target.value })
                                }
                              />
                            </div>
                          </div>

                          <div
                            className="col-lg-12 col-md-11"
                            style={{ paddingTop: "1rem" }}
                          >
                            <div className="form-group">
                              <label htmlFor="name" className="form-label">
                                Email<span className="required">&#42;</span>
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                placeholder="Email Address"
                                required
                                name="email"
                                value={email}
                                onChange={(e) =>
                                  setAdmin({
                                    ...admin,
                                    email: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>

                          <div
                            className="col-lg-12 col-md-11"
                            style={{ paddingTop: "1rem" }}
                          >
                            <div className="form-group">
                              <label
                                htmlFor="mobileNumber"
                                className="form-label"
                              >
                                phone Number
                                <span className="required">&#42;</span>
                              </label>
                              <input
                                type="tel"
                                className="form-control"
                                placeholder="Phone Number"
                                required
                                maxLength="10"
                                name="mobileNumber"
                                value={mobileNumber}
                                onChange={(e) =>
                                  setAdmin({
                                    ...admin,
                                    mobileNumber: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>

                          <div
                            className="col-lg-6 col-md-5"
                            style={{ paddingTop: "1rem" }}
                          >
                            <div className="form-group">
                              <label htmlFor="role" className="form-label">
                                Role<span className="required">&#42;</span>
                              </label>
                              <select
                                name="role"
                                className="form-control"
                                value={role}
                                required
                                onChange={(e) =>
                                  setAdmin({ ...admin, role: e.target.value })
                                }
                              >
                                <option disabled>Select Role</option>
                                <option value="Admin">Admin</option>
                                <option value="Super Admin">Super Admin</option>
                              </select>
                            </div>
                          </div>

                          <div
                            className="col-lg-16 col-md-5"
                            style={{ paddingTop: "1rem" }}
                          >
                            <div className="form-group">
                              <label htmlFor="status" className="form-label">
                                Status<span className="required">&#42;</span>
                              </label>
                              <select
                                name="status"
                                className="form-control"
                                value={status}
                                required
                                onChange={(e) =>
                                  setAdmin({
                                    ...admin,
                                    status: e.target.value,
                                  })
                                }
                              >
                                <option disabled>Select status</option>
                                <option value="Active">Active</option>
                                <option value="Inactive">Inactive</option>
                              </select>
                            </div>
                          </div>

                          <button className="save-btn hover-btn" type="submit">
                            {currentId ? "Update" : "Add New"}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </div>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Modal>
  );
};

export default AddNewAdminForm;
