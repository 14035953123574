import { Modal } from "react-bootstrap";

const ServiceForm = ({
  service,
  setService,
  handleSubmit,
  handleChange,
  currentId,
  show,
  handleClose,
  serviceCategory
}) => {
  
  const { name, service_category_id,frequency_in_days } = service;
 
 
 
  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <div>
        <Modal.Header closeButton data-dismiss="modal">
          <h5 className="modal-title" id="exampleModalLabel">
            {currentId ? "Update Service" : "+ Add New Service"}
          </h5>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="card card-static-2 mb-30">
                  <div className="card-body-table">
                    <div className="news-content-right pd-20">
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div
                            className="col-lg-12 col-md-11"
                            style={{ paddingTop: "1rem" }}
                          >
                            <div className="form-group">
                              <label htmlFor="name" className="form-label">
                                Service Name
                                <span className="required">&#42;</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Service Name"
                                required
                                name="name"
                                value={name}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-lg-12 col-md-11" 
                          style={{ paddingTop: "1rem" }}>
                            <div className="form-group">
                              <label htmlFor="serviceCategory" className="form-label">
                                Service Categories<span className="required">&#42;</span>
                              </label>
                              <select
                                name="service_category_id"
                                className="form-control"
                                onChange={handleChange}
                                value={service_category_id}
                                // required
                              >
                                <option value="">None</option>
                                {serviceCategory
                                  ? serviceCategory?.map((service, index) => (
                                      <option key={index} value={service._id}>
                                        {service?.name}
                                      </option>
                                    ))
                                  : "loading..."}
                              </select>
                            </div>
                          </div>
                          <div
                            className="col-lg-12 col-md-11"
                            style={{ paddingTop: "1rem" }}
                          >
                            <div className="form-group">
                              <label htmlFor="frequency_in_days" className="form-label">
                                Frequency(In days)
                                <span className="required">&#42;</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Frequency(In days)"
                                required
                                name="frequency_in_days"
                                value={frequency_in_days}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                         
                          <button className="save-btn hover-btn" type="submit" style={{ marginTop: "1rem" }}>
                            {currentId ? "Update" : "Add New"}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default ServiceForm;
