import DeleteIcon from "@material-ui/icons/Delete";
import { Link } from "react-router-dom";
import moment from "moment";
import TableHeader from "../../0-ResuableComponents/tableComponents/TableHeader";

const headers = [
  { name: "Product", field: "name", sortable: true },
  { name: "Product ID", field: "id", sortable: false },
  { name: "Variants", field: "variants", sortable: false },
  { name: "Created By", field: "CreatedBy", sortable: false },
  { name: "Created At", field: "CreatedAt", sortable: false },
  { name: "Last Updated By", field: "LastUpdatedBy", sortable: false },
  { name: "Last Updated At", field: "LastUpdatedAt", sortable: false },
  { name: "Edit", field: "Edit", sortable: false },
  { name: "Delete", field: "Delete", sortable: false },
];

const ProductTable = ({
  productList,
  setCurrentId,
  handleProductDelete,
  setConfirmDialog,
  setSorting,
  handleShow,
}) => {
  // console.log("product List=====>", productList);
  return (
    <div className="col-lg-12 col-md-11">
      <div className="all-cate-tags">
        <div className="row justify- content-between">
          <div className="col-lg-12 col-md-12">
            <div className="card card-static-2 mb-30">
              <div className="card-title-2">
                <h4>All Products</h4>
              </div>
              <div className="card-body-table">
                <div className="table-responsive">
                  <table className="table ucp-table table-hover">
                    <TableHeader
                      headers={headers}
                      onSorting={(field, order) => setSorting({ field, order })}
                    />
                    <tbody>
                      {productList?.map((product) => (
                        <tr key={product._id}>
                          <td>{product.name}</td>
                          <td>{product.id}</td>
                          <td>
                            <Link
                              to={`/catalog/products/variants/${product.id}`}
                            >
                              {product?.variants?.filter(
                                (variant) => !variant.isDeleted
                              ).length > 1
                                ? `${
                                    product?.variants?.filter(
                                      (variant) => !variant.isDeleted
                                    ).length
                                  } Variants`
                                : `${
                                    product?.variants?.filter(
                                      (variant) => !variant.isDeleted
                                    ).length
                                  } Variant`}
                            </Link>
                          </td>
                          <td>{product.metaInfo.created?.by?.name}</td>
                          <td>
                            {moment(product.metaInfo.created.at).format("lll")}
                          </td>
                          <td>{product.metaInfo.updated?.by?.name}</td>
                          <td>
                            {moment(product.metaInfo.updated.at).format("lll")}
                          </td>
                          <td className="action-btns">
                            <i
                              className="fas fa-edit"
                              data-toggle="modal"
                              data-target="#exampleModal"
                              data-whatever="@mdo"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setCurrentId(product._id);
                                handleShow();
                              }}
                            />
                          </td>
                          <td>
                            <DeleteIcon
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setConfirmDialog({
                                  isOpen: true,
                                  title: "Are you sure to delete this record?",
                                  subTitle: "You can't undo this operation",
                                  onConfirm: () => {
                                    handleProductDelete(product._id);
                                  },
                                });
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductTable;
