const NoOrder = () => {
  return (
    <div className="col-md-auto d-block">
      <div className="card">
        <div className="card-body lead">The user has made No Orders yet </div>
      </div>
    </div>
  );
};

export default NoOrder;
