/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import TableSearch from "../../0-ResuableComponents/tableComponents/TableSearch";
import ConfirmDialog from "../../0-ResuableComponents/confirmDialog/ConfirmDialog";
import Pagination from "../../0-ResuableComponents/pagination/Pagination";
import ProductForm from "./ProductForm";
import { serialize } from "object-to-formdata";
import ProductTable from "./ProductTable";
import { allBrands } from "../../../actions/brandActions";
import { allSubcategories } from "../../../actions/subCategoryActions";
import {
  createNewProduct,
  allProducts,
  updateProduct,
  deleteProduct,
} from "../../../actions/productActions";

const product_initState = {
  name: "",
  brandId: "",
  gender: "",
  subcategoryId: "",
  photoUrl: "",
  description: "",
  warranty:"",
  guarantee:"",
  returnConditions:"",
  productHandlingTips: {
    picking: "",
    ordering: "",
  },
};

const AddProduct = () => {
  const [product, setProduct] = useState(product_initState);
  const { auth } = useSelector((state) => ({ ...state }));
  const { token } = auth;
  const [productList, setProductList] = useState([]);
  const [currentId, setCurrentId] = useState(0);
  const [brandList, setBrandList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [search, setSearch] = useState("");
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [show, setShow] = useState(false);
  const handleClose = () => {setShow(false); clear()};
  const handleShow = () => setShow(true);

  const fetchAllProducts = async () => {
    let res = await allProducts(token);
    setProductList(res.data);
  };

  const fetchAllBrands = async () => {
    let res = await allBrands(token);
    setBrandList(res.data);
  };

  const fetchAllSubcategories = async () => {
    let res = await allSubcategories(token);
    setSubCategoryList(res.data);
  };

  const singleProduct = currentId
    ? productList.products.find((product) => product._id === currentId)
    : null;

  useEffect(() => {
    fetchAllProducts();
    fetchAllBrands();
    fetchAllSubcategories();
    if (singleProduct) setProduct(singleProduct);
  }, [currentId]);

  const productsData = useMemo(() => {
    let computedProducts = productList?.products;

    if (search) {
      computedProducts = computedProducts?.filter((product) =>
        product.name.toLowerCase().includes(search.toLowerCase())
      );
    }

    //Sorting products
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedProducts = computedProducts?.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    return computedProducts;
  }, [productList, search, sorting]);

  const clear = () => {
    setCurrentId(0);
    setProduct({
      name: "",
      brandId: "",
      subcategoryId: "",
      photoUrl: "",
      description: "",
      productHandlingTips: {
        picking: "",
        ordering: "",
      },
      warranty:"",
      guarantee:"",
      returnConditions:"",
    });
  };

  const {
    name,
    brandId,
    subcategoryId,
    gender,
    photoUrl,
    description,
    productHandlingTips,
    returnConditions,
    guarantee,
    warranty,
  } = product;
  let productData = new FormData();
  const object = {
    name,
    brandId,
    gender,
    subcategoryId,
    photoFile: photoUrl,
    description,
    warranty,
    guarantee,
    returnConditions,
    productHandlingTips  
  };

  
  const handleSubmit = async (e) => {
    e.preventDefault();
    // if (photoUrl) {
    //   productData.append(`photoUrl`, photoUrl);
    //   // return;
    // } else {
    // console.log("There is no photourl");
    // // return;
    // }
    console.log("gender from handleSubmit ==> ", gender);
    // let productData = new FormData();

    // productData.append("name", name);
    // productData.append("brandId", brandId);
    // productData.append("subcategoryId", subcategoryId);
    // productData.append("gender", gender);
    // photoUrl && productData.append("photoFile", photoUrl);
    // productData.append("description", description);
    // productData.append(
    //   `productHandlingTips.ordering`,
    //   productHandlingTips.ordering
    // );
    // productData.append(
    //   `productHandlingTips.picking`,
    //   productHandlingTips.picking
    // );
    // console.log([...productData]);
    const formData = serialize(object);
    try {
      if (currentId === 0) {
        await createNewProduct(token, formData);
        fetchAllProducts();
        toast.success(`New Product ${product.name} is added Successfully...`);
        clear();
        handleClose();
      } else {
        setConfirmDialog({
          isOpen: true,
          title: "Are you sure to Update this record?",
          subTitle: "You can't undo this operation",
          onConfirm: async () => {
            setConfirmDialog({
              ...confirmDialog,
              isOpen: false,
            });
            await updateProduct(token, formData, currentId)
              .then(() => {
                fetchAllProducts();
                toast.success(
                  `Product ${product.name} is updated Successfully...`
                );
                clear();
                handleClose();
              })
              .catch((err) => toast.error(err.response.data.errorMessage));
          },
        });
      }
    } catch (err) {
      toast.error(err.response.data.errorMessage);
    }
  };

  const handleImageChange = (e) => {
    setProduct({ ...product, photoUrl: e.target.files[0] });
  };

  const handleChange = (e) => {
    setProduct({ ...product, [e.target.name]: e.target.value });
  };

  const handleProductDelete = async (productId) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    deleteProduct(token, productId)
      .then((res) => {
        toast.success("Product Deleted successfully...");
        fetchAllProducts();
      })
      .catch((err) => toast.error(err.response.data.errorMessage));
  };

  // Get current rows
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = productsData?.slice(indexOfFirstRow, indexOfLastRow);
  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div id="layoutSidenav_content">
      <div className="container-fluid">
        <h2 className="mt-25 page-title">Products</h2>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Dashboard</Link>
          </li>
          <li className="breadcrumb-item active">Products</li>
        </ol>
        <div className="col-lg-12 col-md-12 back-btn">
          <button
            className="save-btn hover-btn"
            onClick={() => {
              setCurrentId(0);
              clear();
              handleShow();
            }}
          >
            + Add New Product
          </button>
        </div>
        <TableSearch
          onSearch={(value) => {
            setSearch(value);
          }}
          placeholder="Search by Product Name"
        />
        <ProductForm
          product={product}
          setProduct={setProduct}
          handleSubmit={handleSubmit}
          handleImageChange={handleImageChange}
          handleChange={handleChange}
          brandList={brandList}
          subCategoryList={subCategoryList}
          currentId={currentId}
          show={show}
          handleClose={handleClose}
        />
        <div className="row">
          <ProductTable
            productList={currentRows}
            setCurrentId={setCurrentId}
            handleProductDelete={handleProductDelete}
            setConfirmDialog={setConfirmDialog}
            setSorting={setSorting}
            handleShow={handleShow}
          />
          <Pagination
            rowsPerPage={rowsPerPage}
            totalRows={productList?.products?.length}
            paginate={paginate}
            setRowsPerPage={setRowsPerPage}
            url="/catalog/products"
          />
        </div>
      </div>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </div>
  );
};

export default AddProduct;
