import { BASE_URL_LOCATION, BASE_URL_ORDER } from "./api";
import axios from "axios";
//appfactorydevapi.letsendorse.com/order-service/order/admin?startDate=06/06/2020&endDate=06/08/2021
export const allOrders = async (token) =>
  await axios.get(`${BASE_URL_ORDER}/order/admin`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const States = async (token) =>
  await axios.get(`${BASE_URL_LOCATION}/locations/states`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const Cities = async (token, state) =>
  await axios.get(`${BASE_URL_LOCATION}/locations/cities/${state}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const dumpdata = [
  {
    bagId: "60c30f72ed6f0ed3ccf83155",
    consumerId: "60c30ea8e39881ba27ba619a",
    createdAt: "2021-06-11T07:24:20.980Z",
    deliveryAddress: { state: "Chhattisgarh", city: "Jashpur" },
    deliveryAttempts: [],
    entrepreneurId: "60bb70afd7dcc9f33327a422",
    fulfilledItems: [],
    paymentMethod: "PG",
    selectedSlot: { date: "2021-06-12T07:24:03.000Z", timeSlot: "16:00-18:00" },
    status: "Order Placed",
    totalItemsFulfilled: 0,
    totalValueFulfilled: 0,
    updatedAt: "2021-06-11T07:24:20.980Z",
    _id: "60c361f60f0137e43eba763d",
  },
  {
    bagId: "60c336c611d28edc60fceb18",
    consumerId: "60c30ea8e39881ba27ba619a",
    createdAt: "2021-06-11T13:15:34.716Z",
    deliveryAddress: { state: "Haryana", city: "Faridabad" },
    deliveryAttempts: [],
    entrepreneurId: "60bb70afd7dcc9f33327a422",
    fulfilledItems: [],
    paymentMethod: "PG",
    selectedSlot: { date: "2021-06-12T13:15:11.000Z", timeSlot: "16:00-18:00" },
    status: "Order Placed",
    totalItemsFulfilled: 0,
    totalValueFulfilled: 0,
    updatedAt: "2021-06-11T13:15:34.716Z",
    _id: "60c361f60f0137e43eba763d",
  },

  {
    bagId: "60c3633d0f0137e43eba7642",
    consumerId: "60c30ea8e39881ba27ba619a",
    createdAt: "2021-06-11T13:21:37.279Z",
    deliveryAddress: { state: "Kerala", city: "Kannur" },
    deliveryAttempts: [],
    entrepreneurId: "60bb70afd7dcc9f33327a422",
    fulfilledItems: [],
    paymentMethod: "PG",
    selectedSlot: { date: "2021-06-12T13:15:11.000Z", timeSlot: "16:00-18:00" },
    status: "Order Placed",
    totalItemsFulfilled: 0,
    totalValueFulfilled: 0,
    updatedAt: "2021-06-11T13:21:37.279Z",

    _id: "60c363610f0137e43eba764b",
  },
  {
    bagId: "60c363d60f0137e43eba7650",
    consumerId: "60c30ea8e39881ba27ba619a",
    createdAt: "2021-06-11T13:31:30.458Z",
    deliveryAddress: { state: "Chhattisgarh", city: "Jashpur" },
    deliveryAttempts: [],
    entrepreneurId: "60bb70afd7dcc9f33327a422",
    fulfilledItems: [],
    paymentMethod: "PG",
    selectedSlot: { date: "2021-06-12T13:26:47.000Z", timeSlot: "16:00-18:00" },
    status: "Order Placed",
    totalItemsFulfilled: 0,
    totalValueFulfilled: 0,
    updatedAt: "2021-06-11T13:31:30.458Z",
    _id: "60c365b20f0137e43eba7656",
  },
  {
    bagId: "60c30f72ed6f0ed3ccf83155",
    consumerId: "60c30ea8e39881ba27ba619a",
    createdAt: "2021-06-11T07:24:20.980Z",
    deliveryAddress: { state: "Haryana", city: "Karnal" },
    deliveryAttempts: [],
    entrepreneurId: "60bb70afd7dcc9f33327a422",
    fulfilledItems: [],
    paymentMethod: "PG",
    selectedSlot: { date: "2021-06-12T07:24:03.000Z", timeSlot: "16:00-18:00" },
    status: "Order Placed",
    totalItemsFulfilled: 0,
    totalValueFulfilled: 0,
    updatedAt: "2021-06-11T07:24:20.980Z",
    _id: "60c361f60f0137e43eba763d",
  },
  {
    bagId: "60c336c611d28edc60fceb18",
    consumerId: "60c30ea8e39881ba27ba619a",
    createdAt: "2021-06-11T13:15:34.716Z",
    deliveryAddress: { state: "Kerala", city: "Idukki" },
    deliveryAttempts: [],
    entrepreneurId: "60bb70afd7dcc9f33327a422",
    fulfilledItems: [],
    paymentMethod: "PG",
    selectedSlot: { date: "2021-06-12T13:15:11.000Z", timeSlot: "16:00-18:00" },
    status: "Order Placed",
    totalItemsFulfilled: 0,
    totalValueFulfilled: 0,
    updatedAt: "2021-06-11T13:15:34.716Z",
    _id: "60c361f60f0137e43eba763d",
  },

  {
    bagId: "60c3633d0f0137e43eba7642",
    consumerId: "60c30ea8e39881ba27ba619a",
    createdAt: "2021-06-11T13:21:37.279Z",
    deliveryAddress: { state: "Chhattisgarh", city: "Balrampur" },
    deliveryAttempts: [],
    entrepreneurId: "60bb70afd7dcc9f33327a422",
    fulfilledItems: [],
    paymentMethod: "PG",
    selectedSlot: { date: "2021-06-12T13:15:11.000Z", timeSlot: "16:00-18:00" },
    status: "Order Placed",
    totalItemsFulfilled: 0,
    totalValueFulfilled: 0,
    updatedAt: "2021-06-11T13:21:37.279Z",

    _id: "60c363610f0137e43eba764b",
  },
  {
    bagId: "60c363d60f0137e43eba7650",
    consumerId: "60c30ea8e39881ba27ba619a",
    createdAt: "2021-06-11T13:31:30.458Z",
    deliveryAddress: { state: "Haryana", city: "karnal" },
    deliveryAttempts: [],
    entrepreneurId: "60bb70afd7dcc9f33327a422",
    fulfilledItems: [],
    paymentMethod: "PG",
    selectedSlot: { date: "2021-06-12T13:26:47.000Z", timeSlot: "16:00-18:00" },
    status: "Order Placed",
    totalItemsFulfilled: 0,
    totalValueFulfilled: 0,
    updatedAt: "2021-06-11T13:31:30.458Z",
    _id: "60c365b20f0137e43eba7656",
  },
];
