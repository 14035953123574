/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import { singleEntrepreneur } from "../../../actions/entrepreneurActions";
import { singleVariant } from "../../../actions/variantActions";
import PriceLogsTable from "./PriceLogsTable";

const PriceLogs = ({ match }) => {
  const { auth } = useSelector((state) => ({ ...state }));
  const [entrepreneur, setEntrepreneur] = useState("");
  const [variant, setVariant] = useState("");

  const fetchVariant = async () => {
    let res = await singleVariant(auth.token, match.params.variantId);
    setVariant(res.data.variant);
  };

  const fetchEntrepreneur = async () => {
    let res = await singleEntrepreneur(auth.token, match.params.entrepreneurId);
    setEntrepreneur(res.data.entrepreneur);
  };

  useEffect(() => {
    fetchEntrepreneur();
    fetchVariant();
  }, []);

  return (
    <div id="layoutSidenav_content" style={{ userSelect: "auto" }}>
      <main>
        <div className="container-fluid">
          <h2 className="mt-30 page-title">{entrepreneur?.name}</h2>
          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/homies">Entrepreneurs</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={`/homies/view/${entrepreneur?._id}/catalog`}>
                Catalog
              </Link>
            </li>
            <li className="breadcrumb-item active">Price Logs</li>
          </ol>
          <div className="row">
            <div className="card card-static-2 col-md-7 ml-4">
              <div className="shopowner-dts">
                <div className="shopowner-dt-list">
                  <span className="left-dt">Homie Name</span>
                  <span className="right-dt">{entrepreneur?.name}</span>
                </div>
                <div className="shopowner-dt-list">
                  <span className="left-dt">Product ID</span>
                  <span className="right-dt">{variant?.productId}</span>
                </div>
                <div className="shopowner-dt-list">
                  <span className="left-dt">Variant ID</span>
                  <span className="right-dt">{variant?.id}</span>
                </div>
                <div className="shopowner-dt-list">
                  <span className="left-dt">Variant Name</span>
                  <span className="right-dt">{variant?.name}</span>
                </div>
              </div>
            </div>
            <ol className="col-lg-4 col-md-4 back-btn ml-4">
              <Link
                to={`/homies/edit/${entrepreneur?._id}`}
                style={{ textDecoration: "none" }}
              >
                <Button variant="contained" className="status-btn hover-btn">
                  Update Price
                </Button>
              </Link>
            </ol>
          </div>
          <PriceLogsTable entrepreneur={entrepreneur} variant={variant} />
        </div>
      </main>
      <footer className="py-4 bg-footer mt-auto">
        <div className="container-fluid">
          <div className="d-flex align-items-center justify-content-between small">
            <div className="text-muted-1">
              © 2021 <b>App Factory</b>
            </div>
            <div className="footer-links">
              <a href="/">Privacy Policy</a>
              <a href="/">Terms &amp; Conditions</a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default PriceLogs;
