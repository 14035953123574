import DeleteIcon from "@material-ui/icons/Delete";
import moment from "moment";
import { Link } from "react-router-dom";
import TableHeader from "../../0-ResuableComponents/tableComponents/TableHeader";

const headers = [
  { name: "Entrepreneurs", field: "name", sortable: false },
  // { name: "Variant ID", field: "id", sortable: false },
  // { name: "Available", field: "id", sortable: false},
  { name: "Mobile", field: "contact", sortable: false },
  { name: "Gender", field: "gender", sortable: false },
  { name: "Location", field: "location", sortable: false },
  // { name: "Photo", field: "photo", sortable: false },
  { name: "GST", field: "gst", sortable: false },
//   { name: "Services", field: "contact", sortable: false },
//   { name: "Customers", field: "costumers", sortable: false },
//   { name: "Orders", field: "orders", sortable: false },
  { name: "Edit", field: "Edit", sortable: false },
  { name: "Delete", field: "Delete", sortable: false },
//   { name: "Created By", field: "CreatedBy", sortable: false },
  { name: "Created At", field: "CreatedAt", sortable: false },
//   { name: "Last Updated By", field: "LastUpdatedBy", sortable: false },
  { name: "Last Updated At", field: "LastUpdatedAt", sortable: false },
];

const ServiceEntrepreneursTable = ({ serviceEntrepreneursList,
    setCurrentId,
    handleServiceEntrepreneursDelete,
    setConfirmDialog,
    setSorting,
    handleShow
}) => {
  return (
    <div className="col-lg-12 col-md-11">
      <div className="all-cate-tags">
        <div className="row justify- content-between">
          <div className="col-lg-12 col-md-12">
            <div className="card card-static-2 mb-30">
              <div className="card-title-2">
                <h4>Service Entrepreneurs</h4>
              </div>
              <div className="card-body-table">
                <div className="table-responsive">
                  <table className="table ucp-table table-hover">
                    <TableHeader
                      headers={headers}
                      onSorting={(field, order) => setSorting({ field, order })}
                    />
                    <tbody>
                      {serviceEntrepreneursList?.map((entrepreneurs) => (
                        <tr key={entrepreneurs._id}>
                          <td>
                            <Link to={`/services-entrepreneurs/${entrepreneurs._id}`} > 
                            {entrepreneurs.name}</Link>
                          </td>
                          
                          <td>{entrepreneurs?.contact_details?.mobile}</td>
                          <td>{entrepreneurs?.gender}</td>
                          <td>{entrepreneurs?.contact_details?.location?.googleMapUrl}</td>
                          {/* <td> &#x20B9; {variant?.price?.mrp}</td> */}
                          
                          {/* <td>  
                          {entrepreneurs?.photoUrl && ( <img style={{ height: "40px",width: "100%" }} 
                          src={ `${entrepreneurs?.photoUrl}`} alt="Service_Photo_Entrepreneurs" />)}
                                </td> */}
                                <td>{entrepreneurs?.contact_details?.gst}</td>
                          <td className="action-btns">
                            <i
                              className="fas fa-edit"
                              data-toggle="modal"
                              data-target="#exampleModal1"
                              data-whatever="@mdo"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setCurrentId(entrepreneurs._id);
                                handleShow();
                              }}
                            />
                          </td>
                          <td>
                            <DeleteIcon
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setConfirmDialog({
                                  isOpen: true,
                                  title: "Are you sure to delete this record?",
                                  subTitle: "You can't undo this operation",
                                  onConfirm: () => {
                                    handleServiceEntrepreneursDelete(entrepreneurs._id);
                                  },
                                });
                              }}
                            />
                          </td>
                          {/* {/* <td>{entrepreneurs.metaInfo.created?.by?.name ||"-"}</td> */}
                          <td>
                            {moment(entrepreneurs?.contact_details?.created_at).format("lll") || "-"}
                          </td>
                          {/* <td>{entrepreneurs.metaInfo.updated?.by?.name||"-"}</td> */}
                          <td>
                            {moment(entrepreneurs?.contact_details?.updated_at).format("lll")||"-"}
                          </td>                          
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceEntrepreneursTable;
