import { useState, useEffect, useMemo } from "react";
import { allUsers } from "../../actions/consumerAction";
import { Link } from "react-router-dom";
import ConsumerTable from "./tables/consumerTable";
import { useSelector } from "react-redux";
import TableSearch from "../0-ResuableComponents/tableComponents/TableSearch";
import Pagination from "../0-ResuableComponents/pagination/Pagination";

const NewConsumers = () => {
  const [consumers, setConsumerList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const { auth } = useSelector((state) => ({ ...state }));
  const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(200);
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const consumersData = useMemo(() => {
    let computedConsumers = consumers;

    if (search) {
      computedConsumers = computedConsumers?.filter(
        (consumer) =>
          consumer?.name?.toLowerCase().includes(search.toLowerCase()) ||
          consumer?.email?.toLowerCase().includes(search.toLowerCase())
      );
    }
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedConsumers = computedConsumers?.sort(
        (a, b) => reversed * a[sorting.field]?.localeCompare(b[sorting.field])
      );
    }
    return computedConsumers;
  }, [consumers, search, sorting]);

  const fetchAllConsumers = async () => {
    let res = await allUsers(auth.token);
    console.log("this is all consumers ==>> ", res);
    setConsumerList(res.data.consumers);
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    fetchAllConsumers();
  }, [currentPage]);

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = consumersData?.slice(indexOfFirstRow, indexOfLastRow);

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <h2 className="mt-25 page-title">Consumers</h2>
          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li className="breadcrumb-item active">Consumers</li>
          </ol>
          {/* search  */}
          <TableSearch
            onSearch={(value) => {
              setSearch(value);
            }}
            placeholder="Search by Name/ Email"
          />

          <div className="row">
            <ConsumerTable consumers={currentRows} setSorting={setSorting} />
          </div>
        </div>

        {/* pagination */}
        <Pagination
          rowsPerPage={rowsPerPage}
          totalRows={consumers?.length}
          paginate={paginate}
          setRowsPerPage={setRowsPerPage}
          url="/consumers"
        />
      </main>
    </div>
  );
};

export default NewConsumers;
