import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { BASE_URL } from "../../actions/api";
import { allProducts } from "../../actions/productActions";
import { allVariantsByProduct } from "../../actions/variantActions";
import { v4 as uuidv4 } from "uuid";
import refresh from "../../images/refresh.png";
import { allUsers } from "../../actions/consumerAction";

const ORDER_STATUS_DUMMY = [
  "New",
  "Waiting for Production",
  "Ready to be shipped",
  "Delivered",
  "Problem in Delivery",
];

const DUMMY_CONSUMERS = [
  "Consumer 1",
  "Consumer 2",
  "Consumer 3",
  "Consumer 4",
  "Consumer 5",
];

const orderItem_init = {
  productList: [],
  productVariantList: [],
};

const NewOrderForm = () => {
  const { auth } = useSelector((state) => ({ ...state }));
  const { token } = auth;

  const [orders, setOrders] = useState([
    {
      productId: "",
      productVariantId: "",
      productVariants: [],
      numberOfProducts: 0,
      costOfEachProduct: 0,
      gstForEachProduct: 0,
      totalCostForOrderItem: 0,
    },
  ]);
  const [products, setProducts] = useState([]);
  const [dummyUpdate, setDummyUpdate] = useState(false);
  const [consumerList, setConsumerList] = useState([]);
  const [finalOrder, setFinalOrder] = useState({
    consumerId: "",
    theOrders: [],
    deliveryDate: "",
    deliveryAddress: "",
    status: "",
  });

  const fetchAllConsumers = async () => {
    const res = await allUsers(token);
    //console.log("all consumers ==>> ", res.data.consumers);
    setConsumerList(res?.data.consumers);
  };

  useEffect(() => {
    fetchAllConsumers();
    //console.log("this is consumerList state ==> ", consumerList);
  }, []);

  useEffect(() => {
    // Fetch products from API
    const fetchProducts = async () => {
      try {
        const response = await allProducts(token);

        setProducts(response?.data?.products);
      } catch (error) {
        console.error("Failed to fetch products", error);
      }
    };

    fetchProducts();
  }, []);

  const handleProductChange = async (productId, orderIndex) => {
    const updatedOrders = [...orders];
    updatedOrders[orderIndex].productId = productId;
    updatedOrders[orderIndex].productVariantId = "";
    updatedOrders[orderIndex].productVariants = []; // Reset productVariants for the selected order
    setOrders(updatedOrders);

    // Fetch product variants for the selected product from API
    try {
      const response = await allVariantsByProduct(token, productId);

      const updatedOrder = {
        ...updatedOrders[orderIndex],
        productVariants: response?.data?.variants,
      };
      updatedOrders.splice(orderIndex, 1, updatedOrder); // Replace the order at the specified index with the updated order
      setOrders(updatedOrders);
    } catch (error) {
      console.error(
        `Failed to fetch product variants for product ${productId}`,
        error
      );
    }
  };

  const handleProductVariantChange = (productVariantId, orderIndex) => {
    const updatedOrders = [...orders];
    updatedOrders[orderIndex].productVariantId = productVariantId;
    setOrders(updatedOrders);
  };

  const handleNumberOfProductsChange = (index, numberOfProducts) => {
    // Update the number of products for the selected order
    setOrders((prevOrders) => {
      const updatedOrders = [...prevOrders];
      updatedOrders[index].numberOfProducts = Number(numberOfProducts);
      updatedOrders[index].totalCostForOrderItem =
        updatedOrders[index].numberOfProducts *
        updatedOrders[index].costOfEachProduct;
      return updatedOrders;
    });
  };

  const handleCostOfEachProductChange = (index, costOfEachProduct) => {
    // Update the cost of each product for the selected order
    setOrders((prevOrders) => {
      const updatedOrders = [...prevOrders];
      updatedOrders[index].costOfEachProduct = Number(costOfEachProduct);
      updatedOrders[index].totalCostForOrderItem =
        updatedOrders[index].numberOfProducts *
        updatedOrders[index].costOfEachProduct;
      return updatedOrders;
    });
  };

  const handleGstForEachProductChange = (index, gstForEachProduct) => {
    // Update the GST for each product for the selected order
    setOrders((prevOrders) => {
      const updatedOrders = [...prevOrders];
      updatedOrders[index].gstForEachProduct = Number(gstForEachProduct);
      return updatedOrders;
    });
  };

  const handleAddOrder = () => {
    setOrders([
      ...orders,
      { productId: "", productVariantId: "", productVariants: [] },
    ]);
  };

  const handleRemoveOrder = (orderIndex) => {
    const updatedOrders = [...orders];
    updatedOrders.splice(orderIndex, 1);
    setOrders(updatedOrders);
  };

  const handleDummy = () => {
    setDummyUpdate((prev) => !prev);
  };

  const handleAllOrders = () => {
    //console.log("all orders items ==>> ", orders);
    let tempOrders = [...orders];
    setFinalOrder({ ...finalOrder, theOrders: tempOrders });
    //console.log("final Order ==>> ", finalOrder);
  };
  const handleConsumerChange = (e) => {
    let consumer = e.target.value;
    let parsedConsumer = JSON.parse(consumer);
    //console.log("this is parsed consumer ==>> ", parsedConsumer);
    const { _id, name } = parsedConsumer;
    setFinalOrder({ ...finalOrder, consumerId: name });
  };

  const handleOrderStatusChange = (e) => {
    let tempStatus = e.target.value;
    setFinalOrder({ ...finalOrder, status: tempStatus });
  };

  const handleOrderDeliveryDateChange = (e) => {
    let tempDeliveryDate = e.target.value;
    setFinalOrder({ ...finalOrder, deliveryDate: tempDeliveryDate });
  };
  const handleOrderDeliveryAddressChange = (e) => {
    let tempDeliveryAddress = e.target.value;
    setFinalOrder({ ...finalOrder, deliveryAddress: tempDeliveryAddress });
  };

  return (
    <div className="m-3 p-3 border container">
      <div>
        <div>
          <label style={{ minWidth: "150px" }}>Consumer</label>
          <select onChange={(e) => handleConsumerChange(e)}>
            <option>Select the Consumer</option>
            {consumerList?.map((singleConsumer, index) => (
              <option
                key={singleConsumer._id}
                value={JSON.stringify(singleConsumer)}
              >
                {singleConsumer.name}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label style={{ minWidth: "150px" }}>Status</label>
          <select onChange={(e) => handleOrderStatusChange(e)}>
            <option>Select the Status</option>
            {ORDER_STATUS_DUMMY.map((dummyStatus, index) => (
              <option key={dummyStatus} value={dummyStatus}>
                {dummyStatus}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label style={{ minWidth: "150px" }}>Delivery Date</label>
          <input
            type="date"
            defaultValue={finalOrder.deliveryDate}
            onChange={(e) => handleOrderDeliveryDateChange(e)}
          />
        </div>

        <div>
          <label style={{ minWidth: "150px" }}>Delivery Address</label>
          <input
            type="text"
            defaultValue={finalOrder.deliveryAddress}
            onChange={(e) => handleOrderDeliveryAddressChange(e)}
            style={{ height: "25px", minWidth: "200px", margin: "8px 0px" }}
          />
        </div>

        {orders.map((order, index) => (
          <div className="border m-2 p-1" key={index}>
            <h6 style={{ color: "black" }}>Order Item {index + 1}</h6>
            <label style={{ minWidth: "150px" }}>Products & Variants</label>
            <div className="d-inline-flex justify-content-center align-items-center">
              <select
                value={order.productId}
                onChange={(e) => handleProductChange(e.target.value, index)}
              >
                <option value="">Select Product</option>
                {products.map((product) => (
                  <option key={product.id} value={product.id}>
                    {product.name}
                  </option>
                ))}
              </select>

              <button
                className="btn  d-inline-flex justify-content-center align-items-center"
                onClick={handleDummy}
              >
                <img style={{ height: "12px" }} src={refresh} alt="refresh" />
              </button>
            </div>

            <select
              value={order.productVariantId}
              onChange={(e) =>
                handleProductVariantChange(e.target.value, index)
              }
            >
              <option value="">Refresh for Product Variant</option>
              {order.productVariants.map((productVariant) => (
                <option key={productVariant.id} value={productVariant.id}>
                  {productVariant.name}
                </option>
              ))}
            </select>

            {/* Render numberOfProducts input */}
            <div>
              <label style={{ minWidth: "150px" }}>Number of Products</label>
              <input
                type="number"
                defaultValue={
                  order.numberOfProducts === 0 ? "" : order.numberOfProducts
                }
                onChange={(e) =>
                  handleNumberOfProductsChange(index, e.target.value)
                }
                placeholder="Enter Number of Products"
                style={{ height: "25px", minWidth: "200px" }}
              />
            </div>

            {/* Render costOfEachProduct input */}
            <div>
              <label style={{ minWidth: "150px" }}>Cost per Product</label>
              <input
                type="number"
                defaultValue={
                  order.costOfEachProduct === 0 ? "" : order.costOfEachProduct
                }
                onChange={(e) =>
                  handleCostOfEachProductChange(index, e.target.value)
                }
                placeholder="Enter Cost of Single Product"
                style={{ height: "25px", minWidth: "200px" }}
              />
            </div>

            {/* Render totalCostOfOrderItem input */}
            <div>
              <label style={{ minWidth: "150px" }}>Cost of Order Item</label>
              <span style={{ color: "black" }}>
                {order?.totalCostForOrderItem != undefined &&
                order?.totalCostForOrderItem != 0
                  ? order?.totalCostForOrderItem
                  : "-"}
              </span>
            </div>

            {/* Render gstForEachProduct input */}
            <div>
              <label style={{ minWidth: "150px" }}>GST per Product</label>
              <input
                type="number"
                defaultValue={
                  order.gstForEachProduct === 0 ? "" : order.gstForEachProduct
                }
                onChange={(e) =>
                  handleGstForEachProductChange(index, e.target.value)
                }
                placeholder="Enter GST Tax of Single Product"
                style={{ height: "25px", minWidth: "200px" }}
              />
            </div>

            <button
              className="btn btn-sm p-1 d--inline-flex justify-content-center align-items-center text-danger"
              onClick={() => handleRemoveOrder(index)}
            >
              <span>X</span>
            </button>
          </div>
        ))}
        <button
          className="btn btn-sm btn-primary rounded "
          onClick={handleAddOrder}
        >
          Add Order Item
        </button>
      </div>
      <button className="btn btn-warning m-1" onClick={handleAllOrders}>
        Submit
      </button>
    </div>
  );
};

export default NewOrderForm;
