import axios from "axios";
import { BASE_URL_VALUECHAIN_SERVICE } from "./api";

// CREATE A NEW SERVICE ENTREPRENEURS
export const createNewServiceEntrepreneurs = async (token, data) =>
  await axios.post(`${BASE_URL_VALUECHAIN_SERVICE}/service-categories`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// GET ALL THE SERVICE ENTREPRENEURS 
export const allServiceEntrepreneurs = async (token) =>
  await axios.get(`${BASE_URL_VALUECHAIN_SERVICE}/entities`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// UPDATE A SERVICE ENTREPRENEURS BY ID
export const updateServiceEntrepreneurs = async (token, data, serviceId) =>
  await axios.put(`${BASE_URL_VALUECHAIN_SERVICE}/service-categories/${serviceId}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// DELETE THE A SERVICE ENTREPRENEURS BY ID
export const deleteServiceEntrepreneurs = async (token, id) => {
  await axios.delete(`${BASE_URL_VALUECHAIN_SERVICE}/service-categories/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// GET SINGLE THE ENTREPRENEURS
export const getSingleServiceEntrepreneurs = async (token, id) =>
  await axios.get(`${BASE_URL_VALUECHAIN_SERVICE}/entities/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

//GET SINGLE ENTITITES CUSTOMERS AND ORDERS 

export const getSingleEntitiesCustomerAndOrders = async (token, id) =>
await axios.get(`${BASE_URL_VALUECHAIN_SERVICE}/entities/${id}/orders-and-customers`, {
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

// // ---SEARCH RESULTS---
// export const searchListings = async (query) =>
//   await axios.post(`${BASE_URL}/search-listings`, query);
