import axios from "axios";
import { BASE_URL_VALUECHAIN_SERVICE } from "./api";


// CREATE A NEW SERVICE 
export const createNewService = async (token, data) =>
  await axios.post(`${BASE_URL_VALUECHAIN_SERVICE}/services`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// GET ALL THE SERVICE  
export const allService = async (token) =>
  await axios.get(`${BASE_URL_VALUECHAIN_SERVICE}/services`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// UPDATE A SERVICE  BY ID
export const updateService = async (token, data, serviceId) =>
  await axios.put(`${BASE_URL_VALUECHAIN_SERVICE}/services/${serviceId}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// DELETE THE A SERVICE  BY ID
export const deleteService = async (token, tradeId) => {
  await axios.delete(`${BASE_URL_VALUECHAIN_SERVICE}/services/${tradeId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// GET SINGLE THE TRADE
export const getSingleService = async (token, id) =>
  await axios.get(`${BASE_URL_VALUECHAIN_SERVICE}/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// // ---SEARCH RESULTS---
// export const searchListings = async (query) =>
//   await axios.post(`${BASE_URL}/search-listings`, query);
