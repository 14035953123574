import CryptoJS from "crypto-js";
// import dotenv from "dotenv";

// dotenv.config();
const encryptionKey = "4mShrH[QRq{{;4U/x:5L=&xp_$rc/f";

// Encrypt
export const encryptPlaintext = (plaintext) => {
  // console.log(encryptionKey);
  return CryptoJS.AES.encrypt(plaintext, encryptionKey).toString();
};

export const decryptCiphertext = (ciphertext) => {
  // Decrypt
  const bytes = CryptoJS.AES.decrypt(ciphertext, encryptionKey);
  return bytes.toString(CryptoJS.enc.Utf8);
};
