/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import EntrepreneurTable from "./EntrepreneurTable";
import Pagination from "../0-ResuableComponents/pagination/Pagination";
import { allEntrepreneur } from "../../actions/entrepreneurActions";
import TableSearch from "../0-ResuableComponents/tableComponents/TableSearch";

const Entrepreneur = () => {
  const { auth } = useSelector((state) => ({ ...state }));
  const [entrepreneurList, setEntrepreneurList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(200);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const fetchAllEntrepreneur = async () => {
    let res = await allEntrepreneur(auth.token);
    setEntrepreneurList(res.data);
  };

  useEffect(() => {
    fetchAllEntrepreneur();
  }, []);

  const entrepreneursData = useMemo(() => {
    let computedEntrepreneurs = entrepreneurList?.entrepreneurs;

    if (search) {
      computedEntrepreneurs = computedEntrepreneurs?.filter(
        (entrepreneur) =>
          entrepreneur?.name?.toLowerCase().includes(search.toLowerCase()) ||
          entrepreneur?.email?.toLowerCase().includes(search.toLowerCase())
      );
    }

    //Sorting Trades
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedEntrepreneurs = computedEntrepreneurs?.sort(
        (a, b) => reversed * a[sorting.field]?.localeCompare(b[sorting.field])
      );
    }

    return computedEntrepreneurs;
  }, [entrepreneurList, search, sorting]);

  // Get current rows
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = entrepreneursData?.slice(indexOfFirstRow, indexOfLastRow);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div id="layoutSidenav_content">
      <div className="container-fluid">
        <h2 className="mt-25 page-title">Entrepreneurs</h2>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Dashboard</Link>
          </li>
          <li className="breadcrumb-item active">Entrepreneurs</li>
        </ol>
        <div className="col-lg-12 col-md-12 back-btn">
          <Link to="/homies/new" style={{ textDecoration: "none" }}>
            <button className="save-btn hover-btn">
              + Add New Entrepreneur
            </button>
          </Link>
        </div>
        <TableSearch
          onSearch={(value) => {
            setSearch(value);
          }}
          placeholder="Search by Name/ Email"
        />
        <div className="row">
          <EntrepreneurTable
            entrepreneurList={currentRows}
            setSorting={setSorting}
          />
          <Pagination
            rowsPerPage={rowsPerPage}
            totalRows={entrepreneurList?.entrepreneurs?.length}
            paginate={paginate}
            setRowsPerPage={setRowsPerPage}
            url="/homies"
          />
        </div>
      </div>
    </div>
  );
};

export default Entrepreneur;
