/* eslint-disable jsx-a11y/anchor-is-valid */
import react, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Sidebar = () => {
  const [tabActive, setTabActive] = useState(null);
  const { auth } = useSelector((state) => ({ ...state }));
  const { role } = auth;
  // console.log("role======>",role)
  return (
    <div id="layoutSidenav_nav" className="sidebar">
      <nav
        className="sb-sidenav accordion sb-sidenav-dark"
        id="sidenavAccordion"
      >
        <div className="sb-sidenav-menu">
          <div className="nav">
            <Link className="nav-link active" to="/dashboard">
              <div className="sb-nav-link-icon">
                <i className="fas fa-tachometer-alt" />
              </div>
              Dashboard
            </Link>

            <a
              className="nav-link collapsed"
              href="#"
              data-toggle="collapse"
              data-target="#collapseCategories"
              aria-expanded="false"
              aria-controls="collapseCategories"
              tabIndex={0}
            >
              <div className="sb-nav-link-icon">
                <i className="fas fa-list" />
              </div>
              CATALOG MANAGEMENT
              <div className="sb-sidenav-collapse-arrow">
                <i className="fas fa-angle-down" />
              </div>
            </a>
            <div
              className="collapse"
              id="collapseCategories"
              aria-labelledby="headingTwo"
              data-parent="#sidenavAccordion"
            >
              <nav className="sb-sidenav-menu-nested nav">
                <Link className="nav-link sub_nav_link" to="/catalog/trades">
                  TRADES
                </Link>

                <Link className="nav-link sub_nav_link" to="/catalog/brands">
                  BRANDS
                </Link>

                <Link
                  className="nav-link sub_nav_link"
                  to="/catalog/categories"
                >
                  CATEGORIES
                </Link>

                <Link className="nav-link sub_nav_link" to="/catalog/products">
                  PRODUCTS
                </Link>
              </nav>
            </div>

            <a
              className="nav-link collapsed"
              href="#"
              data-toggle="collapse"
              data-target="#collapseServiceCategories"
              aria-expanded="false"
              aria-controls="collapseServiceCategories"
              tabIndex={1}
            >
              <div className="sb-nav-link-icon">
                <i className="fas fa-screwdriver" />
              </div>
              SERVICES MANAGEMENT
              <div className="sb-sidenav-collapse-arrow">
                <i className="fas fa-angle-down" />
              </div>
            </a>
            <div
              className="collapse"
              id="collapseServiceCategories"
              aria-labelledby="headingThree"
              data-parent="#sidenavAccordion"
            >
              <nav className="sb-sidenav-menu-nested nav">
                <Link
                  className="nav-link sub_nav_link"
                  to="/service-categories"
                >
                  SERVICE CATEGORIES
                </Link>

                <Link className="nav-link sub_nav_link" to="/services">
                  SERVICE
                </Link>
                <Link
                  className="nav-link sub_nav_link"
                  to="/services-entrepreneurs"
                >
                  SERVICE ENTREPRENEURS
                </Link>
              </nav>
            </div>

            {role === "Super Admin" ? (
              <Link className="nav-link" to="/admins">
                <div className="sb-nav-link-icon">
                  <i className="fas fa-users" />
                </div>
                Admins
              </Link>
            ) : null}

            <Link className="nav-link collapsed" to="/homies">
              <div className="sb-nav-link-icon">
                <i className="fas fa-store" />
              </div>
              Entrepreneur
            </Link>

            <Link className="nav-link collapsed" to="/orders">
              <div className="sb-nav-link-icon">
                <i className="fas fa-shopping-bag" />
              </div>
              ORDER MANAGEMENT
            </Link>

            <Link className="nav-link collapsed" to="/consumers">
              <div className="sb-nav-link-icon">
                <i className="fas fa-users" />
              </div>
              USER MANAGEMENT
            </Link>
            <Link className="nav-link collapsed" to="/neworders">
              <div className="sb-nav-link-icon">
                <i className="fas fa-shopping-bag" />
              </div>
              NEW ORDER MANAGEMENT
            </Link>

            <a
              className="nav-link collapsed"
              href="#"
              data-toggle="collapse"
              data-target="#collapseCities"
              aria-expanded="false"
              aria-controls="collapseCities"
            >
              <div className="sb-nav-link-icon">
                <i className="fas fa-map-marked-alt"></i>
              </div>
              CITY MANAGEMENT
              <div className="sb-sidenav-collapse-arrow">
                <i className="fas fa-angle-down" />
              </div>
            </a>
            <div
              className="collapse"
              id="collapseCities"
              aria-labelledby="headingTwo"
              data-parent="#sidenavAccordion"
            >
              <nav className="sb-sidenav-menu-nested nav">
                <Link className="nav-link sub_nav_link" to="/cities">
                  MANAGE CITIES
                </Link>
              </nav>
            </div>
            <Link className="nav-link active" to="/coupons">
              <div className="sb-nav-link-icon">
                <i className="fas fa-gift"></i>
              </div>
              Offers
            </Link>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Sidebar;
