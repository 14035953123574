import moment from "moment";
import { Link } from "react-router-dom";
import TableHeader from "../0-ResuableComponents/tableComponents/TableHeader";

const headers = [
  { name: "Name", field: "name", sortable: true },
  { name: "Email", field: "email", sortable: true },
  { name: "Phone", field: "phone", sortable: false },
  { name: "Status", field: "status", sortable: false },
  { name: "catalog", field: "catalog", sortable: false },
  { name: "Created By", field: "CreatedBy", sortable: false },
  { name: "Created At", field: "CreatedAt", sortable: false },
  { name: "Updated By", field: "LastUpdatedBy", sortable: false },
  { name: "Updated At", field: "LastUpdatedAt", sortable: false },
  { name: "Edit", field: "Edit", sortable: false },
];

const EntrepreneurTable = ({ entrepreneurList, setSorting }) => {
  // console.log("entrepreneur list table===>", entrepreneurList);
  return (
    <div className="col-lg-12 col-md-11">
      <div className="all-cate-tags">
        <div className="row justify-content-between">
          <div className="col-lg-12 col-md-12">
            <div className="card card-static-2 mb-30">
              <div className="card-title-2">
                <h4>All Entrepreneurs</h4>
              </div>
              <div className="card-body-table">
                <div className="table-responsive">
                  <table
                    className="table ucp-table table-hover"
                    style={{ minWidth: "110%" }}
                  >
                    <TableHeader
                      headers={headers}
                      onSorting={(field, order) => setSorting({ field, order })}
                    />
                    <tbody>
                      {entrepreneurList?.map((entrepreneur) => (
                        <tr key={entrepreneur._id}>
                          <td>
                            <Link to={`/homies/view/${entrepreneur._id}`}>
                              {entrepreneur.name}
                            </Link>
                          </td>
                          <td>{entrepreneur.email}</td>
                          <td>{entrepreneur.phone}</td>
                          <td
                            style={{
                              color: `${
                                entrepreneur?.status === "Active"
                                  ? "green"
                                  : entrepreneur?.status === "Inactive"
                                  ? "red"
                                  : "orange"
                              }`,
                            }}
                          >
                            {entrepreneur.status}
                          </td>
                          <td>
                            <Link
                              to={`/homies/view/${entrepreneur._id}/catalog`}
                            >
                              Catalog
                            </Link>
                          </td>
                          <td>{entrepreneur.metaInfo.created?.by?.name}</td>
                          <td>
                            {moment(entrepreneur.metaInfo.created?.at).format(
                              "lll"
                            )}
                          </td>
                          <td>{entrepreneur.metaInfo.updated?.by?.name}</td>
                          <td>
                            {moment(entrepreneur.metaInfo.updated?.at).format(
                              "lll"
                            )}
                          </td>
                          <td className="action-btns">
                            <Link to={`/homies/edit/${entrepreneur._id}`}>
                              <i
                                className="fas fa-edit"
                                style={{ cursor: "pointer" }}
                              />
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EntrepreneurTable;
