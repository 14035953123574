/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import TableSearch from "../../0-ResuableComponents/tableComponents/TableSearch";
import ConfirmDialog from "../../0-ResuableComponents/confirmDialog/ConfirmDialog";
import Pagination from "../../0-ResuableComponents/pagination/Pagination";
import ServiceForm from "./ServiceForm";
import ServicesTable from "./ServicesTable";
import {createNewService,allService,updateService,deleteService,getSingleService} from "../../../actions/serviceAction";
import { allServiceCategory } from "../../../actions/serviceCategoryActions"; 

const Service = () => {
  const [service, setService] = useState({ name: "", service_category_id: "",frequency_in_days:"" });

  const { auth } = useSelector((state) => ({ ...state }));
  const { token } = auth;
  const [serviceList, setServiceList] = useState([]);
  const [currentId, setCurrentId] = useState(0);
  const [serviceCategory,setServiceCategory] = useState([]);
  // const [brandList, setBrandList] = useState([]);
  // const [subCategoryList, setSubCategoryList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(1000);
  const [search, setSearch] = useState("");
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const fetchAllServices = async () => {
    let res = await allService(token);
    let allCategory = await allServiceCategory(token);
    setServiceList(res.data);
    setServiceCategory(allCategory.data.data);
  };

  const singleService = currentId
    ? serviceList.data.find((product) => product._id === currentId)
    : null;

  useEffect(() => {
    fetchAllServices();
    if (singleService) setService(singleService);
  }, [currentId]);

  const servicesData = useMemo(() => {
    let computedProducts = serviceList?.data;

    if (search) {
      computedProducts = computedProducts?.filter((service) =>
        service.name.toLowerCase().includes(search.toLowerCase())
      );
    }

    //Sorting products
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedProducts = computedProducts?.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    return computedProducts;
  }, [serviceList, search, sorting]);

  const clear = () => {
    setCurrentId(0);
    setService({
      name: "",
    service_category_id: "",
    frequency_in_days:""
    });
  };

  const { name, service_category_id, frequency_in_days } = service;

  const handleSubmit = async (e) => {
    e.preventDefault();

    let serviceData = new FormData();
    serviceData.append("name", name);
    serviceData.append("service_category_id", service_category_id);
    serviceData.append("frequency_in_days", frequency_in_days);
    try {
      if (currentId === 0) {
        await createNewService(token, serviceData);
        fetchAllServices();
        toast.success(`New Service ${service.name} is added Successfully...`);
        clear();
        handleClose();
      } else {
        setConfirmDialog({
          isOpen: true,
          title: "Are you sure to Update this record?",
          subTitle: "You can't undo this operation",
          onConfirm: async () => {
            setConfirmDialog({
              ...confirmDialog,
              isOpen: false,
            });
            await updateService(token, serviceData, currentId)
              .then(() => {
                fetchAllServices();
                toast.success(
                  `Service ${service.name} is updated Successfully...`
                );
                clear();
                handleClose();
              })
              .catch((err) => toast.error(err.response.data.errorMessage));
          },
        });
      }
    } catch (err) {
      toast.error(err.response.data.errorMessage);
    }
  };

  const handleChange = (e) => {
    setService({ ...service, [e.target.name]: e.target.value });
  };

  const handleServiceDelete = async (productId) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    deleteService(token, productId)
      .then((res) => {
        toast.success("Service Deleted successfully...");
        fetchAllServices();
      })
      .catch((err) => toast.error(err.response.data.errorMessage));
  };

  // Get current rows
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = servicesData?.slice(indexOfFirstRow, indexOfLastRow);
  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div id="layoutSidenav_content">
      <div className="container-fluid">
        <h2 className="mt-25 page-title">Services</h2>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Dashboard</Link>
          </li>
          <li className="breadcrumb-item active">Services</li>
        </ol>
        <div className="col-lg-12 col-md-12 back-btn">
          <button
            className="save-btn hover-btn"
            onClick={() => {
              setCurrentId(0);
              clear();
              handleShow();
            }}
          >
            + Add New Service
          </button>
        </div>
        <TableSearch
          onSearch={(value) => {
            setSearch(value);
          }}
          placeholder="Search by Service Name"
        />
        <ServiceForm
          service={service}
          setService={setService}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          currentId={currentId}
          show={show}
          handleClose={handleClose}
          serviceCategory= {serviceCategory}
        />
        <div className="row">
          <ServicesTable
            serviceList={currentRows}
            setCurrentId={setCurrentId}
            handleServiceDelete={handleServiceDelete}
            setConfirmDialog={setConfirmDialog}
            setSorting={setSorting}
            handleShow={handleShow}
          />
          <Pagination
            rowsPerPage={rowsPerPage}
            totalRows={serviceList?.data?.length}
            paginate={paginate}
            setRowsPerPage={setRowsPerPage}
            url="/services"
          />
        </div>
      </div>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </div>
  );
};

export default Service;
