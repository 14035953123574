import axios from "axios";
import { BASE_URL_VALUECHAIN_SERVICE } from "./api";

// getting business ideas from swati-api 

export const allBusinessIdeas = async () => {
  // const url = `${process.env.REACT_APP_SWATI_API_URL}/api/v1/bussinessIdeas/all-detailed`;
  const url = 'https://swatiapi.letsendorse.net/api/v1/bussinessIdeas/all-detailed';
  
  axios.get(url).then((response) => {
      
  });
}

// CREATE A NEW SERVICE CATEGORY
export const createNewServiceCategory = async (token, data) =>
  await axios.post(`${BASE_URL_VALUECHAIN_SERVICE}/service-categories`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// GET ALL THE SERVICE CATEGORY 
export const allServiceCategory = async (token) =>
  await axios.get(`${BASE_URL_VALUECHAIN_SERVICE}/service-categories`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// UPDATE A SERVICE CATEGORY BY ID
export const updateServiceCategory = async (token, data, serviceId) =>
  await axios.put(`${BASE_URL_VALUECHAIN_SERVICE}/service-categories/${serviceId}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// DELETE THE A SERVICE CATEGORY BY ID
export const deleteServiceCategory = async (token, id) => {
  await axios.delete(`${BASE_URL_VALUECHAIN_SERVICE}/service-categories/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// GET SINGLE THE TRADE
export const getSingleServiceCategory = async (token, id) =>
  await axios.get(`${BASE_URL_VALUECHAIN_SERVICE}/service-categories/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// // ---SEARCH RESULTS---
// export const searchListings = async (query) =>
//   await axios.post(`${BASE_URL}/search-listings`, query);
