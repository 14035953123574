import { useState } from "react";
import { Modal } from "react-bootstrap";

const BrandForm = ({
  brand,
  setBrand,
  handleSubmit,
  currentId,
  show,
  handleClose,
}) => {
  const {
    name,
    company,
    type,
    gst,
    hqAddress,
    brandPOC,
    billingPOC,
    status,
    logos,
  } = brand;
  const typeList = ["Global", "National", "Regional", "Private"];
  const statusOptions = ["Active", "Inactive"];

  // const [doesGSTNValidates, setDoesGSTNValidates] = useState(false);

  // const handleGSTNChange = (e) => {
  //   const { value } = e.target;
  //   if (value.length === 15) {
  //     setDoesGSTNValidates(true);
  //     setBrand({ ...brand, gst: value });
  //   } else {
  //     setDoesGSTNValidates(false);
  //   }
  // };

  return (
    <Modal show={show} onHide={handleClose}>
      <div>
        <Modal.Header closeButton data-dismiss="modal">
          <h5 className="modal-title" id="exampleModalLabel">
            {currentId ? "Update Brand" : "+ Add New Brand"}
          </h5>
        </Modal.Header>
        <Modal.Body>
          <div className="col-lg-12 col-md-11">
            <div className="card card-static-2 mb-30">
              <div className="card-body-table">
                <div className="news-content-right pd-20">
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label htmlFor="name" className="form-label">
                        Brand Name<span className="required">&#42;</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Brand Name"
                        name="name"
                        required
                        value={name}
                        onChange={(e) =>
                          setBrand({ ...brand, name: e.target.value })
                        }
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="companyName" className="form-label">
                        Company Name<span className="required">&#42;</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Company Name"
                        name="companyName"
                        required
                        value={company}
                        onChange={(e) =>
                          setBrand({ ...brand, company: e.target.value })
                        }
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="type" className="form-label">
                        Type<span className="required">&#42;</span>
                      </label>
                      <select
                        name="type"
                        className="form-control"
                        value={type}
                        required
                        onChange={(e) =>
                          setBrand({ ...brand, type: e.target.value })
                        }
                      >
                        <option value="">Select Type</option>
                        {typeList.map((value, index) => (
                          <option key={index} value={value}>
                            {value}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="form-group">
                      <label htmlFor="logo" className="form-label">
                        Logo
                        <span className="required">&#42;</span>
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        required
                        name="logo"
                        onChange={(e) =>
                          setBrand({ ...brand, logos: e.target.files[0] })
                        }
                        accept="image/*"
                      />
                      {/* <div className="cate-img-5">
                              {photoUrl && (
                                <img src={`${photoUrl}`} alt="variant_img" />
                              )}
                            </div> */}
                    </div>

                    <div className="form-group">
                      <label htmlFor="hqAddress" className="form-label">
                        HQ Address <span className="required">&#42;</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Address"
                        name="hqAddress.address"
                        required
                        value={hqAddress?.address}
                        onChange={(e) =>
                          setBrand({
                            ...brand,
                            hqAddress: {
                              ...hqAddress,
                              address: e.target.value,
                            },
                          })
                        }
                      />
                      <input
                        type="text"
                        className="form-control"
                        placeholder="City"
                        name="hqAddress.city"
                        required
                        value={hqAddress?.city}
                        onChange={(e) =>
                          setBrand({
                            ...brand,
                            hqAddress: {
                              ...hqAddress,
                              city: e.target.value,
                            },
                          })
                        }
                      />
                      <input
                        type="text"
                        className="form-control"
                        placeholder="State"
                        name="hqAddress.state"
                        required
                        value={hqAddress?.state}
                        onChange={(e) =>
                          setBrand({
                            ...brand,
                            hqAddress: {
                              ...hqAddress,
                              state: e.target.value,
                            },
                          })
                        }
                      />
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Pincode"
                        name="hqAddress.pincode"
                        required
                        value={hqAddress?.pincode}
                        onChange={(e) =>
                          setBrand({
                            ...brand,
                            hqAddress: {
                              ...hqAddress,
                              pincode: e.target.value,
                            },
                          })
                        }
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="brandPOC" className="form-label">
                        Brand POC <span className="required">&#42;</span>
                      </label>

                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        name="brandPOC.email"
                        required
                        value={brandPOC?.email}
                        onChange={(e) =>
                          setBrand({
                            ...brand,
                            brandPOC: {
                              ...brandPOC,
                              email: e.target.value,
                            },
                          })
                        }
                      />
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Mobile"
                        name="brandPOC.mobile"
                        required
                        value={brandPOC?.mobile}
                        onChange={(e) =>
                          setBrand({
                            ...brand,
                            brandPOC: {
                              ...brandPOC,
                              mobile: e.target.value,
                            },
                          })
                        }
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="billingPOC" className="form-label">
                        Billing POC <span className="required">&#42;</span>
                      </label>

                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        name="billingPOC.email"
                        required
                        value={billingPOC?.email}
                        onChange={(e) =>
                          setBrand({
                            ...brand,
                            billingPOC: {
                              ...billingPOC,
                              email: e.target.value,
                            },
                          })
                        }
                      />
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Mobile"
                        name="billingPOC.mobile"
                        required
                        value={billingPOC?.mobile}
                        onChange={(e) =>
                          setBrand({
                            ...brand,
                            billingPOC: {
                              ...billingPOC,
                              mobile: e.target.value,
                            },
                          })
                        }
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="gst" className="form-label">
                        GSTN<span className="required">&#42;</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="GSTN"
                        name="gst"
                        required
                        value={gst}
                        onChange={(e) =>
                          setBrand({ ...brand, gst: e.target.value })
                        }
                      />
                      {/* <div>
                        {doesGSTNValidates == false
                          ? "GSTN should be unique 15 digit Number"
                          : null}
                      </div> */}
                    </div>

                    <div className="form-group">
                      <label htmlFor="status" className="form-label">
                        Status<span className="required">&#42;</span>
                      </label>
                      <select
                        name="status"
                        className="form-control"
                        value={status}
                        required
                        onChange={(e) =>
                          setBrand({ ...brand, status: e.target.value })
                        }
                      >
                        <option value="">Select Status</option>
                        {statusOptions?.map((value, index) => (
                          <option key={index} value={value}>
                            {value}
                          </option>
                        ))}
                      </select>
                    </div>

                    <button className="save-btn hover-btn">
                      {currentId ? "Update" : "Add New"}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default BrandForm;
