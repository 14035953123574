import MultipleSelectTradeList from "./MultipleSelectTradeList";
import { Modal } from "react-bootstrap";

const CategoryForm = ({
  category,
  setCategory,
  handleSubmit,
  tradeList,
  currentId,
  handleImageChange,
  show,
  handleClose,
}) => {
  const { name, tradeIds=[], photoUrl } = category;
  // console.log("tradeIds", tradeIds);
  return (
    <Modal show={show} onHide={handleClose}>
      <div>
        <Modal.Header closeButton data-dismiss="modal">
          <h5 className="modal-title" id="exampleModalLabel">
            {currentId ? "Update Category" : "+Add New Category"}
          </h5>
        </Modal.Header>
        <Modal.Body>
          <div className="col-lg-12 col-md-11">
            <div className="card card-static-2 mb-30">
              <div className="card-body-table">
                <div className="news-content-right pd-20">
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label htmlFor="name" className="form-label">
                        Category Name
                        <span className="required">&#42;</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Category Name"
                        name="name"
                        required
                        value={name}
                        onChange={(e) =>
                          setCategory({ ...category, name: e.target.value })
                        }
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="photoUrl" className="form-label">
                        Image<span className="required">&#42;</span>
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        name="photoUrl"
                        required={!photoUrl}
                        onChange={handleImageChange}
                        accept="image/*"
                      />
                      <div className="cate-img-5">
                        {photoUrl && (
                          <img src={`${photoUrl}`} alt="product_img" />
                        )}
                      </div>
                    </div>

                    <MultipleSelectTradeList
                      tradeList={tradeList}
                      tradeIds={tradeIds}
                      setCategory={setCategory}
                      category={category}
                    />

                    <button className="save-btn hover-btn">
                      {currentId ? "Update" : "Add New"}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default CategoryForm;
