import { useState } from "react";
import { toast } from "react-toastify";
import { forgotPassword } from "../../../actions/authAction";
import ForgotPasswordForm from "./ForgotPasswordForm";

const ForgotPassword = ({ history }) => {
  const [email, setEmail] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("SEND DATA", { email });
    try {
      let res = await forgotPassword({ email });
      if (res.data) {
        // console.log(res.data);
        toast.success(res.data.message);
        history.push("/");
      }
    } catch (err) {
      toast.error(err.response.data.errorMessage);
    }
  };

  return (
    <div
      id="layoutSidenav_content"
      style={{ marginLeft: "28%", marginTop: "4%" }}
    >
      <main>
        <div className="container-fluid">
          <div className="row">
            <ForgotPasswordForm
              handleSubmit={handleSubmit}
              email={email}
              setEmail={setEmail}
            />
          </div>
        </div>
      </main>
    </div>
  );
};

export default ForgotPassword;
