import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";

const EditCityForm = ({
  city,
  fetchAllCities,
  showModal,
  handleCityUpdate,
  setShowEditCityModal,
}) => {
  const status = ["Active", "Inactive", "Hidden"];
  const [updatedStatus, setUpdatedStatus] = useState("");
  const [updatedRollout, setUpdatedRollout] = useState("");
  const [isUpdated, setIsUpdated] = useState(false);
  const rollOut = ["Full", "Partial"];
  const [updatedData, setUpdatedData] = useState({
    trades: city.trades,
    status: "",
    rollout: "",
  });

  useEffect(() => {
    setIsUpdated(true);
    setUpdatedData({
      trades: city.trades,
      status: updatedStatus,
      rollout: updatedRollout,
    });
  }, [updatedStatus, updatedRollout]);

  return (
    <Modal
      show={showModal}
      onHide={() => setShowEditCityModal(false)}
      size="lg"
      className="p-4"
    >
      <Modal.Header closeButton data-dismiss="modal">
        <h5>EDIT CITY</h5>
      </Modal.Header>
      <Modal.Body>
        <div className="col-lg-12 col-md-11">
          <div className="card card-static-2 mb-30">
            <div className="card-body-table">
              <div className="news-content-right pd-20">
                {/* <form onSubmit={() =>   handleCityUpdate("updatedData potato")}> */}
                <div className="form-group">
                  <label htmlFor="name" className="form-label">
                    State Name-
                  </label>{" "}
                  <strong>{city.state}</strong>
                </div>
                <div className="form-group">
                  <label htmlFor="name" className="form-label">
                    City name-
                  </label>{" "}
                  <strong>{city.city}</strong>
                </div>
                <div className="form-group">
                  <label htmlFor="name" className="form-label">
                    Status
                  </label>
                  <select
                    name="type"
                    className="form-control"
                    required
                    onChange={(e) => {
                      setUpdatedStatus(e.target.value);
                    }}
                  >
                    <option value={city.status}>{city.status}</option>
                    {status.map((value, index) => (
                      <option key={index} value={value}>
                        {value}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="name" className="form-label">
                    Rollout
                  </label>
                  <select
                    name="type"
                    className="form-control"
                    required
                    onChange={(e) => {
                      setUpdatedRollout(e.target.value);
                    }}
                  >
                    <option value={city.rollout}>{city.rollout}</option>
                    {rollOut.map((value, index) => (
                      <option key={index} value={value}>
                        {value}
                      </option>
                    ))}
                  </select>
                  <div
                    className="button-class"
                    style={{ display: "flex", flexDirection: "row-reverse" }}
                  >
                    <button
                      className="save-btn hover-btn"
                      disabled={!isUpdated}
                      onClick={() => handleCityUpdate(updatedData, city._id)}
                    >
                      SAVE CHANGES
                    </button>
                  </div>
                </div>
                {/* </form> */}
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default EditCityForm;
