export const BASE_URL = process.env.REACT_APP_API;

export const BASE_URL_ADMIN = process.env.REACT_APP_API_ADMIN;

export const BASE_URL_ENTREPRENEUR = process.env.REACT_APP_API_ENTREPRENEUR;

export const BASE_URL_LOCATION = process.env.REACT_APP_API_LOCATION;

export const BASE_URL_USER = process.env.REACT_APP_API_USER;

export const BASE_URL_ORDER = process.env.REACT_APP_API_ORDER;

export const BASE_URL_CITY_MANAGEMENT =
  process.env.REACT_APP_API_CITY_MANAGEMENT;

export const BASE_URL_COUPON = process.env.REACT_APP_API_COUPON;

export const BASE_URL_VALUECHAIN_SERVICE =
  process.env.REACT_APP_API_VALUECHAIN_SERVICE;

export const BASE_URL_MARKET_PLACE_ORDERS =
  process.env.REACT_APP_API_MARKET_PLACE_ORDERS;
