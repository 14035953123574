import axios from "axios";
import { BASE_URL } from "./api";

// CREATE A NEW SUB-CATEGORY
export const createNewSubCategory = async (token, data) =>
  await axios.post(`${BASE_URL}/subcategories`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// GET ALL THE SUB-CATEGORIES
export const allSubcategories = async (token) =>
  await axios.get(`${BASE_URL}/subcategories`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// GET ALL THE SUB-CATEGORIES BY CATEGORY
export const allSubcategoriesByCategory = async (token, categoryId) =>
  await axios.get(`${BASE_URL}/subcategories/byCategory/${categoryId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// UPDATE A SUB-CATEGORY
export const updateSubCategory = async (token, data, SubCategoryId) =>
  await axios.put(`${BASE_URL}/subcategories/${SubCategoryId}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// DELETE THE SUB-CATEGORY
export const deleteSubCategory = async (token, SubCategoryId) => {
  await axios.delete(`${BASE_URL}/subcategories/${SubCategoryId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// GET SINGLE THE SUB-CATEGORY
export const singleSubCategory = async (token, SubCategoryId) =>
  await axios.get(`${BASE_URL}/subcategories/${SubCategoryId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    }
  });

// // ---SEARCH RESULTS---
// export const searchListings = async (query) =>
//   await axios.post(`${BASE_URL}/search-listings`, query);
