import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { itemdump, ConsumerOrder } from "../../actions/consumerAction";
import SingleOrderDetailsTable from "./tables/SingleOrderDetails";
import OrderItemsTable from "./tables/OrderItemsTable";
import { Button } from "@material-ui/core";

const OrderDetails = ({ match }) => {
  const { auth } = useSelector((state) => ({ ...state }));

  const [order, setOrder] = useState({});
  const [bag, setBag] = useState({});
  const orderId = match.params.orderId;
  const consumerId = match.params.consumerId;
  const fetchOrderData = async () => {
    const res = await ConsumerOrder(auth.token, orderId);
    console.log(res.data);
    setOrder(res.data.orderDetails);
    setBag(res.data.bagDetails);
  };

  useEffect(() => {
    fetchOrderData();
  }, []);

  return (
    <div id="layoutSidenav_content">
      <main>
        <div className="container-fluid">
          <Link to={`/consumers/${consumerId}`}>
            <Button variant="contained">
              <i className="fas fa-arrow-left" /> Back
            </Button>
          </Link>
          <div className="col-lg-5 col-md-9 col-lg-6">
            <h2 className="mt-30 page-title">{order?._id} &#62;</h2>
          </div>
          <ol className="breadcrumb mb-30">
            <li className="breadcrumb-item">
              <Link to="/dashboard">Dashboard</Link>
            </li>
            <li className="breadcrumb-item active">
              <Link to="/consumers">Consumers</Link>
            </li>
            <li className="breadcrumb-item active">
              <Link to={`/consumers/${consumerId}`}>Consumer Profile</Link>
            </li>
            <li className="breadcrumb-item active">Order</li>
          </ol>
          <div className="row">
            <SingleOrderDetailsTable
              order={order}
              totalValue={bag?.totalValue}
            />
          </div>
          <div className="row">
            <OrderItemsTable bag={bag} />
          </div>
        </div>
      </main>
    </div>
  );
};

export default OrderDetails;
