import { Modal } from "react-bootstrap";
import BusinessIdeasList from "./BusinessIdeasList";


const TradeForm = ({
  
  setTrade,
  handleSubmit,
  businessList,
  currentId,
  show,
  trade,
  handleClose,
}) => {
  const { name,bussinessIds=[] } = trade
 
  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="md"
      // className="modal fade"
      // id="exampleModal"
      // tabIndex="-1"
      // aria-labelledby="exampleModalLabel"
      // aria-hidden="true"
    >
      <div>
        <Modal.Header closeButton data-dismiss="modal">
          <h5 className="modal-title" id="exampleModalLabel">
            {currentId ? "Update Trade" : "+ Add New Trade"}
          </h5>
          {/* <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button> */}
        </Modal.Header>
        <Modal.Body>
          <div className="col-lg-12 col-md-11">
            <div className="card card-static-2 mb-30">
              <div className="card-title-2">
                <h4>Trade</h4>
              </div>
              <div className="card-body-table">
                <div className="news-content-right pd-20">
                  <form onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label htmlFor="name" className="form-label">
                        Name<span className="required">&#42;</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Trade Name"
                        required
                        name="name"
                        value={name}
                        onChange={(e) => setTrade({...trade, name: e.target.value })}
                      />
                    </div>
                    {/* <MultiSelectBusinessIdeasList
                    businessList={businessList}
                    setTrade={setTrade}
                    bussinessIds={bussinessIds}
                    trade={trade}
                    /> */}
                 <BusinessIdeasList
                  businessList={businessList}
                  setTrade={setTrade}
                  bussinessIds={bussinessIds}
                  trade={trade}
                 />
                    <button className="save-btn hover-btn" type="submit">
                      {currentId ? "Update" : "Add New"}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default TradeForm;
