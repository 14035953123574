import axios from "axios";
import { BASE_URL } from "./api";

// CREATE A NEW TRADE
export const createNewTrade = async (token, data) =>
  await axios.post(`${BASE_URL}/trades`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// GET ALL THE TRADES
export const allTrades = async (token) =>
  await axios.get(`${BASE_URL}/trades`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });


// GET ALL THE TRADES BY CATEGORY
export const allTradesByCategory = async (token, id) =>
  await axios.get(`${BASE_URL}/trades/byCategory/${id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// UPDATE A SERVICE
export const updateTrade = async (token, data, tradeId) =>
  await axios.put(`${BASE_URL}/trades/${tradeId}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// DELETE THE TRADE
export const deleteTrade = async (token, tradeId) => {
  await axios.delete(`${BASE_URL}/trades/${tradeId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// GET SINGLE THE TRADE
export const singleTrade = async (token, tradeId) =>
  await axios.get(`${BASE_URL}/trades/${tradeId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// // ---SEARCH RESULTS---
// export const searchListings = async (query) =>
//   await axios.post(`${BASE_URL}/search-listings`, query);
