/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { singleTrade } from "../../../actions/tradeActions";
import { singleCategory } from "../../../actions/categoryActions";
import { singleSubCategory } from "../../../actions/subCategoryActions";
import { singleProduct } from "../../../actions/productActions";
import { singleBrand } from "../../../actions/brandActions";
import TableHeader from "../../0-ResuableComponents/tableComponents/TableHeader";

const TradesCategoriesSubCategoriesTable = ({ coupon, setSorting }) => {
  const [tradeList, setTradeList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const { auth } = useSelector((state) => ({ ...state }));
  const { token } = auth;

  const fetchTrades = async () => {
    if (!coupon?.tradeIds) return;
    const promises = coupon?.tradeIds?.map((item, idx) => {
      return singleTrade(token, coupon?.tradeIds[idx]).then((response) => {
        return response.data.trade;
      });
    });
    Promise.all(promises).then((results) => {
      const trades = results.map((result) => result);
      setTradeList(trades);
    });
  };

  useEffect(() => {
    fetchTrades();
  }, [coupon?.tradeIds]);

  const fetchCategories = async () => {
    if (!coupon?.categoryIds) return;
    const promises = coupon?.categoryIds?.map((item, idx) => {
      return singleCategory(token, coupon?.categoryIds[idx]).then(
        (response) => {
          return response.data.category;
        }
      );
    });
    Promise.all(promises).then((results) => {
      const categories = results.map((result) => result);
      setCategoryList(categories);
    });
  };

  useEffect(() => {
    fetchCategories();
  }, [coupon?.categoryIds]);

  const fetchSubCategories = async () => {
    if (!coupon?.subCategoryIds) return;
    const promises = coupon?.subCategoryIds?.map((item, idx) => {
      return singleSubCategory(token, coupon?.subCategoryIds[idx]).then(
        (response) => {
          return response.data.subcategory;
        }
      );
    });
    Promise.all(promises).then((results) => {
      const subCategories = results.map((result) => result);
      setSubCategoryList(subCategories);
    });
  };

  useEffect(() => {
    fetchSubCategories();
  }, [coupon?.subCategoryIds]);

  const fetchProducts = async () => {
    if (!coupon?.productIds) return;
    const promises = coupon?.productIds?.map((item, idx) => {
      return singleProduct(token, coupon?.productIds[idx]).then((response) => {
        return response.data.product;
      });
    });
    Promise.all(promises).then((results) => {
      const products = results.map((result) => result);
      setProductList(products);
    });
  };

  useEffect(() => {
    fetchProducts();
  }, [coupon?.productIds]);

  const fetchBrands = async () => {
    if (!coupon?.brandIds) return;
    const promises = coupon?.brandIds?.map((item, idx) => {
      return singleBrand(token, coupon?.brandIds[idx]).then((response) => {
        return response.data.brand;
      });
    });
    Promise.all(promises).then((results) => {
      const brands = results.map((result) => result);
      setBrandList(brands);
    });
  };

  useEffect(() => {
    fetchBrands();
  }, [coupon?.brandIds]);

  const headers = [
    { name: "category", field: "category", sortable: false },
    { name: "sub-categories", field: "sub-categories", sortable: false },
    { name: "status", field: "status", sortable: false },
  ];

  return (
    <>
      <div className="row">
        {/*------------ Products name------ */}
        <div
          className="col-lg-5 col-md-5"
          style={{ userSelect: "auto", marginLeft: "1.2%" }}
        >
          <div className="card card-static-2 mt-30 mb-30">
            <div className="card-body-table">
              <div className="table-responsive">
                <table className="table ucp-table table-hover">
                  <thead>
                    <tr>
                      <th scope="col">Products</th>
                    </tr>
                  </thead>
                  <tbody>
                    {productList?.map((product) => (
                      <tr key={product._id}>
                        <td>{product.name}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/*------------ Brands name------ */}

        <div className="col-lg-6 col-md-6" style={{ userSelect: "auto" }}>
          <div className="card card-static-2 mt-30 mb-30">
            <div className="card-body-table">
              <div className="table-responsive">
                <table className="table ucp-table table-hover">
                  <thead>
                    <tr>
                      <th scope="col">Brands</th>
                    </tr>
                  </thead>
                  <tbody>
                    {brandList?.map((brand) => (
                      <tr key={brand._id}>
                        <td>{brand.name}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ------Trade ==> Category ===> Sub-Category------ */}

      <div className="col-lg-12 col-md-12" style={{ userSelect: "auto" }}>
        <div className="card card-static-2 mt-30 mb-30">
          {tradeList?.map((trade, idx) => (
            <div key={trade.id}>
              <div className="card-title-2">
                <h4>Trade - {trade.name}</h4>
              </div>
              <div className="card-body-table">
                <div className="table-responsive">
                  <table className="table ucp-table table-hover">
                    <TableHeader
                      headers={headers}
                      onSorting={(field, order) => setSorting({ field, order })}
                    />
                    <tbody>
                      {categoryList
                        ?.filter((category) => {
                          if (
                            category.tradeIds.includes(tradeList[idx].id) &&
                            coupon?.categoryIds?.includes(category.id)
                          ) {
                            return category;
                          }
                        })
                        ?.map((category) => (
                          <tr key={category._id}>
                            <td>{category.name}</td>
                            <td>
                              {subCategoryList
                                .filter((subCategory) => {
                                  if (subCategory.categoryId === category.id) {
                                    // console.log(subCategory, "===>");
                                    return subCategory;
                                  }
                                })
                                ?.map((subCategory) => `${subCategory.name},`)}
                            </td>
                            {/* <td>
                            {productList
                              .filter((product, index) => {
                                if (
                                  product.subcategoryId ===
                                  subCategoryList[index]?.id
                                ) {
                                  console.log(subCategoryList[index]?.id);
                                  return product;
                                }
                              })
                              ?.map((product) => `${product.name},`)}
                          </td> */}
                            <td
                              style={{
                                color: `${
                                  coupon?.status === "Active" ? "green" : "red"
                                }`,
                              }}
                            >
                              {coupon?.status}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default TradesCategoriesSubCategoriesTable;
