import axios from "axios";
import { BASE_URL_VALUECHAIN_SERVICE } from "./api";

// CREATE A NEW VARIANT
export const createNewVariant = async (token, data) =>
  await axios.post(`${BASE_URL_VALUECHAIN_SERVICE}/variants`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// GET ALL THE VARIANT
export const allVariants = async (token) =>
  await axios.get(`${BASE_URL_VALUECHAIN_SERVICE}/variants`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// GET ALL THE VARIANTS BY SErvice
export const allVariantsByService = async (token, serviceId) =>
  await axios.get(`${BASE_URL_VALUECHAIN_SERVICE}/variants/byService/${serviceId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// UPDATE A VARIANT
export const updateVariant = async (token, data, variantId) =>
  await axios.put(`${BASE_URL_VALUECHAIN_SERVICE}/variants/${variantId}`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// DELETE THE VARIANT
export const deleteVariant = async (token, variantId) => {
  await axios.delete(`${BASE_URL_VALUECHAIN_SERVICE}/variants/${variantId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// GET SINGLE THE VARIANT
export const single_Variant = async (token, variantId) =>
  await axios.get(`${BASE_URL_VALUECHAIN_SERVICE}/variants/${variantId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

// // ---SEARCH RESULTS---
// export const searchListings = async (query) =>
//   await axios.post(`${BASE_URL_VALUECHAIN_SERVICE}/search-listings`, query);
