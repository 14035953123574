/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import TableSearch from "../../0-ResuableComponents/tableComponents/TableSearch";
import Pagination from "../../0-ResuableComponents/pagination/Pagination";
import ConfirmDialog from "../../0-ResuableComponents/confirmDialog/ConfirmDialog";
import BrandTable from "./BrandTable";
import BrandForm from "./BrandForm";
import {
  createNewBrand,
  allBrands,
  deleteBrand,
  updateBrand,
} from "../../../actions/brandActions";

const brand_initState = {
  name: "",
  company: "",
  type: "",
  hqAddress: {
    address: "",
    city: "",
    state: "",
    pincode: 0,
  },
  gst: "",
  brandPOC: {
    email: "",
    mobile: 0,
  },
  billingPOC: {
    email: "",
    mobile: "",
  },
  status: "",
  logos: "",
};

const CreateBrand = () => {
  const [brand, setBrand] = useState(brand_initState);
  const { auth } = useSelector((state) => ({ ...state }));
  const { token } = auth;
  const [brandList, setBrandList] = useState([]);
  const [currentId, setCurrentId] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [search, setSearch] = useState("");
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const fetchAllBrands = async () => {
    let res = await allBrands(token);
    setBrandList(res.data);
  };

  const singleBrand = currentId
    ? brandList.brands.find((brand) => brand._id === currentId)
    : null;

  useEffect(() => {
    fetchAllBrands();
    if (singleBrand) setBrand(singleBrand);
  }, [currentId]);

  const brandsData = useMemo(() => {
    let computedBrands = brandList?.brands;

    if (search) {
      computedBrands = computedBrands?.filter(
        (brand) =>
          brand.name.toLowerCase().includes(search.toLowerCase()) ||
          brand.company.toLowerCase().includes(search.toLowerCase())
      );
    }

    //Sorting brands
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedBrands = computedBrands?.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    return computedBrands;
  }, [brandList, search, sorting]);

  const clear = () => {
    setCurrentId(0);
    setBrand({ name: "", company: "", type: "" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Brand : ", { brand });
    const mobileRegex = /^(\+91)?[1-9][0-9]{9}$/;
    const gestinRegex =
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;

    const {
      name,
      company,
      type,
      hqAddress,
      gst,
      brandPOC,
      billingPOC,
      status,
      logos,
    } = brand;
    let brandData = new FormData();
    brandData.append("name", name);
    brandData.append("company", company);
    brandData.append("type", type);
    brandData.append("hqAddress", JSON.stringify(hqAddress));
    brandData.append("gst", gst);
    brandData.append("brandPOC", JSON.stringify(brandPOC));
    brandData.append("billingPOC", JSON.stringify(billingPOC));
    brandData.append("status", status);
    logos && brandData.append("logos", logos);

    try {
      if (currentId === 0) {
        if (brand.gst.length != 15 || gestinRegex.test(brand.gst) == false) {
          toast.error("GSTN should be unique 15 digit number");
          return;
        }
        if (mobileRegex.test(brand.billingPOC.mobile) == false) {
          toast.error(
            "Mobile number should be 10 digits with or without prefix +91"
          );
          return;
        }
        if (mobileRegex.test(brand.brandPOC.mobile) == false) {
          toast.error(
            "Mobile number should be 10 digits with or without prefix +91"
          );
          return;
        }

        await createNewBrand(token, brandData);
        fetchAllBrands();
        toast.success(`New Brand ${brand.name} is added Successfully...`);
        clear();
        handleClose();
      } else {
        setConfirmDialog({
          isOpen: true,
          title: "Are you sure to Update this record?",
          subTitle: "You can't undo this operation",
          onConfirm: async () => {
            setConfirmDialog({
              ...confirmDialog,
              isOpen: false,
            });

            //  console.log("brandData ==> ", brandData);

            await updateBrand(token, brandData, currentId)
              .then(() => {
                fetchAllBrands();
                toast.success(`Brand ${brand.name} is updated Successfully...`);
                clear();
                handleClose();
              })
              .catch((err) => toast.error(err.response.data.errorMessage));
          },
        });
      }
    } catch (err) {
      toast.error(err.response.data.errorMessage);
    }
  };

  const handleBrandDelete = async (brandId) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    deleteBrand(token, brandId)
      .then((res) => {
        toast.success("Brand Deleted successfully...");
        fetchAllBrands();
      })
      .catch((err) => toast.error(err.response.data.errorMessage));
  };

  // Get current rows
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = brandsData?.slice(indexOfFirstRow, indexOfLastRow);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div id="layoutSidenav_content">
      <div className="container-fluid">
        <h2 className="mt-25 page-title">Brands</h2>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Dashboard</Link>
          </li>
          <li className="breadcrumb-item active">Brands</li>
        </ol>
        <div className="col-lg-12 col-md-12 back-btn">
          <button
            className="save-btn hover-btn"
            onClick={() => {
              setCurrentId(0);
              clear();
              handleShow();
            }}
          >
            + Add New Brand
          </button>
          <BrandForm
            brand={brand}
            setBrand={setBrand}
            handleSubmit={handleSubmit}
            currentId={currentId}
            show={show}
            handleClose={handleClose}
          />
        </div>
        <TableSearch
          onSearch={(value) => {
            setSearch(value);
          }}
          placeholder="Search by Brand Name"
        />
        <div className="row">
          <BrandTable
            brandList={currentRows}
            setCurrentId={setCurrentId}
            handleBrandDelete={handleBrandDelete}
            setConfirmDialog={setConfirmDialog}
            setSorting={setSorting}
            handleShow={handleShow}
          />
          <Pagination
            rowsPerPage={rowsPerPage}
            totalRows={brandList?.brands?.length}
            paginate={paginate}
            setRowsPerPage={setRowsPerPage}
            url="/catalog/brands"
          />
        </div>
      </div>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </div>
  );
};

export default CreateBrand;
