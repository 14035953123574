import { Modal } from "react-bootstrap";
import { components } from "react-select";
import Select from "react-select";
import DeleteIcon from "@material-ui/icons/Delete";


const CRITERIA_OPTIONS = {
  gender: [{label: 'Men', value: "Men"}, { label: "Women", value: 'Women'}],
    // area: [{label: "Rural", value: 'Rural'},{label: "Urban", value: 'Urban'}]
  };
const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          value={props.value}
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
         <label>{props.label}</label>
      </components.Option>
    </div>
  );
};


const ServiceCategoryForm = ({setServiceCategory,businessIdeas, handleSubmit,currentId,show,handleClose,handleImageChange,handleChange,
                                serviceCategory,setSelectedBusinessIdeas,selectedBusinessIdeas}) => {

let businessIdeasObj = businessIdeas.reduce((prev, curr)=>{prev[curr._id]=curr.inEnglish; return prev;},{});
let {name, business_idea_ids=[] , criteria, representative_icon } = serviceCategory;
business_idea_ids = (business_idea_ids || []).map(x=>({label: businessIdeasObj[x], value: x }));

selectedBusinessIdeas = business_idea_ids

Object.keys(criteria).forEach(cri=>{
if(criteria[cri].length && typeof criteria[cri][0]== 'string'){
  criteria[cri] = criteria[cri].map(val=>({value:val, label: val}));
}
}) 
const handleOptionChange = (selected) => {
  setSelectedBusinessIdeas(selected);
  let ideasId = selected.map(data => data.value);
  setServiceCategory({...serviceCategory,business_idea_ids:ideasId});
};

const handleOptionChange2 = (selected,label) => {
  setServiceCategory({...serviceCategory,criteria :{ ...criteria , [label]:selected }});
};

  return (
    <Modal show={show} onHide={handleClose} size="md">
      <div>
        <Modal.Header closeButton data-dismiss="modal">
          <h5 className="modal-title" id="exampleModalLabel">
            {currentId ? "Update Service Categories" : "+ Service Categories"}
          </h5>
        </Modal.Header>
        <Modal.Body>
        <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="card card-static-2 mb-30">
                  <div className="card-body-table">
                    <div className="news-content-right pd-20">
                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-lg-12 col-md-12">
                            <div className="form-group">
                              <label htmlFor="name" className="form-label">
                                Service Category Name
                                <span className="required">&#42;</span>
                              </label>
                              <input type="text" className="form-control" placeholder="Service Category Name" required name="name"
                                value={name}
                                onChange={handleChange}
                              />
                            </div>
                          </div>

                          <div className="col-lg-12 col-md-12" style={{ paddingTop: "1rem" }}>
                            <div className="form-group">
                              <label htmlFor="name" className="form-label">
                                Criteria
                                <span className="required">&#42;</span>
                              </label>
                              </div>
                                </div>
                                
                              {
                                Object.keys(CRITERIA_OPTIONS).map(option => {
                                 return (
                                  <> 
                                  <div className="col-lg-10 col-md-10" style={{ paddingTop: "1rem" ,marginLeft: '1rem' }}>
                                    <div className="form-group">
                                  <label htmlFor={option} className="form-label" style={{textTransform:'capitalize'}} >
                                {option}
                                 <span className="required">&#42;</span>
                               </label>
                               <Select 
                                  options={CRITERIA_OPTIONS[option]} 
                                  isSearchable 
                                  components={{ Option}} 
                                  isMulti 
                                  value={criteria?.[`${option}`] || []} 
                                  onChange={(selected)=> handleOptionChange2(selected,option)} />
                                  </div>
                                  </div>
                                   </>
                                 )
                                })
                              }
                              
                            
                          
                          <div className="col-lg-12 col-md-12" style={{ paddingTop: "1rem" }}>
                            <div className="form-group">
                              <label htmlFor="business_idea_ids" className="form-label">
                                Business Ideas<span className="required">&#42;</span>
                              </label>
                                 <Select 
                                 options={businessIdeas.map(x=>({...x, label:x.inEnglish, value:x._id}))} 
                                 isSearchable 
                                 components={{ Option}} 
                                 isMulti 
                                 value={selectedBusinessIdeas} 
                                 onChange={handleOptionChange} />
                            </div>
                          </div>
                          <div className="col-lg-12 col-md-11" style={{ paddingTop: "1rem" }}>
                            <div className="form-group">
                              <label htmlFor="representative_icon" className="form-label">
                                Logo  
                              </label>
                              { (typeof representative_icon === 'string') && representative_icon !== "" && currentId !== 0  ? <div className="cate-img-5" >
                                <img src={`${representative_icon}`} alt="product_img" /> 
                                <DeleteIcon
                              style={{ cursor: "pointer",color:'red',marginLeft:'121px',marginTop:'20px' }}
                              onClick={() => {setServiceCategory({...serviceCategory,representative_icon:""})}}
                            />
                            </div>  :<input
                                type="file"
                                className="form-control"
                                name="representative_icon"
                                onChange={handleImageChange}
                                accept="image/*"
                              />}
                              {/* {representative_icon && ()} */}
                            </div>
                          </div>

                          <button className="save-btn hover-btn" type="submit" style={{marginTop:'40px'}}>
                            {currentId ? "Update" : "Add New"}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default ServiceCategoryForm ;
