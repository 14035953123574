import { useEffect } from "react";
const OrderItemsTable = ({ bag }) => {
  const items = bag?.items;
  const status = bag?.status;
  useEffect(() => {
    console.log(bag);
  });
  return (
    <div className="col-lg-12 col-md-11">
      <div className="all-cate-tags">
        <div className="row justify- content-between">
          <div className="col-lg-12 col-md-12">
            <div className="card card-static-2 mb-30">
              <div className="card-body-table">
                <div className="table-responsive">
                  <table className="table ucp-table table-hover">
                    <thead>
                      <tr>
                        <th>Variant ID</th>
                        <th>Variant Name</th>
                        <th>Status</th>
                        <th>Quantity</th>
                        <th>Unit Price (INR)</th>
                        <th>Total Cost (INR)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {items?.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{item.variantId}</td>
                            <td>{item.name}</td>
                            <td>{status}</td>
                            <td>{item.quantity}</td>
                            <td>{item.unitPrice}</td>

                            <td>{item.totalPrice}</td>
                          </tr>
                        );
                      })}
                      <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>SubTotal</th>
                        <th>{bag?.subtotal || "-"}</th>
                      </tr>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>Discount</td>
                        <td>{bag?.discount || "-"}</td>
                      </tr>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>Tax</td>
                        <td>{bag?.tax || "-"}</td>
                      </tr>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>Delivery Charge</td>
                        <td>{bag?.deliveryCharge || "-"}</td>
                      </tr>

                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>Packaging Charge</td>
                        <td>{bag?.packagingCharge || "-"}</td>
                      </tr>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>Tip</td>
                        <td>{bag?.tip || "-"}</td>
                      </tr>

                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>

                        <th>Total Items</th>
                        <th>{bag?.totalItems || "-"}</th>
                      </tr>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>

                        <th>Total Payable</th>
                        <th>{bag?.totalValue || "-"}</th>
                      </tr>

                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>Total Received through digital payment</td>
                        <td>{bag?.totalDigitalPayment || "-"}</td>
                      </tr>

                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>Total Received through cash</td>
                        <td>{bag?.totalCash || "-"}</td>
                      </tr>

                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>Total Received through Homie Wallet</td>
                        <td>{bag?.totalReceivedHomie || "-"}</td>
                      </tr>

                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>Total Received through Coins</td>
                        <td>{bag?.totalRecievedCoins || "-"}</td>
                      </tr>
                      <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>

                        <th>Pending Payment</th>
                        <th>{bag?.pendingPayment || "-"}</th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderItemsTable;
