import { Link } from "react-router-dom";
import moment from "moment";

const OrderHistoryTable = ({ orders }) => {
  const consumerId = orders[0]?.consumerId;

  return (
    <div className="col-lg-12 col-md-11">
      <div className="all-cate-tags">
        <div className="row justify- content-between">
          <div className="col-lg-12 col-md-12">
            <div className="card card-static-2 mb-30">
              <div className="card-body-table">
                <div className="table-responsive">
                  <table className="table ucp-table table-hover">
                    <thead>
                      <tr>
                        <th style={{ minWidth: "120px" }}>Order ID</th>
                        <th style={{ minWidth: "120px" }}>Order Datetime</th>
                        <th>Order components</th>
                        {/* <th>Order Value (INR)</th> */}
                        <th>Order Status</th>
                        {/* <th>Time to delivery (hours)</th> */}
                        <th style={{ minWidth: "150px" }}>Selected Slot</th>
                        {/* <th>Complete/Partial/Pending/No Delivery</th> */}
                        <th>Total Items Fulfilled</th>
                        <th>Order Value Fulfilled (INR)</th>
                        <th style={{ minWidth: "200px" }}>Delivery Address</th>
                        <th>Assigned to</th>
                        <th>Payment Method</th>
                        {/* <th>Payment Status</th>
                        <th>Wallet Sum used</th>
                        <th>Coins used</th>
                        <th>Order Rating</th>
                        <th>Wallet Refund</th>
                        <th>Wallet Balance</th>
                        <th>Coin Balance</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {orders?.map((order, index) => (
                        <>
                          <tr key={index}>
                            <td>{order._id}</td>
                            <td>
                              {moment(order.createdAt).format("lll") || "-"}
                            </td>

                            <Link to={`/consumers/${consumerId}/${order._id}`}>
                              <td className="text-primary">VIEW</td>
                            </Link>
                            {/* <td>{order.orderValue}</td> */}
                            <td>{order.status}</td>
                            {/* <td>{order.timeToDelivery}</td> */}
                            <td>
                              {moment(order.selectedSlot.date).format("lll") +
                                ", " +
                                order.selectedSlot.timeSlot}
                            </td>
                            {/* <td>{order.deliveryStatus}</td> */}
                            <td>{order.totalItemsFulfilled}</td>
                            <td>{order.totalValueFulfilled}</td>
                            <td>
                              {order.deliveryAddress.address.completeAddress +
                                ", " +
                                order.deliveryAddress.address.district +
                                ", " +
                                order.deliveryAddress.address.state}
                            </td>
                            <td>{order.entrepreneurId}</td>
                            <td>{order.paymentMethod}</td>
                            {/* <td>{order.paymentStatus}</td>
                            <td>{order.walletSumUsed}</td>
                            <td>{order.coinsUsed}</td>
                            <td>{order.orderRating}</td>
                            <td>{order.walletRefund}</td>
                            <td>{order.walletBalance}</td>
                            <td>{order.coinBalance}</td> */}
                          </tr>
                        </>
                      ))}
                      {/* <tr className="bold-table-text">
                        <th>Grand Total </th>
                        <th></th>
                        <th></th>

                        <th>1870</th>
                        <th></th>
                        <th></th>
                        <th></th>

                        <th>1275</th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>

                        <th>0</th>
                        <th>0</th>
                        <th>3</th>
                        <th>100</th>
                        <th>100</th>
                        <th>0</th>
                      </tr> */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderHistoryTable;
