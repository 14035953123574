/* eslint-disable react/jsx-no-undef */
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  addZone,
  getAllZonesByCityId,
  updateZoneById,
  deleteZoneById,
} from "../../../actions/zoneAction";
import ConfirmDialog from "../../0-ResuableComponents/confirmDialog/ConfirmDialog";
import Map from "../map/Map";
import AddZoneForm from "./AddZoneForm";
import TradeForm from "./TradeForm";
import ZoneForm from "./ZoneForm";
import EditCoordinates from "./EditCoordinates";
import ZoneTable from "./ZoneTable";

const Zones = () => {
  const { auth } = useSelector((state) => ({ ...state }));
  const { token } = auth;
  const { id } = useParams();
  const [showMap, setShowMap] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [zoneList, setZoneList] = useState(null);
  const [coordinates, setCoordinates] = useState([]);
  const [zones, setZones] = useState(null);
  const [finalData, setFinalData] = useState([]);
  const [currentZone, setCurrentZone] = useState(null);
  const [showEditZoneModal, setShowEditZoneModal] = useState(false);
  const [showEditTradesModal, setShowEditTradesModal] = useState(false);
  const [selectedZone, setSelectedZone] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showTrade, setShowTrade] = useState(false);
  const handleCloseTrade = () => setShowTrade(false);
  const handleShowTrade = () => setShowTrade(true);

  useEffect(() => {
    if (currentZone) {
      setUpdateDetails({
        area: currentZone?.area,
        status: currentZone?.status,
        estimatedFamilies: currentZone?.estimatedFamilies,
        coordinates: currentZone?.coordinates,
        trades: currentZone?.trades,
      });
    }
  }, [currentZone, selectedZone]);

  useEffect(() => {}, [coordinates, selectedZone]);

  useEffect(() => {
    setFinalData([...finalData, { zones, coordinates }]);
  }, [zones]);

  const fetchAllZones = async () => {
    const zones = await getAllZonesByCityId(token, id);
    setZoneList(zones?.data?.zones?.reverse());
  };

  useEffect(() => {
    fetchAllZones();
  }, []);

  useEffect(() => {
    if (zoneList) {
      zoneList.forEach((zone) => {
        if (zone.coordinates) {
          setCoordinates([...coordinates, zone.coordinates]);
        }
      });
    }
  }, [zoneList]);

  const [newZone, setNewZone] = useState({
    area: "",
    status: "",
    estimatedFamilies: "",
    coordinates: [],
    trades: "[]",
  });

  const [updateDetails, setUpdateDetails] = useState({
    area: "",
    status: "",
    estimatedFamilies: "",
    coordinates: [],
    trades: [],
  });

  const handleAddZone = async () => {
    try {
      await addZone(token, newZone, id);
      fetchAllZones();
      toast.success(`zone added successfully...`);
      // window.location.reload();
    } catch (err) {
      toast.error(err.response.data.errorMessage);
    }
  };

  const handleUpdateZone = async (zoneToUpdate, zoneId) => {
    const updateZone = zoneToUpdate;
    updateZone.coordinates = JSON.stringify(zoneToUpdate.coordinates);
    updateZone.trades = JSON.stringify(zoneToUpdate.trades);
    // console.log("UPDATE ZONE HERE ", updateZone);

    try {
      await updateZoneById(token, updateZone, updateZone._id);
      fetchAllZones();
      toast.success("Zone updated successfully...");
      setShowEditZoneModal(false);
      setShowEditTradesModal(false);
    } catch (err) {
      toast.error(err.response.data.errorMessage);
    }
  };

  const handleEditZoneDisplay = (zone) => {
    setSelectedZone(zone);
    setShowEditZoneModal(true);
  };

  const handleEditZones = (zone) => {
    setSelectedZone(zone);
    setShowEditTradesModal(true);
  };

  const handleZoneDelete = async (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    try {
      await deleteZoneById(token, id);
      fetchAllZones();
      toast.success("Zone deleted successfully");
    } catch (err) {
      toast.error(err.response.data.errorMessage);
    }
  };

  return (
    <div id="layoutSidenav_content">
      <div className="container-fluid">
        <h2 className="mt-25 page-title">Zones</h2>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Dashboard</Link>
          </li>
          <li className="breadcrumb-item active">Zones</li>
        </ol>
        <div className="col-lg-12 col-md-12 back-btn mb-30">
          <button
            className="save-btn hover-btn"
            onClick={() => {
              setShowMap(!showMap);
              setShowForm(false);
            }}
          >
            SHOW MAP
          </button>{" "}
          <button
            className="save-btn hover-btn"
            onClick={() => {
              setShowMap(false);
              setShowForm(!showForm);
            }}
          >
            + Add New Zone
          </button>
          {showMap ? (
            <Map coordinates={coordinates} zoneList={zoneList} />
          ) : (
            <></>
          )}
          <div>
            {showForm ? (
              <AddZoneForm
                setShowMap={setShowMap}
                setShowForm={setShowForm}
                setNewZone={setNewZone}
                newZone={newZone}
                handleAddZone={handleAddZone}
              />
            ) : null}
          </div>
        </div>
        {currentZone ? <TradeForm zone={currentZone} /> : null}
        <ZoneForm
          handleSubmit={handleUpdateZone}
          setUpdateDetails={setUpdateDetails}
          updateDetails={updateDetails}
          currentZone={currentZone}
          setCurrentZone={setCurrentZone}
        />
        <div className="row">
          <ZoneTable
            zoneList={zoneList}
            handleZoneDelete={handleZoneDelete}
            setConfirmDialog={setConfirmDialog}
            setCurrentZone={setCurrentZone}
            coordinates={coordinates}
            displayEditZoneModal={(data) => handleEditZoneDisplay(data)}
            displayEditTradesModal={(data) => handleEditZones(data)}
            handleShow={handleShow}
            handleShowTrade={handleShowTrade}
            setSelectedZone={setSelectedZone}
          />
        </div>
      </div>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      {showEditZoneModal && selectedZone && (
        <EditCoordinates
          showEditZone={showEditZoneModal}
          coordinates={coordinates}
          selectedZone={selectedZone}
          handleUpdateZone={handleUpdateZone}
          show={show}
          handleClose={handleClose}
        />
      )}
      {showEditTradesModal && selectedZone && (
        <TradeForm
          showEditTrades={showEditTradesModal}
          selectedZone={selectedZone}
          handleUpdateZone={handleUpdateZone}
          show={showTrade}
          handleClose={handleCloseTrade}
        />
      )}
    </div>
  );
};

export default Zones;
