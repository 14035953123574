/* eslint-disable no-useless-escape */
import * as Yup from "yup";

export const validationSchema = Yup.object({
  couponCode: Yup.string()
    .required("Required")
    .length(12, "Must be exactly 12 characters"),
  couponType: Yup.string().required("Required"),
  couponDescription: Yup.string().required("Required"),
  maxDiscount: Yup.string()
    .required("Required")
    .matches(/^([1-9][0-9]{0,2}|999)$/, "Value must be between 1 to 1000"),
  discountPercentage: Yup.string().matches(
    /^(\b([1-9]|[1-9][0-9]|99)\b)$/,
    "Value must be between 1 to 100"
  ),
  validFrom: Yup.string().required("Required"),
  validTill: Yup.date().required("Required"),
  tradeIds: Yup.array().min(1, "select at-least one Trade"),
  categoryIds: Yup.array().min(1, "select at-least one category"),
  subCategoryIds: Yup.array().min(1, "select at-least one sub-Category"),
  productIds: Yup.array().min(1, "select at-least one product"),
  brandIds: Yup.array().min(1, "select at-least one brand"),
  states: Yup.array().min(1, "select at-least one state"),
  cities: Yup.array().min(1, "select at-least one city"),
  clusters: Yup.array().min(1, "select at-least one cluster"),
  status: Yup.string().required("Required"),
});
