import { Link } from "react-router-dom";
import moment from "moment";
import { useState, useMemo, useCallback } from "react";
import TableHeader from "../0-ResuableComponents/tableComponents/TableHeader";
import { consumerData } from "../../actions/consumerAction";
import { useSelector } from "react-redux";
//import { DUMMY_ORDER_DATA_Arr } from "../../constants/DummyData";
import { useEffect } from "react";

const headers = [
  { name: "Order ID", field: "orderID", sortable: true },
  { name: "Order Time", field: "orderedDate", sortable: false },
  { name: "Ordered By", field: "consumerInfo", sortable: false },
  { name: "Order Status", field: "orderStatus", sortable: false },
  { name: "Order Amount", field: "orderAmount", sortable: false },
  { name: "GST", field: "gstForOrderItem", sortable: false },
  { name: "Order with GST", field: "orderItemValueWithGST", sortable: false },

  { name: "Discount Code", field: "discountCode", sortable: false },

  { name: "Payment Method", field: "paymentMode", sortable: false },
  // { name: "State", field: "state", sortable: false },
  // { name: "City", field: "city", sortable: false },
  // { name: "Cluster", field: "cluster", sortable: false },
  // { name: "Assigned to", field: "assignedTo", sortable: false },
];

const NewOrderTable = ({ orders, setSorting }) => {
  const { auth } = useSelector((state) => ({ ...state }));

  const [namee, setNamee] = useState("");
  const [singleName, setSingleName] = useState("");

  const consumerName = async (id) => {
    const res = await consumerData(auth.token, id);

    let name = res?.data?.consumer[0]?.name;
    // console.log("this is name inside consumername ==>> ", name);
    setNamee(name);
    // return name;
  };
  // const tdname = (id) => {
  //   console.log("id => ", id);

  //   consumerName(id);
  //   let name = namee;
  //   console.log("name => ", name);
  //   return name;
  // };

  const calculateTotalValueWithoutGST = (order) => {
    let totalValueWithoutGST = 0;
    order.orderList.forEach((orderItem) => {
      totalValueWithoutGST += orderItem.units * orderItem.unitPrice;
    });
    return totalValueWithoutGST;
  };

  const calculateTotalGST = (order) => {
    let totalGST = 0;
    order.orderList.forEach((orderItem) => {
      totalGST +=
        (orderItem.units * orderItem.unitPrice * orderItem.gstForOrderItem) /
        100;
    });
    return totalGST;
  };

  const calculateTotalValueWithGST = (order) => {
    const totalValueWithoutGST = calculateTotalValueWithoutGST(order);
    const totalGST = calculateTotalGST(order);
    return totalValueWithoutGST + totalGST;
  };

  return (
    <div className="col-lg-auto col-md-auto">
      <div className="all-cate-tags">
        <div className="row justify-content-between">
          <div className="col-lg-12 col-md-12">
            <div className="card card-static-2 mb-30">
              <div className="card-title-2">
                <h4>All New Orders</h4>
              </div>
              <div className="card-body-table">
                <div className="table-responsive">
                  <table
                    id="order-table"
                    className="table ucp-table table-hover"
                  >
                    <TableHeader
                      headers={headers}
                      onSorting={(field, order) => setSorting({ field, order })}
                    />

                    {orders?.map((order, index) => (
                      <tbody key={order._id}>
                        <tr>
                          <td>
                            <Link
                              to={`newconsumers/${order?.consumer?._id}/${order?._id}`}
                            >
                              {order?.orderId}
                            </Link>
                          </td>

                          <td>
                            {order?.orderedDate
                              ? moment(order?.orderedDate).format("lll")
                              : "-"}
                          </td>
                          <td>
                            <Link to={`/newconsumers/${order.consumer._id}`}>
                              {/* <span>{tdname(order.consumerId)}</span> -- */}
                              <span>
                                {order?.consumer?.name?.length > 0
                                  ? order?.consumer?.name
                                  : "-"}
                              </span>
                            </Link>
                          </td>
                          <td>{order?.status || "-"}</td>
                          <td>{order?.orderValueWithoutGst || "-"}</td>
                          <td>{order?.gstValue || "-"}</td>
                          <td>{order?.totalOrderValue}</td>
                          <td>{order?.discount?.discountCode || "-"}</td>

                          <td>{order?.paymentMode || "-"}</td>
                        </tr>
                      </tbody>
                    ))}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewOrderTable;
