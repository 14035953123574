
const AddressTable = () => {

    return (
        <div className="col-lg-12 col-md-11">
            <div className="all-cate-tags">
                <div className="row justify- content-between">
                    <div className="col-lg-12 col-md-12">
                        <div className="card card-static-2 mb-30">
                            <div className="card-body-table">
                                <div className="table-responsive">
                                    <table className="table ucp-table table-hover">
                                        <thead>
                                            <tr>
                                                <th>S.No.</th>
                                                <th>Delivery Address</th>
                                                <th>City</th>
                                                <th>State</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>H.No. XYZ/2A, Street no.1, near landmarkX </td>
                                                <td>CityM</td>
                                                <td>StateN</td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>H.No. ABC/11, Street no.2, near landmarkY </td>
                                                <td>CityJ</td>
                                                <td>StateK</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddressTable;
