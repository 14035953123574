/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useHistory, Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import EditCouponForm from "./EditCouponForm";
import { singleCoupon, updateCoupon } from "../../../actions/couponActions";
import { allStates, stateCities } from "../../../actions/entrepreneurActions";
import { allTrades } from "../../../actions/tradeActions";
import { CategoriesByTrade } from "../../../actions/categoryActions";
import { allSubcategoriesByCategory } from "../../../actions/subCategoryActions";
import { productsBySubcategory } from "../../../actions/productActions";
import { allBrands } from "../../../actions/brandActions";
import ConfirmDialog from "../../0-ResuableComponents/confirmDialog/ConfirmDialog";

const initialValues = {
  couponCode: "",
  couponType: "",
  couponDescription: "",
  maxDiscount: "",
  discountPercentage: "",
  validFrom: "",
  validTill: "",
  tradeIds: [],
  categoryIds: [],
  subCategoryIds: [],
  productIds: [],
  brandIds: [],
  states: [],
  cities: [],
  clusters: [],
  status: "Active",
};

const EditCoupon = ({ match }) => {
  const [formValues, setFormValues] = useState(initialValues);
  const { auth } = useSelector((state) => ({ ...state }));
  const { token } = auth;
  const [state, setState] = useState(formValues.states);
  const [tradeId, setTradeId] = useState(formValues.tradeIds);
  const [categoryId, setCategoryId] = useState(formValues.categoryIds);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [tradeList, setTradeList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [subCategoryId, setSubCategoryId] = useState(formValues.subCategoryIds);
  const [productList, setProductList] = useState([]);
  const [productId, setProductId] = useState(formValues.productIds);
  const [brandList, setBrandList] = useState([]);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const history = useHistory();

  const fetchCoupon = async () => {
    let res = await singleCoupon(token, match.params.couponId);
    setFormValues(res.data.coupon);
    setState(res.data.coupon.states);
    setTradeId(res.data.coupon.tradeIds);
    setCategoryId(res.data.coupon.categoryIds);
    setSubCategoryId(res.data.coupon.subCategoryIds);
    setProductId(res.data.coupon.categoryIds);
  };

  const redirect = () => {
    history.push("/coupons");
  };

  const fetchAllStates = async () => {
    let res = await allStates(token);
    setStateList(res.data.states);
  };

  const fetchAllCities = async () => {
    if (!state) return;

    const promises = state?.map((item, idx) => {
      return stateCities(token, state[idx]).then((response) => {
        return response.data.cities;
      });
    });

    Promise.all(promises).then((results) => {
      const cities = results.map((result) => result);
      setCityList(cities.filter((city) => city.length > 0));
    });
  };

  const fetchAllTrades = async () => {
    let res = await allTrades(token);
    setTradeList(res.data.trades);
  };

  const fetchCategories = async () => {
    if (!tradeId) return;

    const promises = tradeId?.map((item, idx) => {
      return CategoriesByTrade(token, tradeId[idx]).then((response) => {
        return response.data.categories;
      });
    });

    Promise.all(promises).then((results) => {
      const categories = results.map((result) => result);
      setCategoryList(categories.filter((category) => category.length > 0));
    });
  };

  const fetchSubCategoriesByCategories = () => {
    if (!categoryId) return;
    const promises = categoryId?.map((item, idx) => {
      return allSubcategoriesByCategory(token, categoryId[idx]).then((res) => {
        return res.data.subcategories;
      });
    });

    Promise.all(promises).then((results) => {
      const subcategories = results.map((result) => result);
      setSubCategoryList(
        subcategories.filter((subcategory) => subcategory.length > 0)
      );
    });
  };

  const fetchProductsBySubCategories = () => {
    if (!subCategoryId) return;
    const promises = subCategoryId?.map((item, idx) => {
      return productsBySubcategory(token, subCategoryId[idx]).then((res) => {
        return res.data.products;
      });
    });

    Promise.all(promises).then((results) => {
      const products = results.map((result) => result);
      setProductList(products.filter((product) => product.length > 0));
    });
  };

  const fetchAllBrands = async () => {
    if (!productId) return;
    let res = await allBrands(token);
    setBrandList(res.data.brands);
  };

  useEffect(() => {
    fetchCoupon();
  }, []);

  useEffect(() => {
    fetchAllTrades();
    fetchCategories();
    fetchSubCategoriesByCategories();
    fetchProductsBySubCategories();
  }, [tradeId, categoryId, subCategoryId]);

  useEffect(() => {
    fetchAllBrands();
  }, [productId]);

  useEffect(() => {
    fetchAllStates();
    fetchAllCities();
  }, [state]);

  const onSubmit = async (values, onSubmitProps) => {
    // console.log("values===>", values);
    let {
      tradeIds,
      categoryIds,
      subCategoryIds,
      productIds,
      brandIds,
      states,
      cities,
      clusters,
    } = values;

    let couponData = new FormData();
    couponData.append("couponCode", values.couponCode);
    couponData.append("couponType", values.couponType);
    couponData.append("couponDescription", values.couponDescription);
    couponData.append("maxDiscount", values.maxDiscount || "");
    values.couponType === "Percentage Discount" &&
      couponData.append("discountPercentage", values.discountPercentage || "");
    values.validFrom && couponData.append("validFrom", values.validFrom);
    values.validTill && couponData.append("validTill", values.validTill);
    couponData.append("status", values.status);
    couponData.append("tradeIds", JSON.stringify(tradeIds));
    couponData.append("categoryIds", JSON.stringify(categoryIds));
    couponData.append("subCategoryIds", JSON.stringify(subCategoryIds));
    couponData.append("productIds", JSON.stringify(productIds));
    couponData.append("brandIds", JSON.stringify(brandIds));
    couponData.append("states", JSON.stringify(states));
    couponData.append("cities", JSON.stringify(cities));
    couponData.append("clusters", JSON.stringify(clusters));

    setConfirmDialog({
      isOpen: true,
      title: "Are you sure to Update this record?",
      subTitle: "You can't undo this operation",
      onConfirm: async () => {
        setConfirmDialog({
          ...confirmDialog,
          isOpen: false,
        });
        await updateCoupon(token, couponData, match.params.couponId)
          .then((res) => {
            toast.success("Updated successfully...");
            onSubmitProps.setSubmitting(false);
            onSubmitProps.resetForm();
            history.push("/coupons");
          })
          .catch((err) => toast.error(err.response.data.errorMessage));
      },
    });
  };

  return (
    <div id="layoutSidenav_content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6">
            <h2 className="mt-30 page-title">Coupon</h2>
          </div>
          <div className="col-md-6 back-btn">
            <Button variant="contained" onClick={redirect}>
              <i className="fas fa-arrow-left" /> Back
            </Button>
          </div>
        </div>
        <ol className="breadcrumb mb-30">
          <li className="breadcrumb-item">
            <Link to="/dashboard">Dashboard</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/coupons">Coupons</Link>
          </li>
          <li className="breadcrumb-item active">Update Coupon</li>
        </ol>
        <EditCouponForm
          initialValues={initialValues}
          formValues={formValues}
          onSubmit={onSubmit}
          stateList={stateList}
          tradeList={tradeList}
          cityList={cityList}
          setState={setState}
          setTradeId={setTradeId}
          categoryList={categoryList}
          setCategoryId={setCategoryId}
          subCategoryList={subCategoryList}
          productList={productList}
          setSubCategoryId={setSubCategoryId}
          setProductId={setProductId}
          brandList={brandList}
        />
      </div>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </div>
  );
};

export default EditCoupon;
